import React from 'react';
import {useNavigate} from 'react-router-dom';

import Button from '../../Components/LandingPage/Button';
import NavbarLayout from '../../Layouts/NavbarLayout';
import Congratulations from '../../assets/icons/Congratulations.svg';

const ResetPasswordAcknowledgement = () => {
  const navigate = useNavigate();
  return (
    <>
      <NavbarLayout>
        <div className="block lg:inline-block mt-4 lg:mt-0 lg:ml-4">
          <Button label="Help" bg="plannaPrimaryGreen" text="white" />
        </div>
      </NavbarLayout>
      <div className="h-screen flex flex-col justify-center items-center">
        <img
          src={Congratulations}
          alt="congratulations"
          className="h-fit w-fit"
        />
        <h1 className="text-plannaPrimaryGreen mt-4 font-bold">
          Password updated successfully!!
        </h1>
        <p className="text-plannaNeutral mt-2">
          You can now login into your account
        </p>

        <div className="mt-6">
          <button
            onClick={() => navigate('/login')}
            className="bg-plannaPrimaryGreen rounded-lg text-plannaAccentGreen px-6 py-2">
            Login into your account
          </button>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordAcknowledgement;
