// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import axiosInstance from '../../AxiosInstance';

type Project = {
  title: string;
  uid: string;
  description: string;
  start_date: string;
  end_date: string;
  type: string;
  rate: string;
  additional_links: string;
  workers_count: number;
  rate_type: string;
};
interface ApiResponse {
  // Define the structure of your API response
  // Example: { data: YourDataType }
  results: Project[];
}
interface ProjectShiftOptions {
  uid?: string;
}

const fetchData = async (
  options: ProjectShiftOptions
): Promise<ApiResponse> => {
  const {uid} = options;
  const queryParams = new URLSearchParams({
    limit: '10',
  });
  if (uid !== undefined && uid !== '') {
    queryParams.append('uid', uid);
  }
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `/projects/?${queryParams}`
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetProjects = (
  options: ProjectShiftOptions
): UseQueryResult<ApiResponse, Error> => {
  const {uid} = options;
  return useQuery(['projects', uid], () => fetchData(options));
};

export default useGetProjects;
