import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

interface EditAgencyUserParams {
  first_name: string;
  last_name: string;
  email: string;
  position: string;
  location: string;
  user_permission: string;
  uid: string;
}

const editAgencyUser = async (data: EditAgencyUserParams) => {
  const response = await axiosInstance.patch(
    `agency/agency-users/${data.uid}/`,
    data
  );

  return response;
};

const useEditAgencyUser = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  EditAgencyUserParams
> => {
  const queryClient = useQueryClient();

  return useMutation(editAgencyUser, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('agencyUsers')
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useEditAgencyUser;
