import React from 'react';

import ShiftApprovals from './Components/ShiftApprovals';
import TimeSheets from './Components/Timesheets';
import Tabs from '../Home/Components/Tabs';

const AgencyAttendance = () => {
  const tabs = [
    {
      id: 'tab2',
      label: 'Shift Approvals',
      component: <ShiftApprovals />,
    },
    {id: 'tab3', label: 'Timesheets', component: <TimeSheets />},
  ];
  return (
    <div className="p-4">
      <div className="mt-8">
        <h1 className="text-black font-bold text-xl">Planned shifts</h1>
        <p className="text-black mt-2 text-sm">
          View shift information with real-time insights and streamlined views
          on worker attendance
        </p>
        <div className="mt-8">
          <Tabs tabs={tabs} />
        </div>
      </div>
    </div>
  );
};

export default AgencyAttendance;
