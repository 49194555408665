import moment from 'moment';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../../Components/Common/Spinner';
import SubmitSpinner from '../../../Components/Common/SubmitSpinner';
import SuccessLayout from '../../../Layouts/SuccessLayout';
import useGetAgencyProfile from '../../../hooks/services/Agencys/useGetAgencyProfile';
import useApproveWorkerShifts from '../../../hooks/services/Shifts/useApproveWorkerShifts';
import useGetShiftApprovals from '../../../hooks/services/Shifts/useGetWorkerShiftApproval';
import {convertDecimalHoursToHoursAndMinutes} from '../../../utils/Methods';

const ShiftApprovals = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile();
  const [approvalStatus, setApprovalStatus] = useState('');
  const [status, setStatus] = useState<string>('PENDING_AGENCY_APPROVAL');
  // eslint-disable-next-line
  const {data: approvals, isLoading: approvalsLoading} = useGetShiftApprovals(
    status,
    profile !== undefined ? profile.uid : '',
    'worker_shift__worker__agency__uid'
  );
  const {mutateAsync: approveWorkerMutatiom, isLoading: approveWorkerLoading} =
    useApproveWorkerShifts();
  const [shifts, setShifts] = useState<string[]>([]);

  // Function to toggle selection and update state
  const toggleCheckbox = (id: string) => {
    setShifts((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };
  if (approvalsLoading || profileLoading) {
    return <Spinner />;
  }
  const onApproveShift = async () => {
    const post = {
      worker_shift_approval_uids: shifts,
      action: 'APPROVE',
      action_by: 'AGENCY',
    };
    await approveWorkerMutatiom(post)
      .then(() => setShifts([]))
      .then(() => setApprovalStatus('Approved'))
      .then(() => setOpen(true));
  };
  const onDeclineShift = async () => {
    const post = {
      worker_shift_approval_uids: shifts,
      action: 'REJECT',
      action_by: 'AGENCY',
    };
    await approveWorkerMutatiom(post)
      .then(() => setShifts([]))
      .then(() => setApprovalStatus('Rejected'))
      .then(() => setOpen(true));
  };
  return (
    <div className="p-4">
      <SuccessLayout isOpen={open} onClose={() => setOpen(false)}>
        <p className="text-lg font-bold">Shift {approvalStatus} </p>
        <p>The shift has been {approvalStatus} sucessfully.</p>
      </SuccessLayout>
      <div className="mt-6">
        <h1 className="text-2xl mb-8 text-plannaPrimaryGreen font-bold">
          Shift Approvals
        </h1>
        <div className="flex justify-between items-center text-xs space-x-2 mt-4 mb-6">
          <div className="mt-2">
            {shifts.length > 0 && (
              <div className="mt-6 flex space-x-4">
                <button
                  onClick={onApproveShift}
                  className="bg-plannaPrimaryGreen text-plannaAccentGreen px-6 py-2 rounded-full">
                  {approveWorkerLoading ? (
                    <SubmitSpinner />
                  ) : (
                    'Approve shift(s) selected'
                  )}
                </button>
                <button
                  onClick={onDeclineShift}
                  className="bg-plannaNeutral text-plannaPrimaryGreen px-6 py-2 rounded-full">
                  {approveWorkerLoading ? (
                    <SubmitSpinner />
                  ) : (
                    'Reject shift(s) selected'
                  )}
                </button>
              </div>
            )}
          </div>
          <div className="mt-2">
            <button
              onClick={() => setStatus('PENDING_AGENCY_APPROVAL')}
              className={`${
                status !== 'PENDING_AGENCY_APPROVAL' ? 'opacity-30' : ''
              }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
              Pending agency approvals
            </button>
            <button
              onClick={() => setStatus('PENDING_PROVIDER_APPROVAL')}
              className={`${
                status !== 'PENDING_PROVIDER_APPROVAL' ? 'opacity-30' : ''
              }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
              Pending provider approvals
            </button>
            <button
              onClick={() => setStatus('APPROVED')}
              className={`${
                status !== 'APPROVED' ? 'opacity-30' : ''
              }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
              Approved
            </button>
            <button
              onClick={() => setStatus('AGENCY_DECLINED')}
              className={`${
                status !== 'AGENCY_DECLINED' ? 'opacity-30' : ''
              }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
              Declined
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="overflow-hidden border rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                        Approval
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                        Worker Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                        Check In
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                        Check Out
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                        Hours
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                        Charge
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                        Action
                      </th>
                    </tr>
                  </thead>
                  {/* eslint-disable-next-line */}
                  {approvals?.results.map((shift) => (
                    // eslint-disable-next-line
                    <tbody key={shift.uid} className="divide-y divide-gray-200">
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <input
                            type="checkbox"
                            disabled={
                              // eslint-disable-next-line
                              shift.status === 'PENDING_AGENCY_APPROVAL'
                                ? false
                                : true
                            }
                            className="accent-plannaPrimaryGreen h-4 w-4"
                            // eslint-disable-next-line
                            onChange={() => toggleCheckbox(shift.uid)}
                          />
                        </td>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                          {/* eslint-disable-next-line */}
                          {shift.worker_shift.worker.user.first_name}{' '}
                          {/* eslint-disable-next-line */}
                          {shift.worker_shift.worker.user.last_name}
                        </td>

                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {/* eslint-disable-next-line */}
                          {moment(shift.worker_shift.checked_in_at)
                            .utcOffset('+03:00')
                            .format('Do MMMM YYYY HH:mm')}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {/* eslint-disable-next-line */}
                          {moment(shift.worker_shift.checked_out_at)
                            .utcOffset('+03:00')
                            .format('Do MMMM YYYY HH:mm')}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {/* eslint-disable-next-line */}
                          {convertDecimalHoursToHoursAndMinutes(
                            shift.worker_shift.duration
                          )}
                        </td>

                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {/* eslint-disable-next-line */}
                          {shift.worker_shift.charge_rate}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          <button
                            onClick={() => {
                              // eslint-disable-next-line
                              navigate(
                                `/single-allocation/${shift.worker_shift.shift.timesheet.agency_shift_allocation.uid}`
                              );
                            }}
                            className="px-6 py-2 rounded-full bg-plannaPrimaryGreen text-plannaAccentGreen">
                            View
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftApprovals;
