/* eslint-disable @typescript-eslint/no-unsafe-return */
// import axios from 'axios';
import {useQuery} from 'react-query';

import axiosInstance from '../../hooks/services/AxiosInstance';

const RQGetNotifications = () => {
  const getNotifications = async () => {
    const response = await axiosInstance.get(`/notifications/notification/`);

    return response?.data;
  };

  return useQuery(['notifications'], getNotifications, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 15,
    refetchInterval: 1000 * 5,
    // enabled: searchTerm ? true : false,
  });
};

export default RQGetNotifications;
