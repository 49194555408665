/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import moment, {Duration} from 'moment';

import {OriginalRate, TransformedRate, TransformedRateData} from './types';

export const getColorBasedOnStatus = (status: string): string => {
  switch (status) {
    case 'Draft':
      return 'purple';
    case 'Scheduled':
      return 'blue';
    case 'Canceled':
      return 'red';
    case 'Started':
      return 'orange';
    case 'Published':
      return 'green';
    case 'ended':
      return 'pink';
    case 'Deleted':
      return 'black';
    default:
      return 'blue'; // Default color if status is not recognized
  }
};
export function formatDate(inputDate: string): string {
  const parts = inputDate.split('-');
  if (parts.length !== 3) {
    throw new Error('Invalid date format');
  }
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];
  return `${day}-${month}-${year}`;
}
export function reverseDate(inputDate: string): string {
  const parts = inputDate.split('-');
  if (parts.length !== 3) {
    throw new Error('Invalid date format');
  }
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];
  return `${year}-${month}-${day}`;
}

export const formatCurrentDate = (date: Date): string => {
  // Get the day, month, and year
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  // Format the date
  return `${year}-${month}-${day}`; // ISO format for input date field
};
interface LocationInfo {
  region: string | null;
  country: string | null;
  countryCode: string | null;
  state: string | null;
  city: string | null;
  street: string | null;
  postalCode: string | null;
  timezone: string | null;
}

export const getLocationInfo = async (
  latitude: number,
  longitude: number
): Promise<LocationInfo> => {
  try {
    const key = process.env.REACT_APP_GOOGLE_PLACES_KEY;

    // Fetch address information
    const addressResponse = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${
        key !== undefined ? key : ''
      }`
    );
    const addressData: {
      status: string;
      results?: {
        address_components: {
          long_name: string;
          short_name: string;
          types: string[];
        }[];
      }[];
    } = await addressResponse.json();

    if (addressData.status === 'OK') {
      const results = addressData.results;
      if (results && results.length > 0) {
        const addressComponents = results[0].address_components;
        let region: string | null = null;
        let country: string | null = null;
        let countryCode: string | null = null;
        let state: string | null = null;
        let city: string | null = null;
        let street: string | null = null;
        let postalCode: string | null = null;
        addressComponents.forEach((component) => {
          if (component.types.includes('administrative_area_level_1')) {
            state = component.long_name;
            region = component.long_name;
          } else if (component.types.includes('country')) {
            country = component.long_name;
            countryCode = component.short_name;
          } else if (component.types.includes('locality')) {
            city = component.long_name;
          } else if (component.types.includes('route')) {
            street = component.long_name;
          } else if (component.types.includes('postal_code')) {
            postalCode = component.long_name;
          }
        });

        // Fetch timezone information
        const timestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
        const timezoneResponse = await fetch(
          `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=${
            key !== undefined ? key : ''
          }`
        );
        const timezoneData: {
          status: string;
          timeZoneId?: string;
          errorMessage?: string;
        } = await timezoneResponse.json();

        let timezone: string | null = null;
        if (timezoneData.status === 'OK' && timezoneData.timeZoneId) {
          timezone = timezoneData.timeZoneId;
        } else {
          console.error(
            'Error fetching timezone info:',
            timezoneData.errorMessage
          );
        }

        return {
          region,
          country,
          countryCode,
          state,
          city,
          postalCode,
          street,
          timezone,
        };
      }
    }
  } catch (error) {
    console.error('Error fetching location info:', error);
  }

  return {
    region: null,
    country: null,
    countryCode: null,
    state: null,
    city: null,
    postalCode: null,
    street: null,
    timezone: null,
  };
};

export function removePrefix(str: string): string {
  const parts = str.split(':');
  if (parts.length > 1) {
    return parts[1];
  }
  return str; // Return the original string if no colon is found
}
export const roundTo6DecimalPlaces = (num: number): number => {
  return +num.toFixed(6);
};
export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export type ChargeRateResult = {
  payRate: number;
  holidayPay: number;
  totalPay: number;
  pensionPay: number;
  employerNi: number;
  agencyMargin: number;
  chargeRate: number;
};
export function convertRates(originalRates: OriginalRate[]): TransformedRate[] {
  const convertedRates: TransformedRate[] = [];

  originalRates.forEach((rate) => {
    const transformedData: TransformedRateData = {
      //eslint-disable-next-line
      rate_name: rate.rate_name,
      //eslint-disable-next-line
      rate_duration_type: rate.rate_duration_type,
      //eslint-disable-next-line
      base_rate: rate.base_rate, // Convert string to number
      //eslint-disable-next-line
      break_duration: rate.break_duration,
      //eslint-disable-next-line
      minimum_shift_duration_for_breaks: rate.minimum_shift_duration_for_breaks,
      //eslint-disable-next-line
      paid_breaks: rate.paid_breaks === true ? 'true' : 'false',
      //eslint-disable-next-line
      rate_type: rate.rate_type,
      //eslint-disable-next-line
      default_margin_type: rate.default_margin_type,
      //eslint-disable-next-line
      default_margin: parseFloat(rate.default_margin), // Convert string to number
      uid: rate.uid,
    };

    const convertedRate: TransformedRate = {
      //eslint-disable-next-line
      type: rate.rate_type.toUpperCase(), // Assuming rate_type is already in uppercase
      //eslint-disable-next-line
      data: transformedData,
    };

    convertedRates.push(convertedRate);
  });

  return convertedRates;
}

export function calculateChargeRate(
  marginType: string,
  margin: string,
  baseRate: number,
  holidayPayPercentage: number,
  pensionContributionPercentage: string,
  employersNiPercentage: number,
  otherCosts: string
): ChargeRateResult {
  const payRate = baseRate;
  const holidayPay = (payRate * holidayPayPercentage) / 100;
  const totalPay = payRate + holidayPay;
  const pensionPay =
    totalPay * (parseFloat(pensionContributionPercentage) / 100);
  const employerNi = totalPay * (employersNiPercentage / 100);
  let agencyMargin = 0;

  if (marginType === 'PERCENTAGE' && parseFloat(margin) <= 100) {
    agencyMargin = totalPay * (parseFloat(margin) / 100);
  } else {
    agencyMargin = parseFloat(margin);
  }
  const chargeRate =
    totalPay + pensionPay + employerNi + agencyMargin + parseFloat(otherCosts);

  return {
    payRate,
    holidayPay,
    totalPay,
    pensionPay,
    employerNi,
    agencyMargin,
    chargeRate,
  };
}
export function convertDecimalHoursToHoursAndMinutes(
  decimalHours: string
): string {
  const decimalValue = parseFloat(decimalHours);
  const duration: Duration = moment.duration(decimalValue, 'hours');
  const hours: number = Math.floor(duration.asHours());
  const minutes: number = Math.round(duration.asMinutes() % 60);

  return `${hours} hrs & ${minutes} mins`;
}
export function convertToISO(dateString: Date): string {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string');
  }

  return date.toISOString();
}
export function formatDateToIso(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}T00:00:00Z`;
}
export function getDateAWeekAgo(): string {
  const today = new Date();
  const aWeekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
  return formatDateToIso(aWeekAgo);
}
export function getDateAWeekAhead(): string {
  const today = new Date();
  const aWeekAhead = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
  return formatDateToIso(aWeekAhead);
}
