import React from 'react';

import PastShiftReports from './PastShiftReports';
import UpcomingShiftReports from './UpcomingShiftReports';
import Tabs from '../Home/Components/Tabs';

const ShiftReports = () => {
  const tabs = [
    {
      id: 'tab1',
      label: 'Upcoming shift reports',
      component: <UpcomingShiftReports />,
    },
    {
      id: 'tab2',
      label: 'Allocated shift reports',
      component: <PastShiftReports />,
    },
  ];
  return (
    <div className=" p-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl text-plannaPrimaryGreen font-bold">
          View your Shift reports
        </h1>
      </div>
      <div className="mt-8">
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
};

export default ShiftReports;
