import React from 'react';

type Props = {
  inputValue: string | number | undefined;
  type: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  readonly?: boolean;
  name: string;
  required?: boolean;
  placeholder?: string;
  min?: string;
};

const TemplateInput = ({
  inputValue,
  type,
  onChange,
  label,
  readonly,
  name,
  required,
  placeholder,
  min,
}: Props) => {
  return (
    <div className="w-full">
      <label
        htmlFor="inputField"
        className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ">
        {label}
        {required && (
          <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
            *
          </span>
        )}
      </label>

      <input
        type={type}
        min={min}
        value={inputValue}
        onChange={onChange}
        readOnly={readonly}
        name={name}
        placeholder={placeholder}
        required={required}
        className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-3 px-3 focus:outline-none"
      />
    </div>
  );
};

export default TemplateInput;
