import {PDFDownloadLink, PDFViewer} from '@react-pdf/renderer';
import {useState} from 'react';

import Spinner from '../../../Components/Common/Spinner';
import SubmitSpinner from '../../../Components/Common/SubmitSpinner';
import InvoicePdf from '../../../Components/InvoicePDFViewer';
import Input from '../../../Components/Registration/Input';
import DialogLayout from '../../../Layouts/DialogLayout';
import SuccessLayout from '../../../Layouts/SuccessLayout';
import useInvoiceAction from '../../../hooks/services/Invoices/useActionInvoice';
import useGetInvoices from '../../../hooks/services/Invoices/useGetInvoices';
import useGetProviderProfile from '../../../hooks/services/useGetProviderProfile';
import {formatCurrentDate, reverseDate} from '../../../utils/Methods';
import {InvoiceType} from '../../../utils/types';

const ViewInvoice = () => {
  const {data: profile, isLoading: profileLoading} = useGetProviderProfile();
  const {mutateAsync, isLoading: invoiceLoading} = useInvoiceAction();
  const [status, setStatus] = useState('PENDING_APPROVAL');
  const [isOpen, setIsOpen] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [invoiceData, setInvoiceData] = useState<InvoiceType>();
  const onClose = () => setIsOpen(false);
  const currentDate = new Date();
  const formattedDate = formatCurrentDate(currentDate);
  const [inputDate, setInputDate] = useState<string>(formattedDate);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputDate(event.target.value);
  };

  // eslint-disable-next-line
  const {data, isLoading} = useGetInvoices(
    reverseDate(inputDate),
    status,
    profile !== undefined ? profile.uid : '',
    'provider__uid'
  );
  if (profileLoading || isLoading) {
    return <Spinner />;
  }

  const onInvoiceAction = async (action: string, invoice: string) => {
    const post = {action, invoice};
    await mutateAsync(post).then(() => setIsOpen(true));
  };

  const handleOpen = (invoicing: InvoiceType) => {
    setInvoiceData(invoicing);
    setIsInvoice(true);
  };
  console.log(invoiceData);
  return (
    <>
      <SuccessLayout isOpen={isOpen} onClose={onClose}>
        <h1 className="text-center text-plannaPrimaryGreen">
          Action Successfull
        </h1>
        <p className="mt-2 text-center text-plannaPrimaryGreen">
          Invoice Approved/rejected successfully
        </p>
      </SuccessLayout>
      <DialogLayout isOpen={isInvoice} onClose={() => setIsInvoice(false)}>
        <div className="flex flex-col items-center justify-center">
          <PDFViewer height="595vh" width="495vh">
            <InvoicePdf
              jobName="Hello"
              providerEmail={
                invoiceData !== undefined ? invoiceData.provider.email : ''
              }
              providerName={
                invoiceData !== undefined
                  ? invoiceData.provider.company.name
                  : ''
              }
              providerPhone={
                invoiceData !== undefined
                  ? invoiceData.provider.phone_number
                  : ''
              }
              providerLocation={
                invoiceData !== undefined
                  ? invoiceData.provider.location.region
                  : ''
              }
              // eslint-disable-next-line
              agencyEmail={
                invoiceData !== undefined ? invoiceData.agency.email : ''
              }
              // eslint-disable-next-line
              agencyLocation={
                invoiceData !== undefined
                  ? invoiceData.agency.location.region
                  : ''
              }
              agencyName={
                invoiceData !== undefined ? invoiceData.agency.name : ''
              }
              agencyPhone={
                invoiceData !== undefined ? invoiceData.agency.phone_number : ''
              }
              invoiceId={invoiceData !== undefined ? invoiceData.uid : ''}
              invoiceDate={
                invoiceData !== undefined ? invoiceData.invoice_date : ''
              }
              invoiceAmount={
                invoiceData !== undefined ? invoiceData.total_amount : ''
              }
            />
          </PDFViewer>
        </div>
      </DialogLayout>
      <div className="flex justify-end">
        <div className="w-1/4">
          <Input
            inputValue={inputDate}
            name="date"
            type="date"
            label="Select date"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="flex justify-end items-center text-xs space-x-2 mt-4 mb-6">
        <button
          onClick={() => setStatus('PENDING_APPROVAL')}
          className={`${
            status !== 'PENDING_APPROVAL' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Pending approval
        </button>
        <button
          onClick={() => setStatus('APPROVED')}
          className={`${
            status !== 'APPROVED' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Approved
        </button>
        <button
          onClick={() => setStatus('REJECTED')}
          className={`${
            status !== 'REJECTED' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Rejected
        </button>
        <button
          onClick={() => setStatus('PENDING_PAYMENT')}
          className={`${
            status !== 'PENDING_PAYMENT' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Pending payment
        </button>
        <button
          onClick={() => setStatus('PAID')}
          className={`${
            status !== 'PAID' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Paid
        </button>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Invoice Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Active
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Agency name
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Agency phone
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Total amount
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Action
                    </th>
                  </tr>
                </thead>
                {/* eslint-disable-next-line */}
                {data?.results.map((invoice) => (
                  // eslint-disable-next-line
                  <tbody key={invoice.uid} className="divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {invoice.invoice_date}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {invoice.is_active === true ? 'Active' : 'Not active'}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {invoice.agency.name}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {invoice.agency.phone_number}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {invoice.total_amount}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        <div className="flex space-x-1">
                          {
                            // eslint-disable-next-line
                            invoice.status === 'PENDING_APPROVAL' && (
                              <div className="flex space-x-1">
                                <button
                                  onClick={() =>
                                    // eslint-disable-next-line
                                    onInvoiceAction('APPROVE', invoice.uid)
                                  }
                                  className="px-6 py-2 rounded-full bg-plannaPrimaryGreen text-plannaAccentGreen">
                                  {invoiceLoading ? (
                                    <SubmitSpinner />
                                  ) : (
                                    'Approve'
                                  )}
                                </button>
                                <button
                                  onClick={() =>
                                    // eslint-disable-next-line
                                    onInvoiceAction('REJECT', invoice.uid)
                                  }
                                  className="px-6 py-2 rounded-full bg-plannaTertiaryRed text-white">
                                  {invoiceLoading ? (
                                    <SubmitSpinner />
                                  ) : (
                                    'Reject'
                                  )}
                                </button>
                              </div>
                            )
                          }

                          <PDFDownloadLink
                            document={
                              <InvoicePdf
                                jobName="Hello"
                                providerEmail={invoice.provider.email}
                                providerName={invoice.provider.company.name}
                                providerPhone={invoice.provider.phone_number}
                                providerLocation={
                                  invoice.provider.location.region
                                }
                                // eslint-disable-next-line
                                agencyEmail={invoice.agency.email}
                                // eslint-disable-next-line
                                agencyLocation={invoice.agency.location.region}
                                agencyName={invoice.agency.name}
                                agencyPhone={invoice.agency.phone_number}
                                invoiceId={invoice.uid}
                                invoiceDate={invoice.invoice_date}
                                invoiceAmount={invoice.total_amount}
                              />
                            }
                            fileName={`${invoice.agency.name}.pdf`}>
                            <div className="px-6 py-2 rounded-full bg-plannaNeutralGrey text-plannaPrimaryGreen">
                              <span>Download</span>
                            </div>
                          </PDFDownloadLink>

                          <div
                            onClick={() => handleOpen(invoice)}
                            className="px-6 cursor-pointer py-2 rounded-full text-center bg-plannaPrimaryGreen text-plannaAccentGreen">
                            <span>View</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewInvoice;
