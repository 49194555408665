import React from 'react';
import {NavLink} from 'react-router-dom';

import Logo from '../assets/icons/Planna-Default.png';

type Props = {
  children: React.ReactNode;
};
const NavbarLayout = ({children}: Props) => {
  return (
    <nav className="bg-plannaNeutralWhite z-40 fixed top-0 left-0 w-full p-6">
      <div className="flex items-center justify-between">
        <div className="flex lg:ml-20 items-center flex-shrink-0 text-white mr-6">
          <NavLink to="/" className="font-bold text-xl">
            <img src={Logo} alt="plnna-logo" className="h-8 w-fit" />
          </NavLink>
        </div>
        {children}
      </div>
    </nav>
  );
};

export default NavbarLayout;
