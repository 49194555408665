import {useNavigate} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import circularForward from '../../assets/icons/circular-forward.png';
import people from '../../assets/icons/people.png';
import useGetAgencys from '../../hooks/services/Agencys/useGetAgencys';
const Agencys = () => {
  const navigate = useNavigate();
  const {data, isLoading, isError, error} = useGetAgencys();

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="p-4 mt-12">
      <h1 className="text-lg font-bold mb-12">Agencies</h1>
      <div className="lg:w-9/12 w-full">
        <div className="flex justify-end">
          <button
            onClick={() => navigate('/agency/invite')}
            className="px-4 py-2  bg-plannaPrimaryGreen text-plannaAccentGreen rounded-lg focus:outline-none">
            + Invite an agency
          </button>
        </div>
        {data?.agencies.map((agency, index) => (
          <div
            onClick={() => navigate(`/agency/${agency.uid}`)}
            key={index}
            className="card l w-full cursor-pointer p-4 border border-plannaPrimaryGreen rounded-lg mt-8 flex items-center justify-between">
            <div className="flex space-x-6">
              <img src={people} alt="people" className="h-fit w-fit" />
              <div className="flex flex-col">
                <h1 className="text-lg text-plannaPrimaryGreen font-semibold">
                  {agency.name}
                </h1>
                <p className="text-plannaPrimaryGreen">{agency.email}</p>
              </div>
            </div>
            <img src={circularForward} alt="forward" className="h-fit w-fit" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Agencys;
