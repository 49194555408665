import moment from 'moment';
import React from 'react';
import {useParams} from 'react-router-dom';

import Spinner from '../../../Components/Common/Spinner';
import useGetProviderDailyShiftsDetails from '../../../hooks/services/Shifts/useGetDailyShiftsDetails';

const DailyShiftDetails = () => {
  const params = useParams();
  // eslint-disable-next-line
  const {data, isLoading} = useGetProviderDailyShiftsDetails({
    shift_uid: params.id !== undefined ? params.id : '',
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="p-4">
      <div className="mt-8 mb-16">
        <h1 className="text-black font-bold text-xl">
          Timesheets Daily Shifts Details
        </h1>
        <p className="text-black mt-2 text-sm">
          View shift information with real-time insights and streamlined views
          on worker attendance
        </p>
      </div>

      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Worker Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Check In
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Check Out
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Holiday pay
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Charge rate
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Pay rate
                    </th>
                  </tr>
                </thead>
                {/* eslint-disable-next-line */}
                {data?.results.map((shift) => (
                  // eslint-disable-next-line
                  <tbody key={shift.uid} className="divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        <div className="flex items-center ">
                          <div className="h-8 w-8 mr-2 rounded-full text-center flex items-center justify-center text-white bg-plannaPositiveGreen">
                            {/* eslint-disable-next-line */}
                            {shift.worker.user.first_name.charAt(0)}
                            {/* eslint-disable-next-line */}
                            {shift.worker.user.last_name.charAt(0)}
                          </div>
                          {/* eslint-disable-next-line */}
                          {shift.worker.user.first_name}{' '}
                          {/* eslint-disable-next-line */}
                          {shift.worker.user.last_name}
                        </div>
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {moment(shift.checked_in_at)
                          .utcOffset('+03:00')
                          .format('Do MMMM YYYY HH:mm')}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.checked_out_at === null
                          ? 'Null'
                          : // eslint-disable-next-line
                            moment(shift.checked_out_at)
                              .utcOffset('+03:00')
                              .format('Do MMMM YYYY HH:mm')}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.status.toLowerCase()}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.holiday_pay}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.charge_rate}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.pay_rate}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyShiftDetails;
