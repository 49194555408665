import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';

const MessageSearchInput = ({
  title,
  searchFunction,
  setSearchResults,
  onClicked,
}: {
  title: string;
  searchFunction: (search: string) => void;
  setSearchResults: React.Dispatch<
    React.SetStateAction<{
      results: any[];
      isLoading: boolean;
      isTyping: boolean;
      type: any;
    }>
  >;
  onClicked: (result: any) => void;
}) => {
  const {register, getValues, setValue} = useForm();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const onSearchChange = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const typedTerm: string = getValues('search');
    setSearchTerm(typedTerm);
  };

  const onResultClick = (result: any) => {
    onClicked(result);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    // setValue('search', result?.full_name);
  };

  useEffect(() => {
    setSearchResults({
      results: [],
      isLoading: false,
      isTyping: true,
      type: null,
    });

    const handler = setTimeout(() => {
      //   if (searchTerm?.length > 0) {
      searchFunction(searchTerm?.toLocaleLowerCase());
      //   }
    }, 800);

    if (!searchTerm) {
      setSearchResults({
        results: [],
        isLoading: false,
        isTyping: false,
        type: null,
      });
    }

    return () => {
      clearTimeout(handler);
      setSearchResults({
        results: [],
        isLoading: false,
        isTyping: false,
        type: null,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <form className="form-action">
      <div className="form-group">
        <input
          type="text"
          className="form-input"
          data-ms-editor="false"
          placeholder={`${title}`}
          autoComplete="off"
          {...register('search', {
            onChange: onSearchChange,
          })}
        />
      </div>
    </form>
  );
};

export default MessageSearchInput;
