/* eslint-disable @typescript-eslint/no-unsafe-return */
import {useQuery} from 'react-query';

import axiosInstance from '../../hooks/services/AxiosInstance';

const RQGetGroupChannels = () => {
  const getGroupChannels = async () => {
    const response = await axiosInstance.get(`/messaging/mattermost-group/`);

    return response?.data;
  };

  return useQuery(['groupChannels'], getGroupChannels, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 15,
    refetchInterval: 1000 * 2,
  });
};

export default RQGetGroupChannels;
