// usecreateShift.ts
import {useMutation, useQueryClient, UseMutationResult} from 'react-query';
import {useNavigate} from 'react-router-dom';

import axiosInstance from '../AxiosInstance';
import useAuthUser from '../useUserDetails';
function formatDateToISO(date: Date): string {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
}
interface CreateShiftParams {
  name: string;
  description: string;
  workerCount: string;
  location: string;
  start_time: string;
  end_time: string;
  start_date: string;
  job_template_uid: string;
  recurrenceString: string;
}

const createShift = async (data: CreateShiftParams) => {
  const newDate = new Date(data.start_date);
  const targetMonth = newDate.getMonth() + 1; // Month is zero-indexed, so we add 1
  const targetDay = newDate.getDate();
  const recurrence = `RRULE:FREQ=MONTHLY;COUNT=1;BYMONTH=${targetMonth};BYMONTHDAY=${targetDay};`;
  const worker_count = parseInt(data.workerCount, 10);
  const stringRule = data.recurrenceString;
  const newData = {
    ...data,
    worker_count,
    recurrence: stringRule === '' ? recurrence : stringRule,
  };
  const response = await axiosInstance.post('/shifts/', newData);

  return response;
};

const useCreateShift = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  CreateShiftParams
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {user} = useAuthUser();
  return useMutation(createShift, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('shifts')
        .then(() =>
          navigate(
            user?.group === 'is_provider'
              ? '/shift-templates'
              : '/agency/allocation-worker'
          )
        )
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useCreateShift;
