// useRegisterUser.ts
import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

type Rate = {
  rate_name: string;
  rate_duration_type: string;
  base_rate: number;
  break_duration: string;
  minimum_shift_duration_for_breaks: string;
  paid_breaks: boolean;
  rate_type: string;
  default_margin_type: string;
  default_margin: number;
};

interface FormState {
  rates: Rate[] | undefined;
}

const PatchRate = async (data: FormState) => {
  try {
    await axiosInstance.patch('/rates/', data.rates);
  } catch (error) {
    // Handle error if needed
    console.error('Error occurred while sending work experience:', error);
  }
};

const usePatchRate = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  FormState
> => {
  const queryClient = useQueryClient();

  return useMutation(PatchRate, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('jobtemplate')
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default usePatchRate;
