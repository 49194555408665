/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {useNavigate} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';
import RQGetProviders from '../../utils/Queries/RQGetProviders';

const ProvidersList = () => {
  const {data: agencyData, isLoading: isFetchingAgencyData} =
    useGetAgencyProfile();

  const {data: providers, isLoading} = RQGetProviders(agencyData?.uid);
  const navigate = useNavigate();

  if (isFetchingAgencyData || isLoading) {
    return <Spinner />;
  }

  console.log(providers);
  return (
    <div className="providers-list-container">
      <div className="header">
        <div className="col">
          <h1 className="text-2xl text-plannaPrimaryGreen font-bold">
            Providers
          </h1>
        </div>
        <div className="col">
          <div className="actions">
            <button
              className="btn primary"
              onClick={() => navigate(`/agency/providers/new`)}>
              + Add Provider
            </button>
          </div>
        </div>
      </div>

      <div className="provider-list-body">
        <div className="worker-details">
          <div className="max-width">
            <div className="mt-8 mb-10">
              <div className="flex flex-col">
                <div className="overflow-x-auto">
                  <div className="p-1.5 w-full inline-block align-middle">
                    <div className="overflow-hidden border rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                              Company Name
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                              Location
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                              Phone
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                              Dafault User
                            </th>

                            <th
                              scope="col"
                              className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {providers?.results?.map((provider) => (
                            <tr
                              onClick={() =>
                                navigate(
                                  `/agency/providers-view/${provider?.uid}`
                                )
                              }
                              key={provider?.uid}>
                              <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                                <div className="flex items-center ">
                                  {provider?.company?.name}
                                </div>
                              </td>
                              <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                                <div className="flex items-center ">
                                  {provider?.location?.region},{' '}
                                  {provider?.location?.country?.name}
                                </div>
                              </td>

                              <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                                {provider?.phone_number}
                              </td>
                              <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                                {
                                  provider?.default_provider_user?.user
                                    ?.first_name
                                }{' '}
                                {
                                  provider?.default_provider_user?.user
                                    ?.last_name
                                }
                              </td>

                              <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                                {provider?.is_active === true
                                  ? 'active'
                                  : 'inactive'}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvidersList;
