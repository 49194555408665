// useDeleteUser.ts
import {useMutation, useQueryClient, UseMutationResult} from 'react-query';
import {useNavigate} from 'react-router-dom';

import axiosInstance from '../../AxiosInstance';

const deleteProject = async (userId: string) => {
  const response = await axiosInstance.delete(`/projects/delete/${userId}/`);
  return response;
};

// eslint-disable-next-line
const useDeleteProject = () // eslint-disable-next-line
: UseMutationResult<any, Error, string> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(deleteProject, {
    onSuccess: () => {
      queryClient
        .invalidateQueries('projects')
        .then(() => navigate('/agency/projects'))
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adju
    },
  });
};

export default useDeleteProject;
