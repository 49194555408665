/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {useQuery} from 'react-query';

import axiosInstance from '../../hooks/services/AxiosInstance';

const RQGetMessageThreads = (type = null) => {
  const getUserToken = async () => {
    const response = await axiosInstance.get(`/messaging/send-message/`);

    return response?.data?.data;
  };

  return useQuery(['userThread'], getUserToken, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 15,
    refetchInterval: 1000 * 5,
  });
};

export default RQGetMessageThreads;
