import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

interface DeleteAgencyUserParams {
  uid: string;
}

const deleteAgencyUser = async (data: DeleteAgencyUserParams) => {
  const response = await axiosInstance.delete(
    `agency/agency-users/${data.uid}/`
  );

  return response;
};

const useDeleteAgencyUser = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  DeleteAgencyUserParams
> => {
  const queryClient = useQueryClient();

  return useMutation(deleteAgencyUser, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('agencyUsers')
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useDeleteAgencyUser;
