// useRegisterUser.ts
import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import {TransformedRate} from '../../../utils/types';
import axiosInstance from '../AxiosInstance';
type Rate = {
  rate_name: string;
  rate_duration_type: string;
  base_rate: number;
  break_duration: string;
  minimum_shift_duration_for_breaks: string;
  paid_breaks: boolean;
  rate_type: string;
  default_margin_type: string;
  default_margin: number;
};
interface UpdateTemplateParams {
  template_name: string;
  job_description: string;
  industry_uid: string;
  role_uid: string;
  rates: TransformedRate[];
  template_uid: string;
  skills_required: string[];
}

const updateTemplate = async (data: UpdateTemplateParams) => {
  const response = await axiosInstance.patch(
    `/job/${data.template_uid}/`,
    data
  );

  return response;
};

const useUpdateJobTemplate = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  UpdateTemplateParams
> => {
  const queryClient = useQueryClient();

  return useMutation(updateTemplate, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('jobtemplate')
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useUpdateJobTemplate;
