import React from 'react';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import people from '../../assets/icons/people.png';
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';
import useGetWorkerQuestions from '../../hooks/services/Agencys/useGetWorkerQuestions';

const WorkerOnboardingQuestions = () => {
  const navigate = useNavigate();
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile();
  const {data, isLoading} = useGetWorkerQuestions(
    profile !== undefined ? profile.uid : ''
  );
  if (profileLoading || isLoading) return <Spinner />;
  return (
    <div className="p-4 mx-6 ">
      <h1 className="text-2xl text-plannaPrimaryGreen font-bold mb-12">
        Worker Questions
      </h1>
      <div className="templates-container">
        <div className="flex justify-end">
          <button
            onClick={() => navigate('/worker-questions/add')}
            className="px-4 py-2  bg-plannaPrimaryGreen text-plannaAccentGreen rounded-lg focus:outline-none">
            + Add new questions
          </button>
        </div>
        <div className="mb-8 mt-12">
          <h1 className="font-bold text-plannaPrimaryGreen text-xl">
            All Questions
          </h1>
        </div>
        {data?.results.map((question, index) => (
          <div
            key={index}
            className="card w-full cursor-pointer p-4 border border-plannaPrimaryGreen rounded-lg mt-5 flex items-center justify-between"
            style={{borderColor: '#C2CDD0'}}>
            <div className="flex space-x-6">
              <img src={people} alt="people" className="h-fit w-fit" />
              <div className="flex flex-col">
                <h1 className="text-lg text-plannaPrimaryGreen font-semibold">
                  {question.question}
                </h1>
                <p className=" text-plannaNeutral70 text-sm mt-1 font-hanken-grotesk font-normal">
                  No expected answer
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkerOnboardingQuestions;
