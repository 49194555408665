import {useState} from 'react';

// Dropdown component
const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div
          className="absolute z-10 hidden bg-white py-2 px-4 shadow-md"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <a href="#dropdown-link1" className="block text-gray-800 py-2">
            Dropdown Link 1
          </a>
          <a href="#dropdown-link2" className="block text-gray-800 py-2">
            Dropdown Link 2
          </a>
          {/* Add more dropdown links as needed */}
        </div>
      )}
    </>
  );
};
export default Dropdown;
