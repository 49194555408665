// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

type Data = {
  question: string;
};

export interface ApiResponse {
  results: Data[];
}

const fetchData = async (id: string): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      `agency/agency-onboarding-question/?agency_uid=${id}`
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetWorkerQuestions = (
  id: string
): UseQueryResult<ApiResponse, Error> => {
  return useQuery(['workerquestions', id], () => fetchData(id));
};

export default useGetWorkerQuestions;
