// usecreateShift.ts
import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

interface UpdateShiftTemplateParams {
  shift_template_uid: string;
  name: string;
  description: string;
  worker_count: number;
  start_date: string;
  start_time: string;
  job_template_uid: string;
  recurrence: string;
  update_type: string;
}

const updateShiftTemplate = async (data: UpdateShiftTemplateParams) => {
  const response = await axiosInstance.patch('/shifts/', data);

  return response;
};

const useUpdateShiftTemplate = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  UpdateShiftTemplateParams
> => {
  const queryClient = useQueryClient();

  return useMutation(updateShiftTemplate, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('singleshiftschedule')
        .then((data) => {
          return data;
        })
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useUpdateShiftTemplate;
