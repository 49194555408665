import React from 'react';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../../Components/Common/Spinner';
import circularForward from '../../../assets/icons/circular-forward.png';
import people from '../../../assets/icons/people.png';
import useGetProjects from '../../../hooks/services/Agencys/Projects/useGetProjects';
const Projects = () => {
  const navigate = useNavigate();
  const {data, isLoading} = useGetProjects({});
  if (isLoading) return <Spinner />;
  return (
    <div className="p-4 mx-6 ">
      <h1 className="text-2xl text-plannaPrimaryGreen font-bold mb-12">
        Projects
      </h1>
      <div className="templates-container">
        <div className="flex justify-end">
          <button
            onClick={() => navigate('/agency/projects/new')}
            className="px-4 py-2  bg-plannaPrimaryGreen text-plannaAccentGreen rounded-lg focus:outline-none">
            + Add a new project
          </button>
        </div>
        <div className="mb-8 mt-12">
          <h1 className="font-bold text-plannaPrimaryGreen text-xl">
            All Projects
          </h1>
        </div>
        {data?.results.map((project, index) => (
          <div key={index}>
            <div
              key={index}
              onClick={() => navigate(`/agency/projects/${project.uid}`)}
              className="card w-full cursor-pointer p-4 border border-plannaPrimaryGreen rounded-lg mt-5 flex items-center justify-between"
              style={{borderColor: '#C2CDD0'}}>
              <div className="flex space-x-6">
                <img src={people} alt="people" className="h-fit w-fit" />
                <div className="flex flex-col">
                  <h1 className="text-lg text-plannaPrimaryGreen font-semibold">
                    {project.title}
                  </h1>
                  <p className=" text-plannaNeutral70 text-sm mt-1 font-hanken-grotesk font-normal">
                    View and update your project details and description
                  </p>
                </div>
              </div>
              <img
                src={circularForward}
                alt="forward"
                className="h-fit w-fit"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
