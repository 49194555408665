import {jwtDecode} from 'jwt-decode';
import {useMutation, UseMutationResult, useQueryClient} from 'react-query';

import axiosInstance from './AxiosInstance';
import useAuthToken from './useAuthToken';
import useAuthUser from './useUserDetails';

interface LoginUserParams {
  email: string;
  password: string;
}
type Data = {
  token: string;
};
type Response = {
  data: Data;
};
type DataError = {
  errors: string;
  error_code: string;
};
type Error = {
  data: DataError;
};
type ResponseError = {
  response: Error;
};

interface DecodedToken {
  user_id: string;
  email: string;
  group: string;
  fullname: string;
}

const loginUser = async (data: LoginUserParams) => {
  const response = await axiosInstance.post('/auth/jwt/login/', data);

  return response;
};

const useLoginUser = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  ResponseError,
  LoginUserParams
> => {
  const {setAuthToken} = useAuthToken();
  const {setAuthUser} = useAuthUser();
  const queryClient = useQueryClient();
  return useMutation(loginUser, {
    onSuccess: (response: Response) => {
      setAuthToken(response.data.token);
      const decodedToken: DecodedToken = jwtDecode(response.data.token);
      setAuthUser(decodedToken);
      queryClient
        .invalidateQueries()
        .then(() => {
          if (decodedToken.group === 'is_provider') {
            window.location.assign('/reports');
          } else {
            window.location.assign('/agency/home');
          }
        })
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useLoginUser;
