import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Button from './Button';
import NavbarLayout from '../../Layouts/NavbarLayout';
import circularBack from '../../assets/icons/Circular-Backward.png';
import {countries} from '../../utils/constants';
import TextArea from '../Common/TextArea';
import Input from '../Registration/Input';
import Select from '../Registration/Select';
const RequestDemo = () => {
  const initialState = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    companyName: '',
    email: '',
    website: '',
    companySize: '',
    industry: '',
    description: '',
    country: '',
  };
  const [formData, setFormData] = useState(initialState);
  const navigate = useNavigate();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target;

    setFormData({...formData, [name]: value});
  };
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  return (
    <>
      <NavbarLayout>
        <div className="block lg:inline-block mt-4 lg:mt-0 lg:ml-4">
          <Button label="Help" bg="plannaPrimaryGreen" text="white" />
        </div>
      </NavbarLayout>
      <div className="mt-24">
        <img
          onClick={() => navigate(-1)}
          src={circularBack}
          alt="back"
          className="h-12 w-12 ml-12  cursor-pointer"
        />
        <div className="flex flex-col justify-center items-center  mt-4">
          <h1 className="font-bold text-3xl text-plannaPrimaryGreen">
            Request a Demo
          </h1>
          <p className="text-center mt-4">
            Enter your details below and we’ll be in touch to book in a free,
            <br />
            no-obligation demo with you
          </p>
          <div className="w-5/12 mt-4">
            <div className="flex space-x-1">
              <Input
                demo
                name="firstName"
                label="First Name"
                type="text"
                onChange={handleChange}
                required
                inputValue={formData.firstName}
              />
              <Input
                demo
                name="lastName"
                label="Last Name"
                type="text"
                onChange={handleChange}
                required
                inputValue={formData.lastName}
              />
            </div>
            <div className="mt-2">
              <Input
                demo
                name="phoneNumber"
                label="Phone Number"
                type="text"
                onChange={handleChange}
                required
                inputValue={formData.phoneNumber}
                placeholder="+44 3456454543"
              />
            </div>
            <div className="mt-2">
              <Input
                demo
                name="companyName"
                label="Company Name"
                type="text"
                onChange={handleChange}
                required
                inputValue={formData.companyName}
              />
            </div>
            <div className="mt-2">
              <Select
                demo
                name="country"
                label="Where are you located?"
                onChange={handleSelect}
                required
                inputValue={formData.country}
                options={countries}
              />
            </div>
            <div className="mt-2">
              <Input
                demo
                name="email"
                label="Company Email Address"
                type="email"
                onChange={handleChange}
                required
                inputValue={formData.email}
              />
            </div>
            <div className="mt-2">
              <Input
                demo
                name="website"
                label="Company Website"
                type="text"
                onChange={handleChange}
                required
                inputValue={formData.website}
              />
            </div>
            <div className="mt-2">
              <Input
                demo
                name="companySize"
                label="Company Size"
                type="text"
                onChange={handleChange}
                required
                inputValue={formData.companySize}
                placeholder="e.g large or medium or small"
              />
            </div>
            <div className="mt-2">
              <Input
                demo
                name="industry"
                label="What Industry do you deal with?"
                type="text"
                placeholder="e.g healthcare"
                onChange={handleChange}
                required
                inputValue={formData.industry}
              />
            </div>
            <div className="mt-2">
              <TextArea
                demo
                name="decription"
                label="More information"
                onChange={handleDescriptionChange}
                required
                value={formData.description}
              />
            </div>
            <div className="mt-2">
              <p>
                By submitting you agree to our{' '}
                <span className="font-bold  text-plannaPrimaryGreen">
                  Privacy statement
                </span>
              </p>
            </div>
            <div className="mt-3">
              <button className="px-4 py-2 text-plannaAccentGreen bg-plannaPrimaryGreen rounded-full w-full focus:outline-none">
                Schedule a demo
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestDemo;
