/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {useEffect, useState} from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {useForm} from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {useNavigate} from 'react-router-dom';

import OnBoardingLayout from '../../Layouts/OnBoardingLayout';
import {getLocationInfo, roundTo6DecimalPlaces} from '../../utils/Methods';
import {CountryCodes} from '../../utils/constants';
import SubmitButton from '../Common/SubmitButton';

type Location = {
  longitude: number | null;
  latitude: number | null;
  country_code: string | null;
  google_place_id: string | null;
  state: string | null;
  city: string | null;
  street: string | null;
  postal_code: string | null;
  region: string | null;
};

type Props = {
  onSubmit: (data: any) => void;
  isLoading: boolean;
};

const Registration = ({onSubmit, isLoading}: Props) => {
  const navigate = useNavigate();
  const [longitude, setLongitude] = useState<number>(0);
  const [latitude, setLatitude] = useState<number>(0);
  const [placeId, setPlaceId] = useState('');
  const [locationInfo, setLocationInfo] = useState<Location>({
    longitude: 0,
    latitude: 0,
    country_code: '',
    google_place_id: '',
    state: '',
    city: '',
    street: '',
    postal_code: '',
    region: '',
  });
  const {
    register,
    setValue,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const handleSetLocation = (place: any) => {
    const geocoder = new window.google.maps.Geocoder();
    // eslint-disable-next-line
    geocoder.geocode(
      {placeId: place.value.place_id},
      (results: any, status: any) => {
        if (status === 'OK' && results[0]) {
          const {lat, lng} = results[0].geometry.location;
          setLatitude(parseFloat(lat()));
          setLongitude(parseFloat(lng()));
          setPlaceId(place.value.place_id);
        }
      }
    );
    setValue('location', place);
  };
  const onSubmitForm = (data: any) => {
    const location = {
      longitude: longitude,
      latitude: latitude,
      country_code: data.country,
      google_place_id: placeId,
      state: '',
      city: '',
      street: '',
      postal_code: '',
      region: '',
    };
    onSubmit(registerData(data, locationInfo));
  };

  useEffect(() => {
    getLocationInfo(latitude, longitude)
      .then((locationInfoz) => {
        setLocationInfo((prevLocationInfo) => ({
          ...prevLocationInfo,
          longitude: roundTo6DecimalPlaces(longitude),
          latitude: roundTo6DecimalPlaces(latitude),
          country_code: locationInfoz.countryCode,
          google_place_id: placeId,
          state: locationInfoz.state,
          city: locationInfoz.city,
          postal_code: locationInfoz.postalCode,
          region: locationInfoz.region,
          street: locationInfoz.street,
        }));
      })
      .catch((errr: string) => {
        return errr;
      });
  }, [latitude, longitude, placeId]);

  return (
    <>
      <OnBoardingLayout provider>
        <div className="max-width center p-3">
          <form
            onSubmit={handleSubmit(onSubmitForm)}
            className="form-action inner-max-width">
            <h1 className="font-bold text-2xl text-plannaPrimaryGreen">
              Complete Provider registration
            </h1>
            <p className=" mb-6 mt-4 text-plannaPrimaryGreen">
              Enter your details below and we’ll be in touch to book in a free,
              <br />
              no-obligation demo with you
            </p>
            <div className="split-form-group">
              <div className={`form-group${errors?.firstName ? ' error' : ''}`}>
                <label htmlFor="firstName" className="form-label">
                  FirstName <span>*</span>
                </label>

                <input
                  type="text"
                  {...register('firstName', {
                    required: 'First name is required',
                  })}
                  id="firstName"
                  className="form-input"
                  placeholder="First Name"
                />

                {errors?.firstName && (
                  <span className="text-red-500 text-sm">
                    {String(errors?.firstName?.message)}
                  </span>
                )}
              </div>

              <div className={`form-group${errors?.lastName ? ' error' : ''}`}>
                <label htmlFor="lastName" className="form-label">
                  LastName <span>*</span>
                </label>

                <input
                  type="text"
                  {...register('lastName', {
                    required: 'Last name is required',
                  })}
                  id="lastName"
                  className="form-input"
                  placeholder="Last Name"
                />

                {errors?.lastName && (
                  <span className="text-red-500 text-sm">
                    {String(errors?.lastName?.message)}
                  </span>
                )}
              </div>
            </div>

            <div className={`form-group${errors?.company ? ' error' : ''}`}>
              <label htmlFor="company" className="form-label">
                Company Name <span>*</span>
              </label>

              <input
                type="text"
                {...register('company', {
                  required: 'Company name is required',
                })}
                id="company"
                className="form-input"
                placeholder="Company Name"
              />

              {errors?.company && (
                <span className="text-red-500 text-sm">
                  {String(errors?.company?.message)}
                </span>
              )}
            </div>

            <div className={`form-group${errors?.email ? ' error' : ''}`}>
              <label htmlFor="email" className="form-label">
                Email address <span>*</span>
              </label>

              <input
                type="text"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Invalid email address',
                  },
                })}
                id="email"
                className="form-input"
                placeholder="e.g example@email.com"
              />

              {errors?.email && (
                <span className="text-red-500 text-sm">
                  {String(errors?.email?.message)}
                </span>
              )}
            </div>

            <div className="split-form-group">
              <div className={`form-group${errors?.phone ? ' error' : ''}`}>
                <label htmlFor="phone" className="form-label">
                  Phone Number
                </label>

                <PhoneInput
                  country="gb"
                  inputProps={{
                    name: 'phone',
                    id: 'phone',
                    className: 'form-phone',
                    autoFocus: true,
                  }}
                  inputStyle={{
                    width: '100%',
                    borderColor: errors?.phone ? 'red' : '',
                  }}
                  containerStyle={{
                    marginBottom: '1rem',
                  }}
                  onChange={(phone) => {
                    setValue('phone', phone);
                  }}
                />

                {errors?.phone && (
                  <span className="text-red-500 text-sm">
                    {String(errors?.phone?.message)}
                  </span>
                )}
              </div>
            </div>

            <div className={`form-group${errors?.password ? ' error' : ''}`}>
              <label htmlFor="password" className="form-label">
                Password <span>*</span>
              </label>

              <input
                type="password"
                {...register('password', {
                  required: 'Password is required',
                  minLength: {
                    value: 6,
                    message: 'Password must be at least 6 characters',
                  },
                })}
                id="password"
                className="form-input"
                placeholder="Password"
              />

              {errors?.password && (
                <span className="text-red-500 text-sm">
                  {String(errors?.password?.message)}
                </span>
              )}
            </div>

            <div className={`form-group${errors?.location ? ' error' : ''}`}>
              <label htmlFor="location" className="form-label">
                Location
              </label>
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_PLACES_KEY}
                selectProps={{
                  placeholder: 'location',
                  className: 'form-input',
                  onChange: handleSetLocation,
                }}
                {...register('location', {
                  required: 'Location is required',
                })}
                autocompletionRequest={{
                  componentRestrictions: {country: ['ke', 'gb']},
                }}
                aria-describedby="basic-addon1"
              />

              {errors?.location && (
                <span className="text-red-500 text-sm">
                  {String(errors?.location?.message)}
                </span>
              )}
            </div>

            <div className="mt-3">
              <SubmitButton isLoading={isLoading} buttonLabel="Submit" />
            </div>
          </form>
        </div>

        <h1 className="mt-6 text-lg text-plannaGreyish">-OR-</h1>

        <div className="mt-6">
          <h1
            onClick={() => navigate('/login')}
            className="text-sm text-plannaGreyish">
            Already have an account? Log in
          </h1>
        </div>
      </OnBoardingLayout>
    </>
  );
};

export default Registration;

const registerData = (data: any, location: any) => {
  return {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    phone_number: data.phone,
    company_name: data.company,
    password: data.password,
    code: data.country,
    provider_type: 'SELF SIGN UP',
    location: {
      longitude: location.longitude,
      latitude: location.latitude,
      country_code: location.country_code,
      google_place_id: location.google_place_id,
      state: location.state,
      city: location.city,
      street: location.street,
      postal_code: location.postal_code,
      region: location.region,
    },
  };
};
