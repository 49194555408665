// useRegisterUser.ts
import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

interface CreateInvoiceParams {
  timesheet_uids: string[];
  provider: string;
  agency: string;
  invoice_date: string;
}
type Data = {
  errors: string;
  error_code: string;
};
type Error = {
  data: Data;
};
type Response = {
  response: Error;
};
const createRate = async (data: CreateInvoiceParams) => {
  const response = await axiosInstance.post('/payments/invoices/', data);
  // eslint-disable-next-line
  return response;
};

const useCreateInvoice = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Response,
  CreateInvoiceParams
> => {
  const queryClient = useQueryClient();

  return useMutation(createRate, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('invoices')
        // eslint-disable-next-line
        .catch((error: Response) => {
          // eslint-disable-next-line
          throw new Error(error.response.data.error_code);
        }); // Adjust the key as needed
    },
  });
};

export default useCreateInvoice;
