import React from 'react';

import Spinner from './SubmitSpinner';

type Props = {
  isLoading?: boolean;
  buttonLabel: string;
  onClick?: () => void;
  fullwidth?: boolean;
};
const SubmitButton = ({isLoading, buttonLabel, onClick, fullwidth}: Props) => {
  return (
    <button
      onClick={onClick}
      className={`btn full primary px-4 py-2 ${
        isLoading ? 'bg-plannaNeutral' : 'bg-plannaPrimaryGreen'
      } text-white  rounded-lg w-full ${
        fullwidth ? 'lg:w-full' : 'lg:w-3/4'
      } focus:outline-none`}>
      {isLoading ? <Spinner /> : buttonLabel}
    </button>
  );
};

export default SubmitButton;
