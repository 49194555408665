// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import {DailyShiftDetailsResponse} from '../../../utils/types';
import axiosInstance from '../AxiosInstance';

interface WorkerShiftOptions {
  shift_uid?: string;
  status?: string;
  worker_uid?: string;
}

const fetchData = async (
  options: WorkerShiftOptions
): Promise<DailyShiftDetailsResponse> => {
  const {shift_uid, status, worker_uid} = options;
  const queryParams = new URLSearchParams({
    limit: '10',
  });
  if (shift_uid !== undefined && shift_uid !== '') {
    queryParams.append('shift__uid', shift_uid);
  }
  if (worker_uid !== undefined && worker_uid !== '') {
    queryParams.append('worker__uid', worker_uid);
  }
  if (status !== undefined && status !== '') {
    queryParams.append('status', status);
  }
  try {
    const response: AxiosResponse<DailyShiftDetailsResponse> =
      // eslint-disable-next-line
      await axiosInstance.get(`attendance/worker-shifts/?${queryParams}`);
    // eslint-disable-next-line
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetProviderDailyShiftsDetails = (
  options: WorkerShiftOptions
): UseQueryResult<DailyShiftDetailsResponse, Error> => {
  const {shift_uid, status, worker_uid} = options;
  return useQuery(['dailyshiftsdetails', shift_uid, status, worker_uid], () =>
    fetchData(options)
  );
};

export default useGetProviderDailyShiftsDetails;
