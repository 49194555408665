import {Route, Routes} from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import AddAgencyUser from '../Screens/Agency/AddUser';
import AgencyAllocations from '../Screens/Agency/AgencyAllocations';
import AgencyHome from '../Screens/Agency/AgencyHome';
import AgencyProfile from '../Screens/Agency/AgencyProfile';
import Agencys from '../Screens/Agency/Agencys';
import Allocation from '../Screens/Agency/Allocation';
import AgencyAttendance from '../Screens/Agency/Attendance';
import AgencyDailyShiftDetails from '../Screens/Agency/Components/DailyShiftDetails';
import AgencyDailyShifts from '../Screens/Agency/Components/DailyShifts';
import SingleShiftInvitation from '../Screens/Agency/Components/SingleShiftInvitation';
import AgencyCreateJobTemplate from '../Screens/Agency/CreateJobTemplate';
import AddAgencyShiftTemplate from '../Screens/Agency/CreateShiftTemplate';
import FinanceReports from '../Screens/Agency/FinancialReports';
import InviteAgency from '../Screens/Agency/InviteAgency';
import InviteWorker from '../Screens/Agency/InviteWorker';
import AgencyInvoicing from '../Screens/Agency/Invoicing';
import AgencyJobTemplates from '../Screens/Agency/JobTemplates';
import AddNewProject from '../Screens/Agency/Projects/AddNewProject';
import AlllocateWorkersProject from '../Screens/Agency/Projects/AlllocateWorkersProject';
import EditProject from '../Screens/Agency/Projects/EditProject';
import Projects from '../Screens/Agency/Projects/Projects';
import ProjectsOverview from '../Screens/Agency/Projects/ProjectsOverview';
import Provider from '../Screens/Agency/Provider';
import ProviderCreate from '../Screens/Agency/ProviderCreate';
import ProvidersList from '../Screens/Agency/ProvidersList';
import ShiftReports from '../Screens/Agency/ShiftReports';
import SingleAgency from '../Screens/Agency/SingleAgency';
import AgencyUsers from '../Screens/Agency/Users';
import ViewSingleAgencyUser from '../Screens/Agency/ViewSingleAgencyUser';
import ViewSingleWorkerAllocation from '../Screens/Agency/ViewSingleWorkerAllocation';
import ViewSingleWorkerApplication from '../Screens/Agency/ViewSingleWorkerApplication';
import ViewWorker from '../Screens/Agency/ViewWorker';
import Workers from '../Screens/Agency/Workers';
import HomePortal from '../Screens/Home';
import AccountSettings from '../Screens/Home/AccountSettings';
import AddOnboardingQuestion from '../Screens/Home/AddOnboardingQuestion';
import BankDetails from '../Screens/Home/BankDetails';
import BusinessDetails from '../Screens/Home/BusinessDetails';
import ProfileDetails from '../Screens/Home/ProfileDetails';
import WorkerOnboardingQuestions from '../Screens/Home/WorkerOnboardingQuestions';
import Messages from '../Screens/Messages';
import Reports from '../Screens/Reports';
import ProviderAgencyAllocations from '../Screens/Shifts/AgencyAllocations';
import Attendance from '../Screens/Shifts/Attendance';
import AddShiftTemplate from '../Screens/Shifts/Components/AddShiftTemplate';
import DailyShiftDetails from '../Screens/Shifts/Components/DailyShiftDetails';
import DailyShifts from '../Screens/Shifts/Components/DailyShifts';
import EditShiftTemplate from '../Screens/Shifts/Components/EditShiftSchedule';
import TimeSheets from '../Screens/Shifts/Components/Timesheets';
import EditJobTemplate from '../Screens/Shifts/EditJobTemplate';
import ProviderInvoicing from '../Screens/Shifts/Invoicing';
import JobTemplate from '../Screens/Shifts/JobTemplate';
import NewJobTemplate from '../Screens/Shifts/NewJobTemplate';
import ShiftTemplates from '../Screens/Shifts/ShiftTemplates';

const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/home" element={<HomePortal />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/account-settings" element={<AccountSettings />} />
        <Route
          path="/worker-questions"
          element={<WorkerOnboardingQuestions />}
        />
        <Route
          path="/worker-questions/add"
          element={<AddOnboardingQuestion />}
        />
        <Route path="/bank-details" element={<BankDetails />} />
        <Route path="/business-details" element={<BusinessDetails />} />
        <Route path="/profile-details" element={<ProfileDetails />} />
        <Route path="/shift-template/new" element={<AddShiftTemplate />} />
        <Route
          path="/shift-template/new/agency"
          element={<AddAgencyShiftTemplate />}
        />
        <Route
          path="/shift-template/new/:dateTime"
          element={<AddShiftTemplate />}
        />
        <Route
          path="/shift-template/edit/:id"
          element={<EditShiftTemplate />}
        />
        <Route path="/shift-templates" element={<ShiftTemplates />} />
        <Route path="/job-template" element={<JobTemplate />} />
        <Route path="/job-template/new" element={<NewJobTemplate />} />
        <Route path="/job-template/agency" element={<AgencyJobTemplates />} />
        <Route
          path="/job-template/new/agency"
          element={<AgencyCreateJobTemplate />}
        />

        <Route path="/job-template/:id" element={<EditJobTemplate />} />
        <Route path="/agencys" element={<Agencys />} />
        <Route path="/agency/users" element={<AgencyUsers />} />
        <Route path="/agency/user/:id" element={<ViewSingleAgencyUser />} />
        <Route path="/agency/users/add" element={<AddAgencyUser />} />
        <Route path="/agency/:id" element={<SingleAgency />} />
        <Route path="/agency/invite" element={<InviteAgency />} />
        <Route path="/agency/invite/worker" element={<InviteWorker />} />
        <Route path="/agency/workers" element={<Workers />} />
        <Route path="/agency/workers" element={<Workers />} />
        <Route path="/agency/single-worker/:id" element={<ViewWorker />} />
        <Route path="/agency/single-worker/:id/:tab" element={<ViewWorker />} />
        <Route path="/agency/home" element={<AgencyHome />} />
        <Route path="/agency/shift-reports" element={<ShiftReports />} />
        <Route path="/agency/finance-reports" element={<FinanceReports />} />
        <Route path="/agency/profile" element={<AgencyProfile />} />
        <Route
          path="/agency/allocation-calendar/:id"
          element={<Allocation />}
        />
        <Route path="/agency/providers" element={<ProvidersList />} />
        <Route path="/agency/providers/new" element={<ProviderCreate />} />
        <Route path="/agency/providers-view/:id" element={<Provider />} />
        <Route path="/agency/providers-view/:id/:tab" element={<Provider />} />
        <Route
          path="/provider/agency-allocations"
          element={<ProviderAgencyAllocations />}
        />
        <Route
          path="/agency/allocation-worker"
          element={<AgencyAllocations />}
        />
        <Route
          path="/agency/allocation-worker/:id"
          element={<ViewSingleWorkerAllocation />}
        />
        <Route
          path="/agency/application-worker/:id"
          element={<ViewSingleWorkerApplication />}
        />
        <Route
          path="/single-allocation/:id"
          element={<SingleShiftInvitation />}
        />
      </Route>
      <Route path="/attendance" element={<Attendance />} />
      <Route path="/timesheets" element={<TimeSheets />} />

      <Route path="/agency/attendance" element={<AgencyAttendance />} />
      <Route path="/daily-shifts-details/:id" element={<DailyShiftDetails />} />
      <Route path="/daily-shifts/:id" element={<DailyShifts />} />
      <Route path="/agency-daily-shifts/:id" element={<AgencyDailyShifts />} />
      <Route
        path="/agency/daily-shifts-details/:id"
        element={<AgencyDailyShiftDetails />}
      />
      <Route path="/agency-invoicing" element={<AgencyInvoicing />} />
      <Route path="/provider-invoicing" element={<ProviderInvoicing />} />
      <Route path="/messages" element={<Messages />} />
      <Route path="/messages/:chatType" element={<Messages />} />
      <Route
        path="/messages/:chatType/:channel_id/:user/:email"
        element={<Messages />}
      />
      <Route path="/agency/projects" element={<Projects />} />
      <Route path="/agency/projects/new" element={<AddNewProject />} />
      <Route path="/agency/projects/:id" element={<ProjectsOverview />} />
      <Route
        path="/agency/projects/allocate-worker/:id"
        element={<AlllocateWorkersProject />}
      />
    </Routes>
  );
};

export default ProtectedRoutes;
