import {DatesSetArg, EventClickArg} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, {DateClickArg} from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import useGetShifts from '../../hooks/services/Shifts/UseGetShifts';
import useGetProviderProfile from '../../hooks/services/useGetProviderProfile';
import './styles.css';
import {formatDateToIso} from '../../utils/Methods';
import {Shift} from '../../utils/types';

type EventDetails = {
  title: string;
  description: string;
  start: string;
  end: string;
  agency: string;
  workerCount: number;
  jobTemplateName: string;
  status: string;
  location: string;
  employerNI: string;
  pensionContribution: string;
  holidayPay: string;
  otherCosts: string;
  marginType: string;
  margin: string;
};

function formatDateAndJoinTime(originalDate: string, newTime: string): string {
  const dateObject = new Date(originalDate);

  // Extract the date part (year, month, day)
  const formattedDate = dateObject.toISOString().split('T')[0];

  // Join with the new time
  const combinedDateTime = `${formattedDate}T${newTime}`;

  return combinedDateTime;
}
interface DateRange {
  start: Date;
  end: Date;
}
const ShiftTemplatesCalendar = () => {
  const {data: profile, isLoading: profileLoading} = useGetProviderProfile();
  const [dateRange, setDateRange] = useState<DateRange>({
    start: new Date(Date.now()),
    end: new Date(Date.now()),
  });
  const handleDatesSet = (dateInfo: DatesSetArg) => {
    setDateRange({
      start: dateInfo.start,
      end: dateInfo.end,
    });
  };
  const [status, setStatus] = useState('DRAFT');

  const {data, isLoading} = useGetShifts(
    status,
    profile !== undefined ? profile.uid : '',
    '2024-04-14T00:00:00Z',
    '2024-08-14T00:00:00Z'
  );
  const [weekendsVisible] = useState(true);
  //eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [eventDetails, setEventDetails] = useState<EventDetails>({
    title: '',
    description: '',
    start: '',
    end: '',
    status: '',
    agency: '',
    workerCount: 0,
    jobTemplateName: '',
    location: '',
    employerNI: '',
    pensionContribution: '',
    holidayPay: '',
    otherCosts: '',
    marginType: '',
    margin: '',
  });

  const openDialog = () => {
    setIsOpen(true);
  };

  const handleDateSelect = () => {
    openDialog();
  };
  const handleEventClick = (clickInfo: EventClickArg) => {
    console.log(clickInfo.event);

    const event = clickInfo.event;
    const start = JSON.stringify(event.start);
    const end = JSON.stringify(event.end);
    const details = {
      title: event.title,
      // eslint-disable-next-line
      description: event.extendedProps?.description,
      // eslint-disable-next-line
      start: JSON.parse(start),
      // eslint-disable-next-line
      end: JSON.parse(end),
      // eslint-disable-next-line
      agency: event.extendedProps?.agency,
      // eslint-disable-next-line
      status: event.extendedProps?.status,
      // eslint-disable-next-line
      workerCount: event.extendedProps?.workerCount,
      // eslint-disable-next-line
      jobTemplateName: event.extendedProps?.jobTemplateName,
      // eslint-disable-next-line
      location: event.extendedProps?.location,
      // eslint-disable-next-line
      employerNI: event.extendedProps?.employerNI,
      // eslint-disable-next-line
      pensionContribution: event.extendedProps?.pensionContribution,
      // eslint-disable-next-line
      holidayPay: event.extendedProps?.holidayPay,
      // eslint-disable-next-line
      otherCosts: event.extendedProps?.otherCosts,
      // eslint-disable-next-line
      marginType: event.extendedProps?.marginType,
      // eslint-disable-next-line
      margin: event.extendedProps?.margin,
    };
    setEventDetails(details);
    navigate(`/shift-template/edit/${event.extendedProps?.allocationId}`);
  };
  const eventsNew = data?.results.flatMap((event: Shift) =>
    // eslint-disable-next-line
    event.occurrences
      .filter((dateStr) => {
        return moment(dateStr).isSameOrAfter(event.start_date, 'day'); // Check if dateStr is on or after startDate (considering day)
      })
      .map((date: string) => ({
        title: event.name,
        uid: event.uid,
        start: formatDateAndJoinTime(date, event.start_time),
        end: formatDateAndJoinTime(date, event.end_time),
        color:
          event.status === 'DRAFT'
            ? 'yellow'
            : event.status === 'PUBLISHED'
              ? 'yellow'
              : event.status === 'ASSIGNED'
                ? 'green'
                : 'pink',
        status: event.status,
        workerCount: event.worker_count,
        jobTemplateName: event.job_template.template_name,
        // eslint-disable-next-line
        location: event.location.region,
        // eslint-disable-next-line
        allocationId: event.uid,
        shift: event,
      }))
  );

  if (isLoading || profileLoading) {
    return <Spinner />;
  }
  const handleDateClick = (info: DateClickArg) => {
    navigate(`/shift-template/new/${info?.dateStr}`);
  };

  const refinedEvents: {
    date: string;
    title: string;
    extendedProps: {subtitle: string; variant: string; cancleled: boolean};
  }[] = [];

  data?.results?.forEach((event) => {
    event?.occurrences?.forEach((occurence) => {
      const startTime = event?.start_time?.slice(0, 5); // Assuming start_time is in HH:mm format
      const endTime = event?.end_time?.slice(0, 5);

      const refinedEvent = {
        date: occurence,
        allocationId: event.uid,
        start: occurence?.slice(0, 10) + 'T' + startTime,
        end: occurence?.slice(0, 10) + 'T' + endTime,
        title: `${getTimePeriod(
          event?.start_time?.slice(0, 5)
        )} - ${getTimePeriod(event?.end_time?.slice(0, 5))}`,
        extendedProps: {
          subtitle: event?.name,
          variant: 'warning',
          cancleled: event?.status === 'ARCHIVED' ? true : false,
        },
      };
      refinedEvents.push(refinedEvent);
    });
  });

  return (
    <div className="w-11/12 ">
      <div className="calendar-container mt-6">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          weekends={true}
          headerToolbar={{
            left: 'title prev,next',
            center: '',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          datesSet={handleDatesSet}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          dateClick={handleDateClick}
          events={refinedEvents}
          dayHeaderFormat={{weekday: 'short', day: 'numeric'}}
          height="700px"
          slotMinTime="06:00"
          slotMaxTime="31:00"
        />
      </div>
      {/* <div className="flex flex-col text-xs bg-plannaNeutralWhite ml-8 p-4">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          selectMirror={false}
          dayMaxEvents={true}
          weekends={weekendsVisible}
          select={handleDateSelect}
          events={eventsNew}
          eventClick={handleEventClick}
          dateClick={handleDateClick}
        />
      </div> */}
    </div>
  );
};

export default ShiftTemplatesCalendar;

const renderEventContent = (eventInfo: {
  event: {
    title: string;
    extendedProps: {
      variant: string;
      cancleled: boolean;
      subtitle: string;
      subtext: string;
    };
  };
}) => {
  return (
    <div
      className={`card-variant ${eventInfo.event.extendedProps.variant}${
        eventInfo.event.extendedProps.cancleled ? ' canceled' : ''
      }`}>
      <div className="fc-title">{eventInfo.event.title}</div>
      <div className="fc-subtitle">
        {eventInfo.event.extendedProps.subtitle}
      </div>{' '}
      <div className="fc-subtext">{eventInfo.event.extendedProps.subtext}</div>{' '}
    </div>
  );
};

export function getTimePeriod(timeString: string) {
  const timeComponents = timeString.split(':');
  let hours = parseInt(timeComponents[0]);
  const minutes = parseInt(timeComponents[1]);

  const period = hours < 12 ? 'AM' : 'PM';

  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  const paddedHours = (hours < 10 ? parseInt('0') + hours : hours).toString();

  const formattedTime =
    paddedHours + ':' + ('0' + minutes.toString()).slice(-2) + ' ' + period;

  return formattedTime;
}
