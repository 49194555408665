import {useMutation, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

interface InviteWorkerParams {
  worker_name: string;
  worker_email: string;
}

const inviteWorker = async (data: InviteWorkerParams) => {
  const response = await axiosInstance.post('/agency/workers/invite/', data);

  return response;
};

const useInviteWorker = (
  openDialog: () => void
): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  InviteWorkerParams
> => {
  return useMutation(inviteWorker, {
    onSuccess: () => {
      openDialog();
    },
  });
};

export default useInviteWorker;
