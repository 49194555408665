// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import axiosInstance from './AxiosInstance';

type Provider = {
  bank_name: string;
  account_name: string;
  account_number: string;
  sort_code: string;
};

interface ApiResponse {
  data: Provider[];
}

const fetchData = async (): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      'providers/provider-bank/bank/'
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetProviderBankDetails = (): UseQueryResult<ApiResponse, Error> => {
  return useQuery('bankdetails', fetchData);
};

export default useGetProviderBankDetails;
