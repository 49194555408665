/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios from 'axios';
import {useMutation} from 'react-query';

// type Props = {};
const messagingUrl = process.env.REACT_APP_MESSAGING_URL;
const RQCreateDirectMessageChannel = (onSuccess: any, onError: any) => {
  const createDirectChannel = async (data: any) => {
    const response = await axios.post(`${messagingUrl}/channels/direct`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_MESSAGING_TOKEN}`,
        credentials: true,
      },
    });
    return response?.data;
  };

  return useMutation(createDirectChannel, {
    onSuccess,
    onError,
  });
};

export default RQCreateDirectMessageChannel;
