import React from 'react';

const PastShiftReports = () => {
  return (
    <div className="p-4">
      <iframe
        className="w-full"
        height="1800"
        src="https://lookerstudio.google.com/embed/reporting/d6e1db30-074c-4590-8327-0e1555c91703/page/TX10D"
        style={{border: 0}}
        allowFullScreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      />
    </div>
  );
};

export default PastShiftReports;
