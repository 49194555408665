// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

type Agency = {
  name: string;
  uid: string;
  email: string;
  phone_number: string;
  website: string;
  week_start: string;
  created_at: string;
};
interface ApiResponse {
  // Define the structure of your API response
  // Example: { data: YourDataType }
  agency: Agency;
}

const fetchData = async (id: string): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      `providers/agency/${id}/`
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetSingleAgency = (id: string): UseQueryResult<ApiResponse, Error> => {
  return useQuery(['agency', id], () => fetchData(id));
};

export default useGetSingleAgency;
