import {useMutation, UseMutationResult, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';

import axiosInstance from './AxiosInstance';

interface ResetPasswordParams {
  email: string;
}

const resetPassword = async (data: ResetPasswordParams) => {
  const response = await axiosInstance.post(`/auth/forget-password/`, data);

  return response;
};

const useSendResetPasswordEmail = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  ResetPasswordParams
> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(resetPassword, {
    onSuccess: () => {
      // eslint-disable-next-line
      queryClient
        .invalidateQueries()
        .then(() => navigate('/reset-password'))
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useSendResetPasswordEmail;
