import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import SubmitButton from '../../Components/Common/SubmitButton';
import Input from '../../Components/Registration/Input';
import SuccessLayout from '../../Layouts/SuccessLayout';
import circularBack from '../../assets/icons/Circular-Backward.png';
import useAddProviderBankDetails from '../../hooks/services/useAddProviderBankDetails';
import useGetProviderBankDetails from '../../hooks/services/useGetProviderBankDetails';
const BankDetails = () => {
  const navigate = useNavigate();
  const {data, isLoading} = useGetProviderBankDetails();
  const {mutateAsync, isLoading: bankLoading} = useAddProviderBankDetails();
  const initialState = {
    bank_name: '',
    account_name: '',
    account_number: 0,
    sort_code: '',
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    if (data !== undefined && data.data.length > 0) {
      const newData = data.data[0];
      const {bank_name, account_name, account_number, sort_code} = newData;
      setFormData({
        bank_name,
        account_name,
        account_number: parseInt(account_number),
        sort_code,
      });
    }
  }, [data]);
  const [isSuccess, setIsSuccess] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const post = {
      ...formData,
    };
    await mutateAsync(post).then(() => setIsSuccess(true));
  };
  const onCloseSuccessDialog = () => setIsSuccess(false);
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="mx-8">
      <SuccessLayout isOpen={isSuccess} onClose={onCloseSuccessDialog}>
        <p className="text-lg font-bold">Bank details added</p>
        <p>The details have been added sucessfully.</p>
      </SuccessLayout>

      <img
        onClick={() => navigate(-1)}
        src={circularBack}
        alt="back"
        className="h-12 w-12 mt-4  cursor-pointer"
      />
      <div className="flex flex-col items-center">
        <div className="lg:w-8/12 w-full">
          <h1 className="text-2xl text-plannaPrimaryGreen font-bold mt-9">
            Bank Details
          </h1>
          <p className=" text-sm text-plannaPrimaryGreen mt-2 font-normal">
            Manage your bank details
          </p>
          <form onSubmit={onSubmit}>
            <div className="mt-7">
              <Input
                inputValue={formData.bank_name}
                name="bank_name"
                type="text"
                label="Bank name"
                onChange={handleChange}
              />
            </div>
            <div className="mt-4">
              <Input
                inputValue={formData.account_name}
                name="account_name"
                type="text"
                label="Account name"
                onChange={handleChange}
              />
            </div>
            <div className="mt-4">
              <Input
                inputValue={formData.account_number}
                name="account_number"
                type="number"
                label="Account Number"
                onChange={handleChange}
              />
            </div>
            <div className="mt-4">
              <Input
                inputValue={formData.sort_code}
                name="sort_code"
                type="text"
                label="Sort code"
                onChange={handleChange}
              />
            </div>
            <div className="mt-4 w-3/4">
              <SubmitButton
                isLoading={bankLoading}
                buttonLabel={
                  data !== undefined && data.data.length > 0
                    ? 'Update'
                    : 'Submit'
                }
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
