// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import {AgencyShiftResponse} from '../../../utils/types';
import axiosInstance from '../AxiosInstance';

type IdType = 'shift_template__provider__uid' | 'agency__uid';
const fetchData = async (
  status: string,
  id: string,
  idType: IdType,
  start_date: string,
  end_date: string
): Promise<AgencyShiftResponse> => {
  const queryParams = new URLSearchParams({
    limit: '100',
    status: status,
    start_date: start_date,
    end_date: end_date,
  });

  queryParams.append(idType, id);
  try {
    const response: AxiosResponse<AgencyShiftResponse> =
      // eslint-disable-next-line
      await axiosInstance.get(`/shifts/agency-allocations/?${queryParams}`);
    // eslint-disable-next-line
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetAgencyShifts = (
  status: string,
  id: string,
  idType: IdType,
  start_date: string,
  end_date: string
): UseQueryResult<AgencyShiftResponse, Error> => {
  return useQuery(['agencyAllocations', status, id, idType], () =>
    fetchData(status, id, idType, start_date, end_date)
  );
};

export default useGetAgencyShifts;
