// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import {WorkerShiftResponseAlternative} from '../../../utils/types';
import axiosInstance from '../AxiosInstance';
interface WorkerShiftOptions {
  uid: string;
}
const fetchData = async (
  options: WorkerShiftOptions
): Promise<WorkerShiftResponseAlternative> => {
  const {uid} = options;
  const queryParams = new URLSearchParams({
    uid: uid,
    start_date: '2024-01-27T00:00:00Z',
    end_date: '2028-08-27T00:00:00Z',
  });
  try {
    const response: AxiosResponse<WorkerShiftResponseAlternative> =
      // eslint-disable-next-line
      await axiosInstance.get(`/shifts/worker-applications/?${queryParams}`);
    // eslint-disable-next-line
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetSingleWorkerShiftsApplications = (
  options: WorkerShiftOptions
): UseQueryResult<WorkerShiftResponseAlternative, Error> => {
  const {uid} = options;
  return useQuery(['workershifts', uid], () => fetchData(options));
};

export default useGetSingleWorkerShiftsApplications;
