import React from 'react';

import TimeSheets from './Components/Timesheets';
import ViewInvoice from './Components/ViewInvoice';
import Tabs from '../Home/Components/Tabs';

const AgencyInvoicing = () => {
  const tabs = [
    {
      id: 'tab1',
      label: 'Create Invoices',
      component: <TimeSheets invoicing />,
    },
    {
      id: 'tab2',
      label: 'View Invoices',
      component: <ViewInvoice />,
    },
  ];
  return (
    <div className="p-4">
      <h1 className="text-lg font-bold mb-6 text-plannaPrimaryGreen">
        Invoicing
      </h1>
      <Tabs tabs={tabs} />
    </div>
  );
};

export default AgencyInvoicing;
