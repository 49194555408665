/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import {ChevronDownIcon, PlusIcon} from '@heroicons/react/24/outline';
import {motion} from 'framer-motion';
import {useRef, useState} from 'react';
import {FaPlus} from 'react-icons/fa';
import {NavLink, useNavigate, useParams} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import backIcon from '../../assets/icons/Circular-Backward.png';
import logo from '../../assets/icons/logo-icon.png';
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';
import RQGetProvider from '../../utils/Queries/RQGetProvider';

const Provider = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState({
    companyInformation: true,
    contactInformation: false,
    workExperience: false,
    bankDetails: false,
    workerDocuments: false,
  });

  const {data: agencyData, isLoading: isFetchingAgencyData} =
    useGetAgencyProfile();

  const {data: providerInfo, isLoading} = RQGetProvider(
    // agencyData?.uid,
    urlParams?.id
  );

  console.log(providerInfo?.results[0]);

  const companyInformationRef = useRef(null);
  const contactInformationRef = useRef(null);
  const workExperienceRef = useRef(null);
  const bankDetailsRef = useRef(null);
  const workerDocumentsRef = useRef(null);

  const [updateData, setUpdateData] = useState({
    companyInformation: {status: false, ref: companyInformationRef},
    contactInformation: {status: false, ref: contactInformationRef},
    workExperience: {status: false, ref: workExperienceRef},
    bankDetails: {status: false, ref: bankDetailsRef},
    workerDocuments: {status: false, ref: workerDocumentsRef},
  });

  const toggleShowDropdown = (name) => {
    setShowDropdown((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const toggleEditUserDetails = (name) => {
    setUpdateData((prevState) => ({
      ...prevState,
      [name]: {status: !prevState[name].status},
    }));
  };

  if (isLoading || isFetchingAgencyData) {
    return <Spinner />;
  }

  return (
    <div className="seeker-profile-container">
      <div className="profile-max-width">
        <div className="profile-header">
          <div
            className="back-icon"
            onClick={() => navigate(`/agency/providers`)}>
            <img src={backIcon} alt="" />
          </div>
          <div className="col">
            <h1 className="profile-title">
              {providerInfo?.results[0]?.company?.name}
              <span>
                {' '}
                (
                {
                  providerInfo?.results[0]?.default_provider_user?.user
                    ?.first_name
                }
                )
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div className="profile-body">
        <div className="nav-tabs">
          <div className="max-width">
            <NavLink
              to={`/agency/providers-view/${urlParams?.id}/profile`}
              className={`nav-tab${
                urlParams?.tab == null || urlParams?.tab == undefined
                  ? ' active'
                  : ''
              }`}>
              Profile
            </NavLink>
            <NavLink
              to={`/agency/providers-view/${urlParams?.id}/jobs`}
              className="nav-tab">
              View Jobs
            </NavLink>
          </div>
        </div>

        {(urlParams?.tab === 'profile' || urlParams?.tab === undefined) && (
          <div className="worker-details">
            <div className="max-width">
              <motion.div
                ref={companyInformationRef}
                initial={{
                  height:
                    companyInformationRef?.current?.scrollHeight ?? 'auto',
                }}
                animate={{
                  height:
                    updateData?.companyInformation?.status === true
                      ? 'auto'
                      : showDropdown?.companyInformation
                        ? companyInformationRef?.current?.scrollHeight
                        : '90px',
                }}
                transition={{duration: 0.1}}
                className="details-card">
                <div
                  className={`dropdown-holder${
                    showDropdown?.companyInformation === true ? ' active' : ''
                  }`}
                  onClick={() => toggleShowDropdown('companyInformation')}>
                  Company information
                  <div className="icon ">
                    <ChevronDownIcon />
                  </div>
                </div>

                {/* {updateData?.companyInformation?.status === true && (
                    <div className="editable-form p-2">
                      <form action="" className="form-action">
                        <div className="split-form-group">
                          <div className="form-group">
                            <label htmlFor="" className="form-label">
                              Name
                            </label>
                            <input type="text" className="form-input" />
                          </div>
                          <div className="form-group">
                            <label htmlFor="" className="form-label">
                              Email
                            </label>
                            <input type="text" className="form-input" />
                          </div>
                          <div className="form-group">
                            <label htmlFor="" className="form-label">
                              Mobile
                            </label>
                            <input type="tel" className="form-input" />
                          </div>
                        </div>
                        <div className="split-form-group">
                          <div className="form-group">
                            <label htmlFor="" className="form-label">
                              Pronoun
                            </label>
                            <select className="form-input">
                              <option value="">Select pronoun</option>
                              <option value="Mr">Mr.</option>
                              <option value="Mrs">Mrs.</option>
                              <option value="Miss">Miss</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="" className="form-label">
                              Date of Birth
                            </label>
                            <input type="date" className="form-input" />
                          </div>
                        </div>
                        <div className="split-form-group">
                          <div className="form-group">
                            <label htmlFor="" className="form-label">
                              Nationality
                            </label>
                            <input type="text" className="form-input" />
                          </div>
                          <div className="form-group">
                            <label htmlFor="" className="form-label">
                              National Insurance
                            </label>
                            <input type="number" className="form-input" />
                          </div>
                        </div>
                        <div className="form-group row">
                          <button
                            type="button"
                            onClick={() =>
                              toggleEditUserDetails('companyInformation')
                            }
                            className="btn danger">
                            Cancel
                          </button>
                          <button type="submit" className="btn primary">
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                  )} */}
                {updateData?.companyInformation?.status === false && (
                  <div className="details-col-holder">
                    <div className="details-col">
                      <div className="info-container">
                        <div className="label">Name </div>
                        <div className="value">
                          {providerInfo?.results[0]?.company?.name}
                        </div>
                      </div>
                      <div className="info-container">
                        <div className="label">Location </div>
                        <div className="value">
                          {providerInfo?.results[0]?.location?.region},{' '}
                          {providerInfo?.results[0]?.location?.country?.name}
                        </div>
                      </div>
                      <div className="info-container">
                        <div className="label">Street </div>
                        <div className="value">
                          {providerInfo?.results[0]?.location?.street}
                        </div>
                      </div>
                    </div>
                    <div className="details-col">
                      <div className="info-container">
                        <div className="label">Company number </div>
                        <div className="value">
                          {providerInfo?.results[0]?.client_number ?? 'N/A'}
                        </div>
                      </div>
                      <div className="info-container">
                        <div className="label">VAT Number </div>
                        <div className="value">
                          {providerInfo?.results[0]?.location?.vat_number ??
                            'N/A'}
                        </div>
                      </div>
                      <div className="info-container">
                        <div className="label">Date of Registration </div>
                        <div className="value">
                          {convertToLocalTime(
                            providerInfo?.results[0]?.created_at
                          )?.slice(0, 10)}
                        </div>
                      </div>
                    </div>
                    <div className="details-col">
                      <div className="info-container">
                        <div className="label">Postcode </div>
                        <div className="value">
                          {providerInfo?.results[0]?.location?.postal_code}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </motion.div>
              <motion.div
                ref={contactInformationRef}
                initial={{
                  height:
                    contactInformationRef?.current?.scrollHeight ?? 'auto',
                }}
                animate={{
                  height:
                    updateData?.contactInformation?.status === true
                      ? 'auto'
                      : showDropdown?.contactInformation
                        ? contactInformationRef?.current?.scrollHeight
                        : '90px',
                }}
                transition={{duration: 0.1}}
                className="details-card">
                <div
                  className={`dropdown-holder${
                    showDropdown?.contactInformation === true ? ' active' : ''
                  }`}
                  onClick={() => toggleShowDropdown('contactInformation')}>
                  Contact information
                  <div className="icon ">
                    <ChevronDownIcon />
                  </div>
                </div>

                {updateData?.contactInformation?.status === false && (
                  <div className="details-col-holder">
                    <div className="details-col">
                      <div className="info-container">
                        <div className="label">Email </div>
                        <div className="value">
                          {providerInfo?.results[0]?.email}
                        </div>
                      </div>
                      <div className="info-container">
                        <div className="label">Phone </div>
                        <div className="value">
                          {providerInfo?.results[0]?.phone_number}
                        </div>
                      </div>
                      <div className="info-container">
                        <div className="label">Street </div>
                        <div className="value">Company Street name</div>
                      </div>
                    </div>
                  </div>
                )}
              </motion.div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Provider;

function convertToLocalTime(isoString) {
  // Create a new Date object from the ISO string
  let date = new Date(isoString);

  // Get the local date and time components
  let options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // 24-hour format
    timeZoneName: 'short', // Include the time zone name
  };

  // Convert to a locale string
  let localTime = date.toLocaleString('en-US', options);

  return localTime;
}
