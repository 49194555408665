import {useMutation, useQueryClient, UseMutationResult} from 'react-query';
import {useNavigate} from 'react-router-dom';

import axiosInstance from './AxiosInstance';
interface LocationInfo {
  longitude: number | null;
  latitude: number | null;
  country_code: string | null;
  google_place_id: string | null;
  state: string | null;
  city: string | null;
  street: string | null;
  postal_code: string | null;
  region: string | null;
}
interface CreateWorkerParams {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  agency_uid: string | undefined;
  industries: string[];
  roles: string[];
  day: string;
  month: string;
  year: string;
  locationData: LocationInfo;
}

const createWorker = async (data: CreateWorkerParams) => {
  const date_of_birth =
    data.agency_uid !== undefined &&
    data.agency_uid === process.env.REACT_APP_OUTERBOX_ID
      ? '1998-08-24'
      : `${data.year}-${data.month}-${data.day}`;
  const locationInfo =
    data.locationData.postal_code === null ||
    data.locationData.street === null ||
    data.locationData.city === null
      ? (({postal_code, street, city, ...rest}: LocationInfo) => rest)(
          data.locationData
        )
      : {...data.locationData};
  const newData =
    data.agency_uid === ''
      ? (({agency_uid, ...rest}: CreateWorkerParams) => rest)(data)
      : {...data};

  const post = {...newData, date_of_birth, location: locationInfo};
  const response = await axiosInstance.post('/workers/', post);

  return response;
};

const useCreateWorker = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  CreateWorkerParams
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(createWorker, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('workers')
        .then(() => navigate('/onboarding/thank-you'))
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useCreateWorker;
