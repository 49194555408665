/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {useMutation} from 'react-query';

import axiosInstance from '../../hooks/services/AxiosInstance';

const RQCreateAgencyProvider = (onSuccess: any, onError: any) => {
  const sendDirectMessage = async (data: any) => {
    const location =
      data.location.postal_code === null ||
      data.location.street === null ||
      data.location.city === null
        ? (({postal_code, street, city, ...rest}: any) => rest)(data.location)
        : {...data.location};

    const newData = {...data, location};
    const response = await axiosInstance.post('/providers/', newData);
    return response?.data;
  };

  return useMutation(sendDirectMessage, {
    onSuccess,
    onError,
  });
};

export default RQCreateAgencyProvider;
