/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {ChevronDownIcon} from '@heroicons/react/24/outline';
import {motion} from 'framer-motion';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {FaEye} from 'react-icons/fa';
import {FaPencil, FaX} from 'react-icons/fa6';
import {FiDownloadCloud, FiUploadCloud} from 'react-icons/fi';
import {useParams, NavLink, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import ViewWorkerJobs from './ViewWorkerJobs';
import WorkerDetails from './WorkerDetails';
import Spinner from '../../Components/Common/Spinner';
import SubmitSpinner from '../../Components/Common/SubmitSpinner';
import TextArea from '../../Components/Common/TextArea';
import Select from '../../Components/Registration/Select';
import SuccessLayout from '../../Layouts/SuccessLayout';
import backIcon from '../../assets/icons/Circular-Backward.png';
import Camera from '../../assets/icons/person.svg';
import WorkerImage from '../../assets/icons/worker.png';
import useUpdateWorkerEmploymentStatus from '../../hooks/services/Agencys/useChangeEmploymentStatus';
import useChangeWorkerStatus from '../../hooks/services/Agencys/useChangeWorkerStatus';
import useGetWorker from '../../hooks/services/Agencys/useGetSingleWorker';
import useUpdatePersonalDetails from '../../hooks/services/Agencys/useUpdateWorkerPersonalDetails';
import useGetProviderDailyShiftsDetails from '../../hooks/services/Shifts/useGetDailyShiftsDetails';
import {getLocationInfo} from '../../utils/Methods';
import Tabs from '../Home/Components/Tabs';

type WorkExperienceType = {
  job_title: string;
  company: string;
  start_date: string;
  end_date: string;
};

type Props = {
  id?: string;
};

interface ShowDropdownState {
  [key: string]: boolean;
}

const ViewWorker = ({id}: Props) => {
  const {
    register,
    setValue,
    handleSubmit: handleSubmitContactDetails,
    handleSubmit: handleSubmitPersonalDetails,
    formState: {errors},
  } = useForm();
  const [showPopup, setShowPopup] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const {data: jobsData, isLoading: isLoadingJobsData} =
    useGetProviderDailyShiftsDetails({worker_uid: params.id});
  const {
    mutateAsync: personalDetailsMutation,
    isLoading: personalDetailsLoading,
  } = useUpdatePersonalDetails();
  const {data, isLoading} = useGetWorker(params.id || id || '');

  const [toggle, setToggle] = useState(false);
  const [status, setStatus] = useState('');
  const [buttonLabel, setButtonLabel] = useState('');
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState('');
  const {mutateAsync: approveMutation, isLoading: changeStatusLoading} =
    useChangeWorkerStatus(params.id || '');
  const {mutateAsync, isLoading: employmentStatusLaoding} =
    useUpdateWorkerEmploymentStatus();
  const [open, setOpen] = useState(false);
  const [employmentStatus, setEmploymentStatus] = useState<
    'ACTIVE' | 'TERMINATED' | 'BLOCKED' | 'RESIGNED'
  >('ACTIVE');

  const [showDropdown, setShowDropdown] = useState<ShowDropdownState>({
    personalDetails: true,
    contactDetails: false,
    workExperience: false,
    bankDetails: false,
    workerLogs: false,
    workerDocuments: false,
  });

  const personalDetailsRef = useRef<HTMLDivElement>(null);
  const contactDetailsRef = useRef<HTMLDivElement>(null);
  const workExperienceRef = useRef<HTMLDivElement>(null);
  const bankDetailsRef = useRef<HTMLDivElement>(null);
  const workerLogsRef = useRef<HTMLDivElement>(null);
  const workerDocumentsRef = useRef<HTMLDivElement>(null);
  const workerSkillsRef = useRef<HTMLDivElement>(null);

  const [updateData, setUpdateData] = useState({
    personalDetails: {status: false, ref: personalDetailsRef},
    contactDetails: {status: false, ref: contactDetailsRef},
    workExperience: {status: false, ref: workExperienceRef},
    bankDetails: {status: false, ref: bankDetailsRef},
    workerLogs: {status: false, ref: workerLogsRef},
    workerDocuments: {status: false, ref: workerDocumentsRef},
    workerSkills: {status: false, ref: workerSkillsRef},
  });

  const toggleShowDropdown = (name: string) => {
    setShowDropdown((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleToggle = (statusText: string, label: string) => {
    setToggle(true);
    setStatus(statusText);
    setButtonLabel(label);
  };
  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const {value} = event.target;
    setComment(value);
  };
  const handleRatingChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target;
    setRating(value);
  };

  const tabs = [
    {
      id: 'tab1',
      label: 'Worker Details',
      component: <WorkerDetails data={data} />,
    },
    {
      id: 'tab2',
      label: 'Jobs',
      component: <ViewWorkerJobs id={params.id || ''} />,
    },
    {
      id: 'tab3',
      label: 'Timesheets',
      component: <WorkerDetails data={data} />,
    },
  ];

  const handleBlockWorker = async () => {
    await mutateAsync({
      worker_uid: params.id || '',
      employment_status: 'BLOCKED',
    })
      .then(() => setEmploymentStatus('BLOCKED'))
      .then(() => setOpen(true));
  };
  const handleTerminateWorker = async () => {
    await mutateAsync({
      worker_uid: params.id || '',
      employment_status: 'TERMINATED',
    })
      .then(() => setEmploymentStatus('TERMINATED'))
      .then(() => setOpen(true));
  };
  const handleActivateWorker = async () => {
    await mutateAsync({
      worker_uid: params.id || '',
      employment_status: 'ACTIVE',
    })
      .then(() => setEmploymentStatus('ACTIVE'))
      .then(() => setOpen(true));
  };

  const identificationDocuments = data?.Worker.worker_documents.filter(
    (obj) => obj.document_type === 'CERTIFICATE' || obj.document_type === null
  );
  const licenses = data?.Worker.worker_documents.filter(
    (obj) => obj.document_type === 'LICENSE'
  );

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const post = {
      status,
      comment,
      rating: 'C',
      worker_id: params.id !== undefined ? params.id : '',
    };
    await approveMutation(post)
      .then(() =>
        toast.success(
          `Worker ${
            buttonLabel === 'Approve' ? 'Approved' : 'Rejected'
          } successfully`,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          }
        )
      )
      .catch(() =>
        toast.error('An error occured', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      );
  };
  const profilePicture = data?.Worker.worker_documents.filter(
    (document) => document.document_type === 'IDENTIFICATION'
  );

  const toggleEditUserDetails = (name: string) => {
    setUpdateData((prevState) => ({
      ...prevState,
      // @ts-ignore
      [name]: {status: !prevState[name].status},
    }));
  };

  useEffect(() => {
    if (data?.Worker !== undefined) {
      //Personal Details
      setValue(
        'name',
        `${data.Worker.user.first_name} ${data.Worker.user.last_name}`
      );
      setValue('email', data.Worker?.user?.email);
      setValue(
        'phone_number',
        data.Worker.contact_details !== null
          ? data.Worker.contact_details?.phone_number
          : 'Not set'
      );
      setValue(
        'pronoun',
        data.Worker.worker_personal_details !== null
          ? data.Worker.worker_personal_details.pronoun
          : 'Not set'
      );
      setValue(
        'date_of_birth',
        data.Worker.worker_personal_details !== null
          ? data.Worker.worker_personal_details.date_of_birth
          : 'Not set'
      );
      setValue(
        'nationality',
        data.Worker.worker_personal_details !== null
          ? data.Worker.worker_personal_details.nationality
          : 'Not set'
      );
      setValue(
        'national_insurance',
        data.Worker.worker_personal_details !== null
          ? data.Worker.worker_personal_details.national_insurance
          : 'Not set'
      );

      //Contact Details
      setValue(
        'postal_address',
        data.Worker.contact_details !== null
          ? data.Worker.contact_details?.address
          : 'Not set'
      );
      setValue(
        'postal_code',
        data.Worker.contact_details !== null
          ? data.Worker.contact_details?.postal_code
          : 'Not set'
      );
      setValue(
        'town',
        data.Worker.contact_details !== null
          ? data.Worker.contact_details?.town
          : 'Not set'
      );
      setValue(
        'kin_name',
        data.Worker.contact_details !== null
          ? data.Worker.contact_details?.next_of_kin_name
          : 'Not set'
      );
      setValue(
        'kin_email',
        data.Worker.contact_details !== null
          ? data.Worker.contact_details?.next_of_kin_email
          : 'Not set'
      );
      setValue(
        'kin_phone',
        data.Worker.contact_details !== null
          ? data.Worker.contact_details?.next_of_kin_phone_number
          : 'Not set'
      );

      //Work Experience
      // data.Worker.worker_experience.map((experience: WorkExperienceType) => {
      //   setValue('job_title', experience.job_title);
      //   setValue('company', experience.company);
      //   setValue('start_date', experience.start_date);
      //   setValue('end_date', experience.end_date);
      // });

      //Bank Details
      setValue(
        'bank_name',
        data.Worker.bank_details !== null
          ? data.Worker.bank_details.bank_name
          : 'Not set'
      );
      setValue(
        'account_name',
        data.Worker.bank_details !== null
          ? data.Worker.bank_details.account_name
          : 'Not set'
      );
      setValue(
        'account_number',
        data.Worker.bank_details !== null
          ? data.Worker.bank_details.account_number
          : 'Not set'
      );
      setValue(
        'sort_code',
        data.Worker.bank_details !== null
          ? data.Worker.bank_details.sort_code
          : 'Not set'
      );
    }
  }, [data]);

  const onSubmitPersonalDetails = async (data: any) => {
    const post = {
      ...data,
      worker_uid: params.id !== undefined ? params.id : '',
    };
    toggleEditUserDetails('personalDetails');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    await personalDetailsMutation(post);
  };

  useEffect(() => {
    if (data !== undefined && data.Worker.missing_information.length > 0) {
      setShowPopup(true);
    }
  }, [data]);

  if (isLoading || isLoadingJobsData || personalDetailsLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <SuccessLayout isOpen={open} onClose={() => setOpen(false)}>
        <p className="text-lg text-center font-bold">
          The worker has been{' '}
          {employmentStatus === 'BLOCKED'
            ? 'Blocked'
            : employmentStatus === 'ACTIVE'
              ? 'Activated'
              : 'Terminated'}{' '}
          successfully
        </p>
      </SuccessLayout>

      {/* <div className={`popup-container${showPopup ? ' show' : ''}`}>
        <div className="popup-overlay" onClick={() => setShowPopup(false)} />

        <div className="popup-panel">
          <div className="close" onClick={() => setShowPopup(false)}>
            <FaX />
          </div>

          <h1 className="title">Missing Information</h1>
          <p>
            Please fill out the below sections to complete the worker's profile
          </p>

          <div className="missing-info-holder">
            <ul className="missing-info">
              {data?.Worker?.missing_information.map((info, index) => (
                <li className="label" key={index}>
                  {info}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div> */}
      <div className="seeker-profile-container">
        <div className="profile-max-width">
          <div className="profile-header">
            <div
              className="back-icon"
              onClick={() => navigate(`/agency/workers`)}>
              <img src={backIcon} alt="" />
            </div>
            <div className="col">
              <div className="profile-img">
                <img
                  src={
                    data !== undefined &&
                    data.Worker.worker_documents !== null &&
                    data.Worker.worker_documents.length > 0 &&
                    profilePicture !== null &&
                    profilePicture !== undefined
                      ? profilePicture[0].url
                      : Camera
                  }
                  alt="worker"
                  className="h-fit w-fit"
                />
              </div>
            </div>
            <div className="col">
              <h1 className="profile-title">
                {data !== undefined
                  ? `${data.Worker.user.first_name} ${data.Worker.user.last_name}`
                  : ''}

                <span> ({data?.Worker?.status?.toLowerCase()})</span>
                <span className="rating">
                  {' '}
                  {data?.Worker.rating !== null
                    ? data?.Worker.rating
                    : 'Not rated'}
                </span>
              </h1>

              {data?.Worker.agency !== process.env.REACT_APP_OUTERBOX_ID &&
                data?.Worker.status === 'SEEKER' && (
                  <div className="strikes">
                    Seeker Strikes:
                    <span>
                      {data?.Worker?.worker_strikes[0]?.no_of_strikes ?? 0}/3
                    </span>
                  </div>
                )}
              <div className="profile-sub-title">
                {data?.Worker?.user?.email}
              </div>
              <div className="profile-sub-title">
                {data?.Worker.contact_details === null
                  ? 'Not set'
                  : data?.Worker.contact_details?.phone_number}
              </div>
              <div className="profile-sub-title row">
                {data?.Worker?.worker_personal_details !== null &&
                data?.Worker?.worker_personal_details?.languages !== null
                  ? data?.Worker?.worker_personal_details?.languages.map(
                      (language, index) => (
                        <span key={index} className="language">
                          {language}
                        </span>
                      )
                    )
                  : ''}
              </div>
              <div
                className={`status ${
                  data?.Worker.employment_status === 'ACTIVE'
                    ? 'bg-plannaSecondaryGreen50 text-white'
                    : data?.Worker.employment_status === 'BLOCKED'
                      ? 'bg-plannaTertiaryRed text-white'
                      : 'bg-plannaPrimaryOrange text-black'
                } mt-1   text-center text-sm  px-6 py-1 rounded-full w-fit`}>
                {(data?.Worker.employment_status as string)?.toLowerCase()}
              </div>

              <div className="actions">
                {/* {data !== undefined &&
                  data?.Worker.missing_information.length < 1 && (
                    <div className="mt-4 p-4">
                      {data.Worker.status === 'SEEKER' ||
                      data.Worker.status === 'REJECTED' ? (
                        ''
                      ) : (
                        <>
                          {!toggle && (
                            <div className="flex space-x-4 ">
                              <button
                                onClick={() =>
                                  handleToggle('SEEKER', 'Approve')
                                }
                                className="px-6 py-2  bg-plannaPrimaryGreen text-plannaAccentGreen rounded-full">
                                Approve
                              </button>
                              <button
                                onClick={() =>
                                  handleToggle('REJECTED', 'Reject')
                                }
                                className="px-6 py-2 bg-plannaNeutral text-center font-semibold text-plannaPrimaryGreen rounded-full">
                                Reject
                              </button>
                            </div>
                          )}
                        </>
                      )}
                      {toggle && (
                        <>
                          <div className="mt-4 w-2/3">
                            <form onSubmit={onSubmit}>
                              <div className="mt-2">
                                <Select
                                  options={['A*', 'A', 'B', 'C']}
                                  label="Provide a rating"
                                  name="rating"
                                  onChange={handleRatingChange}
                                  inputValue={rating}
                                  required
                                />
                              </div>
                              <div className="mt-2">
                                <TextArea
                                  label="Leave a comment"
                                  value={comment}
                                  name="comment"
                                  onChange={handleCommentChange}
                                  required
                                />
                              </div>
                              <button
                                className={`${
                                  status === 'SEEKER'
                                    ? 'bg-plannaPrimaryGreen text-plannaAccentGreen'
                                    : 'bg-plannaTertiaryRed text-plannaNeutralWhite'
                                } px-8 mt-2 py-2   rounded-full`}>
                                {changeStatusLoading ? (
                                  <SubmitSpinner />
                                ) : (
                                  buttonLabel
                                )}
                              </button>
                            </form>
                          </div>
                        </>
                      )}
                    </div>
                  )} */}
              </div>
            </div>

            <div className="col">
              <div className="approve-actions">
                {data?.Worker.status === 'SEEKER' && (
                  <div className="flex flex-col">
                    <div className="flex space-x-4">
                      {data?.Worker.employment_status === 'BLOCKED' ? (
                        <button
                          onClick={handleActivateWorker}
                          className=" btn primary">
                          {employmentStatusLaoding ? (
                            <SubmitSpinner />
                          ) : (
                            ' Activate Worker'
                          )}
                        </button>
                      ) : (
                        <>
                          {data?.Worker.employment_status === 'ACTIVE' && (
                            <button
                              onClick={handleBlockWorker}
                              className=" btn danger">
                              {employmentStatusLaoding ? (
                                <SubmitSpinner />
                              ) : (
                                ' Block Worker'
                              )}
                            </button>
                          )}
                        </>
                      )}
                      {data?.Worker.employment_status === 'TERMINATED' ? (
                        <button
                          onClick={handleActivateWorker}
                          className=" btn primary">
                          {employmentStatusLaoding ? (
                            <SubmitSpinner />
                          ) : (
                            'Activate Worker'
                          )}
                        </button>
                      ) : (
                        <>
                          {data?.Worker.employment_status === 'ACTIVE' && (
                            <button
                              onClick={handleTerminateWorker}
                              className="btn cancel">
                              {employmentStatusLaoding ? (
                                <SubmitSpinner />
                              ) : (
                                'Terminate Worker'
                              )}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="profile-body">
          <div className="nav-tabs">
            <div className="max-width">
              <NavLink
                to={`/agency/single-worker/${params?.id || ''}/worker-details`}
                className={`nav-tab${
                  params?.tab == null || params?.tab == undefined
                    ? ' active'
                    : ''
                }`}>
                Worker Details
              </NavLink>
              {data?.Worker.agency !== process.env.REACT_APP_OUTERBOX_ID && (
                <>
                  <NavLink
                    to={`/agency/single-worker/${params?.id || ''}/jobs`}
                    className="nav-tab">
                    Jobs
                  </NavLink>
                  <NavLink
                    to={`/agency/single-worker/${params?.id || ''}/timesheets`}
                    className="nav-tab">
                    Timesheets
                  </NavLink>
                </>
              )}
            </div>
          </div>

          {(params?.tab === 'worker-details' || params?.tab === undefined) && (
            <div className="worker-details">
              <div className="max-width">
                <motion.div
                  ref={personalDetailsRef}
                  initial={{
                    height: personalDetailsRef?.current?.scrollHeight ?? 'auto',
                  }}
                  animate={{
                    height:
                      updateData?.personalDetails?.status === true
                        ? 'auto'
                        : showDropdown?.personalDetails
                          ? (
                              personalDetailsRef?.current as unknown as HTMLElement
                            )?.scrollHeight
                          : '90px',
                  }}
                  transition={{duration: 0.1}}
                  className="details-card">
                  <div
                    className={`dropdown-holder${
                      showDropdown?.personalDetails === true ? ' active' : ''
                    }`}
                    onClick={() => toggleShowDropdown('personalDetails')}>
                    Personal Details
                    <div className="icon ">
                      <ChevronDownIcon />
                    </div>
                  </div>

                  {updateData?.personalDetails?.status === true && (
                    <div className="editable-form p-2">
                      <form
                        onSubmit={handleSubmitPersonalDetails(
                          onSubmitPersonalDetails
                        )}
                        className="form-action">
                        <div className="split-form-group">
                          <div className="form-group">
                            <label htmlFor="" className="form-label">
                              Name
                            </label>
                            <input
                              type="text"
                              {...register('name', {
                                required: 'Name is required',
                              })}
                              className="form-input"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="" className="form-label">
                              Email
                            </label>
                            <input
                              {...register('email', {
                                required: 'Email is required',
                              })}
                              type="text"
                              className="form-input"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="" className="form-label">
                              Mobile
                            </label>
                            <input
                              type="tel"
                              {...register('phone_number', {
                                required: 'Phone number is required',
                              })}
                              className="form-input"
                            />
                          </div>
                        </div>
                        <div className="split-form-group">
                          <div className="form-group">
                            <label htmlFor="" className="form-label">
                              Pronoun
                            </label>
                            <select
                              {...register('pronoun', {
                                required: 'Pronoun is required',
                              })}
                              className="form-input">
                              <option value="">Select pronoun</option>
                              <option value="Mr">Mr.</option>
                              <option value="Mrs">Mrs.</option>
                              <option value="Miss">Miss</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="" className="form-label">
                              Date of Birth
                            </label>
                            <input
                              type="date"
                              {...register('date_of_birth', {
                                required: 'Date of birth is required',
                              })}
                              className="form-input"
                            />
                          </div>
                        </div>

                        <div className="split-form-group">
                          <div className="form-group">
                            <label htmlFor="" className="form-label">
                              Nationality
                            </label>
                            <input
                              type="text"
                              {...register('nationality', {
                                required: 'Please enter your nationality',
                              })}
                              className="form-input"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="" className="form-label">
                              National Insurance
                            </label>
                            <input
                              type="number"
                              {...register('national_insurance', {
                                required:
                                  'Please enter your national insurance number',
                              })}
                              className="form-input"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <button
                            type="button"
                            onClick={() =>
                              toggleEditUserDetails('personalDetails')
                            }
                            className="btn danger">
                            Cancel
                          </button>
                          <button type="submit" className="btn primary">
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                  {updateData?.personalDetails?.status === false && (
                    <div className="details-col-holder">
                      <div className="details-col">
                        <div className="info-container">
                          <div className="label">Name </div>
                          <div className="value">
                            {data !== undefined
                              ? `${data.Worker.user.first_name} ${data.Worker.user.last_name}`
                              : ''}
                          </div>
                        </div>
                        <div className="info-container">
                          <div className="label">Email </div>
                          <div className="value">{data?.Worker.user.email}</div>
                        </div>
                        <div className="info-container">
                          <div className="label">Location </div>
                          <div className="value">
                            {data?.Worker.location !== null
                              ? data?.Worker.location.region
                              : ''}
                            ,
                            {data?.Worker.location !== null
                              ? data?.Worker.location.country.name
                              : ''}
                          </div>
                        </div>
                        <div className="info-container">
                          <div className="label">Postal Code </div>
                          <div className="value">
                            {data?.Worker.location.postal_code ?? 'null'}
                          </div>
                        </div>

                        <div className="info-container">
                          <div className="label">Portfolio URL </div>
                          <a
                            href={data?.Worker.portifolio_url}
                            target="_blank"
                            className="value underline text-blue-500">
                            Portfolio URL
                          </a>
                        </div>
                        {data?.Worker.agency !==
                          process.env.REACT_APP_OUTERBOX_ID && (
                          <>
                            <div className="info-container">
                              <div className="label">Mobile </div>
                              <div className="value">
                                {data?.Worker?.user?.email}
                              </div>
                            </div>
                            <div className="info-container">
                              <div className="label">Date of birth</div>
                              <div className="value">
                                {data?.Worker.worker_personal_details !== null
                                  ? data?.Worker.worker_personal_details
                                      .date_of_birth
                                  : 'Not set'}
                              </div>
                            </div>
                            <div className="info-container">
                              <div className="label">Student</div>
                              <div className="value">
                                {data?.Worker.worker_personal_details !== null
                                  ? data?.Worker.worker_personal_details
                                      .is_student
                                    ? 'Yes'
                                    : 'No'
                                  : 'Not set'}
                              </div>
                            </div>
                            <div className="info-container">
                              <div className="label">
                                Working time directive
                              </div>
                              <div className="value">
                                {data?.Worker.worker_personal_details !== null
                                  ? data?.Worker.worker_personal_details?.opt_in
                                    ? 'Opted In'
                                    : 'Opted Out'
                                  : 'Not set'}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {data?.Worker.agency !==
                        process.env.REACT_APP_OUTERBOX_ID && (
                        <>
                          <div className="details-col">
                            <div className="info-container">
                              <div className="label">Marital Status</div>
                              <div className="value">
                                {data?.Worker.worker_personal_details !== null
                                  ? data?.Worker.worker_personal_details
                                      .marital_status
                                  : 'Not set'}
                              </div>
                            </div>
                            <div className="info-container">
                              <div className="label">Pronoun</div>
                              <div className="value">
                                {data?.Worker.worker_personal_details !== null
                                  ? data?.Worker.worker_personal_details.pronoun
                                  : 'Not set'}
                              </div>
                            </div>
                            <div className="info-container">
                              <div className="label">Nationality</div>
                              <div className="value">
                                {data?.Worker.worker_personal_details !== null
                                  ? data?.Worker.worker_personal_details
                                      ?.nationality
                                  : 'Not set'}
                              </div>
                            </div>
                            <div className="info-container">
                              <div className="label">National Insurance</div>
                              <div className="value">
                                {data?.Worker.worker_personal_details !== null
                                  ? data?.Worker.worker_personal_details
                                      ?.national_insurance
                                  : 'Not set'}
                              </div>
                            </div>
                          </div>
                          <div className="details-col">
                            <div className="info-container">
                              <div className="label">Has medical condition</div>
                              <div className="value">
                                {data?.Worker.worker_personal_details !== null
                                  ? data?.Worker.worker_personal_details
                                      .has_medical_condition === true
                                    ? 'Yes'
                                    : 'No'
                                  : 'Not set'}
                              </div>
                            </div>
                            <div className="info-container">
                              <div className="label">Medical Condition</div>
                              <div className="value">
                                {data?.Worker.worker_personal_details !== null
                                  ? data?.Worker.worker_personal_details
                                      .medical_condition
                                    ? data?.Worker.worker_personal_details
                                        .medical_condition
                                    : 'No condition'
                                  : 'Not set'}
                              </div>
                            </div>

                            <div className="info-container">
                              <div className="label">Criminal Conviction</div>
                              <div className="value">
                                {data?.Worker.worker_personal_details !== null
                                  ? data?.Worker.worker_personal_details
                                      .has_criminal_conviction === true
                                    ? 'Yes'
                                    : 'No'
                                  : 'Not set'}
                              </div>
                            </div>

                            <div className="info-container">
                              <div className="label">
                                Criminal Conviction Comment
                              </div>
                              <div className="value">
                                {data?.Worker.worker_personal_details !== null
                                  ? data?.Worker.worker_personal_details
                                      .criminal_conviction_comment
                                    ? data?.Worker.worker_personal_details
                                        .criminal_conviction_comment
                                    : 'No conviction'
                                  : 'Not set'}
                              </div>
                            </div>
                          </div>
                          <div className="details-col">
                            <div
                              className="btn secondary"
                              onClick={() =>
                                toggleEditUserDetails('personalDetails')
                              }>
                              Edit Personal Details{' '}
                              <div className="icon">
                                <FaPencil />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </motion.div>
                {data?.Worker.agency !== process.env.REACT_APP_OUTERBOX_ID && (
                  <>
                    <motion.div
                      ref={contactDetailsRef}
                      className="details-card"
                      initial={{height: '90px'}}
                      animate={{
                        height:
                          updateData?.contactDetails?.status === true
                            ? 'auto'
                            : showDropdown?.contactDetails
                              ? (
                                  contactDetailsRef?.current as unknown as HTMLElement
                                )?.scrollHeight
                              : '90px',
                      }}
                      transition={{duration: 0.1}}>
                      <div
                        className={`dropdown-holder${
                          showDropdown?.contactDetails === true ? ' active' : ''
                        }`}
                        onClick={() => toggleShowDropdown('contactDetails')}>
                        Contact Details
                        <div className="icon ">
                          <ChevronDownIcon />
                        </div>
                      </div>

                      {updateData?.contactDetails?.status === true && (
                        <div className="editable-form p-2">
                          <form action="" className="form-action">
                            <div className="split-form-group">
                              <div className="form-group">
                                <label htmlFor="" className="form-label">
                                  Postal Address
                                </label>
                                <input
                                  {...register('postal_address', {
                                    required: 'Postal Address is required',
                                  })}
                                  type="text"
                                  className="form-input"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="" className="form-label">
                                  Postal Code
                                </label>
                                <input
                                  {...register('postal_code', {
                                    required: 'Postal Code is required',
                                  })}
                                  type="text"
                                  className="form-input"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="" className="form-label">
                                  Town
                                </label>
                                <input
                                  {...register('town', {
                                    required: 'Town is required',
                                  })}
                                  type="text"
                                  className="form-input"
                                />
                              </div>
                            </div>
                            <div className="split-form-group">
                              <div className="form-group">
                                <label htmlFor="" className="form-label">
                                  Kin Name
                                </label>
                                <input
                                  {...register('kin_name', {
                                    required: 'Next of kin name is required',
                                  })}
                                  type="text"
                                  className="form-input"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="" className="form-label">
                                  Kin Email
                                </label>
                                <input
                                  {...register('kin_email', {
                                    required: 'Next of kin email is required',
                                  })}
                                  type="text"
                                  className="form-input"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="" className="form-label">
                                  Kin Phone
                                </label>
                                <input
                                  {...register('kin_phone', {
                                    required: 'Next of kin phone is required',
                                  })}
                                  type="text"
                                  className="form-input"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <button
                                type="button"
                                onClick={() =>
                                  toggleEditUserDetails('contactDetails')
                                }
                                className="btn danger">
                                Cancel
                              </button>
                              <button type="submit" className="btn primary">
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                      )}

                      {updateData?.contactDetails?.status === false && (
                        <div className="details-col-holder">
                          <div className="details-col">
                            <div className="info-container">
                              <div className="label">Email </div>
                              <div className="value">
                                {data?.Worker?.contact_details?.email === null
                                  ? 'Not set'
                                  : data?.Worker?.contact_details?.email}
                              </div>
                            </div>
                            <div className="info-container">
                              <div className="label">Phone </div>
                              <div className="value">
                                {data?.Worker?.contact_details?.phone_number ===
                                null
                                  ? 'Not set'
                                  : data?.Worker?.contact_details?.phone_number}
                              </div>
                            </div>
                            <div className="info-container">
                              <div className="label">Postal Address </div>
                              <div className="value">
                                {data?.Worker?.contact_details === null
                                  ? 'Not set'
                                  : data?.Worker?.contact_details?.address}
                              </div>
                            </div>
                            <div className="info-container">
                              <div className="label">Postal Code </div>
                              <div className="value">
                                {data?.Worker?.contact_details === null
                                  ? 'Not set'
                                  : data?.Worker?.contact_details?.postal_code}
                              </div>
                            </div>
                            <div className="info-container">
                              <div className="label">Town </div>
                              <div className="value">
                                {data?.Worker?.contact_details === null
                                  ? 'Not set'
                                  : data?.Worker?.contact_details?.town}
                              </div>
                            </div>
                          </div>
                          <div className="details-col">
                            <div className="info-container">
                              <div className="label">Kin Name</div>
                              <div className="value">
                                {data?.Worker?.contact_details === null
                                  ? 'Not set'
                                  : data?.Worker?.contact_details
                                      ?.next_of_kin_name}
                              </div>
                            </div>
                            <div className="info-container">
                              <div className="label">Kin Email</div>
                              <div className="value">
                                {data?.Worker?.contact_details === null
                                  ? 'Not set'
                                  : data?.Worker?.contact_details
                                      ?.next_of_kin_email}
                              </div>
                            </div>
                            <div className="info-container">
                              <div className="label">Kin Phone</div>
                              <div className="value">
                                {data?.Worker?.contact_details === null
                                  ? 'Not set'
                                  : data?.Worker?.contact_details
                                      .next_of_kin_phone_number}
                              </div>
                            </div>
                          </div>
                          <div className="details-col">
                            <div
                              className="btn secondary"
                              onClick={() =>
                                toggleEditUserDetails('contactDetails')
                              }>
                              Edit Contact Details{' '}
                              <div className="icon">
                                <FaPencil />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </motion.div>
                    <motion.div
                      className="details-card"
                      initial={{height: '90px'}}
                      ref={workExperienceRef}
                      animate={{
                        height:
                          updateData?.contactDetails?.status === true
                            ? 'auto'
                            : showDropdown?.workExperience
                              ? (
                                  workExperienceRef?.current as unknown as HTMLElement
                                )?.scrollHeight
                              : '90px',
                      }}
                      transition={{duration: 0.1}}>
                      <div
                        className={`dropdown-holder${
                          showDropdown?.workExperience === true ? ' active' : ''
                        }`}
                        onClick={() => toggleShowDropdown('workExperience')}>
                        Work Experience
                        <div className="icon">
                          <ChevronDownIcon />
                        </div>
                      </div>

                      <div className="editable-form p-2">
                        <form action="" className="form-action">
                          <div className="split-form-group">
                            <div className="form-group">
                              <label htmlFor="" className="form-label" />
                            </div>
                          </div>
                        </form>
                      </div>

                      {data !== undefined &&
                      data.Worker.worker_experience.length > 0 ? (
                        <>
                          {data?.Worker.worker_experience.map(
                            (experience: WorkExperienceType, index) => (
                              <div className="details-col-holder" key={index}>
                                <div className="details-col experience">
                                  <span>{index + 1}</span>
                                  <div className="info-container">
                                    <div className="label">Title: </div>
                                    <div className="value">
                                      {experience.job_title}
                                    </div>
                                  </div>
                                  <div className="info-container">
                                    <div className="label">Company: </div>
                                    <div className="value">
                                      {experience.company}
                                    </div>
                                  </div>
                                  <div className="info-container">
                                    <div className="label">Start: </div>
                                    <div className="value">
                                      {experience.start_date}
                                    </div>
                                  </div>
                                  <div className="info-container">
                                    <div className="label">End: </div>
                                    <div className="value">
                                      {experience.end_date}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        'Worker experience not set'
                      )}
                    </motion.div>
                    <motion.div
                      className="details-card"
                      initial={{height: '90px'}}
                      ref={bankDetailsRef}
                      animate={{
                        height:
                          updateData?.bankDetails?.status === true
                            ? 'auto'
                            : showDropdown?.bankDetails
                              ? (
                                  bankDetailsRef?.current as unknown as HTMLElement
                                )?.scrollHeight
                              : '90px',
                      }}
                      transition={{duration: 0.1}}>
                      <div
                        className={`dropdown-holder${
                          showDropdown?.bankDetails === true ? ' active' : ''
                        }`}
                        onClick={() => toggleShowDropdown('bankDetails')}>
                        Bank Details
                        <div className="icon ">
                          <ChevronDownIcon />
                        </div>
                      </div>

                      {updateData?.bankDetails?.status === true && (
                        <div className="editable-form p-2">
                          <form action="" className="form-action">
                            <div className="split-form-group">
                              <div className="form-group">
                                <label htmlFor="" className="form-label">
                                  Bank Name
                                </label>
                                <input
                                  {...register('bank_name', {
                                    required: 'Bank name is required',
                                  })}
                                  type="text"
                                  className="form-input"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="" className="form-label">
                                  Sort Code
                                </label>
                                <input
                                  {...register('sort_code', {
                                    required: 'Sort code is required',
                                  })}
                                  type="text"
                                  className="form-input"
                                />
                              </div>
                            </div>
                            <div className="split-form-group">
                              <div className="form-group">
                                <label htmlFor="" className="form-label">
                                  Account Name
                                </label>
                                <input
                                  {...register('account_name', {
                                    required: 'Account name is required',
                                  })}
                                  type="text"
                                  className="form-input"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="" className="form-label">
                                  Account Number
                                </label>
                                <input
                                  {...register('account_number', {
                                    required: 'Account number is required',
                                  })}
                                  type="text"
                                  className="form-input"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <button
                                type="button"
                                onClick={() =>
                                  toggleEditUserDetails('bankDetails')
                                }
                                className="btn danger">
                                Cancel
                              </button>
                              <button type="submit" className="btn primary">
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                      )}

                      {updateData?.bankDetails?.status === false && (
                        <div className="details-col-holder">
                          <div className="details-col">
                            <div className="info-container">
                              <div className="label">Bank Name </div>
                              <div className="value">
                                {data?.Worker.bank_details === null
                                  ? 'Not set'
                                  : data?.Worker.bank_details.bank_name}
                              </div>
                            </div>
                            <div className="info-container">
                              <div className="label">Sort Code </div>
                              <div className="value">
                                {data?.Worker.bank_details === null
                                  ? 'Not set'
                                  : data?.Worker.bank_details.sort_code}
                              </div>
                            </div>
                          </div>
                          <div className="details-col">
                            <div className="info-container">
                              <div className="label">Account Name</div>
                              <div className="value">
                                {data?.Worker.bank_details === null
                                  ? 'Not set'
                                  : data?.Worker.bank_details.account_name}
                              </div>
                            </div>
                            <div className="info-container">
                              <div className="label">Account Number</div>
                              <div className="value">
                                {data?.Worker.bank_details === null
                                  ? 'Not set'
                                  : data?.Worker.bank_details.account_number}
                              </div>
                            </div>
                          </div>
                          <div className="details-col">
                            <div
                              className="btn secondary"
                              onClick={() =>
                                toggleEditUserDetails('bankDetails')
                              }>
                              Edit Bank Details{' '}
                              <div className="icon">
                                <FaPencil />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </motion.div>
                    <motion.div
                      className="details-card"
                      initial={{height: '90px'}}
                      ref={workerDocumentsRef}
                      animate={{
                        height: showDropdown?.workerDocuments
                          ? (
                              workerDocumentsRef?.current as unknown as HTMLElement
                            )?.scrollHeight
                          : '90px',
                      }}
                      transition={{duration: 0.1}}>
                      <div
                        className={`dropdown-holder${
                          showDropdown?.workerDocuments === true
                            ? ' active'
                            : ''
                        }`}
                        onClick={() => toggleShowDropdown('workerDocuments')}>
                        Worker Documents
                        <div className="icon ">
                          <ChevronDownIcon />
                        </div>
                      </div>

                      <div className="details-col-holder full">
                        <div className="details-col">
                          {/* <div
                            className="btn secondary"
                            onClick={() =>
                              toggleEditUserDetails('bankDetails')
                            }>
                            Upload Documents{' '}
                            <div className="icon">
                              <FiUploadCloud />
                            </div>
                          </div> */}
                        </div>
                        <div className="details-col">
                          <h2>Identification Documents</h2>
                          <div className="docs-holder">
                            {identificationDocuments !== undefined &&
                            identificationDocuments.length > 0 ? (
                              <>
                                {identificationDocuments?.map(
                                  (document, index) =>
                                    document.file_name !== null && (
                                      <div className="doc" key={index}>
                                        <div className="doc-info">
                                          {index + 1}
                                        </div>
                                        <div className="doc-info">
                                          {document.file_name}
                                        </div>
                                        <div className="doc-info">
                                          {document.expiry_date === null ||
                                          document.expiry_date === ''
                                            ? 'No expiry date'
                                            : document.expiry_date}
                                        </div>
                                        <div className="doc-info action">
                                          <a
                                            href={
                                              document.url !== null
                                                ? document.url
                                                : '#'
                                            }
                                            target="_blank"
                                            className="action view">
                                            <FaEye />
                                          </a>
                                        </div>
                                        <div className="doc-info action">
                                          <a
                                            href={
                                              document.url !== null
                                                ? document.url
                                                : '#'
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            download={document.file_name}
                                            className="action download">
                                            <FiDownloadCloud />
                                          </a>
                                        </div>
                                      </div>
                                    )
                                )}
                              </>
                            ) : (
                              'Identification documents not set'
                            )}
                          </div>
                        </div>
                        <div className="details-col">
                          <h2>Licenses & Trainings</h2>
                          <div className="docs-holder">
                            {licenses !== undefined && licenses.length > 0 ? (
                              <>
                                {licenses?.map(
                                  (document, index) =>
                                    document.file_name !== null && (
                                      <div className="doc" key={index}>
                                        <div className="doc-info">
                                          {index + 1}
                                        </div>
                                        <div className="doc-info">
                                          {document.file_name}
                                        </div>
                                        <div className="doc-info">
                                          {document.expiry_date === null ||
                                          document.expiry_date === ''
                                            ? 'No expiry date'
                                            : document.expiry_date}
                                        </div>
                                        <div className="doc-info action">
                                          <a
                                            href={
                                              document.url !== null
                                                ? document.url
                                                : '#'
                                            }
                                            target="_blank"
                                            className="action view">
                                            <FaEye />
                                          </a>
                                        </div>
                                        <div className="doc-info action">
                                          <a
                                            href={
                                              document.url !== null
                                                ? document.url
                                                : '#'
                                            }
                                            download
                                            className="action download">
                                            <FiDownloadCloud />
                                          </a>
                                        </div>
                                      </div>
                                    )
                                )}
                              </>
                            ) : (
                              'Licence documents not set'
                            )}
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </>
                )}
                {data?.Worker.agency !== process.env.REACT_APP_OUTERBOX_ID && (
                  <motion.div
                    className="details-card"
                    initial={{height: '90px'}}
                    ref={workerLogsRef}
                    animate={{
                      height:
                        updateData?.workerLogs?.status === true
                          ? 'auto'
                          : showDropdown?.workerLogs
                            ? (workerLogsRef?.current as unknown as HTMLElement)
                                ?.scrollHeight
                            : '90px',
                    }}
                    transition={{duration: 0.1}}>
                    <div
                      className={`dropdown-holder${
                        showDropdown?.workerLogs === true ? ' active' : ''
                      }`}
                      onClick={() => toggleShowDropdown('workerLogs')}>
                      Worker Logs
                      <div className="icon ">
                        <ChevronDownIcon />
                      </div>
                    </div>

                    {updateData?.bankDetails?.status === false && (
                      <div className="details-col-holder">
                        <div className="details-col">
                          {data?.Worker.worker_log.map(
                            (log: any, index: number) => (
                              <>
                                <div className="info-container" key={index}>
                                  <div className="label">{index + 1}.</div>
                                </div>
                                <div className="info-container" key={index}>
                                  <div className="value">{log.status}</div>
                                  <div className="value">{log.comment}</div>
                                </div>
                              </>
                            )
                          )}
                        </div>
                        <div className="details-col" />
                        {/* <div className="details-col">
                        <div
                          className="btn secondary"
                          onClick={() => toggleEditUserDetails('bankDetails')}>
                          Edit Bank Details{' '}
                          <div className="icon">
                            <FaPencil />
                          </div>
                        </div>
                      </div> */}
                      </div>
                    )}
                  </motion.div>
                )}

                <motion.div
                  className="details-card"
                  initial={{height: '90px'}}
                  ref={workerSkillsRef}
                  animate={{
                    height:
                      updateData?.workerSkills?.status === true
                        ? 'auto'
                        : showDropdown?.workerSkills
                          ? (workerSkillsRef?.current as unknown as HTMLElement)
                              ?.scrollHeight
                          : '90px',
                  }}
                  transition={{duration: 0.1}}>
                  <div
                    className={`dropdown-holder${
                      showDropdown?.workerLogs === true ? ' active' : ''
                    }`}
                    onClick={() => toggleShowDropdown('workerSkills')}>
                    Worker Skills
                    <div className="icon ">
                      <ChevronDownIcon />
                    </div>
                  </div>

                  <div className="details-col-holder">
                    <div className="details-col">
                      <div className="skills-holder">
                        <div className="label">Industries:</div>

                        <div className="values industries">
                          {data?.Worker?.industries?.map(
                            (industrie: any, index: number) => (
                              <div className="value" key={index}>
                                {industrie.name}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="skills-holder">
                        <div className="label">Roles:</div>

                        <div className="values">
                          {data?.Worker?.roles?.map(
                            (role: any, index: number) => (
                              <div className="value" key={index}>
                                {role.name}

                                <span>
                                  {role?.is_active ? 'Active' : 'Inactive'}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          )}
          {params?.tab === 'jobs' && (
            <div className="worker-details">
              <div className="max-width">
                <div className="mt-8 mb-10">
                  <div className="flex flex-col">
                    <div className="overflow-x-auto">
                      <div className="p-1.5 w-full inline-block align-middle">
                        <div className="overflow-hidden border rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                                  Provider
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                                  Location
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                                  Shift Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                                  Job Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                                  Check In
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                                  Check Out
                                </th>

                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                                  Status
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                                  Holiday pay
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                                  Charge rate
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                                  Pay rate
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                              {jobsData?.results.map((shift) => (
                                <tr key={shift.uid}>
                                  <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                                    <div className="flex items-center ">
                                      {
                                        shift.shift.timesheet
                                          .agency_shift_allocation
                                          .shift_template.provider.company.name
                                      }
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                                    <div className="flex items-center ">
                                      {
                                        shift.shift.timesheet
                                          .agency_shift_allocation
                                          .shift_template.location.region
                                      }
                                      ,
                                      {
                                        shift.shift.timesheet
                                          .agency_shift_allocation
                                          .shift_template.location.country.name
                                      }
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                                    <div className="flex items-center ">
                                      {
                                        shift.shift.timesheet
                                          .agency_shift_allocation
                                          .shift_template.name
                                      }
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                                    <div className="flex items-center ">
                                      {
                                        shift.shift.timesheet
                                          .agency_shift_allocation
                                          .shift_template.job_template
                                          .template_name
                                      }
                                    </div>
                                  </td>

                                  <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                                    {moment(shift.checked_in_at)
                                      .utcOffset('+03:00')
                                      .format('Do MMMM YYYY HH:mm')}
                                  </td>
                                  <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                                    {shift.checked_out_at === null
                                      ? 'Null'
                                      : moment(shift.checked_out_at)
                                          .utcOffset('+03:00')
                                          .format('Do MMMM YYYY HH:mm')}
                                  </td>

                                  <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                                    {shift.status === 'IN_PROGRESS'
                                      ? 'In progress'
                                      : shift.status === 'ENDED'
                                        ? 'Ended'
                                        : 'Cancelled'}
                                  </td>
                                  <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                                    {shift.holiday_pay}
                                  </td>
                                  <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                                    {shift.charge_rate}
                                  </td>
                                  <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                                    {shift.pay_rate}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className=" ml-2 mb-4 w-full flex flex-col items-center">
            {data?.Worker.status === 'SEEKER' ||
            data?.Worker.status === 'REJECTED' ? (
              ''
            ) : (
              <>
                {!toggle && (
                  <div className="flex space-x-4 ">
                    <button
                      onClick={() => handleToggle('SEEKER', 'Approve')}
                      className="px-6 py-2  bg-plannaPrimaryGreen text-plannaAccentGreen rounded-full">
                      Approve
                    </button>
                    <button
                      onClick={() => handleToggle('REJECTED', 'Reject')}
                      className="px-6 py-2 bg-plannaNeutral text-center font-semibold text-plannaPrimaryGreen rounded-full">
                      Reject
                    </button>
                  </div>
                )}
              </>
            )}
            {data?.Worker.status !== 'SEEKER' &&
              data?.Worker.status !== 'REJECTED' &&
              toggle && (
                <>
                  <div className="mt-4 flex flex-col items-center w-full">
                    <form className="w-3/4" onSubmit={onSubmit}>
                      {buttonLabel === 'Approve' && (
                        <div className="mt-2">
                          <Select
                            options={['A*', 'A', 'B', 'C']}
                            label="Provide a rating"
                            name="rating"
                            onChange={handleRatingChange}
                            inputValue={rating}
                            required
                          />
                        </div>
                      )}
                      <div className="mt-2">
                        <TextArea
                          label="Leave a comment"
                          value={comment}
                          name="comment"
                          onChange={handleCommentChange}
                          required
                        />
                      </div>
                      <button
                        className={`${
                          status === 'SEEKER'
                            ? 'bg-plannaPrimaryGreen text-plannaAccentGreen'
                            : 'bg-plannaTertiaryRed text-plannaNeutralWhite'
                        } px-8 mt-2 py-2   rounded-full`}>
                        {changeStatusLoading ? <SubmitSpinner /> : buttonLabel}
                      </button>
                    </form>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
      {/* <div className="flex items-center space-x-12">
        <img src={WorkerImage} alt="worker" className="h-fit w-fit" />
        <div className="flex flex-col">
          <h1 className="text-2xl font-bold text-plannaPrimaryGreen">
            {data !== undefined
              ? `${data.Worker.user.first_name} ${data.Worker.user.last_name}`
              : ''}
          </h1>
          <div className="flex space-x-12 mt-4">
            <div className="flex flex-col">
              <h1 className="text-lg border-b w-fit text-plannaSecondaryGreen50 border-plannaSecondaryGreen50">
                Email
              </h1>
              <p className="text-lg mt-1 text-plannaPrimaryGreen">
                {data?.Worker.user.email}
              </p>
            </div>
            <div className="flex flex-col">
              <h1 className="text-lg border-b w-fit text-plannaSecondaryGreen50 border-plannaSecondaryGreen50">
                Mobile
              </h1>
              <p className="text-lg mt-1 text-plannaPrimaryGreen">
                {data?.Worker.worker_personal_details !== null
                  ? data?.Worker.contact_details?.phone_number
                  : 'null'}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-lg text-plannaPrimaryGreen ">
                Registration Status
              </p>
              <span
                className={`${
                  data?.Worker.status === 'SEEKER'
                    ? 'bg-plannaSecondaryGreen50 text-white'
                    : data?.Worker.status === 'REJECTED'
                      ? 'bg-plannaTertiaryRed text-white'
                      : 'bg-plannaPrimaryOrange text-black'
                } mt-1   text-center text-sm  px-6 py-1 rounded-full w-fit`}>
                {data?.Worker.status.toLowerCase()}
              </span>
            </div>
            <div className="flex flex-col">
              <p className="text-lg text-plannaPrimaryGreen ">Rating</p>
              <span className="mt-1 bg-plannaPrimaryOrange text-black  text-center text-sm  px-6 py-1 rounded-full w-fit">
                {data?.Worker.rating !== null
                  ? data?.Worker.rating
                  : 'Not rated'}
              </span>
            </div>
          </div>
          <div className="flex items-center space-x-12 mt-7">
            <div className="flex flex-col">
              <p className="text-lg text-plannaPrimaryGreen ">
                Employment Status
              </p>
              <span
                className={`${
                  data?.Worker.employment_status === 'ACTIVE'
                    ? 'bg-plannaSecondaryGreen50 text-white'
                    : data?.Worker.employment_status === 'BLOCKED'
                      ? 'bg-plannaTertiaryRed text-white'
                      : 'bg-plannaPrimaryOrange text-black'
                } mt-1   text-center text-sm  px-6 py-1 rounded-full w-fit`}>
                {(data?.Worker.employment_status as string).toLowerCase()}
              </span>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="mt-8">
        <Tabs tabs={tabs} />
      </div> */}
    </div>
  );
};

export default ViewWorker;
