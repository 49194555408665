import React from 'react';

type Button = {
  label: string;
  onClick: () => void;
};
const TemplateDeleteButton = ({label, onClick}: Button) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className=" bg-plannaTertiaryRed  text-center text-plannaNeutralWhite text-lg font-hanken-grotesk font-semibold w-full px-6 py-2.5 rounded-full">
      {label}
    </button>
  );
};

export default TemplateDeleteButton;
