import Calendar from './Components/Calendar';

const Allocation = () => {
  return (
    <div className="p-4 w-full h-screen">
      <div className="flex flex-row justify-between w-full  p-4">
        <div className="mt-4">
          <h1 className="text-plannaPrimaryGreen text-2xl font-bold">
            Shift Allocation
          </h1>
        </div>
      </div>
      <div className="mt-8">
        <Calendar />
      </div>
    </div>
  );
};

export default Allocation;
