/* eslint-disable @typescript-eslint/restrict-template-expressions */
// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

type Roles = {
  name: string;
  uid: string;
  industry: string;
};
interface ApiResponse {
  results: Roles[];
}
interface ProjectShiftOptions {
  uid?: string;
}
const fetchData = async (
  options: ProjectShiftOptions
): Promise<ApiResponse> => {
  const {uid} = options;
  const queryParams = new URLSearchParams({
    limit: '100',
  });
  if (uid !== undefined && uid !== '') {
    queryParams.append('industry__uid', uid);
  }
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      `/company/roles/?${queryParams}`
    );

    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetRoles = (
  options: ProjectShiftOptions
): UseQueryResult<ApiResponse, Error> => {
  const {uid} = options;
  return useQuery(['roles', uid], () => fetchData(options));
};

export default useGetRoles;
