import moment from 'moment';
import React from 'react';

import Spinner from '../../Components/Common/Spinner';
import useGetProviderDailyShiftsDetails from '../../hooks/services/Shifts/useGetDailyShiftsDetails';

type Props = {
  id: string;
};
const ViewWorkerJobs = ({id}: Props) => {
  const {data, isLoading} = useGetProviderDailyShiftsDetails({worker_uid: id});
  if (isLoading) return <Spinner />;
  return (
    <div className="mt-8 mb-10">
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Provider
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Location
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Shift Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Job Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Check In
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Check Out
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Holiday pay
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Charge rate
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Pay rate
                    </th>
                  </tr>
                </thead>
                {data?.results.map((shift) => (
                  <tbody key={shift.uid} className="divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        <div className="flex items-center ">
                          {
                            shift.shift.timesheet.agency_shift_allocation
                              .shift_template.provider.company.name
                          }
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        <div className="flex items-center ">
                          {
                            shift.shift.timesheet.agency_shift_allocation
                              .shift_template.location.region
                          }
                          ,
                          {
                            shift.shift.timesheet.agency_shift_allocation
                              .shift_template.location.country.name
                          }
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        <div className="flex items-center ">
                          {
                            shift.shift.timesheet.agency_shift_allocation
                              .shift_template.name
                          }
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        <div className="flex items-center ">
                          {
                            shift.shift.timesheet.agency_shift_allocation
                              .shift_template.job_template.template_name
                          }
                        </div>
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {moment(shift.checked_in_at)
                          .utcOffset('+03:00')
                          .format('Do MMMM YYYY HH:mm')}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {shift.checked_out_at === null
                          ? 'Null'
                          : moment(shift.checked_out_at)
                              .utcOffset('+03:00')
                              .format('Do MMMM YYYY HH:mm')}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {shift.status === 'IN_PROGRESS'
                          ? 'In progress'
                          : shift.status === 'ENDED'
                            ? 'Ended'
                            : 'Cancelled'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {shift.holiday_pay}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {shift.charge_rate}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {shift.pay_rate}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewWorkerJobs;
