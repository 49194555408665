// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import axiosInstance from '../AxiosInstance';
type Industries = {
  name: string;
  uid: string;
};

interface ApiResponse {
  industries: Industries[];
}

const fetchData = async (): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      '/company/industries/'
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useIndustriesData = (): UseQueryResult<ApiResponse, Error> => {
  return useQuery('industries', fetchData);
};

export default useIndustriesData;
