/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import axios from 'axios';
import {useQuery} from 'react-query';

import axiosInstance from '../../hooks/services/AxiosInstance';
const messagingUrl = process.env.REACT_APP_MESSAGING_URL;

const RQGetChannelPosts = (
  userID: string,
  channelId: string,
  chatType: string
) => {
  const getChannelPosts = async () => {
    const response = await axios.get(
      `${messagingUrl}/channels/${channelId}/posts`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userID}`,
          credentials: true,
        },
      }
    );

    return response?.data;
  };
  const getGroupChannelPosts = async () => {
    console.log('channelId group', channelId);

    const response = await axiosInstance.get(`/messaging/group/${channelId}`);

    return response?.data;
  };

  return useQuery(
    ['channel-posts', channelId],
    chatType === 'groups' ? getGroupChannelPosts : getChannelPosts,
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 15,
      refetchInterval: 1000 * 2,
      enabled: channelId ? true : false,
    }
  );
};

export default RQGetChannelPosts;
