// usecreateShift.ts
import {useMutation, useQueryClient, UseMutationResult} from 'react-query';
import {useNavigate} from 'react-router-dom';

import axiosInstance from '../../AxiosInstance';

interface CreateProjectParams {
  title: string;
  description: string;
  workers_count: string;
  start_date: string;
  end_date: string;
  status: string;
  type: string;
  rate: string;
  additional_links: string;
}

const createProject = async (data: CreateProjectParams) => {
  const response = await axiosInstance.post('/projects/', data);

  return response;
};

const useCreateProject = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  CreateProjectParams
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(createProject, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('projects')
        .then(() => navigate('/agency/projects'))
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useCreateProject;
