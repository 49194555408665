import React from 'react';

type Props = {
  label: string;
  onClick?: () => void;
  bg: string;
  text: string;
};
const Button = ({label, onClick, bg, text}: Props) => {
  return (
    <button
      onClick={onClick}
      className={`px-4 py-2 text-${text} bg-${bg}  rounded-full focus:outline-none `}>
      {label}
    </button>
  );
};

export default Button;
