// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import axiosInstance from '../../AxiosInstance';
type User = {
  first_name: string;
  last_name: string;
  email: string;
};
type Worker = {
  user: User;
  uid: string;
};
type Project = {
  title: string;
  uid: string;
  description: string;
  start_date: string;
  end_date: string;
  type: string;
  rate: string;
  additional_links: string;
  workers_count: number;
};
type AgencyAllocation = {
  uid: string;
  project: Project;
};
type ProjectAllocation = {
  uid: string;
  start_at: string;
  stop_at: string;
  agency_project_allocation: AgencyAllocation;
  worker: Worker;
};
interface ApiResponse {
  // Define the structure of your API response
  // Example: { data: YourDataType }
  results: ProjectAllocation[];
}
interface ProjectShiftOptions {
  uid?: string;
  agency_project_allocation__uid?: string;
  status?: string;
}
const fetchData = async (
  options: ProjectShiftOptions
): Promise<ApiResponse> => {
  const {uid, agency_project_allocation__uid, status} = options;
  const queryParams = new URLSearchParams({
    limit: '10',
  });
  if (uid !== undefined && uid !== '') {
    queryParams.append('uid', uid);
  }
  if (status !== undefined && status !== '') {
    queryParams.append('status', status);
  }
  if (
    agency_project_allocation__uid !== undefined &&
    agency_project_allocation__uid !== ''
  ) {
    queryParams.append(
      'agency_project_allocation__uid',
      agency_project_allocation__uid
    );
  }
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `/projects/project-worker-allocation/?${queryParams}`
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetWorkerProjectsAllocations = (
  options: ProjectShiftOptions
): UseQueryResult<ApiResponse, Error> => {
  const {uid, agency_project_allocation__uid, status} = options;
  return useQuery(
    ['projectsallocations', uid, agency_project_allocation__uid, status],
    () => fetchData(options)
  );
};

export default useGetWorkerProjectsAllocations;
