/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import axios from 'axios';
import {useQuery} from 'react-query';
const messagingUrl = process.env.REACT_APP_MESSAGING_URL;
const RQGetUserProfile = (userId: string, userToken: string) => {
  const getImage = async () => {
    const response = await axios.get(
      `${messagingUrl}/users/${userId}/image/default`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
          credentials: true,
        },
        responseType: 'arraybuffer',
      }
    );

    console.log(response);
    const image = URL.createObjectURL(new Blob([response.data]));

    return image;
  };

  return useQuery(['userImage', userId], getImage, {
    refetchOnWindowFocus: false,
    // staleTime: 1000 * 60 * 15,
    // enabled: searchTerm ? true : false,
  });
};

export default RQGetUserProfile;
