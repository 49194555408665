import React from 'react';

import Input from '../../../Components/Registration/Input';
type ContactDetails = {
  email: string;
  address: string;
  postal_code: string;
  town: string;
  next_of_kin_email: string;
  next_of_kin_name: string;
  next_of_kin_phone_number: string;
  worker_uid: string;
  phone_number: string;
};

type Props = {
  contacts: ContactDetails | undefined;
};
const ContactInformation = ({contacts}: Props) => {
  return (
    <div className="p-4 w-full">
      {contacts !== null ? (
        <>
          <div className="flex ">
            <Input
              label="Email address"
              type="text"
              name="address"
              inputValue={contacts?.email}
              readonly
            />
            <Input
              label="Phone number"
              type="text"
              name="postal_code"
              inputValue={contacts?.phone_number}
              readonly
            />
          </div>
          <div className="flex mt-4">
            <Input
              label="Postal address"
              type="text"
              name="address"
              inputValue={contacts?.address}
              readonly
            />
            <Input
              label="Postal code"
              type="text"
              name="postal_code"
              inputValue={contacts?.postal_code}
              readonly
            />
          </div>
          <div className="flex mt-4">
            <Input
              label="Town"
              type="text"
              name="town"
              inputValue={contacts?.town}
              readonly
            />
            <Input
              label="Next of kin name"
              type="text"
              name="next_of_kin_name"
              inputValue={contacts?.next_of_kin_name}
              readonly
            />
          </div>
          <div className="flex mt-4">
            <Input
              label="Next of kin phone number"
              type="text"
              name="next_of_kin_phone_number"
              inputValue={contacts?.next_of_kin_phone_number}
              readonly
            />
            <Input
              label="Next of kin email"
              type="text"
              name="next_of_kin_email"
              inputValue={contacts?.next_of_kin_email}
              readonly
            />
          </div>
        </>
      ) : (
        <h1>Contact details are currently null</h1>
      )}
    </div>
  );
};

export default ContactInformation;
