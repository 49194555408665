import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Question from './Components/Question';
import Spinner from '../../Components/Common/Spinner';
import SubmitButton from '../../Components/Common/SubmitButton';
import circularBack from '../../assets/icons/Circular-Backward.png';
import useAddWorkerQuestions from '../../hooks/services/Agencys/useAddWorkerQuestions';
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';

const AddOnboardingQuestion = () => {
  const navigate = useNavigate();
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile();
  const {mutate, isLoading} = useAddWorkerQuestions();
  const initialState = {
    questions: [
      {
        question: '',
        expected_answer: null,
      },
    ],
  };
  const [formData, setFormData] = useState(initialState);
  const handleQuestionChange = (index: number, newName: string) => {
    const newQuestions = [...formData.questions];
    newQuestions[index].question = newName;
    setFormData({...formData, questions: newQuestions});
  };
  const addQuestion = () => {
    const emptyQuestions = {
      question: '',
      expected_answer: null,
    };
    setFormData({
      ...formData,
      questions: [...formData.questions, emptyQuestions],
    });
  };
  const removeQuestion = (index: number) => {
    if (formData.questions.length > 1) {
      const newQuestions = [...formData.questions];
      newQuestions.splice(index, 1);
      setFormData({
        ...formData,
        questions: newQuestions,
      });
    }
  };
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate({...formData, agency_uid: profile !== undefined ? profile.uid : ''});
  };
  if (profileLoading) return <Spinner />;
  return (
    <div className="p-4">
      <img
        onClick={() => navigate(-1)}
        src={circularBack}
        alt="back"
        className="h-12 w-12 ml-12  cursor-pointer"
      />
      <div className="w-full  flex flex-col justify-center items-center">
        <h1 className="text-center font-space-grotesk font-bold text-plannaPrimaryGreen text-2xl">
          Create Worker Questions{' '}
        </h1>
        <p className="mt-4 text-sm font-normal text-plannaPrimaryGreen font-hanken-grotesk">
          These questions enable you to get specific answers from your workers,{' '}
          <br /> it eases the onboarding process.
        </p>
        <form onSubmit={onSubmit} className="w-full max-w-4xl">
          <Question
            questions={formData.questions}
            handleQuestionChange={handleQuestionChange}
            handleRemoveQuestion={removeQuestion}
          />
          <div className="flex justify-end mt-2">
            <button
              type="button"
              onClick={addQuestion}
              className="bg-plannaSecondaryGreen50 px-4 w-1/4 py-2 text-white rounded-full">
              Add a Question
            </button>
          </div>

          <div className="mt-8">
            <SubmitButton
              buttonLabel="Submit"
              fullwidth
              isLoading={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOnboardingQuestion;
