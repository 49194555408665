// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

type Industry = {
  uid: string;
};
type Agency = {
  name: string;
  uid: string;
  email: string;
  industry: Industry;
};
interface ApiResponse {
  // Define the structure of your API response
  // Example: { data: YourDataType }
  results: Agency[];
}

const fetchData = async (uid: string): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      `/agency/?uid=${uid}`
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetAgency = (uid: string): UseQueryResult<ApiResponse, Error> => {
  return useQuery(['agency', uid], () => fetchData(uid), {enabled: uid !== ''});
};

export default useGetAgency;
