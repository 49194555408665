/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {useMutation} from 'react-query';

import axiosInstance from '../../hooks/services/AxiosInstance';

const RQCreateGroup = (onSuccess: any, onError: any) => {
  const createGroup = async (data: any) => {
    const response = await axiosInstance.post(
      `/messaging/mattermost-group/`,
      data
    );
    return response?.data;
  };

  return useMutation(createGroup, {
    onSuccess,
    onError,
  });
};

export default RQCreateGroup;
