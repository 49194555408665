// useRegisterUser.ts
import {useMutation, useQueryClient, UseMutationResult} from 'react-query';
import {useNavigate} from 'react-router-dom';

import axiosInstance from '../AxiosInstance';
import useAuthUser from '../useUserDetails';

type Uniforms = {
  color: string;
  material: string;
  style: string;
  sample_image: string;
  description: string;
};
type Rate = {
  rate_name: string;
  rate_duration_type: string;
  base_rate: number;
  break_duration: string;
  minimum_shift_duration_for_breaks: string;
  paid_breaks: boolean;
  rate_type: string;
  default_margin_type: string;
  default_margin: number;
};
interface RegisterUserParams {
  template_name: string;
  job_description: string;
  industry_uid: string;
  role_uid: string;
  rates: Rate[] | undefined;
  uniform: Uniforms;
  skills_required: string[];
}

const registerUser = async (data: RegisterUserParams) => {
  const response = await axiosInstance.post('/job/', data);

  return response;
};

const useCreateShiftTemplate = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  RegisterUserParams
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {user} = useAuthUser();
  return useMutation(registerUser, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('jobtemplates')
        .then(() =>
          navigate(
            user?.group === 'is_provider'
              ? '/job-template'
              : '/job-template/agency'
          )
        )
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useCreateShiftTemplate;
