import Input from '../../../Components/Registration/Input';

type PersonalDetails = {
  pronoun: string;
  phone_number: string;
  nationality: string;
  marital_status: string;
  date_of_birth: string;
  is_student: boolean;
  national_insurance: string;
  criminal_conviction_comment: string;
  has_criminal_conviction: boolean;
  has_medical_condition: boolean;
  medical_condition: string;
  languages: string[];
};
type User = {
  first_name: string;
  last_name: string;
  email: string;
};
type Props = {
  personalDetails: PersonalDetails | undefined;
  user: User | undefined;
};
const PersonalDetails = ({personalDetails, user}: Props) => {
  console.log(personalDetails);

  return (
    <div className="p-4 w-full">
      {personalDetails !== null ? (
        <>
          <div className="flex ">
            <Input
              label="First Name"
              type="text"
              name="first_name"
              inputValue={user?.first_name}
              readonly
            />
            <Input
              label="Last Name"
              type="text"
              name="last_name"
              inputValue={user?.last_name}
              readonly
            />
            <Input
              label="Email"
              type="email"
              name="email"
              inputValue={user?.email}
              readonly
            />
          </div>
          <div className="flex mt-4">
            <Input
              label="Date of birth"
              type="text"
              name="date_of_birth"
              inputValue={personalDetails?.date_of_birth}
              readonly
            />
            <Input
              label="Marital Status"
              type="text"
              name="marital_status"
              inputValue={personalDetails?.marital_status}
              readonly
            />
            <Input
              label="Nationality"
              type="text"
              name="nationality"
              inputValue={personalDetails?.nationality}
              readonly
            />
          </div>
          <div className="flex mt-4">
            <Input
              label="Preferred pronoun"
              type="text"
              name="pronoun"
              inputValue={personalDetails?.pronoun}
              readonly
            />
            <Input
              label="Criminal conviction"
              type="text"
              name="marital_status"
              inputValue={
                personalDetails?.has_criminal_conviction === true ? 'Yes' : 'No'
              }
              readonly
            />

            <Input
              label="Criminal conviction comment"
              type="text"
              name="pronoun"
              inputValue={
                personalDetails?.has_criminal_conviction === true
                  ? personalDetails?.criminal_conviction_comment
                  : 'No conviction'
              }
              readonly
            />
          </div>
          <div className="flex mt-4">
            <Input
              label="Has Medical Condition"
              type="text"
              name="national_insurance"
              inputValue={
                personalDetails?.has_medical_condition === true ? 'Yes' : 'No'
              }
              readonly
            />
            <Input
              label="Medical Condition"
              type="text"
              name="national_insurance"
              inputValue={
                personalDetails?.has_medical_condition === true
                  ? personalDetails?.medical_condition
                  : 'No condition'
              }
              readonly
            />
            <Input
              label="National Insurance"
              type="text"
              name="national_insurance"
              inputValue={personalDetails?.national_insurance}
              readonly
            />
          </div>
          <div className="mt-4">
            <div className="flex flex-col">
              <p className="text-lg text-plannaPrimaryGreen ">Languages</p>
              <div className="flex space-x-2">
                {personalDetails !== null && personalDetails?.languages !== null
                  ? personalDetails?.languages.map((language, index) => (
                      <span
                        key={index}
                        className="bg-plannaPrimaryOrange text-black  text-center text-sm  px-6 py-1 rounded-full w-fit">
                        {language}
                      </span>
                    ))
                  : 'null'}
              </div>
            </div>
          </div>
        </>
      ) : (
        <h1>Personal details are currently null</h1>
      )}
    </div>
  );
};

export default PersonalDetails;
