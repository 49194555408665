import React from 'react';

import Card from './Components/Card';
import Table from './Components/Table';
import Tabs from './Components/Tabs';
import Spinner from '../../Components/Common/Spinner';
import calendar from '../../assets/icons/calendar.png';
import completed from '../../assets/icons/completed.png';
import late from '../../assets/icons/late.png';
import useGetAgencys from '../../hooks/services/Agencys/useGetAgencys';
import useGetJobTemplates from '../../hooks/services/Shifts/GetJobTemplates';
import useGetShifts from '../../hooks/services/Shifts/UseGetShifts';
import useGetProviderProfile from '../../hooks/services/useGetProviderProfile';
import useAuthUser from '../../hooks/services/useUserDetails';
import {getDateAWeekAgo, getDateAWeekAhead} from '../../utils/Methods';
import DailyShifts from '../Shifts/Components/DailyShifts';

const HomePortal = () => {
  const {user} = useAuthUser();
  const {data: profile, isLoading: profileLoading} = useGetProviderProfile();
  const {data: agencies, isLoading} = useGetAgencys();
  const {data: jobTemplates, isLoading: templatesLoading} = useGetJobTemplates({
    provider_id: profile !== undefined ? profile.uid : '',
  });
  const {data: shiftTemplates, isLoading: shiftsLoading} = useGetShifts(
    'CONFIRMED',
    profile !== undefined ? profile.uid : '',
    getDateAWeekAgo(),
    getDateAWeekAhead()
  );

  if (isLoading || templatesLoading || shiftsLoading || profileLoading) {
    return <Spinner />;
  }
  const tabs = [
    {
      id: 'tab1',
      label: 'Published Shifts',
      component: <Table />,
    },
    {id: 'tab2', label: 'Attendance', component: <DailyShifts />},
    {id: 'tab3', label: 'Past Shifts', component: <Table />},
  ];
  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold text-plannaPrimaryGreen">
        Hi {user?.fullname}
      </h1>
      <p className="mt-4 text-plannaPrimaryGreen">
        👋 Welcome to your {profile?.company.name} board
      </p>
      <div className="lg:flex lg:space-x-4  mr-10 justify-between mt-8">
        <Card
          image={calendar}
          title={agencies?.agencies.length}
          subTitle="Agencies invited"
          color="plannaPrimaryBlue"
        />
        <Card
          image={late}
          title={jobTemplates?.results.length}
          subTitle="Job templates created"
          color="plannaTertiary"
        />
        <Card
          image={completed}
          title={shiftTemplates?.results.length}
          subTitle="Shifts templates created"
          color="plannaSecondaryGreen50"
        />
      </div>
      <div className="mt-8">
        <h1 className="text-black font-bold text-xl">Planned shifts</h1>
        <p className="text-black mt-2 text-sm">
          View shift information with real-time insights and streamlined views
          on worker attendance
        </p>
        <div className="mt-8">
          <Tabs tabs={tabs} />
        </div>
      </div>
    </div>
  );
};

export default HomePortal;
