// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

type User = {
  first_name: string;
  last_name: string;
  email: string;
  uid: string;
};
type AgencyUser = {
  position: string;
  user_permission: string;
  email: string;
  user: User;
  uid: string;
  location: string;
};
interface WorkerShiftOptions {
  agency_uid: string;
  uid?: string;
}
interface ApiResponse {
  // Define the structure of your API response
  // Example: { data: YourDataType }
  results: AgencyUser[];
}

const fetchData = async (options: WorkerShiftOptions): Promise<ApiResponse> => {
  const {agency_uid, uid} = options;

  const queryParams = new URLSearchParams({
    agency_uid: agency_uid,
  });
  if (uid !== undefined && uid !== '') {
    queryParams.append('uid', uid);
  }
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `/agency/agency-users/?${queryParams}`
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetAgencysUsers = (
  options: WorkerShiftOptions
): UseQueryResult<ApiResponse, Error> => {
  const {agency_uid, uid} = options;
  return useQuery(['agencyUsers', agency_uid, uid], () => fetchData(options));
};

export default useGetAgencysUsers;
