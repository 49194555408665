import React from 'react';

import Button from '../../Components/LandingPage/Button';
import NavbarLayout from '../../Layouts/NavbarLayout';
import Congratulations from '../../assets/icons/Congratulations.svg';
import Appstore from '../../assets/images/app-store.png';
import Playstore from '../../assets/images/googleplay.png';

const AppDownloadPage = () => {
  return (
    <>
      <NavbarLayout>
        <div className="block lg:inline-block mt-4 lg:mt-0 lg:ml-4">
          <Button label="Help" bg="plannaPrimaryGreen" text="white" />
        </div>
      </NavbarLayout>
      <div className="h-screen flex flex-col justify-center  items-center">
        <div className="p-4 flex flex-col justify-center items-center">
          <img
            src={Congratulations}
            alt="congratulations"
            className="h-fit w-fit"
          />
          <h1 className="text-2xl font-bold mt-4 text-plannaPrimaryGreen">
            Congratulations!!!
          </h1>
          <h1 className="text-lg mt-4 text-plannaPrimaryGreen">
            Your information has been collected for Planna!{' '}
          </h1>
        </div>
        <div className="mt-4 text-lg">
          <h1 className="font-bold ">See what happens next?</h1>
          <p className="text-plannaPrimaryGreen  mt-2">
            Download our mobile app from playstore or appstore, the links are
            below
          </p>
          <p className="text-plannaPrimaryGreen  mt-2">
            Login using the email and password that you set
          </p>
          <p className="text-plannaPrimaryGreen  mt-2">
            Follow the next steps as instructed
          </p>
        </div>

        <div className="flex space-x-2 mt-4 text-lg">
          <p className="text-plannaPrimaryGreen  mt-2">Get the worker app</p>
          <img src={Playstore} alt="playstore" className="h-fit w-fit" />
          <img src={Appstore} alt="appstore" className="h-fit w-fit" />
        </div>
      </div>
    </>
  );
};

export default AppDownloadPage;
