// useRegisterUser.ts
import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

interface ApproveWorkerShiftParams {
  worker_shift_approval_uids: string[];
  action: string;
  action_by: string;
}

const approveWorkerShifts = async (data: ApproveWorkerShiftParams) => {
  const response = await axiosInstance.post(
    'attendance/worker-shifts/approval-requests/action/',
    data
  );

  return response;
};

const useApproveWorkerShifts = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  ApproveWorkerShiftParams
> => {
  const queryClient = useQueryClient();

  return useMutation(approveWorkerShifts, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('workerapprovals')
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useApproveWorkerShifts;
