/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {toast} from 'react-toastify';

import Registration from '../../Components/Registration/Registration';
import useCreateProvider from '../../hooks/services/RegisterUser';

const OnBoarding = () => {
  const {mutateAsync, isLoading} = useCreateProvider();

  const onSubmit = async (data: any) => {
    await mutateAsync(data).catch(() =>
      toast.error('The email is already registered', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    );
  };

  const renderStep = () => {
    return <Registration onSubmit={onSubmit} isLoading={isLoading} />;
  };

  return <>{renderStep()}</>;
};

export default OnBoarding;
