// usecreateShift.ts
import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

interface UpdateWorkerEmploymentStatus {
  worker_uid: string;
  employment_status: 'ACTIVE' | 'TERMINATED' | 'BLOCKED' | 'RESIGNED';
}

const updateWorkerEmploymentStatus = async (
  data: UpdateWorkerEmploymentStatus
) => {
  const response = await axiosInstance.put(
    `agency/worker-employment-status-update/${data.worker_uid}`,
    data
  );

  return response;
};

const useUpdateWorkerEmploymentStatus = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  UpdateWorkerEmploymentStatus
> => {
  const queryClient = useQueryClient();

  return useMutation(updateWorkerEmploymentStatus, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('worker')
        .then((data) => {
          return data;
        })
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useUpdateWorkerEmploymentStatus;
