// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import axiosInstance from './AxiosInstance';
type Company = {
  name: string;
};
type Country = {
  name: string;
};
type Location = {
  region: string;
  country: Country;
};

interface ApiResponse {
  phone_number: string;
  vat_number: string;
  client_number: string;
  company: Company;
  uid: string;
  location: Location;
}

const fetchData = async (): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      'providers/profile/provider/'
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetProviderProfile = (): UseQueryResult<ApiResponse, Error> => {
  return useQuery('providerprofile', fetchData);
};

export default useGetProviderProfile;
