// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

type Agency = {
  name: string;
  uid: string;
  email: string;
};
interface ApiResponse {
  // Define the structure of your API response
  // Example: { data: YourDataType }
  agencies: Agency[];
}

const fetchData = async (): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> =
      await axiosInstance.get('providers/agency/');
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetAgencys = (): UseQueryResult<ApiResponse, Error> => {
  return useQuery('agencys', fetchData);
};

export default useGetAgencys;
