import {motion} from 'framer-motion';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import SubmitSpinner from '../../Components/Common/SubmitSpinner';
import SuccessLayout from '../../Layouts/SuccessLayout';
import backIcon from '../../assets/icons/Circular-Backward.png';
import useDeleteAgencyUser from '../../hooks/services/Agencys/useDeleteAgencyUser';
import useEditAgencyUser from '../../hooks/services/Agencys/useEditAgencyUser';
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';
import useGetAgencysUsers from '../../hooks/services/Agencys/useGetAgencyUsers';
import useLocationsData from '../../hooks/services/common/useGetLocations';

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  location: '',
  position: '',
  user_permission: '',
  uid: '',
};
const ViewSingleAgencyUser = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile();
  const {data, isLoading} = useGetAgencysUsers({
    agency_uid: profile !== undefined ? profile.uid : '',
    uid: params.id,
  });
  const {data: locations, isLoading: locationsLoading} = useLocationsData();
  const {mutateAsync, isLoading: editingLoading} = useEditAgencyUser();
  const {mutateAsync: deleteMutation, isLoading: deleteLoading} =
    useDeleteAgencyUser();
  const [formData, setFormData] = useState(initialState);
  const agencyUser = data?.results[0];
  useEffect(() => {
    if (agencyUser !== undefined) {
      const {user, user_permission, position, location, uid} = agencyUser;

      setFormData({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        location: location,
        position: position,
        user_permission: user_permission,
        uid: uid,
      });
    }
  }, [agencyUser]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;

    setFormData({...formData, [name]: value});
  };
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target;

    setFormData({...formData, [name]: value});
  };
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await mutateAsync(formData).then(() => setIsSuccess(true));
  };
  const onDeleteAgencyUser = async () => {
    await deleteMutation({uid: params.id !== undefined ? params.id : ''}).then(
      () => navigate('/agency/users')
    );
  };
  if (isLoading || profileLoading || locationsLoading) {
    return <Spinner />;
  }

  return (
    <div className="seeker-profile-container">
      <SuccessLayout isOpen={isSuccess} onClose={() => setIsSuccess(false)}>
        <p>User updated successfully</p>
      </SuccessLayout>

      <div className="profile-max-width">
        <div className="profile-header">
          <div className="back-icon mt-7" onClick={() => navigate(-1)}>
            <img src={backIcon} alt="" />
          </div>
          <div className="col">
            <h1 className="profile-title">Agency user</h1>
            <p className="profile-sub-title">Edit or delete a user </p>
          </div>
        </div>
      </div>

      <div className="profile-body">
        <form onSubmit={onSubmit} className="form-action">
          <div className="worker-details">
            <div className="max-width">
              <motion.div
                initial={{
                  height: 'auto',
                }}
                animate={{
                  height: 'auto',
                }}
                transition={{duration: 0.1}}
                className="details-card no-overflow">
                <div className="dropdown-holder active">
                  Personal Information
                </div>
                <div className="editable-form p-2">
                  <div className="split-form-group">
                    <div className="form-group">
                      <label htmlFor="" className="form-label">
                        Firstname <span>*</span>
                      </label>
                      <input
                        name="first_name"
                        value={formData.first_name}
                        type="text"
                        className="form-input"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="" className="form-label">
                        Lastname <span>*</span>
                      </label>
                      <input
                        name="last_name"
                        value={formData.last_name}
                        type="text"
                        className="form-input"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="split-form-group">
                    <div className="form-group">
                      <label htmlFor="" className="form-label">
                        Email
                      </label>
                      <input
                        name="email"
                        value={formData.email}
                        type="email"
                        className="form-input"
                        onChange={handleChange}
                      />
                    </div>
                    <div className=" w-full">
                      <label
                        htmlFor="inputField"
                        className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                        Location
                        <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                          *
                        </span>
                      </label>
                      <select
                        name="location"
                        value={formData.location}
                        onChange={handleSelectChange}
                        className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border  border-plannaNeutral py-2 px-3 focus:outline-none">
                        <option selected>Select location</option>
                        {locations?.locations.map((location) => {
                          return (
                            <option key={location.uid} value={location.uid}>
                              {location.region},{location.country.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{
                  height: 'auto',
                }}
                animate={{
                  height: 'auto',
                }}
                transition={{duration: 0.1}}
                className="details-card">
                <div className="dropdown-holder active">
                  Roles and Permissions
                </div>
                <div className="flex justify-between space-x-2 w-full">
                  <div className="mt-4 w-full">
                    <label
                      htmlFor="inputField"
                      className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                      User role/position
                      <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                        *
                      </span>
                    </label>

                    <select
                      name="position"
                      value={formData.position}
                      onChange={handleSelectChange}
                      className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border  border-plannaNeutral py-2 px-3 focus:outline-none">
                      <option selected>Select position</option>
                      {[
                        {name: 'Recruiter', value: 'RECRUITER'},
                        {name: 'Account Manager', value: 'ACCOUNT_MANAGER'},
                        {name: 'Manager', value: 'MANAGER'},
                      ].map((level) => {
                        return (
                          <option key={level.value} value={level.value}>
                            {level.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mt-4 w-full">
                    <label
                      htmlFor="inputField"
                      className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                      Permission Level
                      <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                        *
                      </span>
                    </label>
                    <select
                      name="user_permission"
                      value={formData.user_permission}
                      onChange={handleSelectChange}
                      className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border  border-plannaNeutral py-2 px-3 focus:outline-none">
                      <option selected>Select level</option>
                      {[
                        {name: 'Admin', value: 'ADMIN'},
                        {name: 'Super Admin', value: 'SUPER_ADMIN'},
                        {name: 'User', value: 'USER'},
                      ].map((level) => {
                        return (
                          <option key={level.value} value={level.value}>
                            {level.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                {/* <div className="mt-4 ml-2">
                  {watch('user_permission') === 'USER'
                    ? userRoles.map((role) => <p>{role}</p>)
                    : watch('user_permission') === 'SUPER_ADMIN'
                      ? superAdminRoles.map((role) => <p>{role}</p>)
                      : watch('user_permission') === 'ADMIN'
                        ? adminRoles.map((role) => <p>{role}</p>)
                        : ''}
                </div> */}
              </motion.div>
              <div className="flex space-x-2">
                <button className="px-6 py-2 bg-plannaPrimaryGreen rounded-full text-plannaAccentGreen">
                  {editingLoading ? <SubmitSpinner /> : 'Submit'}
                </button>
                <button
                  onClick={onDeleteAgencyUser}
                  type="button"
                  className="px-6 py-2 bg-plannaTertiaryRed rounded-full text-plannaNeutralWhite">
                  {deleteLoading ? <SubmitSpinner /> : 'Delete'}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewSingleAgencyUser;
