import {useEffect} from 'react';
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import Select from './Select';
import OnBoardingLayout from '../../Layouts/OnBoardingLayout';
import {capitalizeFirstLetter} from '../../utils/Methods';
import {countries} from '../../utils/constants';

type FormData = {
  country: string;
  citizen: string;
};

type Props = {
  formData: FormData;
  handleNext: () => void;
  handleSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  step: number;
  handlePrevious: () => void;
  handleCitizenChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FormData>;
  watch: (name: keyof FormData) => string | undefined;
  setValue: UseFormSetValue<FieldValues>;
};
const KnockoutQuestions = ({
  formData,
  handleSelect,
  handleNext,
  step,
  handlePrevious,
  handleCitizenChange,
  register,
  errors,
  watch,
  setValue,
}: Props) => {
  useEffect(() => {
    if (watch('country') === '') {
      setValue('citizen', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('country')]);
  return (
    <OnBoardingLayout
      handlePrevious={handlePrevious}
      step={step}
      title="We want to know if you are eligible">
      <p className="w-full mb-6 mt-4 text-plannaPrimaryGreen">
        Please select from the drop down list
      </p>

      <div className={`form-group${errors?.country ? ' error' : ''}`}>
        <label htmlFor="country" className="form-label">
          Please select your country<span>*</span>
        </label>
        <select
          id="country"
          {...register('country', {
            required: 'Please select your country',
          })}
          className="form-input">
          <option value="">Select Country</option>
          {countries?.map((option) => {
            return (
              <option key={option} value={option}>
                {capitalizeFirstLetter(option.toLowerCase())}
              </option>
            );
          })}
        </select>

        {errors?.country && (
          <span className="text-red-500 text-sm">
            {String(errors?.country?.message)}
          </span>
        )}
      </div>

      {watch('country') !== '' && (
        <div className="form-group">
          <label htmlFor="permit" className="form-label">
            Are you authorized to work? <span>*</span>
          </label>
          <div className="radio-inputs-holder">
            <div className="radio-input">
              <input
                type="radio"
                {...register('citizen', {
                  required: 'Please select an option',
                })}
                value="yes"
                className="form-input radio"
              />
              <span>Yes</span>
            </div>

            <div className="radio-input">
              <input
                type="radio"
                {...register('citizen', {
                  required: 'Please select an option',
                })}
                value="no"
                className="form-input radio"
              />
              <span>No</span>
            </div>
          </div>

          {errors?.citizen && (
            <span className="text-red-500 text-sm">
              {String(errors?.citizen?.message)}
            </span>
          )}
        </div>
      )}
    </OnBoardingLayout>
  );
};

export default KnockoutQuestions;
