import React from 'react';

import {CountryCodes} from '../../utils/constants';

type Props = {
  inputValue: string | number | undefined;
  type: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label: string;
  readonly?: boolean;
  name: string;
  required?: boolean;
  placeholder?: string;
  demo?: boolean;
  isPhone?: boolean;
  code?: string;
  min?: string;
};

const Input = ({
  inputValue,
  type,
  onChange,
  label,
  readonly,
  name,
  required,
  placeholder,
  demo,
  isPhone,
  code,
  onSelect,
  min,
}: Props) => {
  return (
    <div className="w-full">
      <label
        htmlFor="inputField"
        className="block mb-3 text-sm font-medium text-plannaPrimaryGreen ">
        {label}
        {required && <span className="text-red-600 ml-1">*</span>}
      </label>
      {isPhone ? (
        <div className="flex space-x-1 w-full lg:w-3/4">
          <select
            value={code}
            name="code"
            required
            onChange={onSelect}
            className="w-full  text-plannaPrimaryGreen border border-plannaNeutral rounded-lg py-2 px-3">
            <option selected>Select code</option>
            {CountryCodes.map((option) => {
              return (
                <option key={option.code} value={option.code}>
                  {option.code} {option.name}
                </option>
              );
            })}
          </select>
          <input
            type={type}
            value={inputValue}
            onChange={onChange}
            readOnly={readonly}
            name={name}
            placeholder={placeholder}
            required={required}
            min={min}
            className={`${
              demo ? 'w-full' : 'lg:w-3/4 w-full'
            }border border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none`}
          />
        </div>
      ) : (
        <input
          type={type}
          value={inputValue}
          onChange={onChange}
          readOnly={readonly}
          name={name}
          placeholder={placeholder}
          required={required}
          min={min}
          className={`${
            demo ? 'w-full' : 'lg:w-3/4 w-full'
          } border border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none`}
        />
      )}
    </div>
  );
};

export default Input;
