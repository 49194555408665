import React, {useState, useRef} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';

import Button from './Button';
import Dropdown from './NavbarDropdowm';
import Logo from '../../assets/icons/Planna-Default.svg';

const Navbar = () => {
  const navigate = useNavigate();
  const [isResponsive, setIsResponsive] = useState<boolean>(true);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleToggle = (): void => {
    setIsResponsive(!isResponsive);
  };

  const handleNavLinkClick = (): void => {
    setIsResponsive(!isResponsive); // Close the menu when a NavLink is clicked
    if (buttonRef.current) {
      buttonRef.current.blur(); // Remove focus from the button
    }
  };

  return (
    <nav
      className="flex flex-row z-40 md:fixed top-0 w-full justify-between lg:px-20 px-1 h-16 lg:h-26 bg-white border-b border-neutral-300"
      style={{minHeight: '80px'}}>
      <div className="flex items-center w-full md:w-fit md:justify-start justify-between flex-shrink-0 text-white">
        <NavLink to="/" className="font-bold text-xl mr-8">
          <img src={Logo} alt="plnna-logo" className="h-8 w-auto" />
        </NavLink>
        <div className="md:hidden flex">
          <button
            ref={buttonRef}
            onClick={handleToggle}
            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400"
            aria-expanded={!isResponsive ? 'true' : 'false'}>
            {isResponsive ? (
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                />
              </svg>
            ) : (
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            )}
          </button>
        </div>
        <div className={!isResponsive ? 'block' : 'hidden sm:hidden'}>
          <div
            className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabIndex={-1}>
            <NavLink
              to="/product"
              onClick={handleNavLinkClick}
              className="block px-4 py-2 text-sm text-gray-700">
              Product
              <Dropdown />
            </NavLink>
            <NavLink
              to="/about"
              onClick={handleNavLinkClick}
              className="block px-4 py-2 text-sm text-gray-700">
              Company
              <Dropdown />
            </NavLink>
            <NavLink
              to="/support"
              onClick={handleNavLinkClick}
              className="block px-4 py-2 text-sm text-gray-700">
              Support
            </NavLink>
          </div>
        </div>
        <div className="hidden md:flex">
          <NavLink
            to="/product"
            onClick={handleNavLinkClick}
            className="mt-4 lg:mt-0 text-plannaPrimaryGreen text-lg hover:text-gray-300 mr-4 relative">
            Product
            <Dropdown />
          </NavLink>
          <NavLink
            to="/about"
            onClick={handleNavLinkClick}
            className="mt-4 lg:mt-0 text-plannaNeutral text-lg hover:text-gray-300 mr-4 relative">
            Company
            <Dropdown />
          </NavLink>
          <NavLink
            to="/support"
            onClick={handleNavLinkClick}
            className="mt-4 lg:mt-0 text-plannaNeutral text-lg hover:text-gray-300 mr-4">
            Support
          </NavLink>
        </div>
      </div>

      <div className="hidden md:flex items-center w-auto">
        <div className="text-sm flex">
          <div className="mr-4">
            <Button
              bg="plannaAccentGreen"
              onClick={() => navigate('/find-jobs')}
              label="Find jobs"
              text="black"
            />
          </div>
          <div className="mr-4">
            <Button
              bg="plannaPrimaryGreen"
              onClick={() => navigate('/onboarding')}
              label="Sign up"
              text="white"
            />
          </div>
        </div>
        <div className="flex">
          <NavLink
            to="/login"
            onClick={handleNavLinkClick}
            className="mr-4 text-plannaPrimaryGreen hover:text-gray-300">
            Login
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
