import React, {ChangeEvent, useState} from 'react';

import Spinner from '../../Components/Common/Spinner';
import SubmitSpinner from '../../Components/Common/SubmitSpinner';
import ErrorHandlingLayout from '../../Layouts/ErrorHandlingLayout';
import SuccessLayout from '../../Layouts/SuccessLayout';
import useGetAgencys from '../../hooks/services/Agencys/useGetAgencys';
import useAllocateAgencyToShift from '../../hooks/services/Shifts/useAllocateAgencyToShift';

type Props = {
  shiftId: string | undefined;
  handleTemplateClose: () => void;
  startDate: string | undefined;
  recurrence: string | undefined;
  workerCount: number | undefined;
};
const AllocateToAgency = ({
  shiftId,
  handleTemplateClose,
  startDate,
  recurrence,
  workerCount,
}: Props) => {
  const {data: agencys, isLoading: agencysLoading} = useGetAgencys();
  const [isOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    handleTemplateClose();
  };
  const [formData, setFormData] = useState({
    shift_template_uid: shiftId !== undefined ? shiftId : '',
    agency_uid: '',
    start_date: startDate !== undefined ? startDate : '',
    worker_count: workerCount !== undefined ? workerCount : 0,
    recurrence: recurrence !== undefined ? recurrence : '',
    employers_ni: 12.17,
  });
  const {mutateAsync: allocateAgencyMutation, isLoading: allocateLoading} =
    useAllocateAgencyToShift();

  const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };
  if (agencysLoading) {
    return <Spinner />;
  }
  const onAllocateAgency = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await allocateAgencyMutation(formData)
      .then(() => handleOpen())
      .catch(() => setIsError(true));
  };
  return (
    <>
      <SuccessLayout isOpen={isOpen} onClose={handleClose}>
        <h1 className="text-plannaPrimaryGreen font-bold text-center">
          Agency allocated to shift successfully
        </h1>
      </SuccessLayout>
      <ErrorHandlingLayout
        title="Shift already assigned"
        isOpen={isError}
        onClose={() => setIsError(false)}>
        <h1 className="text-plannaPrimaryGreen font-bold text-center">
          The agency selected has already been assigned to this shift
        </h1>
      </ErrorHandlingLayout>
      <div className="mx-8 ">
        <div className="w-full">
          <h1 className="font-bold text-plannaPrimaryGreen">
            Assign this shift template to an agency
          </h1>
          <form onSubmit={onAllocateAgency}>
            <div className="mt-4">
              <div className=" w-full">
                <label
                  htmlFor="inputField"
                  className="block mb-3 text-sm font-medium text-plannaPrimaryGreen">
                  Select an agency
                  <span className="text-red-600 ml-1">*</span>
                </label>
                <select
                  required
                  value={formData.agency_uid}
                  onChange={handleOnSelect}
                  name="agency_uid"
                  className=" w-3/4 text-plannaPrimaryGreen border border-plannaNeutral rounded-xl py-3 px-3 ">
                  <option selected>Select an agency</option>
                  {agencys?.agencies.map((agency) => {
                    return (
                      <option key={agency.uid} value={agency.uid}>
                        {agency.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="mt-4">
              <button className="px-6 py-2 w-3/4 text-plannaAccentGreen bg-plannaPrimaryGreen rounded-full">
                {allocateLoading ? <SubmitSpinner /> : 'Assign'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AllocateToAgency;
