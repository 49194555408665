import React from 'react';
import {useNavigate} from 'react-router-dom';
type Job = {
  name: string;
  location: string;
};
type Props = {
  jobs: Job[];
};
const JobCard = ({jobs}: Props) => {
  const navigate = useNavigate();
  return (
    <>
      {jobs.map((job, index) => (
        <div
          key={index}
          className="lg:w-1/2 w-full p-4 border border-plannaNeutral rounded-lg">
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <h1 className="font-semibold text-plannaPrimaryGreen ">
                {job.name}
              </h1>
              <p className="mt-4 text-plannaNeutralGreen">{job.location}</p>
            </div>
            <button
              onClick={() => navigate('/onboarding/worker')}
              className="rounded-full py-2 px-4 bg-plannaPrimaryGreen text-plannaAccentGreen">
              Apply Now
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default JobCard;
