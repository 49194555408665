import {useQuery} from 'react-query';

import axiosInstance from '../../hooks/services/AxiosInstance';

const RQGetToken = (userId: string) => {
  const getUserToken = async () => {
    const response = await axiosInstance.get(
      `/user-mattermost-token/?user_id=${userId}`
    );

    return response;
  };

  return useQuery(['mattermostToken', userId], getUserToken, {
    // refetchOnWindowFocus: false,
    // cacheTime: 1000 * 60 * 15,
    // enabled: searchTerm ? true : false,
  });
};

export default RQGetToken;
