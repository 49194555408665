import React from 'react';

import ShiftsAllocation from './ShiftsAllocation';
import WorkerShiftApplications from './WorkerShiftApplications';
import Tabs from '../Home/Components/Tabs';

const AgencyAllocations = () => {
  const tabs = [
    {
      id: 'tab1',
      label: 'Agency Shift Allocations',
      component: <ShiftsAllocation />,
    },

    {
      id: 'tab3',
      label: 'Worker Shifts Applications',
      component: <WorkerShiftApplications />,
    },
  ];
  return (
    <div className="p-4">
      <h1 className="text-lg font-bold mb-6 text-plannaPrimaryGreen">
        Shift Allocation(s)
      </h1>
      <Tabs tabs={tabs} />
    </div>
  );
};

export default AgencyAllocations;
