/* eslint-disable @typescript-eslint/no-unsafe-return */

import {useQuery} from 'react-query';

import axiosInstance from '../../hooks/services/AxiosInstance';

function RQGetProvider(providerUid: any) {
  const getAgencyProviders = async () => {
    const response = await axiosInstance.get(
      `/providers/?provider_uid=${providerUid}`
    );

    return response?.data;
  };

  return useQuery(['agency-providers', providerUid], getAgencyProviders, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 15,
  });
}

export default RQGetProvider;
