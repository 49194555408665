import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import {TimesheetsResponse} from '../../../utils/types';
import axiosInstance from '../AxiosInstance';

type IdType = 'provider__uid' | 'agency_shift_allocation__agency';

const fetchData = async (
  id: string,
  idType: IdType,
  date: string
): Promise<TimesheetsResponse> => {
  try {
    const queryParams = new URLSearchParams({
      limit: '10',
      status: 'IN_PROGRESS',
    });

    queryParams.append(idType, id);

    const response: AxiosResponse<TimesheetsResponse> = await axiosInstance.get(
      // eslint-disable-next-line
      `attendance/timesheets/?${queryParams}`
    );
    // eslint-disable-next-line
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetTimesheets = (
  id: string,
  idType: IdType,
  date: string
): UseQueryResult<TimesheetsResponse, Error> => {
  return useQuery(['timesheets', id, date], () => fetchData(id, idType, date));
};

export default useGetTimesheets;
