/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import Spinner from '../../Components/Common/Spinner';
import Button from '../../Components/LandingPage/Button';
import NavbarLayout from '../../Layouts/NavbarLayout';
import logo from '../../assets/icons/logo-icon.png';
import useCreateAgency from '../../hooks/services/Agencys/useCreateAgency';
import useIndustriesData from '../../hooks/services/common/useGetIndustries';
import useLocationsData from '../../hooks/services/common/useGetLocations';

const CreateAgency = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm();

  const {data: industries, isLoading: industriesLoading} = useIndustriesData();
  const {data: locations, isLoading: locationsLoading} = useLocationsData();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [activePayroll, setActivePayroll] = useState<any>(null);
  const {mutateAsync, isLoading} = useCreateAgency();
  const params = useParams();
  const onSubmit = async (values: any) => {
    if (activePayroll !== null && activePayroll !== '') {
      await mutateAsync(dataStructure(values, params)).catch(() =>
        toast.error(
          'A user with this email is already registered, please login',
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          }
        )
      );
      return;
    }
    setActivePayroll('');
  };

  if (industriesLoading || locationsLoading) {
    return <Spinner />;
  }
  return (
    <>
      <NavbarLayout>
        <div className="block lg:inline-block mt-4 lg:mt-0 lg:ml-4">
          <Button label="Help" bg="plannaPrimaryGreen" text="white" />
        </div>
      </NavbarLayout>

      <div className="flex flex-col justify-center items-center  mt-4">
        <div className="max-width center mt-4 p-5">
          <form
            className="form-action inner-max-width"
            onSubmit={handleSubmit(onSubmit)}>
            <h1 className="w-full font-bold text-2xl text-plannaPrimaryGreen">
              Complete Agency registration
            </h1>
            <div className={`form-group${errors?.agencyName ? ' error' : ''}`}>
              <label htmlFor="agencyName" className="form-label">
                Agency Name
              </label>
              <input
                placeholder="e.g ABC Agency"
                type="text"
                {...register('agencyName', {
                  required: 'Agency name is required',
                })}
                className="form-input"
                id="agencyName"
              />
              {errors?.agencyName && (
                <span className="text-red-500 text-sm">
                  {String(errors?.agencyName?.message)}
                </span>
              )}
            </div>

            <div className="split-form-group">
              <div className={`form-group${errors?.firstName ? ' error' : ''}`}>
                <label htmlFor="firstName" className="form-label">
                  First Name{' '}
                  <span className="text">(Agent Managing Account)</span>{' '}
                  <span>*</span>
                </label>
                <input
                  placeholder="e.g John"
                  type="text"
                  {...register('firstName', {
                    required: 'Agent first name is required',
                  })}
                  className="form-input"
                  id="firstName"
                />

                {errors?.firstName && (
                  <span className="text-red-500 text-sm">
                    {String(errors?.firstName?.message)}
                  </span>
                )}
              </div>
              <div className={`form-group${errors?.lastName ? ' error' : ''}`}>
                <label htmlFor="lastName" className="form-label">
                  Last Name{' '}
                  <span className="text">(Agent Managing Account)</span>{' '}
                  <span>*</span>
                </label>
                <input
                  placeholder="e.g Doe"
                  type="text"
                  {...register('lastName', {
                    required: 'Agent last name is required',
                  })}
                  className="form-input"
                  id="lastName"
                />
                {errors?.lastName && (
                  <span className="text-red-500 text-sm">
                    {String(errors?.lastName?.message)}
                  </span>
                )}
              </div>
            </div>

            <div className={`form-group${errors?.email ? ' error' : ''}`}>
              <label htmlFor="email" className="form-label">
                Agency Email <span>*</span>
              </label>
              <input
                placeholder="e.g example@email.com"
                type="email"
                {...register('email', {
                  required: 'Agency email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Invalid email address',
                  },
                })}
                className="form-input"
                id="email"
              />

              {errors?.email && (
                <span className="text-red-500 text-sm">
                  {String(errors?.email?.message)}
                </span>
              )}
            </div>
            <div className="split-form-group">
              <div className={`form-group${errors?.phone ? ' error' : ''}`}>
                <label htmlFor="phone" className="form-label">
                  Agency Phone Number
                </label>

                <PhoneInput
                  country="gb"
                  inputProps={{
                    name: 'phone',
                    id: 'phone',
                    className: 'form-phone',
                    autoFocus: true,
                  }}
                  inputStyle={{
                    width: '100%',
                    borderColor: errors?.phone ? 'red' : '',
                  }}
                  containerStyle={{
                    marginBottom: '1rem',
                  }}
                  onChange={(phone) => {
                    setValue('phone', phone);
                  }}
                />

                {errors?.phone && (
                  <span className="text-red-500 text-sm">
                    {String(errors?.phone?.message)}
                  </span>
                )}
              </div>
            </div>

            <div className={`form-group${errors?.password ? ' error' : ''}`}>
              <label htmlFor="password" className="form-label">
                Agency Password <span>*</span>
              </label>
              <input
                placeholder="Password"
                type="password"
                {...register('password', {
                  required: 'Agency password is required',
                  minLength: {
                    value: 6,
                    message: 'Password must be at least 6 characters',
                  },
                })}
                className="form-input"
                id="password"
              />

              {errors?.password && (
                <span className="text-red-500 text-sm">
                  {String(errors?.password?.message)}
                </span>
              )}
            </div>
            <div className={`form-group${errors?.website ? ' error' : ''}`}>
              <label htmlFor="website" className="form-label">
                Agency Website <span>*</span>
              </label>
              <input
                placeholder="e.g www.example.com"
                type="text"
                {...register('website', {
                  required: 'Agency website is required',
                  //url pattern
                  pattern: {
                    value:
                      // eslint-disable-next-line no-useless-escape
                      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
                    message: 'Invalid website URL',
                  },
                })}
                className="form-input"
                id="website"
              />

              {errors?.website && (
                <span className="text-red-500 text-sm">
                  {String(errors?.website?.message)}
                </span>
              )}
            </div>

            <div className="split-form-group">
              <div className={`form-group${errors?.industry ? ' error' : ''}`}>
                <label htmlFor="industry" className="form-label">
                  Agency Industry <span>*</span>
                </label>
                <select
                  className="form-input"
                  {...register('industry', {
                    required: 'Please select an industry',
                  })}>
                  <option value="">Select an option</option>
                  {industries?.industries.map((industry) => (
                    <option key={industry.uid} value={industry.uid}>
                      {industry.name}
                    </option>
                  ))}
                </select>

                {errors?.industry && (
                  <span className="text-red-500 text-sm">
                    {String(errors?.industry?.message)}
                  </span>
                )}
              </div>

              <div className={`form-group${errors?.location ? ' error' : ''}`}>
                <label htmlFor="location" className="form-label">
                  Agency Location <span>*</span>
                </label>
                <select
                  className="form-input"
                  {...register('location', {
                    required: 'Please select a location',
                  })}>
                  <option value="">Select an option</option>
                  {locations?.locations.map((location) => (
                    <option key={location.uid} value={location.uid}>
                      {location.state}
                    </option>
                  ))}
                </select>

                {errors?.location && (
                  <span className="text-red-500 text-sm">
                    {String(errors?.location?.message)}
                  </span>
                )}
              </div>
            </div>

            <div className="payroll-selector">
              <label htmlFor="">What payroll provider do you use</label>
              <div className="form-description">
                We'll send you some tips on how you can integrate your payroll
                provider with Planna
              </div>

              {activePayroll === '' && (
                <span className="text-red-500 text-sm">
                  Please Select a payroll provider
                </span>
              )}

              <div className="payroll-tabs">
                <div
                  className={`tab${activePayroll === 'sage' ? ' active' : ''}`}
                  onClick={() => setActivePayroll('sage')}>
                  <div className="icon">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="title">Sage</div>
                </div>
                <div
                  className={`tab${activePayroll === 'xero' ? ' active' : ''}`}
                  onClick={() => setActivePayroll('xero')}>
                  <div className="icon">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="title">Xero</div>
                </div>
                <div
                  className={`tab${
                    activePayroll === 'netsuite' ? ' active' : ''
                  }`}
                  onClick={() => setActivePayroll('netsuite')}>
                  <div className="icon">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="title">Netsuite</div>
                </div>
                <div
                  className={`tab${activePayroll === 'iris' ? ' active' : ''}`}
                  onClick={() => setActivePayroll('iris')}>
                  <div className="icon">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="title">Iris</div>
                </div>
                <div
                  className={`tab${activePayroll === 'other' ? ' active' : ''}`}
                  onClick={() => setActivePayroll('other')}>
                  <div className="icon">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="title">Other</div>
                </div>
              </div>
              {activePayroll === 'other' && (
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Enter your payroll provider (optional)
                  </label>

                  <input type="text" className="form-input" />
                </div>
              )}
            </div>
            <div className="mt-4">
              <button className="btn primary full">
                {isLoading ? 'Adding..' : '+Add Agency'}
              </button>
            </div>
          </form>
          <div className="mb-5" />
        </div>
      </div>
    </>
  );
};

export default CreateAgency;

const dataStructure = (data: any, params: any) => {
  return {
    name: data?.agencyName,
    first_name: data.firstName,
    last_name: data.lastName,
    password: data.password,
    email: data.email,
    phone_number: data.phone,
    website: data.website,
    location: data.location,
    industry: data.industry,
    contract: 'Agreement Details',
    shift_type: 'Night Shift',
    provider_uid: params?.id ? params?.id : '',
  };
};
