import React, {useState} from 'react';

type Tab = {id: string; label: string; component: JSX.Element};

type Props = {
  tabs: Tab[];
};

const Tabs = ({tabs}: Props) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <div className="border-b border-plannaNeutral w-full">
        <nav className="flex space-x-6">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`${
                activeTab === tab.id
                  ? 'border-b-4 border-plannaPrimaryGreen text-plannaPrimaryGreen'
                  : ''
              }  p-4 text-lg text-plannaPrimaryGreen focus:border-plannaPrimaryGreen focus:outline-none`}
              onClick={() => handleClick(tab.id)}>
              {tab.label}
            </button>
          ))}
        </nav>
      </div>
      <div className="mt-4">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`${activeTab === tab.id ? 'block' : 'hidden'}`}>
            {activeTab === tab.id && tab.component}
          </div>
        ))}
      </div>
    </>
  );
};

export default Tabs;
