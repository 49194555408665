import React, {useState} from 'react';

import Input from '../../Components/Registration/Input';
import SuccessLayout from '../../Layouts/SuccessLayout';
import useInviteWorker from '../../hooks/services/Agencys/useInviteWorker';

const InviteWorker = () => {
  const initialState = {
    worker_name: '',
    worker_email: '',
  };
  const [formData, setFormData] = useState(initialState);
  const [open, setIsOpen] = useState(false);
  const openDialog = () => {
    setIsOpen(true);
  };
  const {mutate, isLoading} = useInviteWorker(openDialog);
  const onClose = () => {
    setIsOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;

    setFormData({...formData, [name]: value});
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(formData);
  };

  return (
    <div className=" p-4 mt-16">
      <SuccessLayout isOpen={open} onClose={onClose}>
        <div className="p-4 flex flex-col items-center justify-center">
          <p className="text-lg font-bold">Worker Invited</p>
          <p>The worker has been invited sucessfully.</p>
        </div>
      </SuccessLayout>
      <h1 className="text-lg ml-24  font-bold">Invite a Worker</h1>
      <div className="flex flex-col items-center justify-center">
        <div className="card w-10/12 p-4">
          <form onSubmit={onSubmit}>
            <div className="mt-4">
              <Input
                inputValue={formData.worker_name}
                type="text"
                label="Worker Name"
                required
                name="worker_name"
                onChange={handleChange}
              />
            </div>
            <div className="mt-2">
              <Input
                inputValue={formData.worker_email}
                type="email"
                label="Worker Email"
                required
                name="worker_email"
                onChange={handleChange}
              />
            </div>

            <div className="mt-4">
              <button className="px-4 py-2 text-white w-3/4 bg-plannaPrimaryGreen rounded-xl mt-8 focus:outline-none">
                {isLoading ? 'inviting...' : 'Invite Worker'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InviteWorker;
