import {jwtDecode} from 'jwt-decode';
import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';

import useAuthToken from '../hooks/services/useAuthToken';

interface DecodedToken {
  user_id: string;
  email: string;
  group: string;
}
const LandingRedirect: React.FC = () => {
  const {token} = useAuthToken();

  if (token !== null || token) {
    const decodedToken: DecodedToken = jwtDecode(token);

    if (decodedToken.group === 'is_provider') {
      return <Navigate to="/home" replace />;
    } else {
      return <Navigate to="/agency/home" replace />;
    }
  }
  return <Outlet />;
};

export default LandingRedirect;
