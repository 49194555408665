import {useMutation, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

interface InviteAgencyParams {
  agency_name: string;
  agency_email: string;
}

const inviteAgency = async (data: InviteAgencyParams) => {
  const response = await axiosInstance.post('providers/agencies/invite/', data);

  return response;
};

const useInviteAgency = (
  openDialog: () => void
): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  InviteAgencyParams
> => {
  return useMutation(inviteAgency, {
    onSuccess: () => {
      openDialog();
    },
  });
};

export default useInviteAgency;
