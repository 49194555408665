import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import {DailyShiftResponse} from '../../../utils/types';
import axiosInstance from '../AxiosInstance';

type IdType = 'provider_uid' | 'agency__uid';

const fetchData = async (
  id: string,
  idType: IdType,
  date: string,
  timesheet__uid: string,
  status: string
): Promise<DailyShiftResponse> => {
  try {
    const queryParams = new URLSearchParams({
      limit: '10',
      status,
      timesheet__uid,
    });

    queryParams.append(idType, id);

    const response: AxiosResponse<DailyShiftResponse> = await axiosInstance.get(
      // eslint-disable-next-line
      `attendance/shifts/?${queryParams}`
    );

    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetProviderDailyShifts = (
  id: string,
  idType: IdType,
  date: string,
  timesheet__uid: string,
  status: string
): UseQueryResult<DailyShiftResponse, Error> => {
  return useQuery(['dailyshifts', id, date, timesheet__uid, status], () =>
    fetchData(id, idType, date, timesheet__uid, status)
  );
};

export default useGetProviderDailyShifts;
