import React from 'react';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import Input from '../../Components/Registration/Input';
import circularBack from '../../assets/icons/Circular-Backward.png';
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';

const AgencyProfile = () => {
  const navigate = useNavigate();
  const {data, isLoading} = useGetAgencyProfile();
  if (isLoading) return <Spinner />;
  return (
    <div className="mx-4">
      <img
        onClick={() => navigate(-1)}
        src={circularBack}
        alt="back"
        className="h-12 w-12 mt-4  cursor-pointer"
      />
      <div className="flex flex-col items-center">
        <h1 className="text-3xl  mr-48 text-plannaPrimaryGreen font-bold mt-9">
          Agency Profile
        </h1>
        <p className=" text-sm mr-48 text-plannaPrimaryGreen mt-2 font-normal">
          Manage your profile
        </p>
        <div className="w-8/12 mt-7 mb-7">
          <div className="mt-4">
            <Input
              //eslint-disable-next-line
              inputValue={data?.name}
              name="Agency Name"
              type="text"
              label="Agency Name"
              readonly
            />
          </div>

          <div className="mt-2">
            <Input
              //eslint-disable-next-line
              inputValue={data?.email}
              name="client_number"
              type="text"
              label="Agency Email"
              readonly
            />
          </div>
          <div className="mt-2">
            <Input
              //eslint-disable-next-line
              inputValue={data?.phone_number}
              name="client_number"
              type="text"
              label="Agency Phone Number"
              readonly
            />
          </div>
          <div className="mt-2">
            <Input
              //eslint-disable-next-line
              inputValue={data?.website}
              name="client_number"
              type="text"
              label="Agency Website"
              readonly
            />
          </div>
          <div className="mt-2">
            <Input
              //eslint-disable-next-line
              inputValue={data?.location.region}
              name="Default Agency User"
              type="text"
              label="Agency Location"
              readonly
            />
          </div>

          <div className="mt-2">
            <Input
              //eslint-disable-next-line
              inputValue={data?.industry.name}
              name="client_number"
              type="text"
              label="Agency Industry"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyProfile;
