import React from 'react';

import Input from '../../../Components/Registration/Input';
type WorkExperienceType = {
  job_title: string;
  company: string;
  start_date: string;
  end_date: string;
};
type Props = {
  experiences: WorkExperienceType[] | undefined;
};
const WorkExperience = ({experiences}: Props) => {
  return (
    <div className="p-4 w-full">
      {experiences !== undefined && experiences.length > 0 ? (
        <>
          {experiences.map((experience, index) => (
            <>
              <h1 className="mb-4 mt-4 text-plannaPrimaryGreen font-semibold">
                Work experience {index + 1}
              </h1>
              <div className="flex" key={index}>
                <Input
                  label="Company Name"
                  type="text"
                  name="company"
                  inputValue={experience.company}
                  readonly
                />
                <Input
                  label="Job title"
                  type="text"
                  name="job_title"
                  inputValue={experience.job_title}
                  readonly
                />
                <Input
                  label="Start date"
                  type="text"
                  name="start_date"
                  inputValue={experience.start_date}
                  readonly
                />
                <Input
                  label="End date"
                  type="text"
                  name="end_date"
                  inputValue={experience.end_date}
                  readonly
                />
              </div>
            </>
          ))}
        </>
      ) : (
        <h1>Work experience details are currently null</h1>
      )}
    </div>
  );
};

export default WorkExperience;
