// useDeleteUser.ts
import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

const deleteTemplate = async (userId: string) => {
  const response = await axiosInstance.delete(`/job/${userId}/`);
  return response;
};

// eslint-disable-next-line
const useDeleteTemplate = (
  handleClose: () => void
  // eslint-disable-next-line
): UseMutationResult<any, Error, string> => {
  const queryClient = useQueryClient();

  return useMutation(deleteTemplate, {
    onSuccess: () => {
      queryClient
        .invalidateQueries('jobtemplates')
        .then(() => handleClose)
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adju
    },
  });
};

export default useDeleteTemplate;
