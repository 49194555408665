/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import Spinner from '../../Components/Common/Spinner';
import SubmitButton from '../../Components/Common/SubmitButton';
import Input from '../../Components/Registration/Input';
import OnBoardingLayout from '../../Layouts/OnBoardingLayout';
import useLoginUser from '../../hooks/services/useLogin';
import useSendResetPasswordEmail from '../../hooks/services/useSendResetPasswordEmail';

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();
  const {mutateAsync, isLoading} = useLoginUser();
  const {mutateAsync: resetPassword, isLoading: forgotPasswordLoading} =
    useSendResetPasswordEmail();
  const [isLogin, setIsLogin] = useState(true);
  const toggleLogin = () => {
    setIsLogin((prevState) => !prevState);
  };
  const navigate = useNavigate();
  const initialState = {email: '', password: ''};

  const [formData, setFormData] = useState(initialState);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onSubmit = async (values: any) => {
    // console.log(data);

    // e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    await mutateAsync(values).catch(() =>
      toast.error('The credentials provided are not correct', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    );
  };
  const onSubmitForgotPassword = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    await resetPassword(formData).catch(() =>
      toast.error('The email is not registered', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    );
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <OnBoardingLayout provider title={isLogin ? 'Login' : 'Forgot password'}>
      {isLogin ? (
        <form
          className="form-action inner-max-width"
          onSubmit={handleSubmit(onSubmit)}>
          <div className={`form-group${errors?.email ? ' error' : ''}`}>
            <label htmlFor="email" className="form-label">
              Email address <span>*</span>
            </label>
            <input
              type="text"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Invalid email address',
                },
              })}
              placeholder="example@email.com"
              className="form-input"
            />

            {errors.email && (
              <span className="text-red-500 text-sm">
                {String(errors?.email?.message)}
              </span>
            )}
          </div>
          <div className={`form-group${errors?.password ? ' error' : ''}`}>
            <label htmlFor="password" className="form-label">
              Password <span>*</span>
            </label>
            <input
              type="password"
              {...register('password', {
                required: 'Password is required',
                minLength: {
                  value: 6,
                  message: 'Password must be at least 6 characters',
                },
              })}
              placeholder="Password"
              className="form-input"
            />
            {errors.password && (
              <span className="text-red-500 text-sm">
                {String(errors?.password?.message)}
              </span>
            )}
          </div>
          <br />
          <div className="form-group">
            <button className="btn primary full">Login</button>
          </div>
          <div className="left-actions mt-3">
            <h1
              onClick={toggleLogin}
              className="text-sm cursor-pointer  text-plannaGreyish">
              Forgot password?
            </h1>
          </div>
          <h1 className="mt-6 mb-6 text-lg text-center center-actions text-plannaGreyish">
            -OR-
          </h1>

          <div className="center-actions">
            <h1
              onClick={() => navigate('/onboarding')}
              className="text-sm text-center cursor-pointer text-plannaGreyish">
              Don't have an account? Sign up
            </h1>
          </div>
        </form>
      ) : (
        <form
          className="form-action inner-max-width"
          onSubmit={onSubmitForgotPassword}>
          <div className="mt-6">
            <Input
              type="email"
              inputValue={formData.email}
              onChange={handleChange}
              label="Email address"
              name="email"
              required
            />
          </div>
          <div className="mt-3 w-3/4">
            <SubmitButton
              buttonLabel="Submit"
              isLoading={forgotPasswordLoading}
            />
          </div>
          <div className="mt-3">
            <h1
              onClick={toggleLogin}
              className="text-sm cursor-pointer  text-plannaGreyish">
              Go back to login
            </h1>
          </div>
        </form>
      )}
    </OnBoardingLayout>
  );
};

export default Login;
