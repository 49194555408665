// SidebarItem.tsx
import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

import useAuthUser from '../../hooks/services/useUserDetails';

interface SidebarItemProps {
  title: string;
  icon?: string; // Use React.ElementType for the icon prop
  link?: string;
  children?: React.ReactNode;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  title,
  icon,
  link,
  children,
}) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const {user} = useAuthUser();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // Determine if the current link is active
  const isActive = link ? location.pathname === link : false;

  return (
    <li>
      {link ? (
        <Link
          to={link}
          className={`flex items-center p-2 ${icon ? '' : ' ml-5'} ${
            user?.group === 'is_provider'
              ? ' rounded-lg hover:text-plannaAccentGreen text-plannaNeutral  hover:bg-plannaPrimaryGreen'
              : ' rounded-lg hover:text-plannaPrimaryGreen text-plannaNeutralGray  hover:bg-plannaAccentGreen'
          } group ${
            isActive ? ' text-plannaAccentGreen bg-plannaPrimaryGreen' : ''
          }`}>
          {icon && <img src={icon} alt="sidebar" className="h-fit w-fit" />}
          <span className={`ms-3 ${isActive ? 'text-white' : ''}`}>
            {title}
          </span>
        </Link>
      ) : (
        <button
          type="button"
          className={`flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group ${
            user?.group === 'is_provider'
              ? 'e text-plannaNeutral hover:bg-plannaPrimaryGreen hover:text-plannaAccentGreen'
              : ' text-plannaNeutralGray hover:bg-plannaAccentGreen hover:text-plannaPrimaryGreen'
          } ${isActive ? ' bg-gray-600' : ''}`}
          onClick={handleToggle}>
          {icon && <img src={icon} alt="sidebar" className="h-fit w-fit" />}
          <span
            className={`flex-1 ms-3 text-left rtl:text-right whitespace-nowrap ${
              isActive ? 'text-plannaAccentGreen' : ''
            }`}>
            {title}
          </span>
          {children && (
            <svg
              className={`w-3 h-3 ${isActive ? ' text-white' : ''}`}
              aria-hidden="true"
              fill="none"
              viewBox="0 0 10 6">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          )}
        </button>
      )}

      {children && isOpen && <ul className="py-2 space-y-2">{children}</ul>}
    </li>
  );
};

export default SidebarItem;
