import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import CandidatesTable from './Components/CandidatesTable';
import SeekersTable from './Components/Table';
import Spinner from '../../Components/Common/Spinner';
import SubmitSpinner from '../../Components/Common/SubmitSpinner';
import SuccessLayout from '../../Layouts/SuccessLayout';
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';
import useSyncWorkersToWagestream from '../../hooks/services/Agencys/useSyncWorkersToWagestream';
import Tabs from '../Home/Components/Tabs';

const Workers = () => {
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile();
  const {mutateAsync, isLoading} = useSyncWorkersToWagestream();
  const tabs = [
    {id: 'tab1', label: 'Candidates', component: <CandidatesTable />},
    {id: 'tab2', label: 'Seekers', component: <SeekersTable />},
  ];
  const onSyncWorkers = async () => {
    await mutateAsync({
      agency_id: profile !== undefined ? profile.uid : '',
    }).then(() => setIsSuccess(true));
  };
  if (profileLoading) return <Spinner />;
  return (
    <div className=" p-8">
      <SuccessLayout isOpen={isSuccess} onClose={() => setIsSuccess(false)}>
        <p>Workers synced successfully</p>
      </SuccessLayout>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl text-plannaPrimaryGreen font-bold">
          Manage Workers
        </h1>
        <div className="flex space-x-4">
          {profile?.default_agency_user.position !== 'RECRUITER' &&
            profile?.uid !== process.env.REACT_APP_OUTERBOX_ID && (
              <button
                onClick={onSyncWorkers}
                className="px-6 py-2 bg-plannaPrimaryGreen text-plannaAccentGreen rounded-full">
                {isLoading ? <SubmitSpinner /> : '+ Sync workers to wagestream'}
              </button>
            )}
          <button
            onClick={() => navigate('/agency/invite/worker')}
            className="px-6 py-2 bg-plannaPrimaryGreen text-plannaAccentGreen rounded-full">
            + Invite worker
          </button>
        </div>
      </div>
      <div className="mt-8">
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
};

export default Workers;
