// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import {WorkerShiftResponse} from '../../../utils/types';
import axiosInstance from '../AxiosInstance';
interface WorkerShiftOptions {
  status: string;
  agency_id: string;
}
const fetchData = async (
  options: WorkerShiftOptions
): Promise<WorkerShiftResponse> => {
  const {status, agency_id} = options;
  const queryParams = new URLSearchParams({
    limit: '100',
    status: status,
    agency: agency_id,
    start_date: '2024-01-27T00:00:00Z',
    end_date: '2028-08-27T00:00:00Z',
  });
  try {
    const response: AxiosResponse<WorkerShiftResponse> =
      // eslint-disable-next-line
      await axiosInstance.get(`/shifts/worker-applications/?${queryParams}`);

    // eslint-disable-next-line
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetWorkerShiftsApplications = (
  options: WorkerShiftOptions
): UseQueryResult<WorkerShiftResponse, Error> => {
  const {status, agency_id} = options;
  return useQuery(['workerapplications', status, agency_id], () =>
    fetchData(options)
  );
};

export default useGetWorkerShiftsApplications;
