import React from 'react';

import NavbarLayout from '../../Layouts/NavbarLayout';
import Button from '../LandingPage/Button';

const ThankYouNotRegistered = () => {
  return (
    <>
      <NavbarLayout>
        <div className="block lg:inline-block mt-4 lg:mt-0 lg:ml-4">
          <Button label="Help" bg="plannaPrimaryGreen" text="white" />
        </div>
      </NavbarLayout>
      <div className="flex flex-col items-center justify-center mt-28">
        <div className="lg:w-1/2 w-full border-b-2 border-b-red-600 p-4 bg-red-600">
          <div className="flex items-center justify-center space-x-2">
            <p className="text-sm text-center text-white">
              oops: Your account was not created.{' '}
            </p>
          </div>
        </div>
        <h1 className="mt-24 text-black font-bold text-5xl">Thank you</h1>
        <p className="mt-4 text-lg text-center text-black">
          Thank you for completing your registration, you did not meet the
          requirements needed. Please try <br />
          out next time
        </p>
      </div>
    </>
  );
};

export default ThankYouNotRegistered;
