import React from 'react';
import {useNavigate} from 'react-router-dom';

import Footer from '../../Components/LandingPage/Footer';
import HeroSection from '../../Components/LandingPage/HeroSection';
import Navbar from '../../Components/LandingPage/Navbar';
import CheckListIcon from '../../assets/icons/Checklist.svg';
import ProductIcon1 from '../../assets/icons/product_icon1.svg';
import ProductImage2 from '../../assets/images/product_landing_image2.png';

export default function ProductPage() {
  const navigate = useNavigate();
  return (
    <>
      <div className="main-container products">
        <Navbar />
        <HeroSection currentPage="products" />
        <section className="flex md:flex-row flex-col md:py-40 md:pl-12 h-screen bg-white justify-center items-center">
          <div className="md:basis-1/2">
            <div className="pl-8">
              <p className="text-lg font-semibold md:text-4xl text-green-950 md:font-bold font-space-grotesk">
                Digital temp staff check-in, timesheet approval & onboarding
                reduce admin by 38%.
              </p>
            </div>
            <div className="container mx-auto p-8">
              <div className="md:grid grid-cols-2 grid-rows-2 gap-4 flex flex-col">
                <div className="">
                  <img src={ProductIcon1} className="mb-2" />
                  <p>
                    Our worker app reduces no shows
                    <br /> and admin errors with shift
                    <br /> notifications and onboarding
                    <br /> instructions
                  </p>
                </div>
                <div className="">
                  <img src={ProductIcon1} className="mb-2" />
                  <p>
                    Staff can register attendance via
                    <br /> our powerful geolocation powered
                    <br /> clock-in tool.
                  </p>
                </div>
                <div className="">
                  <img src={ProductIcon1} className="mb-2" />
                  <p>
                    Bulk timesheet approvals to save
                    <br /> time and reduce chasers and pay
                    <br /> disputes.
                  </p>
                </div>
                <div className="">
                  <img src={ProductIcon1} className="mb-2" />
                  <p>
                    Publish shifts to workers in real time
                    <br /> to improve efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:basis-1/2  md:flex justify-center items-end">
            <img src={ProductImage2} alt="Image" className="md:h-[40%] h-fit" />
          </div>
        </section>

        <section className="bg-plannaDarkTeal flex flex-col py-24 px-8">
          <div className="flex flex-col justify-center md:items-center">
            <p className="text-white md:text-6xl text-[28px] md:text-center text-start font-space-grotesk">
              Approve timesheets, invoices and track monthly spend.
            </p>
            <p className="text-white mt-10 md:text-center">
              AI powered applicant tracking and worker management, labour
              ordering <br /> and time and attendance verification will improve
              your margins <br />
              and reduce disputes.
            </p>
          </div>
          <div className="flex md:flex-row flex-col pt-8 md:px-8">
            <div className="md:basis-1/2 flex flex-col md:justify-center">
              <p className="text-white text-[30px] md:text-5xl pb-4 font-space-grotesk">
                Increase workers
                <br /> managed per account <br />
                manager by 38%.
              </p>
              <div className="flex mb-4">
                <img src={CheckListIcon} className="mr-2" />
                <p className="text-white ">
                  Publish shifts in real time to workers to improve efficiency.
                </p>
              </div>
              <div className="flex items-start mb-4">
                <img src={CheckListIcon} className="mr-2" />
                <p className="text-white pb-4">
                  Notifications of lapsing right to work and compliance for
                  workers
                  <br />
                  and agency managers.
                </p>
              </div>
              <button className="text-teal-950 text-xl font-semibold font-hanken-grotesk h-14 w-[40%] bg-green-200 rounded-[100px] border border-cyan-500">
                Learn more
              </button>
            </div>
            <div className="md:basis-1/2 flex justify-end items-baseline md:pt-0 mt-3">
              <img src={ProductImage2} alt="Image" className="h-[70%]" />
            </div>
          </div>
          <div className="flex md:flex-row flex-col-reverse pt-8 md:px-8">
            <div className="md:basis-1/2 flex justify-end md:justify-start items-baseline md:pt-0 mt-3">
              <img src={ProductImage2} alt="Image" className="h-[70%]" />
            </div>
            <div className="md:basis-1/2 flex flex-col justify-center md:jus">
              <p className="text-white text-[30px] md:text-5xl pb-4 font-space-grotesk">
                Powerful rate engine to handle holiday pay, margins & over.
              </p>
              <div className="flex items-start mb-4">
                <img src={CheckListIcon} className="mr-2" />
                <p className="text-white pb-4">
                  Single set up with no manual interventions needed.
                </p>
              </div>
              <div className="flex items-start mb-4">
                <img src={CheckListIcon} className="mr-2" />
                <p className="text-white pb-4">
                  Configure margins and rates across each client account.
                </p>
              </div>
              <button className="text-teal-950 text-xl font-semibold font-hanken-grotesk h-14 w-[40%] bg-green-200 rounded-[100px] border border-cyan-500">
                Learn more
              </button>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
