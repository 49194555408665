import {
  Image,
  Text,
  View,
  Page,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import React, {Fragment} from 'react';

import logo from '../assets/icons/Planna-Default.png';

type Props = {
  providerName: string;
  providerPhone: string;
  providerLocation: string;
  providerEmail: string;
  invoiceId: string;
  invoiceDate: string;
  invoiceAmount: string;
  agencyName: string;
  agencyLocation: string;
  agencyPhone: string;
  agencyEmail: string;
  jobName: string;
};
const InvoicePdf = ({
  providerName,
  providerPhone,
  providerLocation,
  providerEmail,
  invoiceId,
  invoiceAmount,
  invoiceDate,
  agencyName,
  agencyEmail,
  agencyLocation,
  agencyPhone,
  jobName,
}: Props) => {
  const styles = StyleSheet.create({
    page: {
      fontSize: 11,
      paddingTop: 20,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.5,
      flexDirection: 'column',
    },

    spaceBetween: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: '#3E3E3E',
    },

    titleContainer: {flexDirection: 'row', marginTop: 24},

    logo: {width: 90},

    reportTitle: {fontSize: 16, textAlign: 'center'},

    addressTitle: {fontSize: 11, fontStyle: 'bold'},

    invoice: {fontWeight: 'bold', fontSize: 20},

    invoiceNumber: {fontSize: 11, fontWeight: 'bold'},

    address: {fontWeight: 400, fontSize: 10},

    theader: {
      marginTop: 20,
      fontSize: 10,
      fontStyle: 'bold',
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      height: 20,
      backgroundColor: '#DEDEDE',
      borderColor: 'whitesmoke',
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    theader2: {flex: 2, borderRightWidth: 0, borderBottomWidth: 1},

    tbody: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      borderColor: 'whitesmoke',
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    total: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1.5,
      borderColor: 'whitesmoke',
      borderBottomWidth: 1,
    },

    tbody2: {flex: 2, borderRightWidth: 1},
  });

  const InvoiceTitle = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <Image style={styles.logo} src={logo} />
        <Text style={styles.reportTitle}>{agencyName}</Text>
      </View>
    </View>
  );

  const Address = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View>
          <Text style={styles.invoice}>Invoice </Text>
          <Text style={styles.invoiceNumber}>Invoice number: {invoiceId} </Text>
        </View>
        <View>
          <Text style={styles.addressTitle}>{agencyPhone} </Text>
          <Text style={styles.addressTitle}>{agencyEmail}</Text>
          <Text style={styles.addressTitle}>{agencyLocation}</Text>
        </View>
      </View>
    </View>
  );

  const UserAddress = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View style={{maxWidth: 200}}>
          <Text style={styles.addressTitle}>Bill to </Text>
          <Text style={styles.address}>{providerName}</Text>
          <Text style={styles.address}>{providerPhone}</Text>
          <Text style={styles.address}>{providerEmail}</Text>
          <Text style={styles.address}>{providerLocation}</Text>
        </View>
        <Text style={styles.addressTitle}>{invoiceDate}</Text>
      </View>
    </View>
  );

  const TableHead = () => (
    <View style={{width: '100%', flexDirection: 'row', marginTop: 10}}>
      <View style={{...styles.theader, ...styles.theader2}}>
        <Text>For services provided from 10/08/2024 to 20/08/2024</Text>
      </View>
      <View style={styles.theader}>
        <Text>NET Amount</Text>
      </View>
      <View style={styles.theader}>
        <Text>20% VAT</Text>
      </View>
    </View>
  );

  const TableBody = () => (
    <Fragment>
      <View style={{width: '100%', flexDirection: 'row'}}>
        <View style={{...styles.tbody, ...styles.tbody2}}>
          <Text>{jobName}</Text>
          <Text style={{marginTop: '15px'}}>3522 hours @ £15.83 Per Hour</Text>
        </View>

        <View style={styles.tbody}>
          <Text>£{invoiceAmount}</Text>
        </View>
        <View style={styles.tbody}>
          <Text>£5,831.89</Text>
        </View>
      </View>
    </Fragment>
  );
  const TotalTableHead = () => (
    <View style={{width: '100%', flexDirection: 'row', marginTop: 10}}>
      <View style={{...styles.theader, ...styles.theader2}}>
        <Text>Items</Text>
      </View>
      <View style={styles.theader}>
        <Text>Price</Text>
      </View>
      <View style={styles.theader}>
        <Text>Qty</Text>
      </View>
      <View style={styles.theader}>
        <Text>Amount</Text>
      </View>
    </View>
  );

  const TotalTableBody = () => (
    <Fragment>
      <View style={{width: '100%', flexDirection: 'row', marginTop: '30px'}}>
        <View style={{...styles.tbody, ...styles.tbody2}}>
          <Text>For inquiries Tel:020 8600 2911</Text>
          <Text>VAT REG NUMBER: 234 1371 38</Text>
          <Text>Planna app ltd</Text>
          <Text>Company Registration No: 09703490</Text>
        </View>

        <View style={styles.tbody}>
          <Text>Total NET £27,837.56</Text>
          <Text>Total VAT £5,837.56</Text>
          <Text>Invoice Total £33,401.47</Text>
        </View>
      </View>
    </Fragment>
  );
  const FooterDetails = () => (
    <Fragment>
      <View
        style={{
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '30px',
        }}>
        <View>
          <Text>For inquiries Tel:020 8600 2911</Text>
          <Text>VAT REG NUMBER: 234 1371 38</Text>
          <Text>Planna app ltd</Text>
          <Text>Company Registration No: 09703490</Text>
        </View>
      </View>
    </Fragment>
  );

  const TableTotal = () => (
    <View style={{width: '100%', flexDirection: 'row'}}>
      <View style={styles.total}>
        <Text />
      </View>
      <View style={styles.total}>
        <Text> </Text>
      </View>
      <View style={styles.tbody}>
        <Text>Total</Text>
      </View>
      <View style={styles.tbody}>
        <Text>{invoiceAmount}</Text>
      </View>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <InvoiceTitle />
        <Address />
        <UserAddress />
        <TableHead />
        <TableBody />
        <TableTotal />
        <TotalTableBody />
        <FooterDetails />
      </Page>
    </Document>
  );
};
export default InvoicePdf;
