import {useState} from 'react';
const TOKEN_KEY = 'jwt'; // Replace with your preferred key

const useAuthToken = () => {
  // State to store the token
  const [token, setToken] = useState<string | null>(() => {
    // Get the initial token value from localStorage
    const storedToken = sessionStorage.getItem(TOKEN_KEY);
    return storedToken || null;
  });

  // Function to set the token in state and localStorage
  const setAuthToken = (newToken: string | null) => {
    setToken(newToken);

    // Store the token in localStorage
    if (newToken) {
      sessionStorage.setItem(TOKEN_KEY, newToken);
    } else {
      // Remove the token from localStorage if null
      sessionStorage.removeItem(TOKEN_KEY);
    }
  };

  return {token, setAuthToken};
};

export default useAuthToken;
