import {useState} from 'react';

// Define the key for storing the object in local storage
const STORAGE_KEY = 'user'; // Replace with your preferred key

// Define the type for the user object
interface User {
  user_id: string;
  group: string;
  email: string;
  fullname: string;
}

const useAuthUser = () => {
  // State to store the user object
  const [user, setUser] = useState<User | null>(() => {
    // Get the initial user object from sessionStorage
    const storedUser = sessionStorage.getItem(STORAGE_KEY);
    return storedUser ? (JSON.parse(storedUser) as User) : null;
  });

  // Function to set the user object in state and sessionStorage
  const setAuthUser = (newUser: User | null) => {
    setUser(newUser);

    // Store the user object in sessionStorage
    if (newUser) {
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(newUser));
    } else {
      // Remove the user object from sessionStorage if null
      sessionStorage.removeItem(STORAGE_KEY);
    }
  };

  return {user, setAuthUser};
};

export default useAuthUser;
