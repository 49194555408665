import React from 'react';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';
import useGetAgencysUsers from '../../hooks/services/Agencys/useGetAgencyUsers';

const AgencyUsers = () => {
  const navigate = useNavigate();
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile();
  const {data, isLoading} = useGetAgencysUsers({
    agency_uid: profile !== undefined ? profile.uid : '',
  });
  if (isLoading || profileLoading) {
    return <Spinner />;
  }
  return (
    <div className="p-4">
      <div className="mt-8">
        <h1 className="text-black font-bold text-xl">Users</h1>
        <p className="text-black mt-2 text-sm">
          View users information with real-time insights and streamlined views
          on worker attendance
        </p>
      </div>
      <div className="flex justify-end">
        <button
          onClick={() => navigate('/agency/users/add')}
          className="px-4 py-2  bg-plannaPrimaryGreen text-plannaAccentGreen rounded-lg focus:outline-none">
          + Add a new user
        </button>
      </div>
      <div className="flex flex-col mt-16">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Role
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      User permission
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Action
                    </th>
                  </tr>
                </thead>

                {data?.results.map((user) => (
                  <tbody className="divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {user.user.first_name} {user.user.last_name}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {user.user.email}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {user.position === 'ACCOUNT_MANAGER'
                          ? 'Account Manager'
                          : user.position === 'RECRUITER'
                            ? 'Recruiter'
                            : 'Manager'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {user.user_permission === 'SUPER_ADMIN'
                          ? 'Super Admin'
                          : user.user_permission === 'USER'
                            ? 'User'
                            : 'Admin'}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        <button
                          onClick={() => navigate(`/agency/user/${user.uid}`)}
                          className="px-6 py-2 rounded-full bg-plannaPrimaryGreen text-plannaAccentGreen">
                          {' '}
                          View Details
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyUsers;
