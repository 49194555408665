// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import {ApiResponse} from '../../../utils/types';
import axiosInstance from '../AxiosInstance';

const fetchData = async (
  status: string,
  provider_uid: string,
  start_date: string,
  end_date: string
): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      `/shifts/?limit=100&start_date=2024-04-14T00:00:00Z&end_date=2024-10-14T00:00:00Z&status=${status}&provider__uid=${provider_uid}`
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetShifts = (
  status: string,
  provider_uid: string,
  start_date: string,
  end_date: string
): UseQueryResult<ApiResponse, Error> => {
  return useQuery(['shifts', status, provider_uid, start_date, end_date], () =>
    fetchData(status, provider_uid, start_date, end_date)
  );
};

export default useGetShifts;
