import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import CreateRate from './Components/CreateRate';
import TemplateCancelButton from './Components/TemplateCancelButton';
import TemplateDeleteButton from './Components/TemplateDeleteButton';
import TemplateDescription from './Components/TemplateDescription';
import TemplateInput from './Components/TemplateInput';
import TemplateSubmitButton from './Components/TemplateSubmitButton';
import Spinner from '../../Components/Common/Spinner';
import SubmitSpinner from '../../Components/Common/SubmitSpinner';
import DialogLayout from '../../Layouts/DialogLayout';
import circularBack from '../../assets/icons/Circular-Backward.png';
import useDeleteTemplate from '../../hooks/services/Shifts/DeleteJobTemplate';
import useGetJobTemplates from '../../hooks/services/Shifts/GetJobTemplates';
import useUpdateJobTemplate from '../../hooks/services/Shifts/useEditJobTemplate';
import useIndustriesData from '../../hooks/services/common/useGetIndustries';
import useGetRoles from '../../hooks/services/common/useGetRoles';
import useAuthUser from '../../hooks/services/useUserDetails';
import {convertRates} from '../../utils/Methods';
import {TransformedRate} from '../../utils/types';

type Uniform = {
  color: string;
  style: string;
  material: string;
  description: string;
  sample_image: string;
};
type TemplateType = {
  template_name: string;
  job_description: string;
  industry_uid: string;
  role_uid: string;
  rates: TransformedRate[];
  template_uid: string;
  skills_required: string[];
  wellness: string;
  uniform: Uniform;
};
type newRates = {
  rates: TransformedRate[];
};
const EditJobTemplate = () => {
  const navigate = useNavigate();
  const params = useParams();
  const {user} = useAuthUser();
  const {data: industries, isLoading: industriesLoading} = useIndustriesData();
  const {data: jobTemplate, isLoading: singleTemplateLoading} =
    useGetJobTemplates({uid: params.id !== undefined ? params.id : ''});
  const singleTemplate = jobTemplate?.results[0];
  const {data: roles, isLoading: rolesLoading} = useGetRoles({});
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleClose = () => setIsDeleteOpen(false);
  const {mutateAsync, isLoading: templateUpdateLoading} =
    useUpdateJobTemplate();
  const {
    mutateAsync: deleteTemplateMutation,
    isLoading: deleteTemplateLoading,
  } = useDeleteTemplate(handleClose);

  const initialState: TemplateType = {
    template_name: '',
    job_description: '',
    industry_uid: '',
    role_uid: '',
    rates: [],
    template_uid: '',
    skills_required: [],
    wellness: '',
    uniform: {
      color: '',
      material: '',
      style: '',
      sample_image: 'https://example.com/uniform_image.jpg',
      description: '',
    },
  };
  const [formData, setFormData] = useState(initialState);
  const [rateFormData, setRateFormData] = useState<newRates>();

  useEffect(() => {
    if (singleTemplate !== undefined) {
      const {
        industry,
        wellness,
        template_name,
        rates,
        role,
        job_description,
        uid,
        required_uniform,
      } = singleTemplate;
      const formattedRates = convertRates(rates);

      const newRates = {rates: formattedRates};
      setRateFormData(newRates);
      setFormData({
        industry_uid: industry,
        template_name,
        role_uid: role,
        rates: formattedRates,
        job_description,
        template_uid: uid,
        skills_required: [],
        wellness: wellness,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        uniform: {...required_uniform, material: 'Cotton'},
      });
    }
  }, [singleTemplate]);
  const handleSelectRateChange = (
    event: ChangeEvent<HTMLSelectElement>,
    rateType: string
  ) => {
    const {name, value} = event.target;
    if (rateFormData !== undefined) {
      const updatedRates = rateFormData.rates.map((rate) => {
        //eslint-disable-next-line
        if (rate.type === rateType) {
          //eslint-disable-next-line
          return {
            ...rate,
            //eslint-disable-next-line
            data: {
              //eslint-disable-next-line
              ...rate.data,
              [name]: value,
            },
          };
        }
        //eslint-disable-next-line
        return rate;
      });

      setRateFormData({
        ...rateFormData,
        rates: updatedRates,
      });
    }
  };
  const handleUniformChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    if (name.startsWith('uniform.')) {
      const uniformsField = name.replace('uniform.', '');
      setFormData((prevData) => ({
        ...prevData,
        uniform: {
          ...prevData.uniform,
          [uniformsField]: value,
        },
      }));
    } else {
      setFormData({...formData, [name]: value});
    }
  };
  const handleWeekendRateChange = (
    event: ChangeEvent<HTMLInputElement>,
    rateType: string
  ) => {
    const {name, value} = event.target;
    if (rateFormData !== undefined) {
      const updatedRates = rateFormData.rates.map((rate) => {
        //eslint-disable-next-line
        if (rate.type === rateType) {
          //eslint-disable-next-line
          return {
            ...rate,
            //eslint-disable-next-line
            data: {
              //eslint-disable-next-line
              ...rate.data,
              [name]: value,
            },
          };
        }
        //eslint-disable-next-line
        return rate;
      });

      setRateFormData({
        ...rateFormData,
        rates: updatedRates,
      });
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target;

    setFormData({...formData, [name]: value});
  };

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };
  const handleUniformDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target;
    if (name.startsWith('uniform.')) {
      const uniformsField = name.replace('uniform.', '');
      setFormData((prevData) => ({
        ...prevData,
        uniform: {
          ...prevData.uniform,
          [uniformsField]: value,
        },
      }));
    } else {
      setFormData({...formData, [name]: value});
    }
  };
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await mutateAsync(formData).then(() =>
      toast.success('Template updated successfully', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    );
  };

  if (industriesLoading || rolesLoading || singleTemplateLoading) {
    return <Spinner />;
  }

  const handleDelete = async () => {
    await deleteTemplateMutation(params.id !== undefined ? params.id : '')
      .then(() =>
        toast.success('Template deleted successfully', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      )
      .then(() =>
        navigate(
          user?.group === 'is_provider'
            ? '/job-template'
            : '/job-template/agency'
        )
      );
  };
  return (
    <div className="p-4">
      <DialogLayout rate isOpen={isOpen} onClose={onClose}>
        <CreateRate
          onClose={onClose}
          handleWeekendRateChange={handleWeekendRateChange}
          handleSelectRateChange={handleSelectRateChange}
          rateFormData={rateFormData}
          setRate={() => setIsOpen(false)}
          edit
        />
      </DialogLayout>
      <img
        onClick={() => navigate(-1)}
        src={circularBack}
        alt="back"
        className="h-12 w-12 ml-12  cursor-pointer"
      />
      <div className="flex flex-col items-center mt-6">
        <DialogLayout onClose={handleClose} isOpen={isDeleteOpen}>
          <div className="flex flex-col items-center justify-center my-6">
            <h1 className="text-plannaPrimaryGreen mb-4 font-bold text-lg">
              Are you sure you want to delete this template
            </h1>
            <div className="flex space-x-3">
              <button
                onClick={handleClose}
                className="px-6 py-2 rounded-full bg-plannaPrimaryGreen text-plannaAccentGreen">
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-6 py-2 rounded-full bg-plannaTertiaryRed text-plannaNeutralWhite">
                {deleteTemplateLoading ? <SubmitSpinner /> : 'Delete'}
              </button>
            </div>
          </div>
        </DialogLayout>
        <form className="form-action inner-max-width" onSubmit={onSubmit}>
          <div className="mt-6">
            <h1 className="  font-space-grotesk font-bold text-plannaPrimaryGreen text-2xl">
              Edit Job Template
            </h1>
            <p className="mt-4 text-sm font-normal text-plannaPrimaryGreen font-hanken-grotesk">
              Template creation enables you to generate jobs with distinct IDs,
              job titles, job descriptions, <br /> locations, and personalized
              rates.
            </p>

            {/* <div className="form-group">
              <label htmlFor="" className="form-label">
                Job Title <span>*</span>
              </label>
              <input type="text" className="form-input" />
            </div> */}
            <div className="mt-7">
              <TemplateInput
                label="Job Title"
                type="text"
                inputValue={formData.template_name}
                name="template_name"
                onChange={handleChange}
                placeholder="ID654"
                required
              />
            </div>
            <div className="mt-4">
              <TemplateDescription
                value={formData.job_description}
                label="Job Description"
                name="job_description"
                onChange={handleDescriptionChange}
                placeholder="Write a job description"
                required
              />
            </div>
            <div className="mt-4">
              <TemplateDescription
                value={formData.wellness}
                label="Health & safety information"
                name="wellness"
                onChange={handleDescriptionChange}
                placeholder="Write description"
                required
              />
            </div>
            <div className="mt-11">
              <h1 className="text-plannaPrimaryGreen font-bold text-xl font-hanken-grotesk">
                Job Rates
              </h1>
              <div className="mt-8">
                <div className="mt-4">
                  <button
                    type="button"
                    onClick={() => setIsOpen(true)}
                    className="bg-plannaNeutralGrey text-center text-plannaPrimaryGreen text-lg font-hanken-grotesk font-semibold w-full px-6 py-2.5 rounded-full">
                    Click to view and edit the rates
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-11">
              <h1 className="text-plannaPrimaryGreen font-bold text-xl font-hanken-grotesk">
                Industry & Roles
              </h1>
              <div className="mt-8">
                <div className=" w-full">
                  <label
                    htmlFor="inputField"
                    className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                    Select Industry
                    <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                      *
                    </span>
                  </label>
                  <select
                    value={formData.industry_uid}
                    onChange={handleSelect}
                    name="industry_uid"
                    required
                    className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-2 px-3 focus:outline-none">
                    {industries?.industries?.map((industry) => {
                      return (
                        <option key={industry.uid} value={industry.uid}>
                          {industry.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="mt-4">
                <div className=" w-full">
                  <label
                    htmlFor="inputField"
                    className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                    Select Role
                    <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                      *
                    </span>
                  </label>
                  <select
                    value={formData.role_uid}
                    onChange={handleSelect}
                    name="role_uid"
                    required
                    className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-2 px-3 focus:outline-none">
                    {roles?.results?.map((role) => {
                      return (
                        <option key={role.uid} value={role.uid}>
                          {role.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="mt-11">
                <h1 className="text-plannaPrimaryGreen font-bold text-xl font-hanken-grotesk">
                  Uniform
                </h1>
                <div className="mt-8">
                  <TemplateInput
                    inputValue={formData.uniform.color}
                    placeholder="black"
                    required
                    type="text"
                    label="Uniform Color"
                    onChange={handleUniformChange}
                    name="uniform.color"
                  />
                </div>

                <div className="mt-8">
                  <TemplateInput
                    inputValue={formData.uniform.style}
                    placeholder="White shirt and black trousers"
                    required
                    type="text"
                    label="Uniform Style"
                    onChange={handleUniformChange}
                    name="uniform.style"
                  />
                </div>

                <div className="mt-4">
                  <TemplateDescription
                    value={formData.uniform.description}
                    label="Uniform Description"
                    name="uniform.description"
                    onChange={handleUniformDescriptionChange}
                    placeholder="Write more details about the uniform"
                    required
                  />
                </div>
              </div>

              <div className="mt-16 mb-8 space-x-6 flex justify-between">
                <TemplateCancelButton label="Cancel" />
                <TemplateDeleteButton
                  onClick={() => setIsDeleteOpen(true)}
                  label="Delete"
                />
                <TemplateSubmitButton
                  shift
                  label="Save"
                  isLoading={templateUpdateLoading}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditJobTemplate;
