import React, {useState} from 'react';
import {useParams} from 'react-router-dom';

import SubmitButton from '../../Components/Common/SubmitButton';
import Button from '../../Components/LandingPage/Button';
import Input from '../../Components/Registration/Input';
import NavbarLayout from '../../Layouts/NavbarLayout';
import useResetPassword from '../../hooks/services/useResetPassowrd';

const ResetPassword = () => {
  const {mutate, isLoading, isError} = useResetPassword();
  const initialState = {password: '', password2: ''};
  const [passwordsMatch, setPasswordsMatch] = useState('');

  const params = useParams();

  const [formData, setFormData] = useState(initialState);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value});
    setPasswordsMatch('');
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const post = {
      password: formData.password,
      id: params.id !== undefined ? params.id : '',
    };
    if (formData.password !== formData.password2) {
      setPasswordsMatch('The passwords do not match');
    } else {
      mutate(post);
    }
  };

  return (
    <>
      <NavbarLayout>
        <div className="block lg:inline-block mt-4 lg:mt-0 lg:ml-4">
          <Button label="Help" bg="plannaPrimaryGreen" text="white" />
        </div>
      </NavbarLayout>
      <div className="mt-0">
        <div className="flex h-screen justify-center items-center">
          <div className=" lg:w-1/2 w-full">
            {isError && (
              <p className="mt-4 text-red-600">
                Something went wrong, check your details
              </p>
            )}
            <h1 className="mt-3  text-plannaPrimaryGreen  text-xl font-bold">
              Reset Password
            </h1>
            <form onSubmit={onSubmit}>
              <div className="mt-3">
                <Input
                  type="password"
                  inputValue={formData.password}
                  onChange={handleChange}
                  label="Password"
                  name="password"
                  required
                />
              </div>

              <div className="mt-3">
                <Input
                  type="password"
                  inputValue={formData.password2}
                  onChange={handleChange}
                  label="Confirm Password"
                  name="password2"
                  required
                />
              </div>
              <p className="mt-4 text-red-500">{passwordsMatch}</p>
              <div className="mt-3">
                <SubmitButton
                  buttonLabel="Reset Password"
                  isLoading={isLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
