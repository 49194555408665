import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../../Components/Common/Spinner';
import Input from '../../../Components/Registration/Input';
import useGetTimesheets from '../../../hooks/services/Shifts/useGetTimesheets';
import useGetProviderProfile from '../../../hooks/services/useGetProviderProfile';
import {formatCurrentDate, reverseDate} from '../../../utils/Methods';

const TimeSheets = () => {
  const {data: profile, isLoading: profileLoading} = useGetProviderProfile();
  const navigate = useNavigate();
  const currentDate = new Date();
  const formattedDate = formatCurrentDate(currentDate);
  const [inputDate, setInputDate] = useState<string>(formattedDate);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputDate(event.target.value);
  };
  // eslint-disable-next-line
  const {data, isLoading} = useGetTimesheets(
    profile !== undefined ? profile.uid : '',
    'provider__uid',
    reverseDate(inputDate)
  );
  if (profileLoading || isLoading) {
    return <Spinner />;
  }
  console.log(data);

  return (
    <div className="p-4">
      <div className="mt-8">
        <h1 className="text-black font-bold text-xl">Timesheets</h1>
        <p className="text-black mt-2 text-sm">
          View shift information with real-time insights and streamlined views
          on worker attendance
        </p>
      </div>
      <div className="flex justify-end">
        <div className="w-1/4">
          <Input
            inputValue={inputDate}
            name="date"
            type="date"
            label="Select date"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Active
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Locked
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Job Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Shift Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Action
                    </th>
                  </tr>
                </thead>
                {/* eslint-disable-next-line */}
                {data?.results.map((shift) => (
                  // eslint-disable-next-line
                  <tbody key={shift.uid} className="divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.date}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.is_active === true ? 'Active' : 'Not active'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.status.toLowerCase()}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.is_locked === true ? 'Locked' : 'Not locked'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {
                          shift.agency_shift_allocation.shift_template
                            .job_template.template_name
                        }
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.agency_shift_allocation.shift_template.name}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        <div className="flex space-x-3">
                          {/* eslint-disable-next-line */}
                          <button
                            onClick={() =>
                              // eslint-disable-next-line
                              navigate(`/daily-shifts/${shift.uid}`)
                            }
                            className="px-6 py-2 bg-plannaPrimaryGreen text-plannaAccentGreen rounded-full">
                            View details
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeSheets;
