import React from 'react';
import {Helmet} from 'react-helmet';

import Navbar from './Navbar';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy policy</title>
        <meta
          name="Planna privacy policy"
          content="This Privacy and Cookie Policy ('Policy'), together with our Terms of Use, for use of the Planna Platform, and any other documents referred to in the Policy, governs the collection, storage, and use of personal information by Planna, a product of Doctor Little LTD (a company registered in the United Kingdom with registration number 14306075 and registered address at 68 Somerset Hall Creighton Road, London, England, N17 8SH) ('we', 'us' or ''our'). "
        />
        <meta name="keywords" content="react, meta tags, seo" />
        <meta name="author" content="Planna" />
        <meta property="og:title" content="Planna privacy policy" />
        <meta
          property="og:description"
          content="This Privacy and Cookie Policy ('Policy'), together with our Terms of Use, for use of the Planna Platform, and any other documents referred to in the Policy, governs the collection, storage, and use of personal information by Planna, a product of Doctor Little LTD (a company registered in the United Kingdom with registration number 14306075 and registered address at 68 Somerset Hall Creighton Road, London, England, N17 8SH) ('we', 'us' or ''our')."
        />
      </Helmet>
      <Navbar />
      <div className="flex justify-center items-center my-8">
        <div className="lg:w-1/2 w-full p-4  mt-12">
          <h1 className="text-plannaPrimaryGreen text-2xl font-bold">
            Our Privacy policy
          </h1>
          <p className="mt-4">
            This Privacy and Cookie Policy ("Policy"), together with our Terms
            of Use, for use of the Planna Platform, and any other documents
            referred to in the Policy, governs the collection, storage, and use
            of personal information by Planna, a product of Doctor Little LTD (a
            company registered in the United Kingdom with registration number
            14306075 and registered address at 68 Somerset Hall Creighton Road,
            London, England, N17 8SH) ("we", "us" or "our"). Protecting your
            personal information is of utmost importance to us. This Policy
            provides you with details about the personal information we collect
            from you, how we use your personal information, and your rights to
            control the personal information we hold about you. Please carefully
            read this Policy to understand our views and practices regarding
            your personal information and how we will treat it. By using our
            website at www.weareplanna.com , or services available through it,
            you confirm that you have read and understood this Policy in its
            entirety. This Policy may change from time to time (see below,
            “Changes to this Policy”). Your continued use of our Website after
            we make changes is deemed acceptance of those changes, so please
            check the Policy periodically for updates.
          </p>
          <h1 className="text-plannaPrimaryGreen mt-3 text-lg font-semibold">
            A Note About Children
          </h1>
          <p className="mt-4">
            The Planna Platform is not directed to users who are children below
            the legal age in the relevant jurisdiction. If you are such a child,
            you cannot register with us for use of the Planna Platform.
          </p>
          <h1 className="text-plannaPrimaryGreen mt-3 text-lg font-semibold">
            What We Do
          </h1>
          <p className="mt-4">
            We provide a software platform (“Planna Platform”) for registered
            businesses ("Clients") to manage shifts and schedules for their
            workforce, facilitating efficient management of resources and
            staffing needs.
          </p>
          <p className="mt-2">
            Profiles will be created for you in any of the following ways:
          </p>
          <ul className="list-disc ml-8">
            <li>
              By you, when requested to do so by the business providing your
              services;
            </li>
            <li>By the business to which you are providing services; and</li>
            <li>
              By us when the business providing your services requests us to do
              so.
            </li>
          </ul>
          <h1 className="text-plannaPrimaryGreen mt-3 text-lg font-semibold">
            Personal Information We Collect About You
          </h1>
          <p className="mt-4">
            This Policy applies to the collection, storage, and use of personal
            information about you where "you" are:
          </p>
          <ul className="list-disc ml-8">
            <li>
              an Individual who has been introduced to our Planna Platform as a
              result of your work with a business signed up to the Planna
              platform; or
            </li>
            <li>a representative of a Client; or</li>
            <li>a contact or client of a Client.</li>
          </ul>
          <p className="mt-3 mb-3">
            We will collect and process the following information about you:
          </p>
          <h1 className="text-plannaPrimaryGreen mt-3 text-lg font-semibold">
            Information You Give to Us
          </h1>
          <p className="mt-4">
            Where you set up a profile at the request of a Client who is
            registered on our Planna Platform, we will collect information you
            provide when you (1) register and/or subscribe to use the Planna
            Platform, (2) when you report a problem with our Website once
            registered with our platform or (4) by you corresponding with us by
            phone, e-mail or otherwise after your registration
          </p>
          <p className="mt-4">This includes:</p>
          <ul className="list-disc ml-8">
            <li>
              Where you are an Individual setting up a profile within the Planna
              Platform, this information may include your name, age, postal
              address, telephone number, email address, nationality, national
              identification details and bank details. Additionally, where
              applicable, we may collect information about your qualifications,
              skills, and experience.
            </li>
            <li>
              Where you are an Individual using the Planna Platform for time and
              attendance purposes at a Client site, we may collect relevant
              information including a photograph of your face for identification
              purposes.
            </li>
            <li>
              Where you are a representative, contact, or client of a Client, we
              may collect your name, postal address, telephone number, and email
              address.
            </li>
          </ul>
          <h1 className="text-plannaPrimaryGreen mt-3 text-lg font-semibold">
            Information We Collect About You
          </h1>
          <p className="mt-4">
            With each of your visits to our Website, we will automatically
            collect technical information, including but not limited to, the
            Internet protocol (IP) address used to connect your computer to the
            Internet, your login information, browser type and version,
            time-zone setting, browser plug-in types and versions, operating
            system and platform.
          </p>
          <p className="mt-4">
            Additionally, we will collect information about your visit,
            including the full Uniform Resource Locators (URL), clickstream to,
            through and from our Website, searches you conducted through our
            Website, page response times, download errors, length of visits to
            certain pages, page interaction information (such as scrolling,
            clicks, and mouse-overs), methods used to browse away from the
            Website, and any phone number used to call our customer service
            number.
          </p>
          <h1 className="text-plannaPrimaryGreen mt-3 text-lg font-semibold">
            Information We Receive From Other Sources
          </h1>
          <p className="mt-4">
            All of the information in the “Information You Give to Us” section
            above will be collected from businesses signed up to our Planna
            Platform in the event that they create your profile, or ask us to
            create your profile, rather than you creating it yourself.
          </p>
          <p className="mt-4">
            In addition to this information, we may collect more information
            about you from Clients or other businesses with whom you are
            associated, having provided your services through the Planna
            Platform. This may include your CV information, shift history, and
            timesheets.
          </p>
          <p className="mt-4">
            We may also receive information from third parties, including
            business partners, subcontractors in technical, payment and delivery
            services, advertising networks, analytics providers, search
            information providers, and credit reference agencies.
          </p>
          <h1 className="text-plannaPrimaryGreen mt-3 text-lg font-semibold">
            Cookies
          </h1>
          <p className="mt-4">
            Our Website uses cookies to collect information about you and to
            distinguish you from other users of our Website. Cookies are small
            text files which are placed on your browser by our Website. When you
            visit our Website again, the cookies allow our Website to recognize
            your browser.
          </p>
          <p className="mt-4">
            Cookies are useful because they help us make your Website experience
            more enjoyable. They help us monitor the way in which our Website is
            used and allow us to recognize your device (for example, your laptop
            or mobile device) so that we can tailor your experience of our
            Website.
          </p>
          <p className="mt-4">
            We do not use cookie technology to collect personal information
            about you.
          </p>
          <p className="mt-4">
            Our Website uses Google Analytics for website analytics purposes.
            The cookies used by Google Analytics are used to collect information
            about how many visitors use our Website, the pages that generate
            interest, and the links that visitors most often click on (amongst
            other things). The information is collected anonymously and will
            only be used for website analytics purposes.
          </p>
          <p className="mt-4">
            You can also block and delete cookies by changing your browser
            settings. To manage cookies using your browser settings, most
            browsers allow you to refuse or accept all cookies or only to accept
            certain types of cookies. The process for the management and
            deletion of cookies can be found in the help function integrated in
            your browser.
          </p>
          <p className="mt-4">
            If you wish to limit the use of cookies, you may not be able to use
            all the interactive functions of our Website.
          </p>
          <h1 className="text-plannaPrimaryGreen mt-3 text-lg font-semibold">
            How We Store and Use Your Information
          </h1>
          <p className="mt-4">
            We use the information we gather in order to perform a number of
            tasks, including:
          </p>
          <ul className="list-disc ml-8">
            <li>
              making our Website and the services provided through it available
              to you;
            </li>
            <li>
              improving our Website to ensure that content is presented in the
              most effective manner for you and your device;
            </li>
            <li>assisting in our efforts to keep our Website safe;</li>
            <li>providing you with services that you request;</li>
            <li>
              enabling businesses to manage their workforce using our Planna
              Platform;
            </li>
            <li>
              conducting profiling and business development activities, as well
              as market research and statistical analysis regarding our
              services.
            </li>
          </ul>
          <p className="mt-4">
            We consider that it is necessary for our legitimate interests as a
            business to process your personal information. We use your personal
            information only for the purposes for which it was collected as
            described above. In addition, where Planna acts as a Data Processor,
            Planna will perform legitimate and relevant tasks as directed or
            authorised by the Data Controller.
          </p>
          <p className="mt-4">
            We recognize that you may wish to have your information shared with
            other businesses associated with our services. Where you request
            this, we will share your contact details and relevant information
            with other such businesses.
          </p>
          <p className="mt-4">
            We will not disclose, sell or rent your personal information to any
            third party unless you have consented to this, except:
          </p>
          <ul className="list-disc ml-8">
            <li>
              to the extent that we are required to do so by applicable law, by
              a governmental body or by a law enforcement agency, or for crime
              prevention purposes;
            </li>
            <li>
              in connection with any legal proceedings (including prospective
              legal proceedings);
            </li>
            <li>in order to establish or defend our legal rights</li>
            <li>
              in the event that we buy or sell any business or assets, in which
              case we may disclose your personal data to the prospective sellers
              or buyer of such business or assets; or
            </li>
            <li>
              if a third party acquires all (or substantially all) of our
              business and/or assets, we may disclose your personal information
              to that third party in connection with the acquisition.
            </li>
          </ul>
          <p className="mt-4">
            Any information provided by you to us, which is not personal
            information including but not limited to market information,
            feedback, questions or comments and other user-generated content,
            shall be deemed to be non-confidential. We shall have no obligation
            of any kind with respect to such information and shall be free to
            reproduce, use, disclose and distribute the information to others
            without limitation.
          </p>
          <h1 className="text-plannaPrimaryGreen mt-3 text-lg font-semibold">
            Your rights
          </h1>
          <p className="mt-4">
            We are committed to ensuring that your personal information is
            handled in accordance with applicable data protection laws,
            including the General Data Protection Regulation (GDPR) in the
            European Union and the Health Insurance Portability and
            Accountability Act (HIPAA) in the United States.
          </p>
          <p className="mt-4">
            As such, you have certain rights regarding your personal
            information, including but not limited to:
          </p>
          <ul className="list-disc ml-8">
            <li>
              The right to access: You have the right to request access to the
              personal information we hold about you. This includes the right to
              obtain confirmation as to whether or not your personal information
              is being processed and, where applicable, access to such personal
              information.
            </li>
            <li>
              The right to rectification: You have the right to request the
              correction of inaccurate personal information about you and to
              have incomplete personal information completed.
            </li>
            <li>
              The right to erasure: In certain circumstances, you have the right
              to request the erasure of your personal information. This includes
              situations where your personal information is no longer necessary
              for the purposes for which it was collected or processed, or if
              you withdraw consent on which the processing is based.
            </li>
            <li>
              The right to restrict processing: You have the right to request
              the restriction of processing of your personal information. This
              includes situations where you contest the accuracy of your
              personal information, or where the processing is unlawful and you
              oppose the erasure of your personal information.
            </li>
            <li>
              The right to data portability: You have the right to receive your
              personal information in a structured, commonly used, and
              machine-readable format and to transmit those data to another
              controller where technically feasible.
            </li>
            <li>
              The right to object: You have the right to object to the
              processing of your personal information, including processing
              based on legitimate interests or for direct marketing purposes.
            </li>
            <li>
              Rights in relation to automated decision making and profiling: We
              commit to ensuring that any automated decision-making and
              profiling we conduct is fair, transparent, and accountable.
            </li>
          </ul>
          <p className="mt-4">
            We are GDPR and HIPAA compliant, meaning that we adhere to the
            principles and requirements set forth by these regulations to
            safeguard your personal information.
          </p>
          <p className="mt-4">
            If you wish to exercise any of these rights, please contact us using
            the details provided in the "Inquiries or Questions" section below.
            We will respond to your request in accordance with applicable data
            protection laws.
          </p>
          <h1 className="text-plannaPrimaryGreen mt-3 text-lg font-semibold">
            Where We Store Your Personal Information
          </h1>
          <p className="mt-4">
            The data that we collect from you may be transferred to, and stored
            at, our servers within the United Kingdom. All information you
            provide is stored on secure servers. We use data processors who are
            third parties who provide elements of services for us. We have
            contracts in place with our data processors. This means that they
            cannot do anything with your personal information unless we have
            instructed them to do it. They will not share your personal
            information with any organization apart from us. They will hold it
            securely and retain it for the period we instruct. Where we use data
            processors who are based outside of the United Kingdom then we will
            ensure that this is legally protected and the transfer is undertaken
            on the basis of an adequacy decision where the appropriate
            safeguards are in place.
          </p>
          <h1 className="text-plannaPrimaryGreen mt-3 text-lg font-semibold">
            Security
          </h1>
          <p className="mt-4">
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we will do our best to protect your
            personal information, we cannot guarantee the security of your
            information transmitted to our Website; any transmission is at your
            own risk.
          </p>
          <p className="mt-4">
            Once we have received your information, we will use strict
            procedures and security features to try to prevent the loss, misuse,
            or alteration of or unauthorized access to your personal
            information.
          </p>
          <p className="mt-4">
            Our employees, contractors, and agents may be given access to your
            personal information which we collect, but their use shall be
            limited to the performance of their duties in relation to
            facilitating your use of our Website and the services available via
            it. Our employees, contractors, and agents who have access to your
            personal information are required to keep that information
            confidential and are not permitted to use it for any purposes other
            than those listed above or to deal with requests which you submit to
            us.
          </p>
          <h1 className="text-plannaPrimaryGreen mt-3 text-lg font-semibold">
            Third Party Sites
          </h1>
          <p className="mt-4">
            The Website may contain links to other websites operated and
            services provided by third parties and we cannot be responsible for
            personal information that third parties may collect, store and use
            through their websites or their services. You should always read the
            privacy policy of each website you visit carefully.
          </p>
          <h1 className="text-plannaPrimaryGreen mt-3 text-lg font-semibold">
            Data Retention
          </h1>
          <p className="mt-4">
            Where your personal information is collected as a result of a Client
            signing up to the Planna Platform, we will retain that information
            for the period which our agreement with that Client lasts.
          </p>
          <p className="mt-4">
            At the end of the periods described above, we will delete your
            personal information except where we need to keep any personal
            information to comply with our legal obligations, resolve disputes,
            or enforce our agreements.
          </p>
          <h1 className="text-plannaPrimaryGreen mt-3 text-lg font-semibold">
            Inquiries or Questions
          </h1>
          <p className="mt-4">
            If you have any questions about how we collect, store and use
            personal information, or if you have any other privacy-related
            questions, please contact us by e-mail at privacy@doctorlittle.org
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
