import React, {ChangeEvent, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import RecurrenceEditor from '../../Components/Common/RecurrenceEditor';
import Spinner from '../../Components/Common/Spinner';
import DialogLayout from '../../Layouts/DialogLayout';
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';
import useGetProviders from '../../hooks/services/Agencys/useGetProviders';
import useGetJobTemplates, {
  Results,
} from '../../hooks/services/Shifts/GetJobTemplates';
import useCreateShift from '../../hooks/services/Shifts/UseCreateShift';
import useLocationsData from '../../hooks/services/common/useGetLocations';
import useGetProviderProfile from '../../hooks/services/useGetProviderProfile';
import useAuthUser from '../../hooks/services/useUserDetails';
import TemplateDescription from '../Shifts/Components/TemplateDescription';
import TemplateInput from '../Shifts/Components/TemplateInput';
import TemplateSubmitButton from '../Shifts/Components/TemplateSubmitButton';

type Rate = {
  type: string;
  rate: string;
};

const AddAgencyShiftTemplate = () => {
  const navigate = useNavigate();
  const urlParams = useParams();

  let selectedDate = '';
  let selectedStartTime = '';
  let estimateEndTime = '';

  if (urlParams?.dateTime) {
    const date = new Date(urlParams?.dateTime);
    const formattedDate = date.toISOString();
    selectedDate = formattedDate.slice(0, 10);
    selectedStartTime = urlParams?.dateTime?.slice(11, 16);

    const selectedStartTimeDate = new Date(urlParams?.dateTime);
    const endTimeDate = new Date(
      selectedStartTimeDate.getTime() + 1 * 60 * 60 * 1000
    );
    const formattedEndTime = `${endTimeDate
      .getHours()
      .toString()
      .padStart(2, '0')}:${endTimeDate
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;

    estimateEndTime = formattedEndTime;
  }

  // console.log(
  //   `${
  //     parseInt(selectedStartTime?.slice(0, 2)) + 1 < 10 ? '0' : ''
  //   }${estimateEndTime}`
  // );
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile();

  const initialState = {
    name: '',
    description: '',
    workerCount: '0',
    location: '',
    start_time: selectedStartTime,
    end_time: estimateEndTime,
    start_date: selectedDate,
    end_date: '2024-09-12',
    company_uid: '',
    job_template_uid: '',
    status: 'ASSIGNED',
    recurrenceString: '',
    agency_uid: profile !== undefined ? profile.uid : '',
    rate_type: '',
    provider_id: '',
  };

  const [formData, setFormData] = useState(initialState);

  const {user} = useAuthUser();
  const {data: jobTemplates, isLoading: templateLoading} = useGetJobTemplates({
    created_by: user?.user_id,
  });
  const {data: providers, isLoading: providersLoading} = useGetProviders({
    agency_id: profile !== undefined ? profile.uid : '',
  });
  const {data: locations, isLoading: locationsLoading} = useLocationsData();
  const [rateTypes, setRateTypes] = useState<Rate[]>([]);
  const [providerTypes, setProviderTypes] = useState<Results[] | undefined>([]);
  const {mutate, isLoading} = useCreateShift();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);

  const ruleCallback = (rule: string) => {
    setFormData({
      ...formData,
      ['recurrenceString']: rule === '' ? rule : `RRULE:${rule}`,
    });
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type} = e.target;
    if (type === 'number') {
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData({...formData, [name]: numericValue});
    }

    setFormData({...formData, [name]: value});
  };
  const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };
  const handleTemplateSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target;
    const selectedTemplateUid = value;
    const selectedTemplate = providerTypes?.find(
      (template) => template.uid === selectedTemplateUid
    );
    if (selectedTemplate) {
      // Access the rates array from the selected template
      const ratesArray = selectedTemplate.rates;
      const newRateTypes = ratesArray.map((obj) => ({
        type: obj.rate_type,
        rate: obj.base_rate,
      }));
      setRateTypes(newRateTypes);
      // You can now use the ratesArray as needed
    }
    setFormData({...formData, [name]: value});
  };
  const handleProviderSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target;
    const selectedProviderUid = value;
    const selectedTemplate = jobTemplates?.results.filter(
      (template) => template.provider === selectedProviderUid
    );
    setProviderTypes(selectedTemplate);
    setFormData({...formData, [name]: value});
  };
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target;

    setFormData({...formData, [name]: value});
  };
  if (
    templateLoading ||
    locationsLoading ||
    profileLoading ||
    providersLoading
  ) {
    return <Spinner />;
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const post = {...formData};
    mutate(post);
  };
  return (
    <div className="flex flex-col items-center mt-6">
      <DialogLayout isOpen={isOpen} onClose={onClose}>
        <div className="mt-2 ">
          <RecurrenceEditor rule="" ruleOutputCallback={ruleCallback} />
        </div>
        <div className="mt-4">
          <button
            type="button"
            onClick={onClose}
            className=" bg-plannaNeutralGrey text-center text-plannaPrimaryGreen text-lg font-hanken-grotesk font-semibold w-full px-6 py-2.5 rounded-full">
            Save
          </button>
        </div>
      </DialogLayout>
      <form onSubmit={onSubmit}>
        <div className="mt-6">
          <h1 className=" text-center font-space-grotesk font-bold text-plannaPrimaryGreen text-2xl">
            Create Shift Schedule
          </h1>
          <p className="mt-4 text-sm font-normal text-plannaPrimaryGreen font-hanken-grotesk">
            Prepare a shift schedule that will meet both your requirements and
            the needs of <br /> your temp workers
          </p>
          <div className="mt-7">
            <TemplateInput
              label="Shift name"
              type="text"
              inputValue={formData.name}
              name="name"
              onChange={handleChange}
              placeholder="weekend shift"
              required
            />
          </div>
          <div className="mt-4">
            <div className=" w-full">
              <label
                htmlFor="inputField"
                className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                Provider
                <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                  *
                </span>
              </label>
              <select
                value={formData !== undefined ? formData.provider_id : ''}
                onChange={handleProviderSelect}
                name="provider_id"
                required
                className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-2 px-3 focus:outline-none">
                <option selected>Select provider</option>
                {providers?.results.map((provider) => {
                  return (
                    <option key={provider.uid} value={provider.uid}>
                      {provider.company.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="mt-4">
            <div className=" w-full">
              <label
                htmlFor="inputField"
                className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                Job Template
                <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                  *
                </span>
              </label>
              <select
                value={formData !== undefined ? formData.job_template_uid : ''}
                onChange={handleTemplateSelect}
                name="job_template_uid"
                required
                className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-2 px-3 focus:outline-none">
                <option selected>Select job template</option>
                {providerTypes?.map((template) => {
                  return (
                    <option key={template.uid} value={template.uid}>
                      {template.template_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="mt-1 5">
            <p
              onClick={() => navigate('/job-template/new/agency')}
              className="underline cursor-pointer text-plannaSecondaryGreen50 font-medium text-sm font-hanken-grotesk">
              Create new job template
            </p>
          </div>
          <div className="mt-4">
            <div className=" w-full">
              <label
                htmlFor="inputField"
                className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                Rate Type
                <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                  *
                </span>
              </label>
              <select
                value={formData.rate_type}
                onChange={handleTemplateSelect}
                name="rate_type"
                required
                className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-2 px-3 focus:outline-none">
                <option selected>Select rate type</option>
                {rateTypes.map((rate) => {
                  return (
                    <option key={rate.type} value={rate.type}>
                      {`${rate.type.toLowerCase()} - `}
                      <span className="text-plannaSecondaryGreen50 font-medium text-sm font-hanken-grotesk">
                        ({rate.rate}/hour)
                      </span>
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="mt-4">
            <div className=" w-full">
              <label
                htmlFor="inputField"
                className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                Location
                <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                  *
                </span>
              </label>
              <select
                value={formData.location}
                onChange={handleOnSelect}
                name="location"
                required
                className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-2 px-3 focus:outline-none">
                <option selected>Select location</option>
                {locations?.locations.map((location) => {
                  return (
                    <option key={location.uid} value={location.uid}>
                      {location.region},{location.country.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mt-4">
              <TemplateInput
                label="Number of workers"
                type="number"
                inputValue={parseInt(formData.workerCount)}
                name="workerCount"
                onChange={handleChange}
                placeholder="8"
                required
                min="0"
              />
            </div>
            <div className="mt-4">
              <TemplateInput
                label="Date"
                type="date"
                inputValue={formData.start_date}
                name="start_date"
                onChange={handleChange}
                required
                min={new Date().toISOString().split('T')[0]}
              />
            </div>
            <div className="mt-1 5">
              <p
                onClick={() => setIsOpen(true)}
                className="underline cursor-pointer text-plannaNeutral font-medium text-sm font-hanken-grotesk">
                Create the same shift on multiple days
              </p>
            </div>
            <div className="mt-4 flex space-x-12">
              <TemplateInput
                label="Start time"
                type="time"
                inputValue={formData.start_time}
                name="start_time"
                onChange={handleChange}
                required
              />
              <TemplateInput
                label="End time"
                type="time"
                inputValue={formData.end_time}
                name="end_time"
                onChange={handleChange}
                required
              />
            </div>
            <div className="mt-4">
              <TemplateDescription
                value={formData.description}
                label="Shift Description"
                name="description"
                onChange={handleDescriptionChange}
                placeholder="Write a shift description"
                required
              />
            </div>

            {/* <>
              <h1 className="mt-4 text-plannaPrimaryGreen font-bold text-xl font-hanken-grotesk">
                Employer
              </h1>
              <div
                className={`form-group mb-4${
                  errors.industry_uid ? ' error' : ''
                }`}>
                <label htmlFor="" className="form-label">
                  Select Employer <span>*</span>
                </label>
                <select
                  className="form-input"
                  {...register('provider_id', {
                    required: 'Please select an employer',
                  })}>
                  <option selected hidden value="">
                    Select Employer
                  </option>
                  {providers?.results.map((provider) => (
                    <option key={provider.uid} value={provider.uid}>
                      {provider.company.name}
                    </option>
                  ))}
                </select>

                {errors.provider_id && (
                  <span className="text-red-500 text-sm">
                    {String(errors?.provider_id?.message)}
                  </span>
                )}
              </div>
            </> */}
            <div className="mt-16 mb-8 space-x-6 flex justify-between">
              <TemplateSubmitButton
                label="Create Shift"
                isLoading={isLoading}
                shift
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddAgencyShiftTemplate;
