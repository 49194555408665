import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import {WorkerApprovalsResponse} from '../../../utils/types';
import axiosInstance from '../AxiosInstance';
type IdType = 'worker_shift__worker__agency__uid' | 'provider__uid';

const fetchData = async (
  status: string,
  id: string,
  idType: IdType
): Promise<WorkerApprovalsResponse> => {
  try {
    const queryParams = new URLSearchParams({
      status: status,
    });
    queryParams.append(idType, id);
    const response: AxiosResponse<WorkerApprovalsResponse> =
      await axiosInstance.get(
        // eslint-disable-next-line
        `attendance/worker-shifts/approval-requests/?${queryParams}`
      );
    // eslint-disable-next-line
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetShiftApprovals = (
  status: string,
  id: string,
  idType: IdType
): UseQueryResult<WorkerApprovalsResponse, Error> => {
  return useQuery(['workerapprovals', status, id, idType], () =>
    fetchData(status, id, idType)
  );
};

export default useGetShiftApprovals;
