/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {useMutation} from 'react-query';

import axiosInstance from '../../hooks/services/AxiosInstance';

const RQSendDirectMessage = (onSuccess: any, onError: any, urlParams: any) => {
  const sendDirectMessage = async (data: any) => {
    let url = '';
    if (urlParams?.chatType === 'chats' || !urlParams?.chatType) {
      url = '/messaging/send-message/';
    }
    if (urlParams?.chatType === 'groups') {
      url = '/messaging/group/';
    }
    const response = await axiosInstance.post(url, data);
    return response?.data;
  };

  return useMutation(sendDirectMessage, {
    onSuccess,
    onError,
  });
};

export default RQSendDirectMessage;
