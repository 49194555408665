// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import {AgencyProfile} from '../../../utils/types';
import axiosInstance from '../AxiosInstance';

const fetchData = async (): Promise<AgencyProfile> => {
  try {
    const response: AxiosResponse<AgencyProfile> = await axiosInstance.get(
      'agency/agency-profile/'
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetAgencyProfile = (
  is_agency?: boolean
): UseQueryResult<AgencyProfile, Error> => {
  return useQuery('agencyprofile', fetchData, {
    enabled: !!is_agency, // Enable the query only if is_agency is true
  });
};

export default useGetAgencyProfile;
