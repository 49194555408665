// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import axiosInstance from '../AxiosInstance';
type Company = {
  name: string;
};
type Country = {
  name: string;
};
type Location = {
  region: string;
  country: Country;
};
interface Data {
  phone_number: string;
  vat_number: string;
  client_number: string;
  company: Company;
  uid: string;
  location: Location;
}

interface ApiResponse {
  results: Data[];
}

interface WorkerShiftOptions {
  agency_id: string;
}
const fetchData = async (options: WorkerShiftOptions): Promise<ApiResponse> => {
  const {agency_id} = options;

  const queryParams = new URLSearchParams({
    agency_id: agency_id,
  });

  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      // eslint-disable-next-line
      `/providers/?${queryParams}`
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetProviders = (
  options: WorkerShiftOptions
): UseQueryResult<ApiResponse, Error> => {
  const {agency_id} = options;
  return useQuery(['providers', agency_id], () => fetchData(options));
};

export default useGetProviders;
