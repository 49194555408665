import moment from 'moment';
import React, {ChangeEvent, useState} from 'react';
import {MultiSelect} from 'react-multi-select-component';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../../Components/Common/Spinner';
import SuccessLayout from '../../../Layouts/SuccessLayout';
import useGetAgencyProfile from '../../../hooks/services/Agencys/useGetAgencyProfile';
import useGetWorkers from '../../../hooks/services/Agencys/useGetWorkers';
import useIndustriesData from '../../../hooks/services/common/useGetIndustries';
import useLocationsData from '../../../hooks/services/common/useGetLocations';
import useGetRoles from '../../../hooks/services/common/useGetRoles';
type SelectedOption = {
  label: string;
  value: string;
};
const SeekersTable = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [city, setCity] = useState('');
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile();
  const {data: industries, isLoading: industriesLoading} = useIndustriesData();
  const {data: roles, isLoading: rolesLoading} = useGetRoles({
    uid: profile?.industry.uid,
  });
  const {data: locations, isLoading: locationsLoading} = useLocationsData();

  const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState<
    SelectedOption[]
  >([]);
  const [selectedIndustries, setSelectedIndustries] = useState<
    SelectedOption[]
  >([]);
  const [selectedRoles, setSelectedRoles] = useState<SelectedOption[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<SelectedOption[]>(
    []
  );
  const [selectedRatings, setSelectedRatings] = useState<SelectedOption[]>([]);
  const {
    data: workersData,
    isLoading: workersLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetWorkers({
    status: 'SEEKER',
    agency_id: profile !== undefined ? profile.uid : '',
    roles: selectedRoles.map((item) => `${item.value}`).join(','),
    industries: selectedIndustries.map((item) => `${item.value}`).join(','),
    location_id: selectedLocations.map((item) => `${item.value}`).join(','),
    employment_status: selectedEmploymentStatus
      .map((item) => `${item.value}`)
      .join(', '),
    city: city,
    email: email,
    phone_number: phoneNumber,
    job_title: jobTitle,
    first_name: firstName,
    postal_code: postalCode,
    rating: selectedRatings.map((item) => `${item.value}`).join(','),
    limit: 10,
  });

  const multiRatings = () => {
    return ['A*', 'A', 'B', 'C'].map((obj) => ({
      label: obj,
      value: obj,
    }));
  };
  const multiEmploymentStatus = () => {
    return ['ACTIVE', 'TERMINATED', 'BLOCKED'].map((obj) => ({
      label: obj.toLowerCase(),
      value: obj,
    }));
  };
  const multiIndustries = () => {
    if (industries !== undefined) {
      return industries.industries.map((obj) => ({
        label: obj.name,
        value: obj.uid,
      }));
    }
  };
  const multiRoles = () => {
    return roles?.results.map((obj) => ({
      label: obj.name,
      value: obj.uid,
    }));
  };
  const multiLocations = () => {
    return locations?.locations.map((obj) => ({
      label: `${obj.region},${obj.country.name}`,
      value: obj.uid,
    }));
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setEmail(value);
  };
  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setPhoneNumber(value);
  };

  const handleJobTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setJobTitle(value);
  };
  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setFirstName(value);
  };
  const handlePostalCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setPostalCode(value);
  };

  if (
    industriesLoading ||
    rolesLoading ||
    locationsLoading ||
    workersLoading ||
    profileLoading
  ) {
    return <Spinner />;
  }
  const customStrings = {
    allItemsAreSelected: 'All items are selected.',
    clearSearch: 'Clear Search',
    noOptions: 'No options',
    search: 'Search',
    selectAll: 'Select All',
    selectAllFiltered: 'Select All (Filtered)',
    selectSomeItems: 'Select employment status..',
    create: 'Create',
  };
  const customIndustryStrings = {
    allItemsAreSelected: 'All items are selected.',
    clearSearch: 'Clear Search',
    noOptions: 'No options',
    search: 'Search',
    selectAll: 'Select All',
    selectAllFiltered: 'Select All (Filtered)',
    selectSomeItems: 'Select industries..',
    create: 'Create',
  };
  const customRoleStrings = {
    allItemsAreSelected: 'All items are selected.',
    clearSearch: 'Clear Search',
    noOptions: 'No options',
    search: 'Search',
    selectAll: 'Select All',
    selectAllFiltered: 'Select All (Filtered)',
    selectSomeItems: 'Select roles..',
    create: 'Create',
  };
  const customLocationStrings = {
    allItemsAreSelected: 'All items are selected.',
    clearSearch: 'Clear Search',
    noOptions: 'No options',
    search: 'Search',
    selectAll: 'Select All',
    selectAllFiltered: 'Select All (Filtered)',
    selectSomeItems: 'Select locations..',
    create: 'Create',
  };
  const customRatingStrings = {
    allItemsAreSelected: 'All items are selected.',
    clearSearch: 'Clear Search',
    noOptions: 'No options',
    search: 'Search',
    selectAll: 'Select All',
    selectAllFiltered: 'Select All (Filtered)',
    selectSomeItems: 'Select ratings..',
    create: 'Create',
  };

  return (
    <>
      <div className="flex justify-between mb-8">
        <div className="flex justify-end items-center text-sm space-x-2  mt-4 ">
          <div className="flex  items-center flex-wrap text-sm  mt-4 ">
            {profile?.uid !== process.env.REACT_APP_OUTERBOX_ID && (
              <>
                <div className="w-1/6 mb-2 ml-2">
                  <MultiSelect
                    options={multiIndustries() || []}
                    value={selectedIndustries}
                    onChange={setSelectedIndustries}
                    labelledBy="employment status"
                    className="text-plannaPrimaryGreen multi-select border w-full border-plannaNeutral rounded-lg"
                    overrideStrings={customIndustryStrings}
                  />
                </div>
                <div className="w-1/6 mb-2 ml-2">
                  <input
                    type="text"
                    value={jobTitle}
                    onChange={handleJobTitleChange}
                    placeholder="Search by job title"
                    className="text-plannaPrimaryGreen w-full border border-plannaNeutral rounded-lg py-2 px-3"
                  />
                </div>
                <div className="w-1/6 mb-2 ml-2">
                  <input
                    type="text"
                    value={postalCode}
                    onChange={handlePostalCodeChange}
                    placeholder="Search by postal code"
                    className="text-plannaPrimaryGreen w-full border border-plannaNeutral rounded-lg py-2 px-3"
                  />
                </div>
              </>
            )}

            <div className="w-1/6 mb-2 ml-2">
              <MultiSelect
                options={multiRoles() || []}
                value={selectedRoles}
                onChange={setSelectedRoles}
                labelledBy="employment status"
                className="text-plannaPrimaryGreen multi-select border w-full border-plannaNeutral rounded-lg "
                overrideStrings={customRoleStrings}
              />
            </div>
            <div className="w-1/6 mb-2 ml-2">
              <MultiSelect
                options={multiLocations() || []}
                value={selectedLocations}
                onChange={setSelectedLocations}
                labelledBy="employment status"
                className="text-plannaPrimaryGreen multi-select border w-full border-plannaNeutral rounded-lg "
                overrideStrings={customLocationStrings}
              />
            </div>

            <div className="w-1/6 mb-2 ml-2">
              <MultiSelect
                options={multiEmploymentStatus()}
                value={selectedEmploymentStatus}
                onChange={setSelectedEmploymentStatus}
                labelledBy="employment status"
                className="text-plannaPrimaryGreen multi-select border w-full border-plannaNeutral rounded-lg "
                overrideStrings={customStrings}
              />
            </div>
            <div className="w-1/6 mb-2 ml-2">
              <MultiSelect
                options={multiRatings()}
                value={selectedRatings}
                onChange={setSelectedRatings}
                labelledBy="employment status"
                className="text-plannaPrimaryGreen border multi-select w-full border-plannaNeutral rounded-lg "
                overrideStrings={customRatingStrings}
              />
            </div>
            <div className="w-1/6 mb-2 ml-2">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Search by email"
                className="text-plannaPrimaryGreen w-full  border border-plannaNeutral rounded-lg py-2 px-3"
              />
            </div>

            <div className="w-1/6 mb-2 ml-2">
              <input
                type="text"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="Search by phone number"
                className="text-plannaPrimaryGreen w-full border border-plannaNeutral rounded-lg py-2 px-3"
              />
            </div>

            <div className="w-1/6 mb-2 ml-2">
              <input
                type="text"
                value={firstName}
                onChange={handleFirstNameChange}
                placeholder="Search by first name"
                className="text-plannaPrimaryGreen w-full border border-plannaNeutral rounded-lg py-2 px-3"
              />
            </div>
            <div className="w-1/6 mb-2 ml-2">
              <button
                onClick={() => {
                  setSelectedEmploymentStatus([]),
                    setSelectedIndustries([]),
                    setSelectedRoles([]),
                    setSelectedLocations([]),
                    setSelectedRatings([]),
                    setCity('');
                  setEmail('');
                  setPhoneNumber('');
                  setJobTitle('');
                  setPostalCode('');
                  setFirstName('');
                }}
                className="  bg-plannaNeutralGray text-black px-6 py-2 rounded-xl">
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Registration Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Location
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Postal code
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Phone Number
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Action
                    </th>
                  </tr>
                </thead>
                {workersData?.pages.map((page, pageIndex) => (
                  <React.Fragment key={pageIndex}>
                    {page.results.map((worker) => (
                      <tbody className="divide-y divide-gray-200">
                        <tr>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {`${worker.user.first_name} ${worker.user.last_name}`}
                          </td>

                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {worker.user.email}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {moment(worker.created_at).format('DD MMMM YYYY')}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {worker.location.region},
                            {worker.location.country.name}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {worker.location.postal_code ?? 'null'}
                          </td>

                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {worker.contact_details !== null
                              ? worker.contact_details.phone_number
                              : 'null'}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            <div className="flex space-x-3">
                              <button
                                onClick={() =>
                                  navigate(
                                    `/agency/single-worker/${worker.uid}`
                                  )
                                }
                                className="px-6 py-2 bg-plannaPrimaryGreen text-plannaAccentGreen rounded-full">
                                View profile
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </React.Fragment>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
      <div>
        {hasNextPage ? (
          <button
            className=" mt-4 bg-plannaPrimaryGreen text-plannaAccentGreen px-6 py-2 rounded-full"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}>
            {isFetchingNextPage ? 'Loading more...' : 'Load more'}
          </button>
        ) : (
          <p className="mt-4 text-plannaPrimaryGreen" />
        )}
      </div>
    </>
  );
};

export default SeekersTable;
