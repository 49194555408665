/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {useMutation, useQueryClient} from 'react-query';

import axiosInstance from '../../hooks/services/AxiosInstance';

const RQRemoveUsersFromGroup = (
  onSuccess: any,
  onError: any,
  groupId: string
) => {
  const queryClient = useQueryClient();
  const createGroup = async (data: any) => {
    const response = await axiosInstance.post(
      `/messaging/group-users/${groupId}`,
      data
    );
    return response?.data;
  };

  return useMutation(createGroup, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries({
        queryKey: ['planna-group-users', groupId],
      });
      onSuccess(data);
    },
    onError,
  });
};

export default RQRemoveUsersFromGroup;
