// TextAreaComponent.tsx
import React from 'react';

interface TextAreaProps {
  value: string | undefined;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  name: string;
  required?: boolean;
  readonly?: boolean;
  placeholder: string;
}

const TemplateDescription: React.FC<TextAreaProps> = ({
  value,
  onChange,
  label,
  name,
  required,
  placeholder,
  readonly,
}) => {
  return (
    <div className="w-full">
      <label
        htmlFor="inputField"
        className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ">
        {label}
        {required && (
          <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
            *
          </span>
        )}
      </label>
      <textarea
        className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-3 px-3 focus:outline-none"
        value={value}
        onChange={onChange}
        name={name}
        required={required}
        readOnly={readonly}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TemplateDescription;
