/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {ChangeEvent, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import CreateRate from './Components/CreateRate';
import TemplateCancelButton from './Components/TemplateCancelButton';
import TemplateDescription from './Components/TemplateDescription';
import TemplateInput from './Components/TemplateInput';
import TemplateSubmitButton from './Components/TemplateSubmitButton';
import Spinner from '../../Components/Common/Spinner';
import DialogLayout from '../../Layouts/DialogLayout';
import circularBack from '../../assets/icons/Circular-Backward.png';
import TemplateSvg from '../../assets/icons/Job-Template.svg';
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';
import useGetProviders from '../../hooks/services/Agencys/useGetProviders';
import useCreateShiftTemplate from '../../hooks/services/Shifts/CreateNewShiftTemplate';
import useIndustriesData from '../../hooks/services/common/useGetIndustries';
import useGetRoles from '../../hooks/services/common/useGetRoles';
import useGetProviderProfile from '../../hooks/services/useGetProviderProfile';
import useAuthUser from '../../hooks/services/useUserDetails';
interface RateData {
  rate_name: string;
  rate_duration_type: string;
  base_rate: string;
  break_duration: string;
  minimum_shift_duration_for_breaks: string;
  paid_breaks: string;
  rate_type: string;
  default_margin_type: string;
  default_margin: number;
}
interface RateConfig {
  type: string;
  data: RateData;
}
interface FormState {
  rates: RateConfig[];
}
type Uniform = {
  color: string;
  material: string;
  style: string;
  sample_image: string;
  description: string;
};
type TemplateType = {
  template_name: string;
  job_description: string;
  industry_uid: string;
  role_uid: string;
  rate: RateData[];
  uniform: Uniform;
  skills_required: string[];
  wellness: string;
};
const ratesState: FormState = {
  rates: [
    {
      type: 'WEEKEND',
      data: {
        rate_type: 'WEEKEND',
        rate_name: '',
        rate_duration_type: 'HOURLY',
        base_rate: '0',
        break_duration: '',
        minimum_shift_duration_for_breaks: '',
        paid_breaks: '',
        default_margin_type: 'PERCENTAGE',
        default_margin: 2.0,
      },
    },
    {
      type: 'WEEKDAY',
      data: {
        rate_name: '',
        rate_duration_type: 'HOURLY',
        base_rate: '0',
        break_duration: '',
        minimum_shift_duration_for_breaks: '',
        paid_breaks: '',
        rate_type: 'WEEKDAY',
        default_margin_type: 'PERCENTAGE',
        default_margin: 2.0,
      },
    },
    {
      type: 'WEEKNIGHT',
      data: {
        rate_name: '',
        rate_duration_type: 'HOURLY',
        base_rate: '0',
        break_duration: '',
        minimum_shift_duration_for_breaks: '',
        paid_breaks: '',
        rate_type: 'WEEKNIGHT',
        default_margin_type: 'PERCENTAGE',
        default_margin: 2.0,
      },
    },
    {
      type: 'HOLIDAY',
      data: {
        rate_name: '',
        rate_duration_type: 'HOURLY',
        base_rate: '0',
        break_duration: '',
        minimum_shift_duration_for_breaks: '',
        paid_breaks: '',
        rate_type: 'HOLIDAY',
        default_margin_type: 'PERCENTAGE',
        default_margin: 2.0,
      },
    },
    {
      type: 'BASELINE',
      data: {
        rate_name: '',
        rate_duration_type: 'HOURLY',
        base_rate: '0',
        break_duration: '',
        minimum_shift_duration_for_breaks: '',
        paid_breaks: '',
        rate_type: '',
        default_margin_type: 'PERCENTAGE',
        default_margin: 2.0,
      },
    },
  ],
};

// eslint-disable-next-line
const NewJobTemplate = () => {
  const {data: providerProfile, isLoading: providerProfileLoading} =
    useGetProviderProfile();
  const {
    register,
    watch,
    getValues,
    handleSubmit,
    formState: {errors},
  } = useForm();
  const {user} = useAuthUser();
  const navigate = useNavigate();
  const [ratesTrue, setRatesTrue] = useState(false);
  const {data: industries, isLoading: industriesLoading} = useIndustriesData();
  const {data: roles, isLoading: rolesLoading} = useGetRoles({});
  const {mutate, isLoading: templateCreationLoading} = useCreateShiftTemplate();
  const [rateFormData, setRateFormData] = useState(ratesState);

  const initialState: TemplateType = {
    template_name: '',
    job_description: '',
    industry_uid: '',
    role_uid: '',
    rate: [],
    wellness: '',
    uniform: {
      color: '',
      material: 'Cotton',
      style: '',
      sample_image: 'https://example.com/uniform_image.jpg',
      description: '',
    },
    skills_required: [],
  };
  const [formData, setFormData] = useState(initialState);
  const [industryId, setIndustryId] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const handleSelectRateChange = (
    event: ChangeEvent<HTMLSelectElement>,
    rateType: string
  ) => {
    const {name, value} = event.target;
    const updatedRates = rateFormData.rates.map((rate) => {
      if (rate.type === rateType) {
        return {
          ...rate,
          data: {
            ...rate.data,
            [name]: value,
          },
        };
      }
      return rate;
    });

    setRateFormData({
      ...rateFormData,
      rates: updatedRates,
    });
  };
  const handleWeekendRateChange = (
    event: ChangeEvent<HTMLInputElement>,
    rateType: string
  ) => {
    const {name, value, type} = event.target;
    if (type === 'number') {
      const numericValue = value.replace(/[^0-9]/g, '');
      const updatedRates = rateFormData.rates.map((rate) => {
        if (rate.type === rateType) {
          return {
            ...rate,
            data: {
              ...rate.data,
              [name]: numericValue,
            },
          };
        }
        return rate;
      });

      setRateFormData({
        ...rateFormData,
        rates: updatedRates,
      });
    }
    const updatedRates = rateFormData.rates.map((rate) => {
      if (rate.type === rateType) {
        return {
          ...rate,
          data: {
            ...rate.data,
            [name]: value,
          },
        };
      }
      return rate;
    });

    setRateFormData({
      ...rateFormData,
      rates: updatedRates,
    });
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    if (name.startsWith('uniform.')) {
      const uniformsField = name.replace('uniform.', '');
      setFormData((prevData) => ({
        ...prevData,
        uniform: {
          ...prevData.uniform,
          [uniformsField]: value,
        },
      }));
    } else {
      setFormData({...formData, [name]: value});
    }
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target;
    if (name.startsWith('uniform.')) {
      const uniformsField = name.replace('uniform.', '');
      setFormData((prevData) => ({
        ...prevData,
        uniform: {
          ...prevData.uniform,
          [uniformsField]: value,
        },
      }));
    } else {
      setFormData({...formData, [name]: value});
    }
  };

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
    if (name === 'industry_uid') {
      setIndustryId(value);
    }
  };
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const filteredArray = rateFormData?.rates
      .filter((obj) => {
        // Filter condition: Include objects where 'name' is not empty
        return parseFloat(obj.data.base_rate) !== 0;
      })
      .map((rate) => {
        const post = {
          rate_type: rate.type,
          rate_name: rate.data.rate_type,
          rate_duration_type: rate.data.rate_duration_type,
          base_rate: parseFloat(rate.data.base_rate),
          break_duration: rate.data.break_duration,
          minimum_shift_duration_for_breaks:
            rate.data.minimum_shift_duration_for_breaks,
          paid_breaks: rate.data.paid_breaks === 'true' ? true : false,
          default_margin_type: rate.data.default_margin_type,
          default_margin: rate.data.default_margin,
        };
        return post;
      });
    const post = {...formData, rates: filteredArray};
    mutate(post);
  };
  const handleSetState = (value: boolean) => {
    setRatesTrue(value); // Function to update the state
  };

  const onSubmitNewTemplate = (data: any) => {
    const filteredArray = rateFormData?.rates
      .filter((obj) => {
        // Filter condition: Include objects where 'name' is not empty
        return parseFloat(obj.data.base_rate) !== 0;
      })
      .map((rate) => {
        const post = {
          rate_type: rate.type,
          rate_name: rate.data.rate_type,
          rate_duration_type: rate.data.rate_duration_type,
          base_rate: parseFloat(rate.data.base_rate),
          break_duration: rate.data.break_duration,
          minimum_shift_duration_for_breaks:
            rate.data.minimum_shift_duration_for_breaks,
          paid_breaks: rate.data.paid_breaks === 'true' ? true : false,
          default_margin_type: rate.data.default_margin_type,
          default_margin: rate.data.default_margin,
        };
        return post;
      });

    const newObject = {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      template_name: data.jobTitle,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      job_description: data.job_description,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      industry_uid: data.industry_uid,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      role_uid: data.role_uid,
      rate: [],
      rates: filteredArray,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      wellness: data.healthSafetyInfo,
      uniform: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        color: data.uniform_color,
        material: 'Cotton',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        style: data.uniform_style,
        sample_image: 'https://example.com/uniform_image.jpg',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        description: data.uniform_description,
      },
      skills_required: [],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      provider_id: providerProfile !== undefined ? providerProfile.uid : '',
    };

    const post = {...newObject};
    mutate(post);
  };

  if (
    industriesLoading ||
    rolesLoading ||
    templateCreationLoading ||
    providerProfileLoading
  ) {
    return <Spinner />;
  }
  return (
    <div className="p-4">
      <img
        onClick={() => navigate(-1)}
        src={circularBack}
        alt="back"
        className="h-12 w-12 ml-12  cursor-pointer"
      />

      <div className="flex flex-col items-center mx-16 mt-6">
        <DialogLayout rate isOpen={isOpen} onClose={onClose}>
          <CreateRate
            onClose={onClose}
            handleWeekendRateChange={handleWeekendRateChange}
            handleSelectRateChange={handleSelectRateChange}
            rateFormData={rateFormData}
            setRate={handleSetState}
          />
        </DialogLayout>
        <img src={TemplateSvg} alt="template" className="h-fit w-fit" />

        <form
          className="form-action inner-max-width"
          onSubmit={handleSubmit(onSubmitNewTemplate)}>
          <h1 className=" text-center font-space-grotesk font-bold text-plannaPrimaryGreen text-2xl">
            Create Job Template{' '}
          </h1>
          <p className="mt-4 text-sm font-normal text-plannaPrimaryGreen font-hanken-grotesk">
            Template creation enables you to generate jobs with distinct IDs,
            job titles, job descriptions, <br /> locations, and personalized
            rates.
          </p>
          <div className={`form-group ${errors.jobTitle ? 'error' : ''}`}>
            <label htmlFor="" className="form-label">
              Job title <span>*</span>
            </label>
            {/* Input should have a max length of 20 characters */}
            <input
              type="text"
              {...register('jobTitle', {
                required: 'Please provide the job title',
                maxLength: {
                  value: 20,
                  message: 'Job title must be less than 20 characters',
                },
              })}
              placeholder="Waitress"
              className="form-input"
            />
            {errors.jobTitle && (
              <span className="text-red-500 text-sm">
                {String(errors?.jobTitle?.message)}
              </span>
            )}
          </div>
          <div
            className={`form-group ${errors.job_description ? 'error' : ''}`}>
            <label htmlFor="" className="form-label">
              Job Description <span>*</span>
            </label>
            <textarea
              id=""
              className="form-input"
              {...register('job_description', {
                required: 'Please type the job description',
              })}
              placeholder="
              Write a job description"
            />
            {errors.job_description && (
              <span className="text-red-500 text-sm">
                {String(errors?.job_description?.message)}
              </span>
            )}
          </div>
          <div
            className={`form-group ${errors.healthSafetyInfo ? 'error' : ''}`}>
            <label htmlFor="" className="form-label">
              Health &amp; safety Information <span>*</span>
            </label>
            <textarea
              id=""
              {...register('healthSafetyInfo', {
                required: 'Please provide some health and safety information',
              })}
              className="form-input"
              placeholder="Write a description"
            />
            {errors.healthSafetyInfo && (
              <span className="text-red-500 text-sm">
                {String(errors?.healthSafetyInfo?.message)}
              </span>
            )}
          </div>
          <h1 className="mt-4 text-plannaPrimaryGreen font-bold text-xl font-hanken-grotesk">
            Job Rates
          </h1>
          <div className="form-group">
            <div
              className="btn full secondary rounded mt-4"
              onClick={() => setIsOpen(true)}>
              {ratesTrue
                ? 'The rates were saved successfully, click to edit'
                : 'Add new rates'}
            </div>
          </div>
          <h1 className="mt-4 text-plannaPrimaryGreen font-bold text-xl font-hanken-grotesk">
            Industry & Roles
          </h1>
          <div
            className={`form-group mb-4${errors.industry_uid ? ' error' : ''}`}>
            <label htmlFor="" className="form-label">
              Select Industry <span>*</span>
            </label>
            <select
              className="form-input"
              {...register('industry_uid', {
                required: 'Please select an industry',
              })}>
              <option selected hidden value="">
                Select an industry
              </option>
              {industries?.industries?.map((industry) => (
                <option key={industry.uid} value={industry.uid}>
                  {industry.name}
                </option>
              ))}
            </select>

            {errors.industry_uid && (
              <span className="text-red-500 text-sm">
                {String(errors?.industry_uid?.message)}
              </span>
            )}
          </div>
          <div className={`form-group${errors.role_uid ? ' error' : ''}`}>
            <label htmlFor="" className="form-label">
              Select Role <span>*</span>
            </label>
            <select
              className="form-input"
              {...register('role_uid', {
                validate: {
                  selectRole: (value) => {
                    if (watch('industry_uid') === '' && value === '') {
                      return 'Please select an industry and a role';
                    } else if (watch('industry_uid') === '') {
                      return 'Please select an industry first';
                    } else if (value === '') {
                      return 'Please select a role';
                    }
                    return true;
                  },
                },
              })}
              // onChange={handleSelect}
            >
              <option selected value="">
                Select a role
              </option>
              {roles?.results
                ?.filter((role) => role.industry === watch('industry_uid'))
                .map((role) => {
                  return (
                    <option key={role.uid} value={role.uid}>
                      {role.name}
                    </option>
                  );
                })}
            </select>
            {errors.role_uid && (
              <span className="text-red-500 text-sm">
                {String(errors?.role_uid?.message)}
              </span>
            )}
          </div>

          <h1 className="my-5 text-plannaPrimaryGreen font-bold text-xl font-hanken-grotesk">
            Uniform
          </h1>
          <div className={`form-group ${errors.uniform_color ? 'error' : ''}`}>
            <label htmlFor="" className="form-label">
              Uniform Color <span>*</span>
            </label>
            <input
              type="text"
              {...register('uniform_color', {
                required: 'Please provide the uniform color',
              })}
              placeholder="black"
              className="form-input"
            />

            {errors.uniform_color && (
              <span className="text-red-500 text-sm">
                {String(errors?.uniform_color?.message)}
              </span>
            )}
          </div>

          <div className={`form-group ${errors.uniform_style ? 'error' : ''}`}>
            <label htmlFor="" className="form-label">
              Uniform Style <span>*</span>
            </label>
            <input
              type="text"
              {...register('uniform_style', {
                required: 'Please provide the uniform style',
              })}
              placeholder="White shirt and black trousers"
              className="form-input"
            />

            {errors.uniform_style && (
              <span className="text-red-500 text-sm">
                {String(errors?.uniform_style?.message)}
              </span>
            )}
          </div>

          <div
            className={`form-group ${
              errors.uniform_description ? 'error' : ''
            }`}>
            <label htmlFor="" className="form-label">
              Uniform Description <span>*</span>
            </label>
            <textarea
              id=""
              className="form-input"
              {...register('uniform_description', {
                required: 'Please provide the uniform description',
              })}
              placeholder="Write more details about the uniform"
            />
          </div>

          <div className="form-actions">
            <div className="btn cancel">Cancel</div>
            <button className="btn primary">Create Template</button>
          </div>
        </form>
        <form onSubmit={onSubmit}>
          <div className="mt-6 w-100">
            {/* <h1 className=" text-center font-space-grotesk font-bold text-plannaPrimaryGreen text-2xl">
              Create Job Template{' '}
            </h1>
            <p className="mt-4 text-sm font-normal text-plannaPrimaryGreen font-hanken-grotesk">
              Template creation enables you to generate jobs with distinct IDs,
              job titles, job descriptions, <br /> locations, and personalized
              rates.
            </p>
            <div className="mt-7">
              <TemplateInput
                label="Job Title"
                type="text"
                inputValue={formData.template_name}
                name="template_name"
                onChange={handleChange}
                placeholder="ID654"
                required
              />
            </div>
            <div className="mt-4">
              <TemplateDescription
                value={formData.job_description}
                label="Job Description"
                name="job_description"
                onChange={handleDescriptionChange}
                placeholder="Write a job description"
                required
              />
            </div>
            <div className="mt-4">
              <TemplateDescription
                value={formData.wellness}
                label="Health & safety information"
                name="wellness"
                onChange={handleDescriptionChange}
                placeholder="Write description"
                required
              />
            </div> */}
            {/* <div className="mt-11">
              <h1 className="text-plannaPrimaryGreen font-bold text-xl font-hanken-grotesk">
                Job Rates
              </h1>
              <div className="mt-8">
                <div className="mt-4">
                  <button
                    type="button"
                    onClick={() => setIsOpen(true)}
                    className="bg-plannaNeutralGrey text-center text-plannaPrimaryGreen text-lg font-hanken-grotesk font-semibold w-full px-6 py-2.5 rounded-full">
                    {ratesTrue
                      ? 'The rates were saved successfully, click to edit'
                      : 'Add new rates'}
                  </button>
                </div>
              </div>
            </div> */}
            {/* <div className="mt-11">
              <h1 className="text-plannaPrimaryGreen font-bold text-xl font-hanken-grotesk">
                Industry & Roles
              </h1>
              <div className="mt-8">
                <div className=" w-full">
                  <label
                    htmlFor="inputField"
                    className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                    Select Industry
                    <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                      *
                    </span>
                  </label>
                  <select
                    value={formData !== undefined ? formData.industry_uid : ''}
                    onChange={handleSelect}
                    name="industry_uid"
                    required
                    className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-2 px-3 focus:outline-none">
                    <option selected>Select an industry</option>
                    {industries?.industries?.map((industry) => {
                      return (
                        <option key={industry.uid} value={industry.uid}>
                          {industry.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="mt-4">
                <div className=" w-full">
                  <label
                    htmlFor="inputField"
                    className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                    Select Role
                    <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                      *
                    </span>
                  </label>
                  <select
                    value={formData !== undefined ? formData.role_uid : ''}
                    onChange={handleSelect}
                    name="role_uid"
                    required
                    className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-2 px-3 focus:outline-none">
                    <option selected>Select a role</option>
                    {roles?.roles
                      ?.filter((role) => role.industry === industryId)
                      .map((role) => {
                        return (
                          <option key={role.uid} value={role.uid}>
                            {role.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="mt-11">
                <h1 className="text-plannaPrimaryGreen font-bold text-xl font-hanken-grotesk">
                  Uniform
                </h1>
                <div className="mt-8">
                  <TemplateInput
                    inputValue={formData.uniform.color}
                    placeholder="black"
                    required
                    type="text"
                    label="Uniform Color"
                    onChange={handleChange}
                    name="uniform.color"
                  />
                </div>

                <div className="mt-8">
                  <TemplateInput
                    inputValue={formData.uniform.style}
                    placeholder="White shirt and black trousers"
                    required
                    type="text"
                    label="Uniform Style"
                    onChange={handleChange}
                    name="uniform.style"
                  />
                </div>
                <div className="mt-4">
                  <TemplateDescription
                    value={formData.uniform.description}
                    label="Uniform Description"
                    name="uniform.description"
                    onChange={handleDescriptionChange}
                    placeholder="Write more details about the uniform"
                    required
                  />
                </div>
              </div>
              <div className="mt-16 mb-8 space-x-6 flex justify-between">
                <TemplateCancelButton label="Cancel" />
                <TemplateSubmitButton
                  shift
                  label="Create Template"
                  isLoading={templateCreationLoading}
                />
              </div>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewJobTemplate;
