import React, {ChangeEvent, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../../Components/Common/Spinner';
import circularBack from '../../../assets/icons/Circular-Backward.png';
import useCreateProject from '../../../hooks/services/Agencys/Projects/AddNewProject';
import useGetAgencyProfile from '../../../hooks/services/Agencys/useGetAgencyProfile';
import TemplateDescription from '../../Shifts/Components/TemplateDescription';
import TemplateInput from '../../Shifts/Components/TemplateInput';
import TemplateSubmitButton from '../../Shifts/Components/TemplateSubmitButton';

const AddNewProject = () => {
  const navigate = useNavigate();
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile();
  console.log(profile);
  const initialState = {
    title: '',
    description: '',
    workers_count: '0',
    start_date: '',
    end_date: '',
    rate: '',
    type: '',
    additional_links: '',
    status: 'ASSIGNED',
    rate_type: '',
  };
  const {mutate, isLoading} = useCreateProject();
  const [formData, setFormData] = useState(initialState);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type} = e.target;
    if (type === 'number') {
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData({...formData, [name]: numericValue});
    }

    setFormData({...formData, [name]: value});
  };
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target;

    setFormData({...formData, [name]: value});
  };
  const handleTemplateSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const values = {
      ...formData,
      agency_uid: profile !== undefined ? profile.uid : '',
    };
    mutate(values);
  };
  if (profileLoading) {
    return <Spinner />;
  }
  return (
    <div className="p-4">
      <img
        onClick={() => navigate(-1)}
        src={circularBack}
        alt="back"
        className="h-12 w-12 ml-12  cursor-pointer"
      />
      <div className="flex flex-col items-center mt-6">
        <form onSubmit={onSubmit}>
          <div className="mt-6">
            <h1 className=" text-center font-space-grotesk font-bold text-plannaPrimaryGreen text-2xl">
              Create a Project
            </h1>
            <p className="mt-4 text-sm font-normal text-plannaPrimaryGreen font-hanken-grotesk">
              Prepare a project that will meet both your requirements and the
              needs of your workers, save and later on <br /> allocate to the
              workers
            </p>
            <div className="mt-7">
              <TemplateInput
                label="Project Name"
                type="text"
                inputValue={formData.title}
                name="title"
                onChange={handleChange}
                placeholder="weekend shift"
                required
              />
            </div>
            <div className="mt-7">
              <TemplateInput
                label="Project Type"
                type="text"
                inputValue={formData.type}
                name="type"
                onChange={handleChange}
                placeholder="e.g software engineering"
                required
              />
            </div>
            {/* <div className="mt-4">
            <div className=" w-full">
              <label
                htmlFor="inputField"
                className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                Provider
                <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                  *
                </span>
              </label>
              <select
                value={formData !== undefined ? formData.provider_id : ''}
                onChange={handleProviderSelect}
                name="provider_id"
                required
                className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-3 px-3 focus:outline-none">
                <option selected>Select provider</option>
                {providers?.results.map((provider) => {
                  return (
                    <option key={provider.uid} value={provider.uid}>
                      {provider.company.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div> */}

            <div className="mt-4">
              <div className="mt-4">
                <TemplateInput
                  label="Project rate in $"
                  type="number"
                  inputValue={parseInt(formData.rate)}
                  name="rate"
                  onChange={handleChange}
                  placeholder="8"
                  required
                  min="0"
                />
              </div>
              <div className="mt-4">
                <div className=" w-full">
                  <label
                    htmlFor="inputField"
                    className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                    Rate Type
                    <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                      *
                    </span>
                  </label>
                  <select
                    value={formData.rate_type}
                    onChange={handleTemplateSelect}
                    name="rate_type"
                    required
                    className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-3 px-3 focus:outline-none">
                    <option selected>Select a rate type</option>
                    {['Hourly', 'Monthly', 'Weekly', 'Biweekly'].map((type) => {
                      return (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="mt-4">
                <TemplateInput
                  label="Number of workers"
                  type="number"
                  inputValue={parseInt(formData.workers_count)}
                  name="workers_count"
                  onChange={handleChange}
                  placeholder="8"
                  required
                  min="0"
                />
              </div>
              <div className="mt-4">
                <TemplateInput
                  label="Start Date"
                  type="date"
                  inputValue={formData.start_date}
                  name="start_date"
                  onChange={handleChange}
                  required
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>
              <div className="mt-4">
                <TemplateInput
                  label="End Date"
                  type="date"
                  inputValue={formData.end_date}
                  name="end_date"
                  onChange={handleChange}
                  required
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>
              <div className="mt-4">
                <TemplateInput
                  label="Project additional links"
                  type="text"
                  inputValue={formData.additional_links}
                  name="additional_links"
                  onChange={handleChange}
                  placeholder="https://docs.google.com/"
                  required
                />
              </div>
              <div className="mt-4">
                <TemplateDescription
                  value={formData.description}
                  label="Project Description"
                  name="description"
                  onChange={handleDescriptionChange}
                  placeholder="Write a project description"
                  required
                />
              </div>

              <div className="mt-16 mb-8 space-x-6 flex justify-between">
                <TemplateSubmitButton
                  label="Create Project"
                  isLoading={isLoading}
                  shift
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewProject;
