/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, {useState, useEffect, useRef} from 'react';
import {useForm} from 'react-hook-form';
import {FaSearch} from 'react-icons/fa';
import {FaX} from 'react-icons/fa6';
import {useNavigate, useParams} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import MessageSearchInput from '../../Components/MessageSearchInput';
import logo from '../../assets/icons/logo-icon.png';
import sendIcon from '../../assets/icons/send-message-icon.svg';
import useAuthUser from '../../hooks/services/useUserDetails';
import 'react-toastify/dist/ReactToastify.css';
import RQAddUserstoGroup from '../../utils/Queries/RQAddUserstoGroup';
import RQCreateDirectMessageChannel from '../../utils/Queries/RQCreateDirectMessageChannel';
import RQCreateGroup from '../../utils/Queries/RQCreateGroup';
import RQGetChannelPosts from '../../utils/Queries/RQGetChannelPosts';
import RQGetGroupChannels from '../../utils/Queries/RQGetGroupChannels';
import RQGetGroupUsers from '../../utils/Queries/RQGetGroupUsers';
import RQGetMessageThreads from '../../utils/Queries/RQGetMessageThreads';
import RQGetToken from '../../utils/Queries/RQGetToken';
import RQGetUserProfile from '../../utils/Queries/RQGetUserProfile';
import RQGetUsers from '../../utils/Queries/RQGetUsers';
import RQRemoveUsersFromGroup from '../../utils/Queries/RQRemoveUsersFromGroup';
import RQSearchUsers from '../../utils/Queries/RQSearchUsers';
import RQSendDirectMessage from '../../utils/Queries/RQSendDirectMessage';
import {PopupContent} from '../../utils/types';

interface SearchResults {
  results: Array<any>; // Replace 'any' with the actual type of your search results
  isLoading: boolean;
  isTyping: boolean;
  type: string;
}

const Messages: React.FC = () => {
  const {register: sendMessage, reset, handleSubmit, setValue} = useForm();
  const {
    register: groupUsersRegister,
    handleSubmit: groupUsersHandleSubmit,
    reset: groupUsersReset,
    setValue: groupUsersSetValue,
    watch: groupUsersWatch,
  } = useForm();

  const {
    register: registerGroup,
    reset: resetCreateGrouo,
    handleSubmit: handleCreateGroup,
    formState: {errors},
  } = useForm();
  const {user} = useAuthUser();
  const urlParams = useParams();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupType, setShowPopupType] = useState('');
  const [showPopupContent, setShowPopupContent] = useState<PopupContent>({
    message: '',
    title: '',
  });
  const [recipientId, setRecipientId] = useState<string>('');
  const [messagingToken, setMessagingToken] = useState<string>('');
  const [messagingUserId, setMessagingUserId] = useState<string>('');
  const [groupId, setGroupID] = useState<string>('');

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [channelId, setChannelId] = useState<string>(urlParams?.channel ?? '');
  const [searchResults, setSearchResults] = useState<SearchResults>({
    results: [],
    isLoading: false,
    isTyping: false,
    type: 'users',
  });
  const [popupParams, setPopupParams] = useState({
    page: 'messaging',
    showPopup: false,
    type: 'add-group',
    status: '',
    extraParam: null,
    submitFunction: null,
  });
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);

  const {data: channelPosts} = RQGetChannelPosts(
    messagingToken,
    channelId,
    urlParams?.chatType ?? 'chats'
  );

  const {data: groupUsers} = RQGetGroupUsers(groupId);

  // console.log(groupUsers);

  const {data: groupChannelPosts} = RQGetGroupChannels();

  const [postMessages, setPostMessages] = useState<string[]>([]);
  const [userNavigation, setUserNavigation] = useState<any>({
    username: 'Planna Inc',
    email: 'Company',
  });

  const {data: usersSearch, isLoading: isLoadingUserSearch} =
    RQSearchUsers(searchTerm);

  const {data: messageThreads} = RQGetMessageThreads();

  useEffect(() => {
    const navData: any = {
      channel_id: channelId,
      username: userNavigation?.username,
      email: userNavigation?.email,
    };
    if (channelId) navigateToUser(navData);
  }, [channelId]);

  const onSuccess = async (result: any) => {
    setChannelId(result?.id);
    setHasScrolled(false);
  };
  const onError = (error: any) => {
    // console.error(error);
  };
  const onSuccessSendMessage = (result: any) => {
    // setChannelId(result?.id);
  };
  const onErrorSendMessage = (error: any) => {
    console.error(error);
    setShowPopupContent((prev) => ({
      ...prev,
      message: error?.message,
    }));
    openPopup('action-response');
  };
  const onSuccessGroupManagement = (result: any) => {
    //use prev and only set message
    console.log(result);

    setShowPopupContent((prev) => ({
      ...prev,
      message: result?.message,
    }));
    openPopup('action-response');
  };

  const contentContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const posts: any[] = [];
    channelPosts?.order.forEach((post: any) => {
      posts.push(channelPosts?.posts[post]);
    });
    posts.sort((a, b) => a.create_at - b.create_at);

    setPostMessages(posts);

    if (contentContainerRef.current) {
      contentContainerRef.current.scrollTop =
        contentContainerRef.current.scrollHeight;
    }

    setHasScrolled(false);
    // }
  }, [channelPosts]);

  const container = useRef<HTMLDivElement>(null);

  const Scroll = () => {
    const {offsetHeight, scrollHeight, scrollTop} =
      container?.current as HTMLDivElement;
    if (scrollHeight <= scrollTop + offsetHeight + 100) {
      container?.current?.scrollTo(0, scrollHeight);
    }
  };

  useEffect(() => {
    Scroll();
  }, [channelPosts]);

  const {data: getUserToken} = RQGetToken(user?.user_id ?? '');

  // console.log(user?.user_id);

  const {mutate: createDirectMessageChannel, isLoading: isCreatingChannel} =
    RQCreateDirectMessageChannel(onSuccess, onError);

  const {mutate: createGrouo, isLoading: isCreatingGroup} = RQCreateGroup(
    onSuccessSendMessage,
    onErrorSendMessage
  );

  const {mutate: sendDirectMessage, isLoading: isSendingMessage} =
    RQSendDirectMessage(onSuccessSendMessage, onErrorSendMessage, urlParams);

  const {data: profileImage} = RQGetUserProfile(
    messagingUserId,
    messagingToken
  );

  const navigateToUser = (data: any) => {
    navigate(
      `/messages/${
        urlParams?.chatType ? urlParams?.chatType + '/' : 'chats/'
      }${data?.channel_id}/${data?.username}/${data?.email}`
    );
    setHasScrolled(false);
  };

  const createChannelSearch = (result: any) => {
    setUserNavigation({
      username: result?.username ?? 'Planna Inc',
      email: 'Planna User',
    });
    setRecipientId(result?.id);
    setChannelId(result?.mattermost_channel_id);
    setHasScrolled(false);
    createDirectMessageChannel([messagingUserId, result?.id]);
    setHasScrolled(false);
    setSearchTerm('');
  };
  const createChannel = (result: any) => {
    if (urlParams?.chatType === 'groups') {
      setUserNavigation({
        username: result?.name ?? 'Planna Inc',
        email: 'Planna User',
      });
      setChannelId(result?.matter_most_id);
      setRecipientId(result?.matter_most_id);
      setGroupID(result?.uid);
    }
    if (urlParams?.chatType === 'chats' || !urlParams?.chatType) {
      setUserNavigation({
        username: result?.receiver_username ?? 'Planna Inc',
        email: 'Planna User',
      });
      setChannelId(result?.mattermost_channel_id);
      setRecipientId(result?.receiver_mattermost_id);
    }

    setHasScrolled(false);
    setHasScrolled(false);
  };

  const searchFunction = (searchParams: React.SetStateAction<string>) => {
    setSearchTerm(searchParams);
  };

  const userClicked = (row: any) => {
    console.log(row);
  };

  useEffect(() => {
    const resultsArray = usersSearch?.data ? usersSearch.data : [];
    console.log(resultsArray);

    setSearchResults({
      results: resultsArray,
      isLoading: isLoadingUserSearch,
      isTyping: false,
      type: 'users',
    });
  }, [searchTerm, usersSearch, isLoadingUserSearch]);

  useEffect(() => {
    setMessagingToken(getUserToken?.data?.data?.token);
    setMessagingUserId(getUserToken?.data?.data?.mattermost_user_id);
    setHasScrolled(false);
  }, [getUserToken]);

  const onSendMessage = (message: any) => {
    setValue('message', '');

    if (urlParams?.chatType === 'groups') {
      sendDirectMessage({
        message: message.message,
        channel_id: recipientId,
      });
    }
    if (urlParams?.chatType === 'chats') {
      sendDirectMessage({
        message: message.message,
        receiver_mattermost_user_id: recipientId,
      });
    }

    setHasScrolled(false);
  };

  const createGroup = (data: any) => {
    const groupPayload = {
      name: data?.groupName,
      team_id: `${process.env.REACT_APP_MESSAGING_TEAM_ID}`,
      user_ids: [getUserToken?.data?.data?.user_id],
    };
    setShowPopup(false);
    resetCreateGrouo();
    createGrouo(groupPayload);
  };

  useEffect(() => {
    if (!hasScrolled) {
      scrollToBottom('content_container');
      setHasScrolled(true);
    }
  }, [channelPosts, hasScrolled, postMessages]);

  const {data: usersData, isLoading: isFetchigUsers} = RQGetUsers(
    groupUsersWatch('search-group-users')
  );
  const [selectedGroupUsers, setSelectedGroupUsers] = useState<any[]>([]);

  const {mutate: addUsersToGroup, isLoading: isAddingUserToGroup} =
    RQAddUserstoGroup(onSuccessGroupManagement, onErrorSendMessage, groupId);

  const {mutate: removeUsersToGroup, isLoading: isRemovingUserToGroup} =
    RQRemoveUsersFromGroup(
      onSuccessGroupManagement,
      onErrorSendMessage,
      groupId
    );

  const addSelectedUsersToGroup = () => {
    const data = {
      user_ids: selectedGroupUsers.map((user) => user.uid),
    };
    setShowPopupContent({
      message: '',
      title: 'Add Group Users',
    });
    addUsersToGroup(data);

    closePopup();
  };
  const removeSelectedUsersToGroup = () => {
    const data = {
      user_ids: selectedGroupUsers.map((user) => user.uid),
    };
    console.log(data);

    setShowPopupContent({
      message: '',
      title: 'Remove Group Users',
    });

    removeUsersToGroup(data);
    closePopup();
  };

  const addUser = (user: any) => {
    //check if already exists
    const exists = selectedGroupUsers.find((u) => u.email === user.email);
    if (exists) {
      return;
    }
    setSelectedGroupUsers([...selectedGroupUsers, user]);
    groupUsersReset();
  };

  const removeUser = (user: any) => {
    console.log(user);
    setSelectedGroupUsers(
      selectedGroupUsers.filter((u) => u.email !== user.email)
    );
  };

  const openPopup = (type: any = null) => {
    setShowPopup(true);
    console.log(type);

    setShowPopupType(type);
  };
  const closePopup = () => {
    setShowPopup(false);
    setSelectedGroupUsers([]);
    groupUsersReset();
  };

  if (isRemovingUserToGroup || isCreatingGroup || isAddingUserToGroup) {
    return <Spinner />;
  }

  return (
    <>
      <div className={`popup-container${showPopup ? ' show' : ''}`}>
        <div className="popup-overlay" onClick={closePopup} />

        <div className="popup-panel">
          <div className="close" onClick={closePopup}>
            <FaX />
          </div>
          {showPopupType === 'add-group-users' && (
            <>
              <>
                <h1 className="title">
                  Add {urlParams?.user ? urlParams?.user : 'Planna Inc'} Users
                </h1>

                <form className="popup-form-action">
                  <div className="form-group search">
                    <div className="icon">
                      <FaSearch />
                    </div>
                    <input
                      type="text"
                      {...groupUsersRegister('search-group-users')}
                      className="form-input"
                      placeholder="Search users by email"
                    />
                  </div>
                </form>
                {selectedGroupUsers && selectedGroupUsers?.length > 0 && (
                  <div
                    className="btn primary"
                    onClick={addSelectedUsersToGroup}>
                    Add Selected Users ({selectedGroupUsers?.length})
                  </div>
                )}

                <div
                  className={`users-holder${
                    selectedGroupUsers && selectedGroupUsers?.length > 0
                      ? ' expanded'
                      : ''
                  }`}>
                  {usersData?.results?.map((user: any) => (
                    <div
                      className="user-card"
                      key={user?.id}
                      onClick={() => addUser(user)}>
                      <div className="user-card__avatar">
                        <img src={logo} alt="avatar" />
                      </div>
                      <div className="user-card__content">
                        <h4 className="user-card__title">
                          {user?.first_name} {user?.last_name}
                        </h4>
                        <p className="user-card__email">{user?.email}</p>
                      </div>
                    </div>
                  ))}
                </div>

                {selectedGroupUsers && selectedGroupUsers.length > 0 && (
                  <div className="selectec-users">
                    {selectedGroupUsers?.map((user: any) => (
                      <div className="selected-user-card" key={user?.uid}>
                        {user?.first_name} {user?.last_name}{' '}
                        <div
                          className="remove"
                          onClick={() => removeUser(user)}>
                          <FaX />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            </>
          )}
          {showPopupType === 'remove-users' && (
            <>
              <h1 className="title">
                Manage {urlParams?.user ? urlParams?.user : 'Planna Inc'} Users
              </h1>

              <div
                className={`users-holder${
                  selectedGroupUsers && selectedGroupUsers?.length > 0
                    ? ' expanded'
                    : ''
                }`}>
                {selectedGroupUsers && selectedGroupUsers?.length > 0 && (
                  <div
                    className="btn danger"
                    onClick={removeSelectedUsersToGroup}>
                    Remove Selected Users ({selectedGroupUsers?.length})
                  </div>
                )}
                {groupUsers?.results?.map((user_: any) => (
                  <React.Fragment key={user_?.id}>
                    {user_?.uid === user?.user_id && (
                      <>
                        {/* //hover pointer disabled not allowed important*/}
                        <div
                          className="user-card bg-slate-200 p-1 flex gap-1 :hover:bg-slate-300 cursor-not-allowed"
                          key={user_?.id}>
                          <div className="user-card__avatar m-1">
                            <img src={logo} alt="avatar" />
                          </div>
                          <div className="user-card__content">
                            <h4 className="user-card__title">You</h4>
                            <p className="user-card__email">{user_?.email}</p>
                          </div>
                          {/* <div className="actions">
                          <div
                            className="remove">
                            <FaX />
                          </div>
                        </div> */}
                        </div>
                      </>
                    )}
                  </React.Fragment>
                ))}
                {groupUsers?.results?.map((user_: any) => (
                  <React.Fragment key={user_?.id}>
                    {user_?.uid !== user?.user_id && (
                      <div
                        className="user-card p-1 flex gap-1"
                        onClick={() => addUser(user_)}>
                        <div className="user-card__avatar m-1">
                          <img src={logo} alt="avatar" />
                        </div>
                        <div className="user-card__content">
                          <h4 className="user-card__title">
                            {user_?.first_name} {user_?.last_name}
                          </h4>
                          <p className="user-card__email">{user_?.email}</p>
                        </div>
                        {/* <div className="actions">
                          <div
                            className="remove">
                            <FaX />
                          </div>
                        </div> */}
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>

              {selectedGroupUsers && selectedGroupUsers.length > 0 && (
                <div className="selectec-users danger">
                  {selectedGroupUsers?.map((user: any) => (
                    <div className="selected-user-card" key={user?.uid}>
                      {user?.first_name} {user?.last_name}{' '}
                      <div className="remove" onClick={() => removeUser(user)}>
                        <FaX />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
          {showPopupType === 'create-group' && (
            <>
              <h1 className="title">Create Group</h1>

              <form
                onSubmit={handleCreateGroup(createGroup)}
                className="form-action">
                <p className="form-description">
                  Please fill out the form to create a group
                </p>
                <div
                  className={`form-group${errors?.groupName ? ' error' : ''}`}>
                  <label htmlFor="name" className="form-label">
                    Group Name
                  </label>
                  <input
                    type="text"
                    {...registerGroup('groupName', {
                      required: 'Group name is required',
                    })}
                    placeholder="E.g. Planna Work Group"
                    className="form-input"
                  />
                  {errors?.groupName && (
                    <span className="text-red-500 text-sm">
                      {String(errors?.groupName?.message)}
                    </span>
                  )}
                </div>

                <div className="form-group">
                  <button className="btn primary">Create Group</button>
                </div>
              </form>
            </>
          )}
          {showPopupType === 'action-response' && (
            <div className="py-5">
              <div className="icon-holder flex w-full justify-center ">
                <div className="icon border-2  border-slate-800 rounded-full">
                  <img src={logo} alt="logo" />
                </div>
              </div>
              <h1 className="title text-center text-3xl">
                {
                  showPopupContent?.title /* 'Add Group Users' || 'Remove Group Users' */
                }
              </h1>
              <p className="response-message text-center">
                {showPopupContent?.message || ''}
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="dashboard-container">
        <div className="messaging-container">
          <div className="message-conversations">
            <h1 className="title">Messages</h1>
            <h1 className="title">All Messages</h1>
            <div className="border-line" />
            <MessageSearchInput
              title="Search User"
              searchFunction={searchFunction}
              onClicked={userClicked}
              setSearchResults={setSearchResults}
            />
            <div className="border-line" />
            <div className="message-type-selector">
              <div
                className={`option${
                  urlParams?.chatType === 'chats' ||
                  urlParams?.chatType === undefined
                    ? ' active'
                    : ''
                }`}
                onClick={() => navigate(`/messages/chats`)}>
                Chats
              </div>
              <div
                className={`option${
                  urlParams?.chatType === 'groups' ? ' active' : ''
                }`}
                onClick={() => navigate(`/messages/groups`)}>
                Groups
              </div>
            </div>
            <div className="conversations-list">
              {searchResults?.results?.length > 0 && (
                <div className="search-results-holder">
                  <h2>Search Results</h2>
                  {searchResults?.results?.map((result: any) => (
                    <div
                      className={`conversation-card search-result${
                        userNavigation?.username === result?.username
                          ? ' active'
                          : ''
                      }`}
                      key={result?.id}
                      onClick={() => createChannelSearch(result)}>
                      <div className="conversation-card__avatar">
                        <img src={logo} alt="avatar" />
                      </div>
                      <div className="conversation-content">
                        <h4 className="conversation-title">
                          {/* //only display two strings of username */}
                          {
                            result?.username
                              ?.replace(/\W+/g, ' ')
                              ?.split(' ')[0]
                          }{' '}
                          {
                            result?.username
                              ?.replace(/\W+/g, ' ')
                              ?.split(' ')[1]
                          }
                        </h4>
                        <p className="conversation-message">{result?.email}</p>
                      </div>
                    </div>
                  ))}
                  <h2>Chat Conversations</h2>
                </div>
              )}

              {(urlParams?.chatType === 'chats' || !urlParams?.chatType) && (
                <>
                  {messageThreads
                    ?.reverse()
                    ?.map((result: any, index: number) => (
                      <div
                        className={`conversation-card${
                          userNavigation?.username === result?.receiver_username
                            ? ' active'
                            : ''
                        }`}
                        key={index}
                        onClick={() => createChannel(result)}>
                        {result?.unread_messages > 0 && (
                          <div className="messages-count">
                            <span>{result?.unread_messages}</span>
                          </div>
                        )}
                        <div className="conversation-card__avatar">
                          <img src={logo} alt="avatar" />
                        </div>
                        <div className="conversation-content">
                          <h4 className="conversation-title">
                            {result?.receiver_username?.replace(/\W+/g, ' ')}
                          </h4>
                          <p className="conversation-message">
                            Tap to view message
                          </p>
                        </div>
                      </div>
                    ))}
                </>
              )}

              {urlParams?.chatType === 'groups' && (
                <>
                  <div
                    className="conversation-card bg-slate-100"
                    onClick={() => openPopup('create-group')}>
                    <div className="conversation-card__avatar bg-white">+</div>
                    <div className="conversation-content">
                      <h4 className="conversation-title">Create Group</h4>
                      <p className="conversation-message">
                        Tap to create group
                      </p>
                    </div>
                  </div>
                  {groupChannelPosts?.reverse()?.map((result: any) => (
                    <div
                      className={`conversation-card${
                        userNavigation?.username === result?.receiver_username
                          ? ' active'
                          : ''
                      }`}
                      key={result?.id}
                      onClick={() => createChannel(result)}>
                      {result?.unread_messages > 0 && (
                        <div className="messages-count">
                          <span>{result?.unread_messages}</span>
                        </div>
                      )}
                      <div className="conversation-card__avatar">
                        <img src={logo} alt="avatar" />
                      </div>
                      <div className="conversation-content">
                        <h4 className="conversation-title">{result?.name}</h4>
                        <p className="conversation-message">
                          Tap to view message
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="message-conversation">
            <div className="messages-header">
              {urlParams?.channel_id && (
                <>
                  <div
                    className="user-holder"
                    onClick={() =>
                      urlParams?.chatType === 'groups' && urlParams?.channel_id
                        ? openPopup('remove-users')
                        : null
                    }>
                    <div className="avatar">
                      <img src={profileImage ?? logo} alt="avatar" />
                    </div>
                    <div className="content">
                      <div className="name">
                        {urlParams?.user ? urlParams?.user : 'Planna Inc'}
                      </div>
                      <div className="user-title">
                        {urlParams?.email ? urlParams?.email : 'Company'}
                      </div>
                    </div>
                  </div>

                  <div className="action">
                    {urlParams?.chatType === 'groups' &&
                      urlParams?.channel_id && (
                        <div
                          className="btn primary"
                          onClick={() => openPopup('add-group-users')}>
                          Add Users
                        </div>
                      )}
                  </div>
                </>
              )}
            </div>

            <div className="conversation-messages">
              <div
                className="content-container"
                id="content_container"
                ref={container}>
                {/* <div className="date-indicator">today</div> */}

                {urlParams?.channel_id && (
                  <>
                    {postMessages?.map((postOrder: any, key: number) => (
                      <React.Fragment key={key}>
                        {(postOrder?.type === 'system_join_channel' ||
                          postOrder?.type === 'system_add_to_channel') && (
                          <div
                            className={`convo-notification-card ${
                              postOrder?.user_id === messagingUserId
                                ? ' sent'
                                : 'received'
                            }`}>
                            <div className="message-card">
                              <div className="message">
                                {postOrder?.message}
                              </div>
                            </div>
                          </div>
                        )}
                        {!(
                          postOrder?.type === 'system_join_channel' ||
                          postOrder?.type === 'system_add_to_channel'
                        ) && (
                          <div
                            className={`convo-card ${
                              postOrder?.user_id === messagingUserId
                                ? ' sent'
                                : 'received'
                            }`}>
                            {postOrder?.user_id !== messagingUserId &&
                              urlParams?.chatType === 'groups' && (
                                <div className="sent-by">
                                  {postOrder?.planna_user?.first_name[0]}
                                  {postOrder?.planna_user?.last_name[0]}
                                </div>
                              )}
                            <div className="message-card-holder">
                              <div className="message-card">
                                <div className="message">
                                  {postOrder?.user_id !== messagingUserId &&
                                    urlParams?.chatType === 'groups' && (
                                      <span>
                                        {postOrder?.planna_user?.first_name}{' '}
                                        {postOrder?.planna_user?.last_name}
                                      </span>
                                    )}
                                  {postOrder?.message}
                                </div>
                                <div className="link">
                                  {/* <a href="#">Reveal details</a> */}
                                </div>
                              </div>

                              <div className="time">
                                {timeFormatting(postOrder?.create_at)}
                              </div>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                )}
              </div>
            </div>

            {channelId && (
              <div className="message-box-holder">
                <form
                  onSubmit={handleSubmit(onSendMessage)}
                  className="form-action">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-input"
                      {...sendMessage('message', {
                        required: 'Message is required',
                      })}
                    />
                    <button className="send-btn">
                      <img src={sendIcon} alt="send" />
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Messages;

function timeFormatting(timestamp: number) {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    // year: 'numeric',
    // month: '2-digit',
    // day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    // second: 'numeric',
  };
  if (timestamp) {
    const formattedDate = new Intl.DateTimeFormat('en-US', options)?.format(
      date
    );
    return formattedDate;
  }
}

const scrollToBottom = (id: any) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollTop = element.scrollHeight;
  }
};
