// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import {AgencyAllocation} from '../../../utils/types';
import axiosInstance from '../AxiosInstance';

interface ApiResponse {
  // Define the structure of your API response
  // Example: { data: YourDataType }
  results: AgencyAllocation;
}

const fetchData = async (id: string): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      `shifts/agency-allocation/${id}/`
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetSingleAgencyAllocation = (
  id: string
): UseQueryResult<ApiResponse, Error> => {
  return useQuery(['singleAllocation', id], () => fetchData(id));
};

export default useGetSingleAgencyAllocation;
