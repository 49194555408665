import React, {useState} from 'react';

import Footer from '../../Components/LandingPage/Footer';
import Navbar from '../../Components/LandingPage/Navbar';
import RelumeIcon from '../../assets/icons/Relume.svg';
import AboutHeroImage from '../../assets/images/about_hero.png';
import RectagleHero from '../../assets/images/rectangle-about-page-hero.png';
import Argos from '../../assets/planna_images/argos.svg';
import Costa from '../../assets/planna_images/costa.svg';
import Currys from '../../assets/planna_images/currys.svg';
import Lidl from '../../assets/planna_images/lidla.svg';
import Nhs from '../../assets/planna_images/nh.svg';
import Telsco from '../../assets/planna_images/tesco.svg';

export default function AboutPage() {
  const images = [Nhs, Argos, Telsco, Costa, Currys, Lidl];
  const [mapLocation, setMapLocation] = useState('london');
  return (
    <>
      <div className="main-container">
        <Navbar />
        <section className="md:mt-40 flex flex-col justify-center items-center md:px-20 px-6">
          <img src={AboutHeroImage} className="hidden md:block" />
          <img src={RectagleHero} className="md:hidden mt-4" />
          <div className="flex md:flex-row flex-col py-8 w-full">
            <div className="basis-1/2 ">
              <p className="text-[30px] md:text-5xl font-space-grotesk font-semibold text-teal-950">
                Flexible work can be <br />
                fairer, easier to find &<br /> better paid.
              </p>
            </div>

            <div className="basis-1/2">
              <p className="">
                Gig economy workers are increasingly important to the labour
                market.
                <br /> The realities faced by millions of workers worldwide
                entrench
                <br /> conditions of low pay, poor progression and workplace
                insecurity. We
                <br /> believe this doesn't have to be the case so we are on a
                mission to
                <br /> change this.
              </p>

              <div className="grid grid-cols-2 grid-rows-2 gap-4">
                <div className="pt-8">
                  <h2 className="text-5xl font-semibold text-teal-950">
                    14.7%
                  </h2>
                  <p>Working population</p>
                </div>

                <div className="pt-8">
                  <h2 className="text-5xl font-semibold text-teal-950">£375</h2>
                  <p>Median incom</p>
                </div>

                <div className="pt-8">
                  <h2 className="text-5xl font-semibold text-teal-950">
                    2.8m+
                  </h2>
                  <p>Employees</p>
                </div>

                <div className="pt-8">
                  <h2 className="text-5xl font-semibold text-teal-950">30k+</h2>
                  <p>Agencies</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="md:h-screen text-teal-950 bg-plannaAccentGreen flex flex-col justify-evenly md:px-20 px-3 py-6 md:py-0">
          <div className="flex flex-col">
            <p className="font-semibold md:6 md:mb-8 md:mt-0">Our Values</p>
            <p className="text-[34px] md:text-5xl font-semibold mb-8">
              We want to reach 2 million flexible workers by 2030.
            </p>
            <p className="font-light">
              Our belief is that powerful technology can offer workers and
              employers the opportunity to
              <br /> create decent jobs.
            </p>
          </div>
          <div className="flex md:flex-row flex-col justify-between space-y-10 md:space-y-0 py-4 md:py-0">
            <div>
              <img src={RelumeIcon} className="w-[24px] md:w-fit" />
              <p className="text-3xl font-semibold">Decent jobs</p>
              <p className="font-light">
                Work that has secure hours with ample notice,
                <br /> clear expectations and on the job support.
              </p>
            </div>
            <div>
              <img src={RelumeIcon} className="w-[24px] md:w-fit" />
              <p className="text-3xl font-semibold">Liveable wages</p>
              <p className="font-light">
                We are committed to working with companies
                <br /> who pay workers above the living wage.
              </p>
            </div>
            <div>
              <img src={RelumeIcon} className="w-[24px] md:w-fit" />
              <p className="text-3xl font-semibold">Upskill guarantee</p>
              <p className="font-light">
                Part of decent work is the opportunity to
                <br /> acquire skills and learn. We are offering all our
                <br /> users on the platform a self paced learning
                <br /> curriculum that to support continuous
                <br /> professional development.
              </p>
            </div>
          </div>
        </section>
        <section className="md:py-20 py-6 px-6 md:px-0">
          <p className="text-3xl text-teal-950 md:font-semibold md:text-center md:mb-0 mb-6">
            We are Trusted by many companies
          </p>
          <div className="container-max-width">
            <div className="brand-logos-holder">
              {images.map((image, index) => (
                <div className="brand-card" key={index}>
                  <img src={image} alt="logos" className="md:h-fit h-6" />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      <section className="md:pt-20 md:px-20 mt-10 px-6 mb-40">
        <p className="md:text-5xl text-3xl text-teal-950 md:font-semibold md:text-center">
          Our locations
        </p>
        <div className="flex md:pt-20 pt-10 justify-between">
          <div className="basis-1/2">
            <div className="order-l border-l-blue-900 border-l-2 border-l-solid pl-3 mb-10">
              <p className="text-3xl text-teal-950 font-light">London</p>
              {/* <p>123 Sample St, Sydney NWS 200 AU</p> */}
              <p
                className="mt-4"
                onClick={() => setMapLocation('london')}
                style={{cursor: 'pointer'}}>
                View
              </p>
            </div>
            <div className="">
              <p className="text-3xl text-teal-950 font-light">Nairobi</p>
              {/* <p>123 Sample St, Sydney NWS 200 AU</p> */}
              <p
                className="mt-4"
                onClick={() => setMapLocation('nairobi')}
                style={{cursor: 'pointer'}}>
                View
              </p>
            </div>
          </div>
          <div className="basis-1/2 h-96 w-full ">
            {mapLocation === 'london' ? (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d317719.587908343!2d-0.4312397505551248!3d51.528179772553436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2ske!4v1714380674737!5m2!1sen!2ske"
                width="600"
                height="450"
                style={{border: '0'}}
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            ) : (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255282.32390057214!2d36.682580497447134!3d-1.3032035599934264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1172d84d49a7%3A0xf7cf0254b297924c!2sNairobi!5e0!3m2!1sen!2ske!4v1714380873422!5m2!1sen!2ske"
                width="600"
                height="450"
                style={{border: '0'}}
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            )}
            {/* <div className="h-full w-100 bg-slate-400">
              <></>
            </div> */}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
