import React from 'react';

import NavbarLayout from '../../Layouts/NavbarLayout';
import success from '../../assets/icons/Success.png';
import Button from '../LandingPage/Button';

const PasswordReset = () => {
  return (
    <>
      <NavbarLayout>
        <div className="block lg:inline-block mt-4 lg:mt-0 lg:ml-4">
          <Button label="Help" bg="plannaPrimaryGreen" text="white" />
        </div>
      </NavbarLayout>
      <div className="flex flex-col items-center justify-center mt-28">
        <div className="lg:w-96 w-full border-b-2 border-b-plannaSecondaryGreen50 p-4 bg-plannaSecondaryGreen100">
          <div className="flex items-center space-x-2">
            <img src={success} alt="success" className="h-fit w-fit" />
            <p className="text-sm text-plannaSecondaryGreen50">
              Success: A reset link has been sent.{' '}
            </p>
          </div>
        </div>
        <h1 className="mt-24 text-black font-bold text-5xl">Thank you</h1>
        <p className="mt-4 text-lg text-center text-black">
          You’ll receive an email from us regarding the next steps. Please check{' '}
          <br />
          your email to reset your password
        </p>
        <button
          onClick={() => window.open('mailto:')}
          className="mt-10 px-3 py-3 rounded-full bg-plannaPrimaryGreen text-white w-56">
          Check your email
        </button>
      </div>
    </>
  );
};

export default PasswordReset;
