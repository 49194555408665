import {UserCircleIcon} from '@heroicons/react/24/outline';
import {motion} from 'framer-motion';
import React, {useState} from 'react';
import {Link, NavLink, useLocation} from 'react-router-dom';

import SidebarItem from './SidebarItem';
import Spinner from './Spinner';
import AgencyTemplates from '../../assets/icons/Agency-templates-icon.png';
import MessageFilled from '../../assets/icons/Messages-filled.svg';
import Messages from '../../assets/icons/Messages.png';
import Payroll from '../../assets/icons/Payroll.png';
import Logo from '../../assets/icons/Planna-Default.png';
import AgencyFinance from '../../assets/icons/agecy-payroll.png';
import Dashboard from '../../assets/icons/agency-overview.png';
import Allocation from '../../assets/icons/allocation.png';
import ProviderIcon from '../../assets/icons/job-templates.png';
import Overview from '../../assets/icons/overview.png';
import Plan from '../../assets/icons/plan.png';
import LogoWhite from '../../assets/icons/planna-logo-white.png';
import Templates from '../../assets/icons/templates.png';
import Workers from '../../assets/icons/workers.png';
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';
import useAuthUser from '../../hooks/services/useUserDetails';
const appMenuItems = [
  {
    name: 'Templates',
    Icon: Templates,
    link: null,
    items: [
      {
        name: 'Job Templates',
        link: '/job-template',
      },
    ],
  },
  {
    name: 'Plan',
    Icon: Plan,
    link: null,
    items: [
      {
        name: 'Shifts Schedule',
        link: '/shift-templates',
      },
      {
        name: 'Agency Allocations ',
        link: '/provider/agency-allocations',
      },

      {
        name: 'Agencies',
        link: '/agencys',
      },
    ],
  },
  {
    name: 'Attendance',
    Icon: Plan,
    link: '/attendance',
    items: null,
  },
  {
    name: 'Finance',
    Icon: Payroll,
    link: null,
    items: [
      {
        name: 'Timesheets',
        link: '/timesheets',
      },
      {
        name: 'Invoicing',
        link: '/provider-invoicing',
      },
    ],
  },
  {
    name: 'Messages',
    Icon: Messages,
    link: '/messages',
    items: null,
  },
  {
    name: 'Reports',
    Icon: Overview,
    link: '/reports',
    items: null,
  },
];
const agencyMenuItems = [
  {
    name: 'Dashboard',
    Icon: Dashboard,
    items: [
      {
        name: 'Overview',
        link: '/agency/home',
      },
      {
        name: 'Shift Reports',
        link: '/agency/shift-reports',
      },
    ],
  },

  {
    name: 'Allocation',
    Icon: Allocation,
    items: [
      {
        name: 'Shifts calendar',
        link: '/agency/allocation-calendar',
      },
      {
        name: 'Shifts allocations',
        link: '/agency/allocation-worker',
      },
    ],
  },
  {
    name: 'Templates',
    Icon: AgencyTemplates,
    link: null,
    items: [
      {
        name: 'Job Templates',
        link: '/job-template/agency',
      },
      {
        name: 'Shift Templates',
        link: '/shift-template/new/agency',
      },
    ],
  },

  {
    name: 'Projects',
    Icon: AgencyTemplates,
    link: '/agency/projects',
    items: null,
  },

  {
    name: 'Messages',
    Icon: MessageFilled,
    link: '/messages',
    items: null,
  },
  {
    name: 'Workers',
    Icon: Workers,
    items: [
      {
        name: 'Invite Worker',
        link: '/agency/invite/worker',
      },
      {
        name: 'Workers',
        link: '/agency/workers',
      },
    ],
  },

  {
    name: 'Finance',
    Icon: AgencyFinance,
    items: [
      {
        name: 'Attendance',
        link: '/agency/attendance',
      },
      {
        name: 'Invoicing',
        link: '/agency-invoicing',
      },
      {
        name: 'Financial Reports',
        link: '/agency/finance-reports',
      },
    ],
  },
  {
    name: 'Users',
    Icon: Workers,
    link: '/agency/users',
    items: null,
  },

  {
    name: 'Provider',
    Icon: Dashboard,
    link: '/agency/providers',
    items: null,
  },

  // {
  //   name: 'Settings',
  //   Icon: AgencySettings,
  //   items: [
  //     {
  //       name: 'Profile',
  //       link: '/agency/home',
  //     },
  //     {
  //       name: 'Settings',
  //       link: '/agency/home',
  //     },
  //   ],
  // },
];

const Sidebar: React.FC = () => {
  const {user} = useAuthUser();
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile(
    user?.group === 'is_agency' ? true : false
  );
  const location = useLocation();
  interface ShowDropdownState {
    [key: string]: boolean;
  }

  const [showDropdown, setShowDropdown] = useState<ShowDropdownState>({
    Dashboard: false,
    Allocation: false,
    Workers: false,
    Finance: false,
  });

  const toggleShowDropdown = (name: string) => {
    setShowDropdown((prevState) => {
      // Create a new state object with all dropdowns set to false
      const newState: ShowDropdownState = {};
      Object.keys(prevState).forEach((key) => {
        newState[key] = false;
      });
      // Set the specified dropdown to true
      newState[name] = !prevState[name];
      return newState;
    });
  };
  const filteredAgencyMenuItems = agencyMenuItems.filter((item) => {
    if (
      user?.group === 'is_agency' &&
      profile?.uid === process.env.REACT_APP_OUTERBOX_ID
    ) {
      return (
        item.name === 'Workers' ||
        item.name === 'Dashboard' ||
        item.name === 'Messages' ||
        item.name === 'Users' ||
        item.name === 'Provider' ||
        item.name === 'Projects'
      );
    }

    return item.name !== 'Projects';
  });

  if (profileLoading) return <div />;
  return (
    <>
      <div
        className={`sidebar ${
          user?.group === 'is_provider' ? ' provider' : ' agency'
        }`}>
        <div className="sidebar-container">
          {user?.group !== 'is_provider' && (
            <div className="sidebar-logo">
              <img src={LogoWhite} alt="logo" />
            </div>
          )}
          <div className="sidebar-links">
            {user?.group === 'is_provider' && (
              <>
                {appMenuItems.map((menu, menuIndex) => (
                  <>
                    {menu.items ? (
                      <motion.div
                        onClick={() => toggleShowDropdown(menu?.name)}
                        className={`sidebar-link${
                          includesLink(
                            location.pathname,
                            menu.items.map((item) => item.link)
                          )
                            ? ' active'
                            : ''
                        }`}
                        key={menuIndex}>
                        <div className="sidebar-link-row drops">
                          <div className="icon">
                            <img src={menu?.Icon} alt="" />
                          </div>
                          <div className="sidebar-link-content">
                            <div className="text">
                              <p>{menu.name}</p>
                            </div>
                            <svg
                              className={`w-3 h-3 `}
                              aria-hidden="true"
                              fill="none"
                              viewBox="0 0 10 6">
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 4 4 4-4"
                              />
                            </svg>
                          </div>
                        </div>

                        <motion.div
                          className="dropdown"
                          animate={{
                            opacity: showDropdown?.[menu?.name] ? 1 : 0,
                            height: showDropdown?.[menu?.name] ? 'auto' : 0,
                            zIndex: showDropdown?.[menu?.name] ? 1 : -1,
                          }}>
                          {menu.items.map((subMenu, subMenuIndex) => (
                            <NavLink
                              to={subMenu?.link ? subMenu?.link : ''}
                              className="sidebar-link drops"
                              key={subMenuIndex}>
                              <div className="text">
                                <p>{subMenu.name}</p>
                              </div>
                            </NavLink>
                          ))}
                        </motion.div>
                      </motion.div>
                    ) : (
                      <NavLink
                        to={menu?.link ? menu?.link : ''}
                        className="sidebar-link"
                        key={menuIndex}>
                        <div className="sidebar-link-row">
                          <div className="icon">
                            <img src={menu?.Icon} alt="" />
                          </div>
                          <div className="text">
                            <p>{menu.name}</p>
                          </div>
                        </div>
                      </NavLink>
                    )}
                  </>
                ))}
              </>
            )}
            {user?.group !== 'is_provider' && (
              <>
                {filteredAgencyMenuItems.map((menu, menuIndex) => (
                  <>
                    {menu.items ? (
                      <motion.div
                        onClick={() => toggleShowDropdown(menu?.name)}
                        className={`sidebar-link${
                          includesLink(
                            location.pathname,
                            menu.items.map((item) => item.link)
                          )
                            ? ' active'
                            : ''
                        }`}
                        key={menuIndex}>
                        <div className="sidebar-link-row">
                          <div className="icon">
                            <img src={menu?.Icon} alt="" />
                          </div>
                          <div className="sidebar-link-content">
                            <div className="text">
                              <p>{menu.name}</p>
                            </div>
                            <svg
                              className={`w-3 h-3 `}
                              aria-hidden="true"
                              fill="none"
                              viewBox="0 0 10 6">
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 4 4 4-4"
                              />
                            </svg>
                          </div>
                        </div>

                        <motion.div
                          className="dropdown"
                          animate={{
                            opacity: showDropdown?.[menu?.name] ? 1 : 0,
                            height: showDropdown?.[menu?.name] ? 'auto' : 0,
                            zIndex: showDropdown?.[menu?.name] ? 1 : -1,
                          }}>
                          {menu.items.map((subMenu, subMenuIndex) => (
                            <NavLink
                              to={subMenu?.link ? subMenu?.link : ''}
                              className="sidebar-link drops"
                              key={subMenuIndex}>
                              <div className="text">
                                <p>{subMenu.name}</p>
                              </div>
                            </NavLink>
                          ))}
                        </motion.div>
                      </motion.div>
                    ) : (
                      <NavLink
                        to={menu?.link ? menu?.link : ''}
                        className="sidebar-link"
                        key={menuIndex}>
                        <div className="sidebar-link-row">
                          <div className="icon">
                            <img src={menu?.Icon} alt="" />
                          </div>
                          <div className="text">
                            <p>{menu.name}</p>
                          </div>
                        </div>
                      </NavLink>
                    )}
                  </>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      <aside
        id="sidebar-multi-level-sidebar"
        className={`fixed top-0 left-0 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0 ${
          user?.group === 'is_provider'
            ? 'bg-plannaNeutralWhite border-r border-r-plannaNeutralWhite'
            : 'bg-plannaPrimaryGreen border-r border-r-plannaNeutralWhite'
        } shadow-lg`}
        aria-label="Sidebar"
        style={{display: 'none'}}>
        <div className="h-full px-3 py-4 overflow-y-auto">
          <img
            src={user?.group === 'is_provider' ? Logo : LogoWhite}
            alt="planna logo"
            className="ml-4 mt-3 mb-12 h-8 w-fit"
          />
          <ul className="space-y-2 font-medium">
            {user?.group === 'is_provider'
              ? appMenuItems.map((menuItem, index) => (
                  <SidebarItem
                    key={index}
                    title={menuItem.name}
                    icon={menuItem.Icon}
                    link={menuItem.link ?? ''}>
                    {menuItem.items &&
                      menuItem.items.length > 0 &&
                      menuItem.items.map((subItem, subIndex) => (
                        <SidebarItem
                          key={subIndex}
                          title={subItem.name}
                          link={subItem.link}
                        />
                      ))}
                  </SidebarItem>
                ))
              : agencyMenuItems.map((menuItem, index) => (
                  <SidebarItem
                    key={index}
                    title={menuItem.name}
                    icon={menuItem.Icon}
                    link={menuItem.link ?? ''}>
                    {menuItem.items &&
                      menuItem.items.length > 0 &&
                      menuItem.items.map((subItem, subIndex) => (
                        <SidebarItem
                          key={subIndex}
                          title={subItem.name}
                          link={subItem.link}
                        />
                      ))}
                  </SidebarItem>
                ))}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;

const includesLink = (strToCheck: string, links: string[]) => {
  for (const link of links) {
    if (strToCheck.includes(link)) {
      return true;
    }
  }
  return false;
};
