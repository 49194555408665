import React from 'react';

type Props = {
  image: string;
  title: number | undefined;
  subTitle: string;
  color: string;
};
const Card = ({image, title, subTitle, color}: Props) => {
  return (
    <div className="flex mb-4 items-center border border-plannaNeutral p-4 w-full rounded-xl space-x-2">
      <img src={image} alt="card" className="h-12 w-12" />
      <div className="flex flex-col justify-center">
        <h1 className={`text-2xl text-${color}`}>{title}</h1>
        <p className={`text-sm text-${color}`}>{subTitle}</p>
      </div>
    </div>
  );
};

export default Card;
