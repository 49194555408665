import moment from 'moment';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import AllocateWorker from './AllocateWorker';
import Spinner from '../../../Components/Common/Spinner';
import Input from '../../../Components/Registration/Input';
import Select from '../../../Components/Registration/Select';
import DialogLayout from '../../../Layouts/DialogLayout';
import useGetAgencyProfile from '../../../hooks/services/Agencys/useGetAgencyProfile';
import useGetWorkerShifts from '../../../hooks/services/Agencys/useGetWorkerShiftAllocations';
import {WorkerShift} from '../../../utils/types';
import ViewWorker from '../ViewWorker';
import ViewWorkerProviderView from '../ViewWorkerProviderView';
type EventDetails = {
  title: string;
  description: string;
  start: string;
  end: string;
  location: string;
  workerCount: number;
  jobTemplateName: string;
  status: string;
  agency?: string;
  employerNI?: string;
  pensionContribution?: string;
  holidayPay?: string;
  otherCosts?: string;
  marginType?: string;
  margin?: string;
  shiftId?: string;
  startDate?: string;
  locationId?: string;
  recurrence?: string;
};
type Props = {
  eventDetails?: EventDetails | undefined;
  allocationId: string;
  provider?: boolean;
  agencyId: string;
};
const SingleAllocationCalendarView = ({
  eventDetails,
  allocationId,
  provider,
  agencyId,
}: Props) => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const {data: workerShifts, isLoading: workerShiftsLoading} =
    useGetWorkerShifts({
      status: 'CONFIRMED',
      agency_id: agencyId,
      agency_shift_allocation__uid: allocationId,
    });
  // eslint-disable-next-line
  const filteredData = workerShifts?.results.map(
    // eslint-disable-next-line
    (obj: WorkerShift) => obj.worker
  );
  const [open, setOpen] = useState(false);
  const [workerId, setWorkerId] = useState('');
  const [isAllocate, setIsAllocate] = useState(false);
  const handleOpenAllocate = () => {
    setIsAllocate(true);
  };
  const handleCloseAllocate = () => setIsAllocate(false);
  const handleOpen = (id: string) => {
    setOpen(true);
    setWorkerId(id);
  };
  const handleClose = () => setOpen(false);

  if (workerShiftsLoading) {
    return <Spinner />;
  }
  console.log(filteredData);
  return (
    <div className="p-2">
      {/* <DialogLayout isOpen={isAllocate} onClose={handleCloseAllocate}>
        <AllocateWorker
          shiftId={
            eventDetails?.shiftId !== undefined ? eventDetails.shiftId : ''
          }
          handleCloseTemplate={handleCloseAllocate}
          start_date={eventDetails?.startDate ? eventDetails?.startDate : ''}
          location_id={
            eventDetails?.locationId !== undefined
              ? eventDetails?.locationId
              : ''
          }
          recurrence={
            eventDetails?.recurrence !== undefined
              ? eventDetails?.recurrence
              : ''
          }
        />
      </DialogLayout> */}

      <div className="flex justify-between">
        {filteredData !== undefined &&
          eventDetails !== undefined &&
          // eslint-disable-next-line
          eventDetails.workerCount > filteredData?.length &&
          !provider && (
            <button
              onClick={handleOpenAllocate}
              className="text-plannaAccentGreen bg-plannaPrimaryGreen rounded-full px-6 py-2">
              Allocate more workers
            </button>
          )}

        <span
          className={`${
            filteredData !== undefined &&
            eventDetails !== undefined &&
            // eslint-disable-next-line
            eventDetails.workerCount > filteredData?.length
              ? 'bg-plannaTertiaryRed text-white  '
              : ' bg-plannaAccentGreen  text-plannaPrimaryGreen'
          }  border rounded-full font-semibold border-plannaNeutral  text-sm me-2 px-6 py-2 `}>
          {/* eslint-disable-next-line */}
          Worker Count: {filteredData?.length}/
          {eventDetails !== undefined && eventDetails.workerCount}
        </span>
      </div>
      {/* <h1 className=" mt-4 text-plannaPrimaryGreen text-lg">
        {eventDetails !== undefined && eventDetails.title}
      </h1> */}
      <div className="flex flex-col mt-6">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Location
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Phone Number
                    </th>
                    {!provider && (
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                        Action
                      </th>
                    )}
                  </tr>
                </thead>
                {filteredData?.map((worker) => (
                  <tbody className="divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {`${worker.user.first_name} ${worker.user.last_name}`}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {worker.user.email}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {worker.contact_details !== null
                          ? worker.contact_details.town
                          : 'null'}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {worker.contact_details !== null
                          ? worker.contact_details.phone_number
                          : 'null'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {!provider && (
                          <button
                            onClick={() =>
                              navigate(`/agency/single-worker/${worker.uid}`)
                            }
                            className="bg-plannaPrimaryGreen text-plannaAccentGreen px-6 py-2 rounded-full">
                            View
                          </button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* {provider && (
        <>
          <div className="mt-4">
            <Select
              inputValue={
                eventDetails !== undefined ? eventDetails.marginType : ''
              }
              label="Margin type"
              name="margin_type"
              required
              options={['PERCENTAGE', 'VALUE']}
              readonly
            />
          </div>
          <div className="mt-2">
            <Input
              inputValue={eventDetails !== undefined ? eventDetails.margin : ''}
              type="number"
              label="Margin"
              required
              name="margin"
              readonly
            />
          </div>
          <div className="mt-2">
            <Input
              inputValue={
                eventDetails !== undefined ? eventDetails.employerNI : ''
              }
              type="number"
              label="Employers NI"
              required
              name="employers_ni"
              readonly
            />
          </div>
          <div className="mt-2">
            <Input
              inputValue={
                eventDetails !== undefined ? eventDetails.holidayPay : ''
              }
              type="number"
              label="Holiday pay"
              required
              name="holiday_pay"
              readonly
            />
          </div>
          <div className="mt-2">
            <Input
              inputValue={
                eventDetails !== undefined
                  ? eventDetails.pensionContribution
                  : ''
              }
              type="number"
              label="Pension contribution"
              name="pension_contribution"
              readonly
            />
          </div>
          <div className="mt-2">
            <Input
              inputValue={
                eventDetails !== undefined ? eventDetails?.otherCosts : ''
              }
              type="number"
              label="Other costs"
              name="other_costs"
              readonly
            />
          </div>
        </>
      )} */}

      {/* <div className="mt-2">
        <Input
          readonly
          label="Date"
          inputValue={moment(
            eventDetails !== undefined ? eventDetails.start : ''
          ).format('MMMM Do YYYY')}
          name="agency"
          type="text"
        />
      </div> */}

      {/* <div className="flex mt-4 space-x-3">
        <div>
          <p className="mb-2 text-plannaPrimaryGreen">Shift starts</p>
          <span className=" border border-plannaNeutral rounded-full  text-xs font-medium me-2 px-8 py-2  bg-plannaAccentGreen text-plannaPrimaryGreen">
            {eventDetails !== undefined ? eventDetails.start : ''}
          </span>
        </div>
        <div>
          <p className="mb-2 text-plannaPrimaryGreen">Shift ends</p>
          <span className=" border rounded-full border-plannaNeutral  text-xs font-medium me-2 px-6 py-2 bg-plannaAccentGreen text-plannaPrimaryGreen">
            {eventDetails !== undefined ? eventDetails.end : ''}
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default SingleAllocationCalendarView;
