// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import {InvoiceResponse} from '../../../utils/types';
import axiosInstance from '../AxiosInstance';

type IdType = 'provider__uid' | 'agency__uid';

const fetchData = async (
  invoice_date: string,
  status: string,
  id: string,
  idType: IdType
): Promise<InvoiceResponse> => {
  const queryParams = new URLSearchParams({
    limit: '10',
    status,
  });

  queryParams.append(idType, id);
  try {
    const response: AxiosResponse<InvoiceResponse> = await axiosInstance.get(
      // eslint-disable-next-line
      `payments/invoices/?${queryParams}`
    );
    // eslint-disable-next-line
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetInvoices = (
  invoice_date: string,
  status: string,
  id: string,
  idType: IdType
): UseQueryResult<InvoiceResponse, Error> => {
  return useQuery(['invoices', invoice_date, status, id], () =>
    fetchData(invoice_date, status, id, idType)
  );
};

export default useGetInvoices;
