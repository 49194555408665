import React from 'react';

import AgencyFinanceReports from './AgencyFinanceReports';
import AgencyProviderReports from './AgencyProviderReports';
import Tabs from '../Home/Components/Tabs';

const FinanceReports = () => {
  const tabs = [
    {
      id: 'tab1',
      label: 'Agency finance reports',
      component: <AgencyFinanceReports />,
    },
    {
      id: 'tab2',
      label: 'Agency provider reports',
      component: <AgencyProviderReports />,
    },
  ];
  return (
    <div className=" p-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl text-plannaPrimaryGreen font-bold">
          View your financial reports
        </h1>
      </div>
      <div className="mt-8">
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
};

export default FinanceReports;
