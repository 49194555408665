// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

type UniformsType = {
  color: string;
  material: string;
  style: string;
  sample_image: string;
  description: string;
};
type Rate = {
  uid: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  is_active: boolean;
  rate_name: string;
  rate_duration_type: string;
  base_rate: string;
  default_margin_type: string;
  default_margin: string;
  break_duration: string;
  minimum_shift_duration_for_breaks: string;
  paid_breaks: boolean;
  is_overtime_rate: boolean;
  rate_type: string;
  job_template: string;
};

export type Results = {
  template_name: string;
  job_description: string;
  position_type: string;
  years_of_experience: number;
  industry: string;
  role: string;
  rates: Rate[];
  required_uniform: UniformsType;
  skills_required: string[];
  uid: string;
  wellness: string;
  provider: string;
};
interface ApiResponse {
  // Define the structure of your API response
  // Example: { data: YourDataType }
  results: Results[];
}
interface WorkerShiftOptions {
  provider_id?: string;
  created_by?: string;
  uid?: string;
}

const fetchData = async (options: WorkerShiftOptions): Promise<ApiResponse> => {
  const {provider_id, created_by, uid} = options;
  const queryParams = new URLSearchParams({
    limit: '100',
  });

  if (provider_id !== undefined && provider_id !== '') {
    queryParams.append('provider_id', provider_id);
  }
  if (created_by !== undefined && created_by !== '') {
    queryParams.append('created_by', created_by);
  }
  if (uid !== undefined && uid !== '') {
    queryParams.append('uid', uid);
  }
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `/job/?${queryParams}`
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetJobTemplates = (
  options: WorkerShiftOptions
): UseQueryResult<ApiResponse, Error> => {
  const {provider_id, created_by, uid} = options;
  return useQuery(['jobtemplates', provider_id, created_by, uid], () =>
    fetchData(options)
  );
};

export default useGetJobTemplates;
