import {useMutation, UseMutationResult, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';

import axiosInstance from './AxiosInstance';

interface ResetPasswordParams {
  password: string;
  id: string;
}

const resetPassword = async (data: ResetPasswordParams) => {
  const response = await axiosInstance.patch(
    `/auth/forget-password/${data.id}/`,
    data
  );

  return response;
};

const useResetPassword = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  ResetPasswordParams
> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(resetPassword, {
    onSuccess: () => {
      // eslint-disable-next-line
      queryClient
        .invalidateQueries()
        .then(() => navigate('/reset-password-acknowledgement'))
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useResetPassword;
