// useRegisterUser.ts
import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

interface AllocateAgencyToShiftParams {
  shift_template_uid: string;
  agency_uid: string;
  start_date: string;
  worker_count: number;
  recurrence: string;
  employers_ni: number;
}

const allocateAgency = async (data: AllocateAgencyToShiftParams) => {
  const response = await axiosInstance.post(
    '/shifts/agency-allocations/',
    data
  );

  return response;
};

const useAllocateAgencyToShift = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  AllocateAgencyToShiftParams
> => {
  const queryClient = useQueryClient();

  return useMutation(allocateAgency, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('shifts')
        .then((data) => {
          return data;
        })
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useAllocateAgencyToShift;
