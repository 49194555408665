import moment from 'moment';
import React from 'react';
import {useParams} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import Input from '../../Components/Registration/Input';
import useGetSingleAgency from '../../hooks/services/Agencys/useGetSingleAgency';

const SingleAgency = () => {
  const params = useParams();
  const {data, isLoading} = useGetSingleAgency(
    params.id !== undefined ? params.id : ''
  );
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="p-4">
      <div className="flex items-center justify-center">
        <div className="lg:w-3/4 w-full p-4">
          <h1 className="text-lg font-semibold">{data?.agency.name}</h1>
          <div className="mt-4">
            <Input
              readonly
              label="Agency name"
              name="agency_name"
              type="text"
              inputValue={data?.agency.name}
            />
          </div>
          <div className="mt-2">
            <Input
              readonly
              label="Agency email"
              name="agency_email"
              type="email"
              inputValue={data?.agency.email}
            />
          </div>
          <div className="mt-2">
            <Input
              readonly
              label="Agency website"
              name="agency_website"
              type="text"
              inputValue={data?.agency.website}
            />
          </div>
          <div className="mt-2">
            <Input
              readonly
              label="Agency phone number"
              name="agency_phone_number"
              type="text"
              inputValue={data?.agency.phone_number}
            />
          </div>
          <div className="mt-2">
            <Input
              readonly
              label="Weekstart"
              name="weekstart"
              type="text"
              inputValue={data?.agency.week_start.toLowerCase()}
            />
          </div>
          <div className="mt-2">
            <Input
              readonly
              label="Date registered"
              name="created_at"
              type="text"
              inputValue={moment(data?.agency.created_at)
                .utcOffset('+03:00')
                .format('Do MMMM YYYY HH:mm')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleAgency;
