/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, {useState, useRef} from 'react';
import {useNavigate} from 'react-router-dom';

import FaqAccordionItem from '../../Components/Common/FaqAccordionItem';
import Footer from '../../Components/LandingPage/Footer';
import Navbar from '../../Components/LandingPage/Navbar';
import SuppportPlaceHolderIcon from '../../assets/icons/support_placeholder_icon.svg';
import SupportPlaceHolder from '../../assets/images/support_place_holder.png';
import SupportPlaceHolder2 from '../../assets/images/support_place_holder2.png';
import SupportPlaceHolder3 from '../../assets/images/support_placeholder3.png';

export default function SupportPage() {
  const formRef = useRef<HTMLFormElement>(null);
  const [loading, setLoading] = useState(false);
  const scriptUrl =
    'https://script.google.com/macros/s/AKfycbyrom9U2tAaDeF7Vbl5ros2mfpzl5CBMexqY6yIgOCZumwX5h7KheBWM06aLAMJENlx/exec';
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (!formRef.current) {
      return;
    }
    e.preventDefault();

    await fetch(scriptUrl, {
      method: 'POST',
      body: new FormData(formRef.current),
    }).finally(() => {
      if (formRef.current) {
        formRef.current.reset();
        setLoading(false);
      }
    });
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="main-container">
        <Navbar />
        <div className="container-max-width">
          <section className="bg-white flex md:flex-row flex-col md:pt-60 pt-[35px] px-6 space-y-8 pb-20 ">
            <div className=" md:basis-2/5 flex flex-col space-y-2">
              <p className="text-teal-950 text-5xl font-bold">FAQ</p>
              <p className="font-hanken-grotesk font-normal text-base text-plannaPrimaryGreen">
                Our FAQ section is your one-stop destination for quick answers
                to common questions about our products/services. Find clear,
                concise responses to your inquiries, ensuring you have the
                information you need to make informed decisions.
              </p>
            </div>
            <button className="md:hidden bg-plannaAccentGreen rounded-3xl py-4 px-6 border border-[#082E37] font-hanken-grotesk font-semibold text-lg">
              Contact us
            </button>
            <div className="md:basis-3/5 flex flex-col  md:px-6">
              <FaqAccordionItem
                title="What we offer?"
                content="We offer a range of services tailored to meet the diverse needs of our clients. From consulting and advisory services to software development and implementation, we provide comprehensive solutions that drive business growth and success."
              />
              <FaqAccordionItem
                title="What we do?"
                content="Our primary focus is on delivering innovative solutions that solve real-world problems. We work closely with our clients to understand their unique challenges and develop customized strategies to address them effectively."
              />
              <FaqAccordionItem
                title="How to access customer service support for companies?"
                content="For companies seeking customer service support, we offer various channels for assistance. You can reach out to our dedicated support team via email, phone, or our online helpdesk."
              />
              <FaqAccordionItem
                title="How to access customer service support for workers?"
                content="Workers can access customer service support through our platform's built-in helpdesk feature. Simply log in to your account and submit your inquiry, and our support team will assist you promptly. We are committed to providing the best possible support experience for all our users."
              />
              <FaqAccordionItem
                title="How do I download the Planna worker app?"
                content="Sign up on the www.weareplanna.com as a worker and fill in your details. You will recive a link to download the app.Once installed, you can log in with your credentials and start using the app to manage your tasks and schedules."
              />
            </div>
          </section>
          <section className="bg-white pb-6">
            <p className=" pt-6 text-teal-950 md:text-5xl text-5xl font-bold">
              Blog
            </p>
          </section>

          <section className="blog-content-holder">
            {supportBlogs?.map((blog, index) => (
              <div
                className="blog-card"
                key={index}
                onClick={() => navigate(`/blog/${blog.slug}`)}>
                <div className="blog-card-image">
                  <img src={blog.image} alt="Support Placeholder" />
                </div>
                <div className="blog-card-content">
                  <h2 className="blog-card-title">{blog.blogPost}</h2>
                  <div
                    className="blog-card-description"
                    dangerouslySetInnerHTML={{
                      __html:
                        blog.content
                          ?.substring(0, 200)
                          ?.replace(/<[^>]+>/g, '')
                          ?.split(' ')
                          ?.filter(Boolean)
                          ?.filter((item) => !item.includes('\n'))
                          ?.slice(0, 114)
                          ?.join(' ') ?? '',
                    }}
                  />
                </div>
              </div>
            ))}
          </section>
          <section
            className="bg-white md:px-10 px-6 flex md:flex-row flex-col md:h-screen md:mt-0 mt-12"
            style={{marginTop: '3rem'}}>
            <div className="basis-1/2 h-4/6 flex flex-col justify-evenly">
              <p className="text-teal-950 md:text-5xl text-4xl md:font-bold font-semibold font-space-grotesk">
                Contact us
              </p>
              <form
                className="w-4/5 flex flex-col space-y-4 py-4"
                ref={formRef}
                onSubmit={handleSubmit}
                name="google-sheet">
                <div className="">
                  <label
                    htmlFor="fullname"
                    className="block mb-2 text-sm font-medium text-gray-900 ">
                    Fullname
                  </label>
                  <input
                    name="name"
                    type="text"
                    id="fullname"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder=""
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 ">
                    Email
                  </label>
                  <input
                    name="email"
                    type="email"
                    id="email"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-medium text-gray-900 ">
                    Message
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    rows={6}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                    placeholder="Leave a comment..."
                  />
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 dark:bg-gray-700 "
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ms-2 text-sm font-medium underline text-gray-900  font-hanken-grotesk">
                    I accept the Terms
                  </label>
                </div>
                <button
                  disabled={loading}
                  type="submit"
                  className={`text-white ${
                    loading ? 'bg-gray-400' : 'bg-plannaDarkTeal'
                  } focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>
                  {loading ? 'Sending' : 'Submit'}
                </button>
              </form>
            </div>
            <div className="md:basis-1/2 py-10">
              <img src={SupportPlaceHolder3} className="h-fit" />
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}

export const supportBlogs = [
  {
    blogPost:
      'Making the Most of Temporary Staff: Why Smart Employers Opt for Quality Temps',
    title:
      'The Secret Weapon: How Good Temp Staff Can Supercharge Your Business',
    image: SupportPlaceHolder,
    slug: 'the-secret-weapon-how-good-temp-staff-can-supercharge-your-business',
    content: `
      <h2>Introduction</h2>
      <p>
        Hey there, savvy employers! Ever wondered how some businesses seem to effortlessly handle busy periods while others struggle to keep up? Well, the answer might lie in their use of temporary staff. Let's dive into why hiring well-vetted temp workers could be the game-changer your business needs.
      </p>
      <ol>
        <li>
          <h3>Flexible Support, No Strings Attached:</h3>

          <p>When your workload resembles a rollercoaster ride, temp staff offer the flexibility to scale your team up or down as needed, without the long-term commitment.</p>
        </li>

        <li>
          <h3> Saving Cash Without Sacrificing Quality:</h3>
          <p>Quality temp staff provide cost-effective solutions without compromising on the caliber of work, helping you maximize your resources without overspending.</p>
        </li>

        <li>
          <h3> Bringing in Fresh Skills for Special Jobs:</h3>
          <p>For specialized tasks or projects, temp staff bring in expertise that may not be readily available in your core team, ensuring the job gets done right the first time.</p>

        </li>

        <li>
          <h3>Quick Fixes for Sudden Surges:</h3>
          <p>When unexpected surges in demand occur, temp staff can swoop in to provide immediate support, keeping your operations running smoothly without missing a beat.</p>
        </li>

        <li>
          <h3> Mixing Things Up for Better Ideas:</h3>
          <p>The unique perspectives and experiences temp staff bring to the table can inject fresh ideas and innovation into your projects, sparking creativity within your team.</p>
        </li>

        <li>
          <h3>Keeping Things Legal and Safe:</h3>
          <p>With temp agencies handling legalities like payroll taxes and insurance, you can rest assured knowing you're in compliance with employment regulations, minimizing legal risks.</p>
        </li>

      </ol>
      <h2>
      Conclusion:
      </h2>
      So, there you have it! Temporary staff aren't just a quick fix; they're a strategic move that can give your business the edge it needs to thrive.
      `,
  },

  {
    blogPost:
      'Rocking Your Workforce: How Quality Temporary Staff Can Take Your Business to the Next Level',
    title: 'Boosting Your Team: Why Good Temp Staff Are Your Secret Weapon',
    slug: 'boosting-your-team-why-good-temp-staff-are-your-secret-weapon',
    image: SupportPlaceHolder2,
    content: `
    <h2>
    Introduction:
    </h2>
    <p>
      Hey, bosses! Let's talk about something exciting: temporary staff. No, seriously! These guys can be a total game-changer for your business. Stick around to find out why bringing in quality temporary staff might be the best decision you make all year.
    </p>

    <ol>
      <li>
        <h3>Rolling with the Punches:</h3>
        <p>
        Temporary staff offer the flexibility to adapt to changing business needs, allowing you to seamlessly adjust your workforce without the stress of long-term commitments.
        </p>
      </li>

      <li>
        <h3>Getting More Bang for Your Buck:</h3>
        <p>
          By hiring temp staff, you can access top talent without the hefty price tag of full-time employees, ensuring you get the job done efficiently while staying within budget.
        </p>
      </li>

      <li>
      <h3>Adding Some Spice to Your Skill Set:</h3>
        <p>
          Whether it's a niche skill or industry-specific expertise, temp staff bring a diverse range of skills to the table, enriching your team and filling gaps in knowledge.
        </p>
      </li>
      <li>
      <h3>Growing Without the Growing Pains:</h3>
        <p>
          Temp staff provide the scalability needed to handle growth spurts or seasonal demands, allowing your business to expand without overwhelming your core team.
        </p>
      </li>
      <li>
      <h3>Shaking Things Up for Fresh Ideas:</h3>
      <p>
        The fresh perspectives and innovative thinking of temp staff can breathe new life into your projects, inspiring creativity and driving positive change within your organization.
      </p>
      </li>
      <li>
      <h3>Staying Safe and Legal:</h3>
      <p>
        With temp agencies handling employment logistics, you can navigate legal complexities with ease, ensuring compliance and minimizing risks associated with hiring.
      </p>
      </li>

    </ol>

    <h2>Conclusion:</h2>

    There you have it, folks! Quality temporary staff aren't just a short-term fix; they're a long-term investment in the success of your business.

    `,
  },
];
