// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import {Shift} from '../../../utils/types';
import axiosInstance from '../AxiosInstance';

interface ApiResponse {
  // Define the structure of your API response
  // Example: { data: YourDataType }
  data: Shift;
}

const fetchData = async (uid: string): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      `/shifts/?uid=${uid}`
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetSingleShiftSchedule = (
  uid: string
): UseQueryResult<ApiResponse, Error> => {
  return useQuery(['singleshiftschedule', uid], () => fetchData(uid));
};

export default useGetSingleShiftSchedule;
