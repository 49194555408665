import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import axiosInstance from '../../hooks/services/AxiosInstance';

const OuterBoxVerification = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState('');

  useEffect(() => {
    const emailVerification = async () => {
      if (params.token !== undefined) {
        await axiosInstance
          .get(`/auth/email-verification/${params.token}/`)
          // eslint-disable-next-line
          .then(() => {
            if (params.token !== undefined) {
              navigate('/outerbox/app-download');
            }
          })
          .catch(() => setErrors('Something went wrong'));
      }
    };
    if (params.token !== '') {
      // eslint-disable-next-line
      emailVerification();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex mt-64 flex-col justify-center text-center items-center">
      {errors === '' ? <p>Verifying email.....</p> : <p>{errors}</p>}
    </div>
  );
};

export default OuterBoxVerification;
