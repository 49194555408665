import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';

import useAuthToken from '../hooks/services/useAuthToken';

const PrivateRoute: React.FC = () => {
  const {token} = useAuthToken();

  if (token === null || !token) {
    return <Navigate to="/login" replace />;
  }
  return <Outlet />;
};

export default PrivateRoute;
