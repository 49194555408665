import {useMutation, useQueryClient, UseMutationResult} from 'react-query';
import {useNavigate} from 'react-router-dom';

import axiosInstance from '../AxiosInstance';

type Question = {
  question: string;
  expected_answer: null;
};
interface AddWrkerQuestionsParams {
  questions: Question[];
  agency_uid: string;
}

interface ResponseData {
  message: string;
  overlapping_workers: string[];
}

interface ErrorData {
  errors: string;
  error_code: string;
}

interface Error {
  response: {
    data: ErrorData;
  };
}

const addWorkerQuestions = async (
  data: AddWrkerQuestionsParams
): Promise<ResponseData> => {
  const response = await axiosInstance.post<ResponseData>(
    '/agency/agency-onboarding-question/',
    data
  );
  return response.data;
};

const useAddWorkerQuestions = (): UseMutationResult<
  ResponseData,
  Error,
  AddWrkerQuestionsParams
> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(addWorkerQuestions, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('workerquestions')
        .then(() => navigate('/worker-questions'))
        .catch((error: Error) => {
          throw new Error(error.response.data.errors);
        });
    },
  });
};

export default useAddWorkerQuestions;
