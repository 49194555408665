/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {useState, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';

import Spinner from './Spinner';
import Notification from '../../assets/icons/Notification.svg';
import logoDefault from '../../assets/icons/Planna-Default.png';
import logo from '../../assets/icons/logo-icon.png';
import Background from '../../assets/images/background2.png';
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';
import useNotifications, {
  INotificationData,
} from '../../hooks/services/Inbox/useNotification';
import useAuthUser from '../../hooks/services/useUserDetails';
import RQGetMessageThreads from '../../utils/Queries/RQGetMessageThreads';
import RQGetNotifications from '../../utils/Queries/RQGetNotifications';

type ChatMessage = {
  created_at: string;
  deleted_at: string | null;
  is_active: boolean;
  last_post_at: string;
  mattermost_channel_id: string;
  receiver_mattermost_id: string;
  receiver_username: string;
  sender_mattermost_id: string;
  sender_username: string;
  uid: string;
  unread_messages: number;
  updated_at: string;
  user_id: string;
};

type Channel = {
  channel: 'PUSHER-BEAMS';
  created_at: string; // ISO 8601 date string
  deleted_at: string | null;
  is_active: boolean;
  message: string;
  publish_id: string;
  status: 'PENDING';
  uid: string;
  updated_at: string; // ISO 8601 date string
  user: string;
};

const AppBar = () => {
  const navigate = useNavigate();
  const [notificationInfo, setNotificationInfo] = useState<ChatMessage[]>([]);
  const {user} = useAuthUser();
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile(
    user?.group === 'is_agency' ? true : false
  );
  const [notifications, isLoading, isError] = useNotifications();
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [recipientId, setRecipientId] = useState<string>('');
  const [channelId, setChannelId] = useState<string>('');
  const [unreadNotifications, setUnreadNotifications] = useState<
    INotificationData[]
  >([]);
  const [userNavigation, setUserNavigation] = useState<any>({
    username: 'Planna Inc',
    email: 'Company',
  });
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isLoading && !isError) {
      setNotificationCount(unreadNotifications?.length ?? 0);
      setUnreadNotifications(unreadNotifications ?? []);
    }
  }, [notifications, isLoading, isError, unreadNotifications]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const onClick = () => {
    navigate('/account-settings');
  };
  function handleNotificationClick(inbox_uid: string) {
    navigate(`/messages?inbox_uid=${inbox_uid}`);
  }

  // const {data: messageThreads} = RQGetMessageThreads();
  const {data: notificationsEndpoint} = RQGetNotifications();

  const openMessage = (notification: any) => {
    navigate(
      `/messages/${notification?.mattermost_channel_id}/${notification?.receiver_username}/Planna User`
    );
    setShowNotifications(false);
  };

  useEffect(() => {
    // const unreadMessages = messageThreads?.filter(
    //   (message: {unread_messages: number}) => message?.unread_messages == 1
    // );
    // setNotificationInfo(unreadMessages);

    setNotificationCount(notificationsEndpoint?.count ?? 0);
  }, [notificationsEndpoint]);
  if (profileLoading) return <Spinner />;
  return (
    <>
      <nav className="top-nav">
        <div className="nav-container">
          <div className="logo">
            <a href="/">
              <img src={logoDefault} alt="logo" />
            </a>
          </div>

          <div className="nav-options">
            <div className="notifications" onClick={toggleDropdown}>
              <img
                src={Notification}
                alt="notification"
                onClick={() => {
                  return (
                    notificationCount > 0 &&
                    setShowNotifications(!showNotifications)
                  );
                }}
              />
              {notificationCount > 0 && (
                <div className="notification-count">{notificationCount}</div>
              )}

              <div
                className={`notifications-container${
                  showNotifications ? ' show' : ''
                }`}>
                <div className="notofications-holder">
                  {/* {notificationInfo?.map((notification) => (
                    <div
                      className="notification-card"
                      key={notification?.uid}
                      onClick={() => openMessage(notification)}>
                      <div className="image">
                        <img src={logo} alt="logo" />
                      </div>
                      <div className="content">
                        <div className="title">
                          New Message - {notification?.receiver_username}
                        </div>
                        <div className="description">Tap to open message</div>
                      </div>
                    </div>
                  ))} */}
                  {notificationsEndpoint?.results
                    ?.slice(0, 4)
                    ?.map((notification: Channel) => (
                      <div
                        className="notification-card"
                        key={notification?.uid}
                        // onClick={() => openMessage(notification)}
                      >
                        <div className="image">
                          <img src={logo} alt="logo" />
                        </div>
                        <div className="content">
                          <div className="title">
                            Notification - {notification?.message}
                          </div>
                          <div className="description">Tap to open</div>
                        </div>
                      </div>
                    ))}
                  <div className="card-action">
                    <a href="/">Show All Notifications</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="profile" onClick={onClick}>
              <img src={Background} alt="background" />
              <div className="profile-info">
                <h2 className="name">{user?.fullname}</h2>
                <span>
                  {user?.group === 'is_agency' &&
                  profile?.default_agency_user.position === 'RECRUITER'
                    ? 'Recruiter'
                    : user?.group === 'is_agency' &&
                        profile?.default_agency_user.position ===
                          'ACCOUNT_MANAGER'
                      ? 'Account Manager'
                      : 'Regional Manager'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* <header className="bg-plannaNeutralWhite shadow-lg p-4">
        <div className="flex items-center space-x-3 flex-row justify-end">
          <div className="relative" ref={dropdownRef}>
            <button
              id="dropdownHelperButton"
              onClick={toggleDropdown}
              aria-haspopup="true"
              aria-expanded={isDropdownOpen ? 'true' : 'false'}>
              <img
                src={Notification}
                alt="notification"
                className="h-fit w-fit cursor-pointer"
              />
              {notificationCount > 0 && (
                <div className="absolute top-[-8px] right-[-5px] bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                  {notificationCount}
                </div>
              )}
            </button>
            {isDropdownOpen && (
              <div
                id="dropdownHelper"
                className="z-50 absolute right-0 mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-60 ">
                <ul className="p-3 space-y-1 text-sm text-gray-700 ">
                  {unreadNotifications.map((notification) => (
                    <li>
                      <div
                        className="flex p-2 rounded hover:bg-gray-100"
                        onClick={() =>
                          handleNotificationClick(notification.inbox_uid)
                        }>
                        <div className="h-8 w-8 rounded-full text-center flex items-center justify-center text-white bg-plannaPositiveGreen">
                          {`${notification.sender_name.charAt(
                            0
                          )}${notification.sender_name
                            .split(' ')[1]
                            .charAt(0)}`}
                        </div>

                        <div className="ms-2 text-sm">
                          <label
                            htmlFor="helper-checkbox-1"
                            className="font-medium text-gray-900 ">
                            <div>{notification.sender_name}</div>
                            <p
                              id="helper-checkbox-text-1"
                              className="text-xs font-normal text-gray-500">
                              {notification.message}
                            </p>
                          </label>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="flex items-center space-x-3">
            <img
              src={Background}
              onClick={onClick}
              alt="background"
              className="h-8 w-8 cursor-pointer rounded-full"
            />
            <div>
              <h1 className="text-sm">{user?.fullname}</h1>
              <p className="text-sm ">Regional Manager</p>
            </div>
          </div>
        </div>
      </header> */}
    </>
  );
};

export default AppBar;
