/* eslint-disable @typescript-eslint/no-unsafe-return */
import {useQuery} from 'react-query';

import axiosInstance from '../../hooks/services/AxiosInstance';

const RQGetUsers = (email: string) => {
  const getUsers = async () => {
    let url = '/users/';
    if (email) {
      url = `/users/?email=${email}`;
    }
    const response = await axiosInstance.get(url);

    return response?.data;
  };

  return useQuery(['planna-users', email], getUsers, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 15,
    // enabled: searchTerm ? true : false,
  });
};

export default RQGetUsers;
