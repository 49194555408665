import {DatesSetArg, EventClickArg} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {getTimePeriod} from './ShiftTemplatesCalendar';
import Spinner from '../../Components/Common/Spinner';
import DialogLayout from '../../Layouts/DialogLayout';
import useGetAgencyShifts from '../../hooks/services/Agencys/useGetAgencyShifts';
import useGetProviderProfile from '../../hooks/services/useGetProviderProfile';
import {formatDateToIso} from '../../utils/Methods';
import './styles.css';
import {AgencyShift} from '../../utils/types';
import SingleAllocationCalendarView from '../Agency/Components/SingleAllocationCalendarView';

type EventDetails = {
  title: string;
  description: string;
  start: string;
  end: string;
  agency: string;
  workerCount: number;
  jobTemplateName: string;
  status: string;
  location: string;
  employerNI: string;
  pensionContribution: string;
  holidayPay: string;
  otherCosts: string;
  marginType: string;
  margin: string;
};
interface DateRange {
  start: Date;
  end: Date;
}
function formatDateAndJoinTime(originalDate: string, newTime: string): string {
  const dateObject = new Date(originalDate);

  // Extract the date part (year, month, day)
  const formattedDate = dateObject.toISOString().split('T')[0];

  // Join with the new time
  const combinedDateTime = `${formattedDate}T${newTime}`;

  return combinedDateTime;
}

const Shifts = () => {
  const {data: profile, isLoading: profileLoading} = useGetProviderProfile();
  const [dateRange, setDateRange] = useState<DateRange>({
    start: new Date(Date.now()),
    end: new Date(Date.now()),
  });
  const handleDatesSet = (dateInfo: DatesSetArg) => {
    setDateRange({
      start: dateInfo.start,
      end: dateInfo.end,
    });
  };
  const {data, isLoading} = useGetAgencyShifts(
    'CONFIRMED',
    profile !== undefined ? profile.uid : '',
    'shift_template__provider__uid',
    '2024-04-14T00:00:00Z',
    '2024-08-14T00:00:00Z'
  );
  const [weekendsVisible] = useState(true);
  //eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(false);
  const [allocationUid, setAllocationUid] = useState('');
  const [agencyId, setAgencyId] = useState('');
  const [isViewEvent, setIsViewEvent] = useState(false);
  const navigate = useNavigate();
  const [eventDetails, setEventDetails] = useState<EventDetails>({
    title: '',
    description: '',
    start: '',
    end: '',
    status: '',
    agency: '',
    workerCount: 0,
    jobTemplateName: '',
    location: '',
    employerNI: '',
    pensionContribution: '',
    holidayPay: '',
    otherCosts: '',
    marginType: '',
    margin: '',
  });

  const openDialog = () => {
    setIsOpen(true);
  };

  const handleDateSelect = () => {
    openDialog();
  };
  const handleEventClick = (clickInfo: EventClickArg) => {
    const event = clickInfo.event;
    const start = JSON.stringify(event.start);
    const end = JSON.stringify(event.end);
    const details = {
      title: event.title,
      // eslint-disable-next-line
      description: event.extendedProps?.description,
      // eslint-disable-next-line
      start: JSON.parse(start),
      // eslint-disable-next-line
      end: JSON.parse(end),
      // eslint-disable-next-line
      agency: event.extendedProps?.agency,
      // eslint-disable-next-line
      status: event.extendedProps?.status,
      // eslint-disable-next-line
      workerCount: event.extendedProps?.workerCount,
      // eslint-disable-next-line
      jobTemplateName: event.extendedProps?.jobTemplateName,
      // eslint-disable-next-line
      location: event.extendedProps?.location,
      // eslint-disable-next-line
      employerNI: event.extendedProps?.employerNI,
      // eslint-disable-next-line
      pensionContribution: event.extendedProps?.pensionContribution,
      // eslint-disable-next-line
      holidayPay: event.extendedProps?.holidayPay,
      // eslint-disable-next-line
      otherCosts: event.extendedProps?.otherCosts,
      // eslint-disable-next-line
      marginType: event.extendedProps?.marginType,
      // eslint-disable-next-line
      margin: event.extendedProps?.margin,
    };
    setEventDetails(details);
    setIsViewEvent(true);
    // eslint-disable-next-line
    setAllocationUid(event.extendedProps?.allocationId);
    // eslint-disable-next-line
    setAgencyId(event.extendedProps?.agencyId);
  };
  const refinedEvents: {
    date: string;
    title: string;
    extendedProps: {subtitle: string; variant: string; cancleled: boolean};
  }[] = [];

  data?.results?.forEach((event: AgencyShift) => {
    event?.occurrences?.forEach((occurence) => {
      const startTime = event?.shift_template.start_time?.slice(0, 5); // Assuming start_time is in HH:mm format
      const endTime = event?.shift_template?.end_time?.slice(0, 5);

      const refinedEvent = {
        date: occurence,
        allocationId: event.uid,
        workerCount: event.shift_template.worker_count,
        agencyId: event.agency,
        start: occurence?.slice(0, 10) + 'T' + startTime,
        end: occurence?.slice(0, 10) + 'T' + endTime,
        title: `${getTimePeriod(
          event?.shift_template.start_time?.slice(0, 5)
        )} - ${getTimePeriod(event?.shift_template?.end_time?.slice(0, 5))}`,
        extendedProps: {
          subtitle: event?.shift_template?.name,
          variant: 'success',
          cancleled: event?.status === 'ARCHIVED' ? true : false,
        },
      };
      refinedEvents.push(refinedEvent);
    });
  });

  if (isLoading || profileLoading) {
    return <Spinner />;
  }
  const handleDateClick = () => {
    navigate('/shift-template/new');
  };
  return (
    <div className="w-11/12 ">
      <DialogLayout isOpen={isViewEvent} onClose={() => setIsViewEvent(false)}>
        <SingleAllocationCalendarView
          allocationId={allocationUid}
          eventDetails={eventDetails}
          agencyId={agencyId}
          provider
        />
      </DialogLayout>

      <div className="calendar-container mt-6">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          datesSet={handleDatesSet}
          weekends={true}
          headerToolbar={{
            left: 'title prev,next',
            center: '',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          dateClick={handleDateClick}
          events={refinedEvents}
          dayHeaderFormat={{weekday: 'short', day: 'numeric'}}
          height="700px"
          slotMinTime="06:00"
          slotMaxTime="31:00"
        />
      </div>
    </div>
  );
};

export default Shifts;
const renderEventContent = (eventInfo: {
  event: {
    title: string;
    extendedProps: {
      variant: string;
      cancleled: boolean;
      subtitle: string;
      subtext: string;
    };
  };
}) => {
  return (
    <div
      className={`card-variant ${eventInfo.event.extendedProps.variant}${
        eventInfo.event.extendedProps.cancleled ? ' canceled' : ''
      }`}>
      <div className="fc-title">{eventInfo.event.title}</div>
      <div className="fc-subtitle">
        {eventInfo.event.extendedProps.subtitle}
      </div>{' '}
      <div className="fc-subtext">{eventInfo.event.extendedProps.subtext}</div>{' '}
    </div>
  );
};
