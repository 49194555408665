import {jwtDecode} from 'jwt-decode';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import axiosInstance from '../../hooks/services/AxiosInstance';

interface DecodedToken {
  user_id: string;
  email: string;
  group: string;
}
const VerifyEmail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState('');

  useEffect(() => {
    const emailVerification = async () => {
      if (params.token !== undefined) {
        await axiosInstance
          .get(`/auth/email-verification/${params.token}/`)
          // eslint-disable-next-line
          .then(() => {
            if (params.token !== undefined) {
              const decodedToken: DecodedToken = jwtDecode(params.token);
              if (decodedToken.group === 'is_worker') {
                navigate('/app-download');
              } else {
                navigate('/login');
              }
            }
          })
          .catch(() => setErrors('Something went wrong'));
      }
    };
    if (params.token !== '') {
      // eslint-disable-next-line
      emailVerification();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex mt-64 flex-col justify-center text-center items-center">
      {errors === '' ? <p>Verifying email.....</p> : <p>{errors}</p>}
    </div>
  );
};

export default VerifyEmail;
