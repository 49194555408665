/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import {registerLicense} from '@syncfusion/ej2-base';
import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
import React from 'react';
import {transitions, positions, Provider as AlertProvider} from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import ReactDOM from 'react-dom/client';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {BrowserRouter} from 'react-router-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Scroll from './utils/Scroll';
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
};
const license = process.env.REACT_APP_SYNCFUSION_LICENSE;
// Apply the Syncfusion license
registerLicense(license !== undefined ? license : '');
const firebaseConfig = {
  apiKey: 'AIzaSyBkooDSljgZU53jitCXzuXOgWomRUWubn0',
  authDomain: 'planna-b1ca3.firebaseapp.com',
  projectId: 'planna-b1ca3',
  storageBucket: 'planna-b1ca3.appspot.com',
  messagingSenderId: '326452072915',
  appId: '1:326452072915:web:e6f84227cf91d393759f26',
  measurementId: 'G-ZF30F46F0R',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const beamsClient = new PusherPushNotifications.Client({
  instanceId: '04e7cb40-e8f3-49c4-b2b3-e399d1fde7ba',
});

beamsClient
  .start()
  .then(() => beamsClient.addDeviceInterest('hello'))
  .then(() => console.log('Successfully registered and subscribed!'))
  .catch(console.error);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AlertProvider template={AlertTemplate} {...options}>
        <BrowserRouter>
          {/* <Scroll /> */}
          <App />
          {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-left" /> */}
        </BrowserRouter>
      </AlertProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
