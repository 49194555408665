import {format, addDays, subDays} from 'date-fns';
import moment from 'moment';
import React, {ChangeEvent, useCallback, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import AgencyShiftController from './AllocateWorker';
import Spinner from '../../../Components/Common/Spinner';
import SubmitSpinner from '../../../Components/Common/SubmitSpinner';
import DialogLayout from '../../../Layouts/DialogLayout';
import ErrorHandlingLayout from '../../../Layouts/ErrorHandlingLayout';
import SuccessLayout from '../../../Layouts/SuccessLayout';
import useAllocateWorkerShift from '../../../hooks/services/Agencys/useAllocateWorkerShift';
import useGetAgencyProfile from '../../../hooks/services/Agencys/useGetAgencyProfile';
import useGetSingleAgencyAllocation from '../../../hooks/services/Agencys/useGetSingleAgencyAllocation';
import useGetWorkerShifts from '../../../hooks/services/Agencys/useGetWorkerShiftAllocations';
import useGetWorkers, {
  Availability,
  Data,
  TimeOff,
} from '../../../hooks/services/Agencys/useGetWorkers';
import useGetProviderDailyShiftsDetails from '../../../hooks/services/Shifts/useGetDailyShiftsDetails';
import useIndustriesData from '../../../hooks/services/common/useGetIndustries';
import useLocationsData from '../../../hooks/services/common/useGetLocations';
import useGetRoles from '../../../hooks/services/common/useGetRoles';
import {
  DailyShiftDetailsType,
  DailyShiftsType,
  WorkerShift,
} from '../../../utils/types';

const getNextSevenDays = (startDate: Date): string[] => {
  const dates = [];
  for (let i = 0; i < 7; i++) {
    dates.push(format(addDays(startDate, i), 'yyyy-MM-dd'));
  }
  return dates;
};

const Calendar: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [industryId, setIndustryId] = useState('');
  const [roleId, setRoleId] = useState('');
  const [locationId, setLocationId] = useState('');
  const [open, setOpen] = useState(false);
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile();
  const [error, setError] = useState(false);
  const [succcess, setSuccess] = useState(false);
  const {
    data: workersData,
    isLoading: workersLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetWorkers({
    status: 'SEEKER',
    agency_id: profile !== undefined ? profile.uid : '',
    start_date: moment(Date.now()).format('YYYY-MM-DD'),
    end_date: '2024-07-29',
    roles: roleId,
    industries: industryId,
    location_id: locationId,
    limit: 10,
  });
  const {data: industries, isLoading: industriesLoading} = useIndustriesData();
  const {data: roles, isLoading: rolesLoading} = useGetRoles({});
  const {data: locations, isLoading: locationsLoading} = useLocationsData();
  const {data: workerShiftAllocations, isLoading: workerShiftsLoading} =
    useGetWorkerShifts({
      agency_id: profile !== undefined ? profile.uid : '',
      agency_shift_allocation__uid: params.id,
    });
  const {data, isLoading} = useGetProviderDailyShiftsDetails({
    status: 'CANCELED',
  });
  const {
    mutateAsync,
    isLoading: workerAllocationLoading,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    data: allocateResponse,
  } = useAllocateWorkerShift();

  const {data: agencyAllocation, isLoading: agencyAllocationLoading} =
    useGetSingleAgencyAllocation(params.id !== undefined ? params.id : '');
  const [startDate, setStartDate] = useState(new Date());
  const dates = useMemo(() => getNextSevenDays(startDate), [startDate]);
  const [selectedWorkers, setSelectedWorkers] = useState<string[]>(
    workersData?.pages.flatMap((page) =>
      page.results.map((worker) => worker.uid)
    ) || []
  );

  const handleHeaderCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      setSelectedWorkers(
        workersData?.pages.flatMap((page) =>
          page.results.map((worker) => worker.uid)
        ) || []
      );
    } else {
      setSelectedWorkers([]);
    }
  };

  const handleWorkerCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, uid: string) => {
      if (e.target.checked) {
        setSelectedWorkers((prevSelected) => [...prevSelected, uid]);
      } else {
        setSelectedWorkers((prevSelected) =>
          prevSelected.filter((id) => id !== uid)
        );
      }
    },
    []
  );
  const handleIndustrySelect = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const {value} = e.target;
      setIndustryId(value);
    },
    []
  );
  const handleRoleSelect = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const {value} = e.target;
    setRoleId(value);
  }, []);
  const handleLocationSelect = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const {value} = e.target;
      setLocationId(value);
    },
    []
  );

  const getAvailability = useCallback(
    (worker: Data, date: string): Availability | undefined => {
      return worker.worker_availability?.occurrences
        .map((occurence) => new Date(occurence))
        .map((occurenceDate) => occurenceDate.toISOString().slice(0, 10))
        .includes(date)
        ? worker.worker_availability
        : undefined;
    },
    []
  );

  const getCancelledShifts = useCallback(
    (worker: Data, date: string): DailyShiftDetailsType[] | undefined => {
      const cancelledShifts = data?.results.filter(
        (shift) => shift.shift.date === date && shift.worker.uid === worker.uid
      );
      return cancelledShifts;
    },
    [data]
  );

  const getShiftsAllocated = useCallback(
    (worker: Data, date: string): WorkerShift[] => {
      const workerAllocations = workerShiftAllocations?.results.filter(
        (workerAllocation) =>
          workerAllocation.worker.uid === worker.uid &&
          workerAllocation.occurrences
            .map((occurrence) => new Date(occurrence))
            .map((occurrenceDate) => occurrenceDate.toISOString().slice(0, 10))
            .includes(date) &&
          data?.results.filter(
            (shift) =>
              shift.worker.uid === worker.uid && shift.shift.date === date
          ).length === 0
      );

      return workerAllocations || [];
    },
    [workerShiftAllocations, data]
  );

  const getTimeOff = useCallback((worker: Data, date: string): TimeOff[] => {
    const workerTimeOffs = worker.worker_availability?.worker_timeoff.filter(
      (timeOff) =>
        timeOff.occurrences
          .map((occurrence) => new Date(occurrence))
          .map((occurrenceDate) => occurrenceDate.toISOString().slice(0, 10))
          .includes(date)
    );

    return workerTimeOffs || [];
  }, []);
  const onAllocate = async () => {
    const post = {
      recurrence:
        agencyAllocation !== undefined
          ? agencyAllocation.results.shift_template.recurrence
          : '',
      allocation_type: 'PARTIAL',
      workers_uid: selectedWorkers,
      start_date:
        agencyAllocation !== undefined
          ? agencyAllocation.results.shift_template.start_date
          : '',
      agency_shift_allocation_uid:
        agencyAllocation !== undefined ? agencyAllocation.results.uid : '',
    };

    await mutateAsync(post)
      .then(() => setSuccess(true))
      .catch(() => setError(true));
  };
  const getCellContent = useCallback(
    (worker: Data, date: string) => {
      const availability = getAvailability(worker, date);
      const timeOff = getTimeOff(worker, date);
      const allocations = getShiftsAllocated(worker, date);
      const cancelledShifts = getCancelledShifts(worker, date);
      if (availability || timeOff || allocations || cancelledShifts) {
        return (
          <div>
            {availability !== null && availability !== undefined ? (
              <div
                onClick={() => setOpen(true)}
                className=" bg-plannaSecondaryGreen50 cursor-pointer text-plannaNeutralWhite py-3 px-2 flex flex-col items-center mb-2">
                <p> Available</p>(
                {moment(availability?.start_time, 'HH:mm:ss').format('hA')} -
                {moment(availability?.end_time, 'HH:mm:ss').format('hA')})
              </div>
            ) : (
              <div className="bg-gray-200  text-plannaPrimaryGreen py-3 px-1 flex flex-col items-center mb-2">
                Unavailable
              </div>
            )}
            {timeOff.map((off) => (
              <div className=" bg-plannaTertiaryRed text-plannaNeutralWhite px-1 py-3 flex flex-col items-center mb-2">
                <p> {off.comment !== null ? off.comment : 'Time off'} </p>({' '}
                {moment(off.start_time, 'HH:mm:ss').format('hA')} -
                {moment(off.end_time, 'HH:mm:ss').format('hA')})
              </div>
            ))}
            {allocations.map((allocation) => (
              <div className=" bg-plannaAccentGreen text-plannaPrimaryGreen px-2 py-3  flex flex-col  mb-2">
                <p>
                  {allocation.status === 'AWAITING_CONFIRMATION'
                    ? 'Awaiting Confirmation'
                    : allocation.status === 'DECLINED'
                      ? 'Declined'
                      : 'Confirmed'}
                </p>{' '}
                <p>{allocation.agency_shift_allocation.shift_template.name}</p>(
                {moment(
                  allocation.agency_shift_allocation.shift_template.start_time,
                  'HH:mm:ss'
                ).format('hA')}{' '}
                -
                {moment(
                  allocation.agency_shift_allocation.shift_template.end_time,
                  'HH:mm:ss'
                ).format('hA')}
                )
              </div>
            ))}
            {cancelledShifts?.map((shift) => (
              <div className=" bg-plannaAccentGreen text-plannaPrimaryGreen px-2 py-3  flex flex-col  mb-2">
                <p>Cancelled</p>{' '}
                <p className=" line-through">
                  {
                    shift.shift.timesheet.agency_shift_allocation.shift_template
                      .name
                  }
                </p>
                {moment(
                  shift.shift.timesheet.agency_shift_allocation.shift_template
                    .start_time,
                  'HH:mm:ss'
                ).format('hA')}{' '}
                -
                {moment(
                  shift.shift.timesheet.agency_shift_allocation.shift_template
                    .end_time,
                  'HH:mm:ss'
                ).format('hA')}
                )
              </div>
            ))}
          </div>
        );
      }

      return (
        <div className="bg-gray-200  text-plannaPrimaryGreen py-3 px-1 flex flex-col items-center mb-2">
          Unavailable
        </div>
      );
    },
    [getAvailability, getTimeOff, getShiftsAllocated, getCancelledShifts]
  );

  const handleNextWeek = () => {
    setStartDate((prevDate) => addDays(prevDate, 7));
  };

  const handlePreviousWeek = () => {
    setStartDate((prevDate) => subDays(prevDate, 7));
  };
  const isLoadingAll =
    profileLoading ||
    industriesLoading ||
    rolesLoading ||
    locationsLoading ||
    workerShiftsLoading ||
    workersLoading ||
    isLoading ||
    agencyAllocationLoading;

  if (isLoadingAll) {
    return <Spinner />;
  }

  return (
    <div className="overflow-x-auto">
      <SuccessLayout isOpen={succcess} onClose={() => setSuccess(false)}>
        <p className="text-lg font-bold">Shift allocated</p>
        <p className="text-left">The shift has been allocated sucessfully.</p>
        {allocateResponse?.overlapping_workers !== undefined &&
          allocateResponse?.overlapping_workers.length > 0 && (
            <div className="text-left">
              <p className="mt-4 text-left rounded-lg bg-plannaWarning p-2 text-plannaNeutralWhite">
                The following workers have clashing shifts, so they were not
                allocated.
              </p>
              <ul className="list-disc mt-2 ml-4 font-semibold text-sm">
                {allocateResponse?.overlapping_workers.map((worker) => (
                  <li key={worker}>{worker}</li>
                ))}
              </ul>
            </div>
          )}
      </SuccessLayout>
      <ErrorHandlingLayout
        isOpen={error}
        title="Allocation Error"
        onClose={() => setError(false)}>
        <p className="text-lg font-bold">Shift already allocated</p>
        <p>The shift has already been allocated.</p>
      </ErrorHandlingLayout>
      <div className="flex justify-between mb-8">
        <div className="flex justify-end items-center text-xs space-x-2  mt-4 ">
          <div className="flex justify-end items-center text-xs space-x-2 mt-4 ">
            <div className="w-full">
              <select
                onChange={handleIndustrySelect}
                value={industryId}
                className="text-plannaPrimaryGreen border border-plannaNeutral rounded-lg py-2 px-3">
                <option selected hidden value="">
                  Select an industry
                </option>
                {industries?.industries?.map((industry) => (
                  <option key={industry.uid} value={industry.uid}>
                    {industry.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-full">
              <select
                className="text-plannaPrimaryGreen border border-plannaNeutral rounded-lg py-2 px-3"
                value={roleId}
                onChange={handleRoleSelect}>
                <option selected value="">
                  Select a role
                </option>
                {roles?.results
                  ?.filter((role) => role.industry === industryId)
                  .map((role) => {
                    return (
                      <option key={role.uid} value={role.uid}>
                        {role.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="w-full">
              <select
                onChange={handleLocationSelect}
                value={locationId}
                className="text-plannaPrimaryGreen border border-plannaNeutral rounded-lg py-2 px-3">
                <option selected hidden value="">
                  Select a location
                </option>
                {locations?.locations?.map((location) => (
                  <option key={location.uid} value={location.uid}>
                    {location.region},{location.country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full">
              <button
                onClick={() => {
                  setIndustryId('');
                  setRoleId('');
                  setLocationId('');
                }}
                className="  bg-plannaNeutralGray text-black px-6 py-2 rounded-xl">
                Reset
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-between ">
          {selectedWorkers.length > 0 && (
            <div className=" p-4">
              <button
                onClick={onAllocate}
                className=" bg-plannaPrimaryGreen text-plannaAccentGreen px-6 py-2 rounded-xl">
                {workerAllocationLoading ? (
                  <SubmitSpinner />
                ) : (
                  'Allocate a shift'
                )}
              </button>
            </div>
          )}
          <div className="p-4">
            {startDate > new Date() && (
              <button
                onClick={handlePreviousWeek}
                className="bg-plannaPrimaryGreen text-plannaAccentGreen py-1 px-3 mr-2 rounded ">
                {'<'}
              </button>
            )}
            <span className="font-bold text-sm">
              {moment(dates[0]).format('Do MMMM YYYY')} -{' '}
              {moment(dates[dates.length - 1]).format('Do MMMM YYYY')}
            </span>

            <button
              onClick={handleNextWeek}
              className="bg-plannaPrimaryGreen text-plannaAccentGreen py-1 px-3 rounded ml-2">
              {`>`}
            </button>
          </div>
        </div>
      </div>
      <table className="min-w-full bg-white border-collapse">
        <thead>
          <tr>
            <th className="border py-2 px-4 bg-gray-200 flex items-center">
              <input
                type="checkbox"
                className="mr-2 h-5 w-5 accent-plannaPrimaryGreen"
                onChange={handleHeaderCheckboxChange}
                checked={
                  selectedWorkers.length ===
                  workersData?.pages.flatMap((page) =>
                    page.results.map((worker) => worker.uid)
                  ).length
                }
              />
              Workers
            </th>
            {dates.map((date, index) => (
              <th key={index} className="border py-2 px-4">
                {date}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {workersData?.pages.map((page, pageIndex) => (
            <React.Fragment key={pageIndex}>
              {page.results.map((worker, index) => (
                <tr key={index} className="border-t">
                  <td className="py-2 px-4 flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2 h-5 w-5 accent-plannaPrimaryGreen"
                      onChange={(e) =>
                        handleWorkerCheckboxChange(e, worker.uid)
                      }
                      checked={selectedWorkers.includes(worker.uid)}
                    />
                    <div
                      onClick={() =>
                        navigate(`/agency/single-worker/${worker.uid}`)
                      }
                      className="flex space-x-3 items-center cursor-pointer">
                      <div className="bg-plannaPrimaryGreen flex items-center text-plannaAccentGreen rounded-full h-8 w-8 justify-center">
                        {worker.user.first_name.charAt(0)}
                        {worker.user.last_name.charAt(0)}
                      </div>
                      <span className="font-semibold text-plannaPrimaryGreen">
                        {worker.user.first_name} {worker.user.last_name}
                      </span>
                    </div>
                  </td>
                  {dates.map((date, idx) => (
                    <td key={idx} className="py-2 border px-4">
                      {selectedWorkers.includes(worker.uid) &&
                        getCellContent(worker, date)}
                    </td>
                  ))}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div>
        {hasNextPage ? (
          <button
            className=" mt-4 bg-plannaPrimaryGreen text-plannaAccentGreen px-6 py-2 rounded-full"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}>
            {isFetchingNextPage ? 'Loading more...' : 'Load more'}
          </button>
        ) : (
          <p className="mt-4 text-plannaPrimaryGreen">No more workers</p>
        )}
      </div>
    </div>
  );
};

export default Calendar;
