import React from 'react';
import {FiLogOut} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';

import circularForward from '../../assets/icons/circular-forward.png';
import people from '../../assets/icons/people.png';
import useAuthUser from '../../hooks/services/useUserDetails';
const AccountSettings = () => {
  const navigate = useNavigate();
  const {user} = useAuthUser();
  const agencyItems = [
    {
      title: 'Agency Profile',
      subTitle: 'Update your email and other profile details',
      link: '/agency/profile',
    },
    {
      title: 'Worker Onboarding Questions',
      subTitle:
        'Update some of the questions you would like your workers to fill when onboarding them',
      link: '/worker-questions',
    },
  ];
  const providerItems = [
    {
      title: 'Business details',
      subTitle:
        'View and update your business information and find out how it is used to improve app experience',
      link: '/business-details',
    },
    {
      title: 'Payment details',
      subTitle:
        'Securely add or remove payment methods to make it easier for transactions',
      link: '/bank-details',
    },

    {
      title: 'Profile',
      subTitle: 'Update your email and other profile details',
      link: '/profile-details',
    },
  ];

  const items = user?.group === 'is_provider' ? providerItems : agencyItems;
  const onLogout = () => {
    sessionStorage.clear();
    window.location.assign('/login');
  };
  return (
    <div className="p-4">
      <div className="mt-12 flex flex-col justify-center items-center">
        <div className="lg:w-9/12 w-full mb-8">
          <h1 className="text-3xl text-plannaPrimaryGreen mt-12 font-bold">
            Account Settings
          </h1>
          <p className="text-sm font-medium text-plannaPrimaryGreen mt-3.5">
            Manage your experience
          </p>
        </div>
        {items.map((item, index) => (
          <div
            onClick={() => navigate(item.link)}
            key={index}
            className="card lg:w-9/12 w-full cursor-pointer px-6 py-4 border border-plannaPrimaryGreen rounded-lg mb-7 flex  justify-between">
            <div className="flex space-x-6">
              <img src={people} alt="people" className="h-fit w-fit" />
              <div className="flex flex-col">
                <h1 className="text-xl text-plannaPrimaryGreen font-semibold">
                  {item.title}
                </h1>
                <p className="text-plannaPrimaryGreen text-sm font-normal mt-1">
                  {item.subTitle}
                </p>
              </div>
            </div>

            <img src={circularForward} alt="forward" className="h-fit w-fit" />
          </div>
        ))}
        <div className="mt-6  lg:w-9/12 w-full">
          <button
            onClick={onLogout}
            className="flex  w-full justify-center items-center p-2  text-plannaAccentGreen rounded-lg group bg-plannaPrimaryGreen">
            <FiLogOut className="w-5 h-5 transition duration-75 text-gray-400 group-hover:text-white" />
            <span className="ms-3">Logout</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
