import {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import Spinner from '../../../Components/Common/Spinner';
import Input from '../../../Components/Registration/Input';
import useGetAgencyProfile from '../../../hooks/services/Agencys/useGetAgencyProfile';
import useGetProviderDailyShifts from '../../../hooks/services/Shifts/useGetDailyShifts';
import {formatCurrentDate, reverseDate} from '../../../utils/Methods';
import {DailyShiftsType} from '../../../utils/types';

const AgencyDailyShifts = () => {
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile();
  const [status, setStatus] = useState('IN_PROGRESS');
  const navigate = useNavigate();
  const currentDate = new Date();
  const formattedDate = formatCurrentDate(currentDate);
  const [inputDate, setInputDate] = useState<string>(formattedDate);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputDate(event.target.value);
  };
  const params = useParams();
  // eslint-disable-next-line
  const {data, isLoading} = useGetProviderDailyShifts(
    profile !== undefined ? profile.uid : '',
    'agency__uid',
    reverseDate(inputDate),
    params.id !== undefined ? params.id : '',
    status
  );
  if (profileLoading || isLoading) {
    return <Spinner />;
  }
  console.log(data);
  return (
    <div className="p-4">
      <div className="mt-8">
        <h1 className="text-black font-bold text-xl">
          Timesheets Daily Shifts
        </h1>
        <p className="text-black mt-2 text-sm">
          View shift information with real-time insights and streamlined views
          on worker attendance
        </p>
      </div>

      <div className="flex justify-end">
        <div className="w-1/4">
          <Input
            inputValue={inputDate}
            name="date"
            type="date"
            label="Select date"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="flex justify-end items-center text-xs space-x-2 mt-4 mb-6">
        <button
          onClick={() => setStatus('SCHEDULED')}
          className={`${
            status !== 'SCHEDULED' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Scheduled
        </button>
        <button
          onClick={() => setStatus('IN_PROGRESS')}
          className={`${
            status !== 'IN_PROGRESS' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          In progress
        </button>
        <button
          onClick={() => setStatus('ENDED')}
          className={`${
            status !== 'ENDED' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Ended
        </button>
        <button
          onClick={() => setStatus('CANCELED')}
          className={`${
            status !== 'CANCELED' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Cancelled
        </button>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Active
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Job Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Provider
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Action
                    </th>
                  </tr>
                </thead>
                {/* eslint-disable-next-line */}
                {data?.results.map((shift: DailyShiftsType) => (
                  // eslint-disable-next-line
                  <tbody key={shift.uid} className="divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.date}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.is_active === true ? 'Active' : 'Not active'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.status.toLowerCase()}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {
                          shift.timesheet.agency_shift_allocation.shift_template
                            .job_template.template_name
                        }
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {
                          shift.timesheet.agency_shift_allocation.shift_template
                            .provider.company.name
                        }
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        <div className="flex space-x-3">
                          {/* eslint-disable-next-line */}
                          <button
                            onClick={() =>
                              // eslint-disable-next-line
                              navigate(
                                `/agency/daily-shifts-details/${shift.uid}`
                              )
                            }
                            className="px-6 py-2 bg-plannaPrimaryGreen text-plannaAccentGreen rounded-full">
                            View details
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyDailyShifts;
