/* eslint-disable @typescript-eslint/no-unsafe-return */
import {useQuery} from 'react-query';

import axiosInstance from '../../hooks/services/AxiosInstance';

const RQGetGroupUsers = (group_id: string) => {
  const getUsers = async () => {
    const response = await axiosInstance.get(
      `/messaging/group-users-list/${group_id}`
    );

    return response?.data;
  };

  return useQuery(['planna-group-users', group_id], getUsers, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30,
    // enabled: searchTerm ? true : false,
  });
};

export default RQGetGroupUsers;
