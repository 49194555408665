import React from 'react';

import Spinner from '../../../Components/Common/SubmitSpinner';

type Button = {
  label: string;
  isLoading: boolean;
  shift?: boolean;
};
const TemplateSubmitButton = ({label, isLoading, shift}: Button) => {
  return (
    <button
      type="submit"
      className={` ${
        shift ? 'w-full' : ''
      } bg-plannaPrimaryGreen text-plannaAccentGreen text-center  text-lg font-hanken-grotesk font-semibold  px-6 py-2.5 rounded-full`}>
      {isLoading ? <Spinner /> : label}
    </button>
  );
};

export default TemplateSubmitButton;
