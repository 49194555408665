import React from 'react';

import Button from '../../Components/LandingPage/Button';
import NavbarLayout from '../../Layouts/NavbarLayout';
import Congratulations from '../../assets/icons/Congratulations.svg';
import Appstore from '../../assets/images/app-store.png';
import Playstore from '../../assets/images/googleplay.png';

const OuterBoxThankYou = () => {
  return (
    <>
      <NavbarLayout>
        <div className="block lg:inline-block mt-4 lg:mt-0 lg:ml-4">
          <Button label="Help" bg="plannaPrimaryGreen" text="white" />
        </div>
      </NavbarLayout>
      <div className="p-4 flex flex-col justify-center items-center h-screen">
        <img
          src={Congratulations}
          alt="congratulations"
          className="h-fit w-fit"
        />
        <h1 className="mt-4 text-xl text-plannaPrimaryGreen font-bold">
          Thank you
        </h1>
        <p className="text-sm text-plannaPrimaryGreen text-center mt-4">
          We’re reviewing your details, this can take up to 24 hours. We’ll let
          you know as soon as we are done
        </p>
        {/* <div className="mt-4">
        <button
          onClick={() => navigate('/')}
          className="mt-12 px-6 w-full bg-plannaPrimaryGreen text-plannaAccentGreen text-lg font-semibold rounded-full py-3">
          Go to shift calendar
        </button>
      </div> */}
      </div>
    </>
  );
};

export default OuterBoxThankYou;
