import {useMutation, UseMutationResult, useQueryClient} from 'react-query';

import axiosInstance from './AxiosInstance';

interface AddProviderBankDetails {
  bank_name: string;
  account_name: string;
  account_number: number;
  sort_code: string;
}
const providerBankDetails = async (data: AddProviderBankDetails) => {
  const response = await axiosInstance.post(
    `/providers/provider-bank/bank/`,
    data
  );

  return response;
};

const useAddProviderBankDetails = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  AddProviderBankDetails
> => {
  const queryClient = useQueryClient();
  return useMutation(providerBankDetails, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('bankdetails')
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useAddProviderBankDetails;
