import {useMutation, useQueryClient, UseMutationResult} from 'react-query';
import {useNavigate} from 'react-router-dom';

import axiosInstance from './AxiosInstance';
interface LocationInfo {
  longitude: number | null;
  latitude: number | null;
  country_code: string | null;
  google_place_id: string | null;
  state: string | null;
  city: string | null;
  street: string | null;
  postal_code: string | null;
  region: string | null;
}

interface CreateProviderParams {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  company_name: string;
  password: string;
  code: string;
  location: LocationInfo;
}
type Data = {
  errors: string;
  error_code: string;
};
type Error = {
  data: Data;
};
type Response = {
  response: Error;
};

const createProvider = async (data: CreateProviderParams) => {
  const phone_number = `${data.phone_number}`;

  // Conditionally include city and street, and remove postal_code if it's null
  const location =
    data.location.postal_code === null ||
    data.location.street === null ||
    data.location.city === null
      ? (({postal_code, street, city, ...rest}: LocationInfo) => rest)(
          data.location
        )
      : {...data.location};

  const newData = {...data, phone_number, location};
  const response = await axiosInstance.post(`/providers/`, newData);

  return response;
};

const useCreateProvider = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Response,
  CreateProviderParams
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(createProvider, {
    onSuccess: () => {
      queryClient
        .invalidateQueries('users')
        .then(() => navigate('/onboarding/thank-you'))
        .catch((error: Response) => {
          throw new Response(error.response.data.errors);
        });
    },
  });
};

export default useCreateProvider;
