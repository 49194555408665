import {useParams} from 'react-router-dom';

import HeroSection from '../../Components/LandingPage/HeroSection';
import Navbar from '../../Components/LandingPage/Navbar';
import {supportBlogs} from '../Support';

const Blog = () => {
  const urlParams = useParams();

  const blog = supportBlogs?.filter(
    (blog) => blog.slug === urlParams?.slug
  )?.[0];

  return (
    <div className="main-container bg-gray-100 ">
      <Navbar />
      <HeroSection currentPage="blog" textOverride={blog?.title} />

      <div className="container-max-width">
        <div className="flex flex-col space-y-2 justify-center items-center mb-20">
          <div className="w-full bg-white p-4 rounded-lg shadow-md">
            {/* <div className="text-2xl font-bold mb-4">{blog?.title}</div> */}
            <div className="text-gray-600 text-base">
              <div
                className="mt-4 blog-content"
                dangerouslySetInnerHTML={{
                  __html: blog.content ?? '',
                }}
              />
              {/* {blog?.content} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
