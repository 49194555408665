// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import axiosInstance from '../AxiosInstance';
type User = {
  first_name: string;
  last_name: string;
  email: string;
};
type Country = {
  name: string;
};
type Location = {
  region: string;
  country: Country;
  city: string;
  state: string;
  postal_code: string;
  latitude: string;
  longitude: string;
};
type Document = {
  file_name: string;
  file: File | null;
  file_type: string;
  original_file_name: string;
  url: string;
  expiry_date: string;
  document_type: string;
};
type ContactDetails = {
  email: string;
  address: string;
  postal_code: string;
  town: string;
  next_of_kin_email: string;
  next_of_kin_name: string;
  next_of_kin_phone_number: string;
  worker_uid: string;
  phone_number: string;
};

type PersonalDetails = {
  pronoun: string;
  phone_number: string;
  nationality: string;
  marital_status: string;
  date_of_birth: string;
  is_student: boolean;
  national_insurance: string;
  criminal_conviction_comment: string;
  has_criminal_conviction: boolean;
  has_medical_condition: boolean;
  medical_condition: string;
  languages: string[];
  opt_in: boolean;
};
type WorkExperience = {
  job_title: string;
  company: string;
  start_date: string;
  end_date: string;
};
type BankDetails = {
  bank_name: string;
  account_name: string;
  account_number: string;
  sort_code: string;
};
type WorkerLog = {
  status: string;
  comment: string;
};

type Industry = {
  name: string;
  uid: string;
};

type Role = {
  uid: string;
  name: string;
  deleted_at: string | undefined | null;
  is_active: boolean;
  created_at: string;
  updated_at: string;
};

type Data = {
  missing_information: string[];
  uid: string;
  user: User;
  worker_personal_details: PersonalDetails;
  worker_experience: WorkExperience[];
  bank_details: BankDetails;
  date_of_birth: string;
  contact_details: ContactDetails;
  worker_documents: Document[];
  agency: string;
  worker_log: WorkerLog[];
  status: string;
  rating: string;
  employment_status: string;
  worker_strikes: WorkerStrikesData[];
  industries: Industry[];
  roles: Role[];
  portifolio_url: string;
  location: Location;
};

type WorkerStrikesData = {
  commet: string;
  created_at: string;
  deleted_at: string;
  is_active: boolean;
  no_of_strikes: number;
  uid: string;
  updated_at: string;
  worker: string;
};

export interface ApiResponse {
  // Define the structure of your API response
  // Example: { data: YourDataType }
  Worker: Data;
}

const fetchData = async (id: string): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      `/agency/workers/${id}/`
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetWorker = (id: string): UseQueryResult<ApiResponse, Error> => {
  return useQuery(['worker', id], () => fetchData(id), {
    cacheTime: 1000 * 60 * 1,
    refetchOnMount: true,
  });
};

export default useGetWorker;
