import {useEffect, useState} from 'react';
import {
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import OnBoardingLayout from '../../Layouts/OnBoardingLayout';
import useGetAgency from '../../hooks/services/Agencys/useGetAgency';
import useIndustriesData from '../../hooks/services/common/useGetIndustries';
import useGetRoles from '../../hooks/services/common/useGetRoles';
import Spinner from '../Common/Spinner';
import SubmitButton from '../Common/SubmitButton';

type FormData = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  persona: string;
  experience: string;
  industries: string[];
  roles: string[];
  portifolio_url: string;
};

type Props = {
  formData: FormData;
  handleNext: () => void;
  step: number;
  handlePrevious: () => void;
  handleCitizenChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleIndustryToggle: (industry: string) => void;
  handleRolesToggle: (role: string) => void;
  isLoading: boolean;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FormData>;
  watch: (name: keyof FormData) => string | undefined;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  id: string;
};
const WorkExperience = ({
  formData,
  handleNext,
  step,
  handlePrevious,
  handleCitizenChange,
  handleIndustryToggle,
  handleRolesToggle,
  isLoading,
  register,
  errors,
  watch,
  setValue,
  getValues,
  id,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [industriesArray, setIndustryArray] = useState([]);
  const {data: agency, isLoading: agencyLoading} = useGetAgency(id);
  const {data: industries, isLoading: industriesLoading} = useIndustriesData();
  const {data: roles, isLoading: rolesLoading} = useGetRoles({
    uid: agency?.results[0].industry.uid,
  });
  const uniqueRoles = Array.from(
    new Set(
      formData.industries.flatMap((industry) =>
        roles?.results.filter((role) => role.industry === industry)
      )
    )
  );
  useEffect(() => {
    let industriesArray_ = watch('industries') || [];
    if (!Array.isArray(industriesArray_)) {
      industriesArray_ = [];
    }
    if (agency?.results?.[0]?.industry?.uid) {
      setValue('industries', [agency?.results[0].industry.uid]);
    }
    setIndustryArray(industriesArray_);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, agency, watch('industries')]);

  if (industriesLoading || rolesLoading || agencyLoading) return <Spinner />;
  return (
    <OnBoardingLayout
      handlePrevious={handlePrevious}
      step={step}
      title="Work Experience questions">
      {id !== process.env.REACT_APP_OUTERBOX_ID && (
        <div className={`form-group${errors?.experience ? ' error' : ''}`}>
          <label htmlFor="permit" className="form-label mt-5">
            Do you have any work experience? <span>*</span>
          </label>
          <div className="radio-inputs-holder">
            <div className="radio-input">
              <input
                type="radio"
                {...register('experience', {
                  required: 'Please select an option',
                })}
                value="yes"
                className="form-input radio"
              />
              <span>Yes</span>
            </div>

            <div className="radio-input">
              <input
                type="radio"
                {...register('experience', {
                  required: 'Please select an option',
                })}
                value="no"
                className="form-input radio"
              />
              <span>No</span>
            </div>
          </div>

          {errors?.experience && (
            <span className="text-red-500 text-sm">
              {String(errors?.experience?.message)}
            </span>
          )}
        </div>
      )}

      {/* {console.log(watch('experience'))} */}
      {id !== process.env.REACT_APP_OUTERBOX_ID ? (
        <>
          {/* {watch('experience') === 'yes' && (
            <>
              <div className="form-group">
                <label htmlFor="industries" className="form-label">
                  What industries have you worked in?
                </label>

                <div className="checkbox-holder">
                  {industries?.industries.map((industry) => (
                    <label key={industry.uid} className="checkbox-input">
                      <input
                        type="checkbox"
                        {...register('industries', {
                          required: 'Please select at least one industry',
                        })}
                        value={industry.uid}
                        // checked={watch('industries')?.includes(industry.uid)}
                        // onChange={() => handleIndustryToggle(industry.uid)}
                        className="form-input checkbox"
                      />
                      <span>{industry.name}</span>
                    </label>
                  ))}
                </div>

                {errors?.industries && (
                  <span className="text-red-500 text-sm">
                    {String(errors?.industries?.message)}
                  </span>
                )}
              </div>
            </>
          )} */}
          {(watch('industries') ?? []).length > 0 &&
            watch('experience') === 'yes' &&
            getRolesByIndustry(roles?.results, industriesArray).length > 0 && (
              <>
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    What roles have you worked?
                  </label>
                  <div className="checkbox-holder">
                    {getRolesByIndustry(roles?.results, industriesArray).map(
                      (role) => (
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
                        <label key={role?.uid} className="checkbox-input">
                          <input
                            type="checkbox"
                            {...register('roles', {
                              required: 'Please select at least one role',
                            })}
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                            value={role?.uid}
                            className="form-input checkbox"
                          />
                          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                          <span>{role?.name}</span>
                        </label>
                      )
                    )}
                  </div>
                </div>

                {errors?.roles && (
                  <span className="text-red-500 text-sm">
                    {String(errors?.roles?.message)}
                  </span>
                )}
              </>
            )}
        </>
      ) : (
        <>
          <div
            className={`form-group${errors?.portifolio_url ? ' error' : ''}`}>
            <label htmlFor="password" className="form-label">
              What's your portfolio URL?
            </label>

            <input
              type="text"
              id="password"
              {...register('portifolio_url', {
                required: 'Please enter a portfolio url',
              })}
              placeholder="https://www.google.com/"
              className="form-input"
            />
            {errors?.portifolio_url && (
              <span className="text-red-500 text-sm">
                {String(errors?.portifolio_url?.message)}
              </span>
            )}
          </div>
          {/* <div className={`form-group${errors?.password ? ' error' : ''}`}>
            <label htmlFor="password" className="form-label">
              Upload your portfolio
            </label>

            <input
              type="file"
              id="password"
              {...register('portfolio_file')}
              placeholder="Password"
              className="form-input"
            />
          </div> */}

          <>
            <div className="form-group mt-4">
              <label htmlFor="" className="form-label">
                Which role best aligns with what you do? (select one)
              </label>
              <div className="checkbox-holder">
                {roles?.results.map((role) => (
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
                  <label key={role?.uid} className="checkbox-input">
                    <input
                      type="radio"
                      {...register('roles', {
                        required: 'Please select at least one role',
                      })}
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                      value={role?.uid}
                      className="form-input checkbox"
                    />
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                    <span>{role?.name}</span>
                  </label>
                ))}
              </div>
            </div>

            {errors?.roles && (
              <span className="text-red-500 text-sm">
                {String(errors?.roles?.message)}
              </span>
            )}
          </>
        </>
      )}

      {/* <div className="mt-6">
        <SubmitButton
          onClick={handleNext}
          buttonLabel="Submit"
          isLoading={isLoading}
        />
      </div> */}
    </OnBoardingLayout>
  );
};

export default WorkExperience;

function getRolesByIndustry(roles: any, industryIds: string[]) {
  const matchingRoles = [];
  console.log(industryIds);
  for (const role of roles) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (industryIds.includes(role.industry as string)) {
      matchingRoles.push(role);
    }
  }

  // Return the array of matching roles
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return matchingRoles;
}
