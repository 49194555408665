import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../../Components/Common/Spinner';
import SubmitSpinner from '../../../Components/Common/SubmitSpinner';
import Input from '../../../Components/Registration/Input';
import ErrorHandlingLayout from '../../../Layouts/ErrorHandlingLayout';
import useGetAgencyProfile from '../../../hooks/services/Agencys/useGetAgencyProfile';
import useCreateInvoice from '../../../hooks/services/Invoices/useCreateInvoice';
import useGetTimesheets from '../../../hooks/services/Shifts/useGetTimesheets';
import {formatCurrentDate, reverseDate} from '../../../utils/Methods';

type Props = {
  invoicing?: boolean;
};
const TimeSheets = ({invoicing}: Props) => {
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile();
  const {mutateAsync, isLoading: invoiceLoading, error} = useCreateInvoice();
  const [isError, setIsError] = useState(false);

  const onCloseError = () => setIsError(false);

  const navigate = useNavigate();
  const currentDate = new Date();
  const formattedDate = formatCurrentDate(currentDate);
  const [inputDate, setInputDate] = useState<string>(formattedDate);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputDate(event.target.value);
  };
  const [shifts, setShifts] = useState<string[]>([]);
  const [provider, setProvider] = useState('');
  const toggleCheckbox = (id: string, providerId: string) => {
    setShifts((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    setProvider(providerId);
  };
  // eslint-disable-next-line
  const {data, isLoading} = useGetTimesheets(
    profile !== undefined ? profile.uid : '',
    'agency_shift_allocation__agency',
    reverseDate(inputDate)
  );
  if (profileLoading || isLoading) {
    return <Spinner />;
  }
  const onCreateInvoice = async () => {
    const post = {
      timesheet_uids: shifts,
      invoice_date: formatCurrentDate(new Date(Date.now())),
      provider: provider,
      agency: profile !== undefined ? profile.uid : '',
    };
    await mutateAsync(post).catch(() => setIsError(true));
  };
  return (
    <>
      <ErrorHandlingLayout
        isOpen={isError}
        onClose={onCloseError}
        title={error !== null ? error.response.data.error_code : ''}>
        <p className="mt-2 text-center">
          {error !== null && error.response.data.errors}
        </p>
      </ErrorHandlingLayout>
      <div className="flex justify-end">
        <div className="w-1/4">
          <Input
            inputValue={inputDate}
            name="date"
            type="date"
            label="Select date"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="flex justify-between items-center text-xs space-x-2 mt-4 mb-6">
        <div className="mt-2">
          {shifts.length > 0 && invoicing && (
            <div className="mt-6 flex space-x-4">
              <button
                onClick={onCreateInvoice}
                className="bg-plannaPrimaryGreen text-plannaAccentGreen px-6 py-2 rounded-full">
                {invoiceLoading ? (
                  <SubmitSpinner />
                ) : (
                  'Create invoice(s) for timesheet(s) selected'
                )}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                  <tr>
                    {invoicing && (
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                        Actions
                      </th>
                    )}
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Active
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Job Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Provider
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Locked
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Action
                    </th>
                  </tr>
                </thead>
                {/* eslint-disable-next-line */}
                {data?.results.map((shift) => (
                  // eslint-disable-next-line
                  <tbody key={shift.uid} className="divide-y divide-gray-200">
                    <tr>
                      {invoicing && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          <input
                            type="checkbox"
                            className="accent-plannaPrimaryGreen h-4 w-4"
                            // eslint-disable-next-line
                            onChange={() =>
                              toggleCheckbox(
                                shift.uid,
                                // eslint-disable-next-line
                                shift.agency_shift_allocation.shift_template
                                  .provider.uid
                              )
                            }
                          />
                        </td>
                      )}
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.date}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.is_active === true ? 'Active' : 'Not active'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.status.toLowerCase()}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {
                          shift.agency_shift_allocation.shift_template
                            .job_template.template_name
                        }
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {
                          shift.agency_shift_allocation.shift_template.provider
                            .company.name
                        }
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.is_locked === true ? 'Locked' : 'Not locked'}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        <div className="flex space-x-3">
                          {/* eslint-disable-next-line */}
                          <button
                            onClick={() =>
                              // eslint-disable-next-line
                              navigate(`/agency-daily-shifts/${shift.uid}`)
                            }
                            className="px-6 py-2 bg-plannaPrimaryGreen text-plannaAccentGreen rounded-full">
                            View details
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeSheets;
