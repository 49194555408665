import React from 'react';
import {useNavigate} from 'react-router-dom';

type Button = {
  label: string;
};
const TemplateCancelButton = ({label}: Button) => {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      onClick={() => navigate(-1)}
      className=" bg-plannaNeutralGrey text-center text-plannaPrimaryGreen text-lg font-hanken-grotesk font-semibold w-full px-6 py-2.5 rounded-full">
      {label}
    </button>
  );
};

export default TemplateCancelButton;
