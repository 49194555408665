/* eslint-disable @typescript-eslint/restrict-template-expressions */
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';

const AgencyHome = () => {
  const {data: agencyData} = useGetAgencyProfile();

  const iframeUrl = `https://lookerstudio.google.com/embed/u/0/reporting/91c7e062-2713-45c2-a9fa-9dd3b232866b/page/uwUwD?params=%7B%22df17%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${agencyData?.uid}%22%7D`;
  return (
    <div className="p-4">
      <div className="p-4">
        <iframe
          className="w-full"
          height="1800"
          src={iframeUrl}
          frameBorder="0"
          style={{border: '0'}}
          allowFullScreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        />
      </div>
    </div>
  );
};

export default AgencyHome;
