import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import AllocateWorker from './Components/AllocateWorker';
import Spinner from '../../Components/Common/Spinner';
import TextArea from '../../Components/Common/TextArea';
import Input from '../../Components/Registration/Input';
import DialogLayout from '../../Layouts/DialogLayout';
import Profile from '../../assets/icons/Avatar.svg';
import circularBack from '../../assets/icons/Circular-Backward.png';
import useGetSingleWorkerShifts from '../../hooks/services/Agencys/useGetSingleWorkerAllocation';

const ViewSingleWorkerAllocation = () => {
  const navigate = useNavigate();
  const params = useParams();
  //esint-disable-next-line
  const {data, isLoading} = useGetSingleWorkerShifts({
    uid: params.id !== undefined ? params.id : '',
  });
  const [isAllocateWorker, setIsAllocateWorker] = useState(false);
  const agencyAllocation = data?.data.agency_shift_allocation;
  const location = agencyAllocation?.shift_template.location.region;
  const country = agencyAllocation?.shift_template.location.country.name;
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <img
        onClick={() => navigate(-1)}
        src={circularBack}
        alt="back"
        className="h-12 w-12 mt-4 ml-4  cursor-pointer"
      />
      {/* <DialogLayout
        isOpen={isAllocateWorker}
        onClose={() => setIsAllocateWorker(false)}>
        <AllocateWorker
          shiftId={params.id !== undefined ? params.id : ''}
          handleCloseTemplate={() => setIsAllocateWorker(false)}
          start_date={
            agencyAllocation !== undefined
              ? agencyAllocation.shift_template.start_date
              : ''
          }
          location_id={
            agencyAllocation !== undefined
              ? agencyAllocation.shift_template.location.uid
              : ''
          }
          recurrence={
            agencyAllocation !== undefined
              ? agencyAllocation.shift_template.recurrence
              : ''
          }
        />
      </DialogLayout> */}

      <div className="p-8">
        <div className="flex items-center space-x-6">
          <img src={Profile} alt="avatar" className="h-fit w-fit" />
          <div className="flex flex-col">
            <h1 className="text-2xl font-bold text-plannaPrimaryGreen">
              {agencyAllocation?.shift_template.provider.company.name}
            </h1>
            <div className="flex space-x-6 mt-4">
              <div className="flex flex-col">
                <h1 className="text-lg border-b w-fit text-plannaSecondaryGreen50 border-plannaSecondaryGreen50">
                  Email
                </h1>
                <p className="text-lg mt-1 text-plannaPrimaryGreen">
                  {agencyAllocation?.shift_template.provider.email}
                </p>
              </div>
              <div className="flex flex-col">
                <h1 className="text-lg border-b w-fit text-plannaSecondaryGreen50 border-plannaSecondaryGreen50">
                  Mobile
                </h1>
                <p className="text-lg mt-1 text-plannaPrimaryGreen">
                  {agencyAllocation?.shift_template.provider.phone_number}
                </p>
              </div>
              <div className="flex flex-col">
                <h1 className="text-lg text-plannaPrimaryGreen ">Location</h1>
                <span className="mt-1 bg-plannaSecondaryGreen50 text-white   text-center text-sm  px-6 py-1 rounded-full w-fit">
                  {/* eslint-disable-next-line */}
                  {agencyAllocation?.shift_template.provider.location.region},
                  {/* eslint-disable-next-line */}
                  {
                    agencyAllocation?.shift_template.provider.location.country
                      .name
                  }
                </span>
              </div>
            </div>
            <div className="flex space-x-6 mt-4">
              <div className="flex flex-col">
                <h1 className="text-lg ml-2 text-plannaPrimaryGreen ">
                  Action
                </h1>
                <div className="flex space-x-2 mt-1">
                  <button
                    onClick={() => setIsAllocateWorker(true)}
                    className="bg-plannaPrimaryGreen mt-1 text-plannaAccentGreen  font-hanken-grotesk   px-6 py-1 rounded-full text-center">
                    Allocate more Workers
                  </button>
                  <button
                    onClick={() =>
                      navigate(
                        `/agency/single-worker/${
                          data !== undefined ? data.data.worker.uid : ''
                        }`
                      )
                    }
                    className=" bg-plannaNeutralGrey mt-1 text-plannaPrimaryGreen  font-hanken-grotesk   px-6 py-1 rounded-full text-center">
                    View Worker
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b mt-4 border-plannaNeutral w-full" />
        <h1 className="mt-16 text-plannaPrimaryGreen text-xl font-bold font-space-grotesk">
          Job Template
        </h1>
        <div className="w-10/12 mt-14">
          <div className="flex justify-between">
            <Input
              name=""
              inputValue={
                agencyAllocation?.shift_template.job_template.template_name
              }
              type="text"
              label="Job name"
              readonly
            />
            <Input
              name=""
              inputValue={
                agencyAllocation?.shift_template.job_template.required_uniform
                  .color
              }
              type="text"
              label="Uniform color"
              readonly
            />
          </div>
          <div className="flex justify-between mt-7">
            <Input
              name=""
              inputValue={
                agencyAllocation?.shift_template.job_template.required_uniform
                  .style
              }
              type="text"
              label="Uniform style"
              readonly
            />
            <Input
              name=""
              inputValue={
                agencyAllocation?.shift_template.job_template.required_uniform
                  .color
              }
              type="text"
              label="Uniform color"
              readonly
            />
          </div>
          <div className="flex mt-7 justify-between">
            <TextArea
              name="description"
              value={
                agencyAllocation?.shift_template.job_template.required_uniform
                  .description
              }
              label="Uniform description"
              readonly
            />
            <TextArea
              name="description"
              value={
                agencyAllocation?.shift_template.job_template.job_description
              }
              label="Job description"
              readonly
            />
          </div>
          <div className="flex mt-7 justify-between">
            <div className="w-96">
              <label
                htmlFor="inputField"
                className="block mb-3 text-sm font-medium text-plannaPrimaryGreen">
                Health and Safety
              </label>
              <textarea
                className="w-full border border-plannaNeutral rounded-xl py-2 px-3 text-plannaPrimaryGreen resize-none"
                //eslint-disable-next-line
                value={agencyAllocation?.shift_template.job_template.wellness}
                readOnly
              />
            </div>
          </div>
        </div>
        <h1 className="mt-16 text-plannaPrimaryGreen text-xl font-bold font-space-grotesk">
          Shift Template
        </h1>
        <div className="w-10/12 mt-14">
          <div className="flex justify-between">
            <Input
              name=""
              inputValue={agencyAllocation?.shift_template.name}
              type="text"
              label="Shift name"
              readonly
            />
            <Input
              name=""
              inputValue={agencyAllocation?.shift_template.worker_count}
              type="text"
              label="Worker count"
              readonly
            />
          </div>
          <div className="flex justify-between mt-7">
            <Input
              name=""
              inputValue={agencyAllocation?.shift_template.start_date}
              type="date"
              label="Start date"
              readonly
            />
            <Input
              name=""
              inputValue={location}
              type="text"
              label="Shift location"
              readonly
            />
          </div>
          <div className="flex justify-between mt-7">
            <Input
              name=""
              inputValue={agencyAllocation?.shift_template.start_time}
              type="time"
              label="Start time"
              readonly
            />
            <Input
              name=""
              inputValue={agencyAllocation?.shift_template.end_time}
              type="time"
              label="End time"
              readonly
            />
          </div>
          <div className="flex mt-7 justify-between">
            <div className="w-96">
              <label
                htmlFor="inputField"
                className="block mb-3 text-sm font-medium text-plannaPrimaryGreen">
                Shift description
              </label>
              <textarea
                className="w-full border border-plannaNeutral rounded-xl py-2 px-3 text-plannaPrimaryGreen resize-none"
                value={
                  agencyAllocation?.shift_template.job_template.job_description
                }
                readOnly
              />
            </div>
          </div>
        </div>
        <h1 className="mt-16 text-plannaPrimaryGreen text-xl font-bold font-space-grotesk">
          Rate
        </h1>
        <div className="w-10/12 mt-14">
          <div className="flex justify-between">
            <Input
              name=""
              inputValue={agencyAllocation?.rate.rate_name.toLowerCase()}
              type="text"
              label="Rate name"
              readonly
            />
            <Input
              name=""
              inputValue={agencyAllocation?.rate.rate_type.toLowerCase()}
              type="text"
              label="Rate type"
              readonly
            />
          </div>
          <div className="flex justify-between mt-7">
            <Input
              name=""
              inputValue={agencyAllocation?.rate.base_rate}
              type="text"
              label={
                country === 'Kenya' ? 'Base rate (Kes)' : 'Base rate (Gbp)'
              }
              readonly
            />
            <Input
              name=""
              // eslint-disable-next-line
              inputValue={agencyAllocation?.rate.rate_duration_type.toLowerCase()}
              type="text"
              label="Rate duration type"
              readonly
            />
          </div>
          <div className="flex justify-between mt-7">
            <Input
              name=""
              // eslint-disable-next-line
              inputValue={agencyAllocation?.margin_type.toLowerCase()}
              type="text"
              label="Rate duration type"
              readonly
            />

            <Input
              inputValue={
                // eslint-disable-next-line
                agencyAllocation?.margin
              }
              type="number"
              readonly
              label={
                agencyAllocation?.margin_type === 'PERCENTAGE'
                  ? 'Enter a margin (%)'
                  : country === 'Kenya'
                    ? 'Enter a margin(Kes)'
                    : 'Enter a margin (Gbp)'
              }
              required
              name="margin"
              min="0"
            />
          </div>
          <div className="flex justify-between mt-7">
            <Input
              inputValue={agencyAllocation?.employers_ni}
              type="number"
              label="Employers NI (%)"
              required
              name="employers_ni"
              readonly
            />
            <Input
              inputValue={agencyAllocation?.holiday_pay}
              type="number"
              label="Holiday pay (%)"
              required
              name="holiday_pay"
              readonly
            />
          </div>
          <div className="flex justify-between mt-7">
            <Input
              inputValue={
                // eslint-disable-next-line
                agencyAllocation?.pension_contribution
              }
              type="number"
              label="Enter pension contribution (%)"
              name="pension_contribution"
              readonly
              min="0"
            />
            <Input
              inputValue={
                // eslint-disable-next-line
                agencyAllocation?.other_costs
              }
              type="number"
              label={
                country === 'Kenya' ? 'Other costs (Kes)' : 'Other costs (Gbp)'
              }
              readonly
              name="other_costs"
              min="0"
            />
          </div>
          <div className="flex justify-between mt-7">
            <Input
              inputValue={agencyAllocation?.rate.base_rate}
              type="number"
              label={country === 'Kenya' ? 'Pay rate (Kes)' : 'Pay rate (Gbp)'}
              name="pension_contribution"
            />

            <Input
              type="number"
              inputValue={
                // eslint-disable-next-line
                agencyAllocation?.charge_rate
              }
              placeholder="8"
              readonly
              min="0"
              label={
                country === 'Kenya' ? 'Charge rate (Kes)' : 'Charge rate (Gbp)'
              }
              name="other_costs"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewSingleWorkerAllocation;
