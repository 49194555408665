import React from 'react';

import ErrorSvg from '../assets/icons/Error';

type DialogProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
};
const ErrorHandlingLayout = ({
  isOpen,
  onClose,
  children,
  title,
}: DialogProps) => {
  if (!isOpen) return null;
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex  items-center justify-center min-h-screen">
        <div className="fixed inset-0 bg-black opacity-50" />
        <div className="z-20 lg:w-5/12 w-full mt-4 mb-4 bg-white p-6 border border-plannaPrimaryGreen rounded-lg shadow-lg">
          <div className="flex justify-end">
            <button
              className=" text-plannaTertiaryRed hover:text-black"
              onClick={onClose}>
              X
            </button>
          </div>
          <div className="flex flex-col justify-center items-center">
            <ErrorSvg />
            <h1 className="text-lg font-semibold text-plannaTertiaryRed">
              {title}
            </h1>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorHandlingLayout;
