import React from 'react';

import {capitalizeFirstLetter} from '../../../utils/Methods';

type Props = {
  inputValue: string | number | undefined;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label: string;
  name: string;
  options: string[];
  required?: boolean;
  readonly?: boolean;
};

const TemplateSelect = ({
  inputValue,
  onChange,
  label,
  name,
  options,
  required,
  readonly,
}: Props) => {
  return (
    <div className=" w-full">
      <label
        htmlFor="inputField"
        className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
        {label}
        {required && (
          <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
            *
          </span>
        )}
      </label>
      <select
        aria-readonly={readonly}
        required={required}
        value={inputValue}
        onChange={onChange}
        name={name}
        className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-2 px-3 focus:outline-none">
        <option selected>Select</option>
        {options.map((option) => {
          return (
            <option key={option} value={option}>
              {capitalizeFirstLetter(option.toLowerCase())}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default TemplateSelect;
