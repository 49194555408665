// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CalendarComponent.css */

.fc-header-toolbar button {
  background-color: #007bff;
  /* Set button background color */
  color: #ffffff;
  /* Set button text color */
  border-color: #007bff;
  /* Set button border color */
}

.fc-header-toolbar button:hover {
  background-color: #0056b3;
  /* Set button background color on hover */
  border-color: #0056b3;
  /* Set button border color on hover */
}
`, "",{"version":3,"sources":["webpack://./src/Screens/Shifts/styles.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B;EACE,yBAAyB;EACzB,gCAAgC;EAChC,cAAc;EACd,0BAA0B;EAC1B,qBAAqB;EACrB,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;EACzB,yCAAyC;EACzC,qBAAqB;EACrB,qCAAqC;AACvC","sourcesContent":["/* CalendarComponent.css */\n\n.fc-header-toolbar button {\n  background-color: #007bff;\n  /* Set button background color */\n  color: #ffffff;\n  /* Set button text color */\n  border-color: #007bff;\n  /* Set button border color */\n}\n\n.fc-header-toolbar button:hover {\n  background-color: #0056b3;\n  /* Set button background color on hover */\n  border-color: #0056b3;\n  /* Set button border color on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
