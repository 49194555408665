/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {motion} from 'framer-motion';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import backIcon from '../../assets/icons/Circular-Backward.png';
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';
import useLocationsData from '../../hooks/services/common/useGetLocations';
import {getLocationInfo, roundTo6DecimalPlaces} from '../../utils/Methods';
import RQCreateAgencyUser from '../../utils/Queries/RQCreateAgencyUser';

const AddAgencyUser = () => {
  const {
    register,
    clearErrors,
    watch,
    setValue,
    handleSubmit,
    formState: {errors},
  } = useForm();
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [placeId, setPlaceId] = useState('');
  const [placeInfo, setPlaceInfo] = useState({});
  const navigate = useNavigate();
  const {data: agencyData, isLoading} = useGetAgencyProfile();
  const {data: locations, isLoading: locationsLoading} = useLocationsData();

  const onSuccess = (data: any) => {
    navigate('/agency/users');
  };
  const onError = (data: any) => {
    console.log(data);
  };

  const {mutate: createProvider, isLoading: isCreatingProvider} =
    RQCreateAgencyUser(onSuccess, onError);

  const onSubmit = (data: any) => {
    // console.log(providerObject(data, placeInfo, agencyData));
    createProvider(providerObject(data, placeInfo, agencyData));
  };

  const handleSelect = (place: any) => {
    const geocoder = new window.google.maps.Geocoder();
    setValue('location', place.value.description);

    geocoder.geocode(
      {placeId: place.value.place_id},
      (results: any, status: any) => {
        if (status === 'OK' && results[0]) {
          const {lat, lng} = results[0].geometry.location;
          setLatitude(parseFloat(lat()));
          setLongitude(parseFloat(lng()));
          setPlaceId(place.value.place_id);
        }
      }
    );
  };

  const getGeoData = () => {
    getLocationInfo(latitude, longitude)
      .then((locationInfo) => {
        setPlaceInfo((prevPlaceInfo) => ({
          ...prevPlaceInfo,
          longitude: roundTo6DecimalPlaces(longitude),
          latitude: roundTo6DecimalPlaces(latitude),
          country_code: locationInfo.countryCode,
          google_place_id: placeId,
          state: locationInfo.state,
          city: locationInfo.city,
          postal_code: locationInfo.postalCode || 'not set',
          region: locationInfo.region,
          street: locationInfo.street,
        }));
      })
      .catch((errr: string) => {
        return errr;
      });
  };

  useEffect(() => {
    clearErrors('location');
    getGeoData();
  }, [latitude, longitude, placeId]);

  if (isCreatingProvider || isLoading || locationsLoading) {
    return <Spinner />;
  }

  const superAdminRoles = [
    'Can View Financial Reports',
    'Can Prepare/View invoices',
    'Can View Timesheet',
    'Can view Workers',
    'Can create job templates',
    'Can create shift templates',
    'Can view shift template',
    'Can Block worker',
  ];
  const adminRoles = [
    'Can view Workers',
    'Can create job templates',
    'Can create shift templates',
    'Can view shift template',
    'Can Block worker',
  ];
  const userRoles = ['Can only view'];
  return (
    <div className="seeker-profile-container">
      <div className="profile-max-width">
        <div className="profile-header">
          <div className="back-icon mt-7" onClick={() => navigate(-1)}>
            <img src={backIcon} alt="" />
          </div>
          <div className="col">
            <h1 className="profile-title">Agency Users Onboarding</h1>
            <p className="profile-sub-title">
              Fill out the form below to onboard a user{' '}
            </p>
          </div>
        </div>
      </div>

      <div className="profile-body">
        <form className="form-action" onSubmit={handleSubmit(onSubmit)}>
          <div className="worker-details">
            <div className="max-width">
              <motion.div
                initial={{
                  height: 'auto',
                }}
                animate={{
                  height: 'auto',
                }}
                transition={{duration: 0.1}}
                className="details-card no-overflow">
                <div className="dropdown-holder active">
                  Personal Information
                </div>
                <div className="editable-form p-2">
                  <div className="split-form-group">
                    <div
                      className={`form-group${
                        errors.firstname ? ' error' : ''
                      }`}>
                      <label htmlFor="" className="form-label">
                        Firstname <span>*</span>
                      </label>
                      <input
                        type="text"
                        {...register('firstname', {
                          required: 'Firstname is required',
                        })}
                        className="form-input"
                      />
                      {errors.firstname && (
                        <span className="text-red-500 text-sm">
                          {String(errors?.firstname?.message)}
                        </span>
                      )}
                    </div>
                    <div
                      className={`form-group${
                        errors.lastname ? ' error' : ''
                      }`}>
                      <label htmlFor="" className="form-label">
                        Lastname <span>*</span>
                      </label>
                      <input
                        {...register('lastname', {
                          required: 'Lastname is required',
                        })}
                        type="text"
                        className="form-input"
                      />
                      {errors.lastname && (
                        <span className="text-red-500 text-sm">
                          {String(errors?.lastname?.message)}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="split-form-group">
                    <div
                      className={`form-group${errors.email ? ' error' : ''}`}>
                      <label htmlFor="" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        {...register('email', {
                          required: 'Email is required',
                        })}
                        className="form-input"
                      />
                      {errors.email && (
                        <span className="text-red-500 text-sm">
                          {String(errors?.email?.message)}
                        </span>
                      )}
                    </div>
                    <div
                      className={`form-group${
                        errors.password ? ' error' : ''
                      }`}>
                      <label htmlFor="" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        {...register('password', {
                          required: 'password is required',
                        })}
                        className="form-input"
                      />
                      {errors.password && (
                        <span className="text-red-500 text-sm">
                          {String(errors?.password?.message)}
                        </span>
                      )}
                    </div>
                  </div>

                  <div>
                    <div className=" w-full">
                      <label
                        htmlFor="inputField"
                        className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                        Location
                        <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                          *
                        </span>
                      </label>
                      <select
                        required
                        {...register('location', {
                          required: 'Location is required',
                        })}
                        className={`mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border ${
                          errors.location ? ' error' : ''
                        } border-plannaNeutral py-2 px-3 focus:outline-none`}>
                        <option selected>Select location</option>
                        {locations?.locations.map((location) => {
                          return (
                            <option key={location.uid} value={location.uid}>
                              {location.region},{location.country.name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.location && (
                        <span className="text-red-500 text-sm">
                          {String(errors?.location?.message)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{
                  height: 'auto',
                }}
                animate={{
                  height: 'auto',
                }}
                transition={{duration: 0.1}}
                className="details-card">
                <div className="dropdown-holder active">
                  Roles and Permissions
                </div>
                <div className="flex justify-between space-x-2 w-full">
                  <div className="mt-4 w-full">
                    <label
                      htmlFor="inputField"
                      className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                      User role/position
                      <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                        *
                      </span>
                    </label>

                    <select
                      required
                      {...register('position', {
                        required: 'Position is required',
                      })}
                      className={`mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border ${
                        errors.position ? ' error' : ''
                      } border-plannaNeutral py-2 px-3 focus:outline-none`}>
                      <option selected>Select position</option>
                      {[
                        {name: 'Recruiter', value: 'RECRUITER'},
                        {name: 'Account Manager', value: 'ACCOUNT_MANAGER'},
                        {name: 'Manager', value: 'MANAGER'},
                      ].map((level) => {
                        return (
                          <option key={level.value} value={level.value}>
                            {level.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.position && (
                      <span className="text-red-500 text-sm">
                        {String(errors?.position?.message)}
                      </span>
                    )}
                  </div>
                  <div className="mt-4 w-full">
                    <label
                      htmlFor="inputField"
                      className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                      Permission Level
                      <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                        *
                      </span>
                    </label>
                    <select
                      required
                      {...register('user_permission', {
                        required: 'Permission is required',
                      })}
                      className={`mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border ${
                        errors.user_permission ? ' error' : ''
                      } border-plannaNeutral py-2 px-3 focus:outline-none`}>
                      <option selected>Select level</option>
                      {[
                        {name: 'Admin', value: 'ADMIN'},
                        {name: 'Super Admin', value: 'SUPER_ADMIN'},
                        {name: 'User', value: 'USER'},
                      ].map((level) => {
                        return (
                          <option key={level.value} value={level.value}>
                            {level.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.user_permission && (
                      <span className="text-red-500 text-sm">
                        {String(errors?.user_permission?.message)}
                      </span>
                    )}
                  </div>
                </div>
                <div className="mt-4 ml-2">
                  {watch('user_permission') === 'USER'
                    ? userRoles.map((role) => <p>{role}</p>)
                    : watch('user_permission') === 'SUPER_ADMIN'
                      ? superAdminRoles.map((role) => <p>{role}</p>)
                      : watch('user_permission') === 'ADMIN'
                        ? adminRoles.map((role) => <p>{role}</p>)
                        : ''}
                </div>
              </motion.div>
              <div className="form-group">
                <button className="btn primary">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAgencyUser;

const providerObject = (data: any, location: any, agencyInfo: any) => {
  return {
    first_name: data.firstname,
    last_name: data.lastname,
    email: data.email,
    location: data.location,
    user_permission: data.user_permission,
    position: data.position,
    agency: agencyInfo?.uid,
    password: data.password,
  };
};
