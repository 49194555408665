import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import worker1 from '../../assets/images/black_female_nurse.png';
import FindJobsImage from '../../assets/images/find-jobs.png';
import ProductHero from '../../assets/images/products-hero-image.png';
import worker2 from '../../assets/images/worker-2.png';
import worker3 from '../../assets/images/worker-3.png';
import worker from '../../assets/images/worker.png';

const HeroSection = ({
  currentPage,
  textOverride,
}: {
  currentPage: string;
  textOverride?: string;
}) => {
  const navigate = useNavigate();
  const [counter, setCounter] = useState(0);

  const sliders = [
    {
      title: 'Scheduling & Hiring Temp Staff Has Never Been Easier!',
      subText:
        'Planna allows you to automate the forecasting, hiring and managing of external temp staff via a single AI tool.',
      image: worker1,
    },
    {
      title: 'Scheduling & Hiring Temp Staff Has Never Been Easier!',
      subText:
        'Planna allows you to automate the forecasting, hiring and managing of external temp staff via a single AI tool.',
      image: worker3,
    },
    {
      title: 'Scheduling & Hiring Temp Staff Has Never Been Easier!',
      subText:
        'Planna allows you to automate the forecasting, hiring and managing of external temp staff via a single AI tool.',
      image: worker2,
    },
    {
      title: 'Scheduling & Hiring Temp Staff Has Never Been Easier!',
      subText:
        'Planna allows you to automate the forecasting, hiring and managing of external temp staff via a single AI tool.',
      image: worker,
    },
  ];

  const handleNext = () => {
    if (counter === sliders.length - 1) {
      setCounter(0);
      return;
    }
    setCounter(counter + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);
    return () => clearInterval(interval);
  }, [counter]);

  return (
    <div className={`hero-section ${currentPage ? currentPage : ''}`}>
      {currentPage === 'home' && (
        <>
          {sliders.map((slide, index) => (
            <div
              className={`hero-slide slide-${index + 1}${
                counter === index ? ' show' : ''
              }`}
              key={index}>
              <div className="container-max-width row">
                <div className="col center">
                  <div className="hero-content">
                    <div className="hero-text">
                      <h1 className="hero-title">
                        Scheduling & Hiring Temp Staff Has Never Been Easier!
                      </h1>
                      <div className="hero-sub-text">
                        Planna allows you to automate the forecasting, hiring
                        and managing of external temp staff via a single AI
                        tool.
                      </div>
                    </div>
                    <div className="hero-actions">
                      <div
                        className="btn primary"
                        onClick={() => navigate('/onboarding')}>
                        Get Started
                      </div>
                      <div
                        className="btn secondary"
                        onClick={() => navigate('/request-demo')}>
                        Request a demo
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="hero-image">
                    <img src={slide?.image} alt="worker" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {currentPage === 'products' && (
        <div className="container-max-width row">
          <div className="col center">
            <div className="hero-content">
              <div className="hero-text">
                <h1 className="hero-title">
                  Use smart scheduling to avoid over or under hiring!
                </h1>
                <div className="hero-sub-text">
                  Our AI assisted planning tool on average reduces time and
                  money spent on workforce planning by 20%. Giving you the
                  ability to invest more of your resources growing your
                  business.
                </div>
              </div>
              <div className="hero-actions">
                <div
                  className="btn primary"
                  onClick={() => navigate('/onboarding')}>
                  Get Started
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="hero-image">
              <img src={ProductHero} alt="worker" />
            </div>
          </div>
        </div>
      )}
      {currentPage === 'blog' && (
        <div className="container-max-width row">
          <div className="col center">
            <div className="hero-content">
              <div className="hero-text">
                <h1 className="hero-title">{textOverride}</h1>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="hero-image">
              <img src={FindJobsImage} alt="worker" />
            </div>
          </div>
        </div>
      )}
      {currentPage === 'findJobs' && (
        <div className="container-max-width row">
          <div className="col center">
            <div className="hero-content">
              <div className="hero-text">
                <h1 className="hero-title">
                  Find jobs on Planna platform heading
                </h1>
                <div className="hero-sub-text">
                  Planna allows you to easily hire care staff, manage rotas, pay
                  and retain employees all in one tool.
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="hero-image">
              <img src={FindJobsImage} alt="worker" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeroSection;
