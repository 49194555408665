/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import {useQuery} from 'react-query';
const messagingUrl = process.env.REACT_APP_MESSAGING_URL;
const RQSearchUsers = (searchTerm: string) => {
  // const getUsers = async () => {
  //   if (searchTerm !== '') {
  //     let url = '/users/';
  //     if (searchTerm) {
  //       url = `/users/?email=${searchTerm}`;
  //     }
  //     const response = await axiosInstance.get(url);

  //     return response?.data;
  //   }
  //   return null;
  // };

  const querySearch = async () => {
    if (searchTerm !== '') {
      const response = await axios.post(
        `${messagingUrl}/users/search`,
        {term: searchTerm !== '' ? searchTerm : ''},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_MESSAGING_TOKEN}`,
            credentials: true,
          },
        }
      );
      return response;
    }
    return null;
  };

  return useQuery(['searchUserMessage', searchTerm], querySearch, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 15,
    // enabled: false,
  });
};

export default RQSearchUsers;
