import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';

import Input from './Input';
import OnBoardingLayout from '../../Layouts/OnBoardingLayout';
import {Location} from '../../Screens/Onboarding/Worker';

type FormData = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  agency: string | undefined;
  day: string;
  month: string;
  year: string;
  phone_number: string;
  location: Location;
};

// type RefReturn = {
//   ref: (instance: HTMLInputElement | null) => void;
// };

type Props = {
  formData: FormData;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNext: () => void;
  step: number;
  handleSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FormData>;
  setValue: UseFormSetValue<FieldValues>;
  handleSetLocation: (place: any) => void;
  id: string;
};
const BasicDetails = ({
  formData,
  handleChange,
  step,
  handleNext,
  handleSelect,
  register,
  errors,
  setValue,
  handleSetLocation,
  id,
}: Props) => {
  return (
    <OnBoardingLayout step={step} title="Let’s get started">
      <p className="w-full mb-6 mt-4 text-plannaPrimaryGreen">
        First we need your personal information
      </p>

      <div className="split-form-group">
        <div className={`form-group ${errors?.first_name ? 'error' : ''}`}>
          <label htmlFor="" className="form-label">
            First Name <span>*</span>
          </label>
          <input
            type="text"
            {...register('first_name', {
              required: 'First name is required',
            })}
            placeholder="E.g John"
            className="form-input"
          />
          {errors?.first_name && (
            <span className="text-red-500 text-sm">
              {String(errors?.first_name?.message)}
            </span>
          )}
        </div>
        <div className={`form-group ${errors?.last_name ? 'error' : ''}`}>
          <label htmlFor="" className="form-label">
            Last Name <span>*</span>
          </label>
          <input
            type="text"
            {...register('last_name', {
              required: 'Last name is required',
            })}
            placeholder="E.g Doe"
            className="form-input"
          />
          {errors?.last_name && (
            <span className="text-red-500 text-sm">
              {String(errors?.last_name?.message)}
            </span>
          )}
        </div>
      </div>
      <div className="split-form-group">
        <div className={`form-group${errors?.phone_number ? ' error' : ''}`}>
          <label htmlFor="phone" className="form-label">
            Phone Number
          </label>

          <PhoneInput
            country="gb"
            inputProps={{
              name: 'phone_number',
              id: 'phone',
              className: 'form-phone',
              autoFocus: true,
            }}
            inputStyle={{
              width: '100%',
              borderColor: errors?.phone_number ? 'red' : '',
            }}
            containerStyle={{
              marginBottom: '1rem',
            }}
            onChange={(phone_number) => {
              setValue('phone_number', phone_number);
            }}
          />

          {errors?.phone_number && (
            <span className="text-red-500 text-sm">
              {String(errors?.phone_number?.message)}
            </span>
          )}
        </div>
      </div>

      {id !== process.env.REACT_APP_OUTERBOX_ID && (
        <div
          className={`form-group${
            errors?.day || errors?.month || errors?.year ? ' error' : ''
          }`}>
          <label htmlFor="" className="form-label">
            Date of birth <span>*</span>
          </label>
          <div className="inputs-flex">
            <select
              className="date-input"
              {...register('day', {
                required: 'Please select the day',
              })}>
              <option defaultValue="" value="">
                Day
              </option>
              {Array.from({length: 31}, (_, i) => i + 1).map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
            <select
              className="date-input"
              {...register('month', {
                required: 'Please select the month',
              })}>
              <option defaultValue="" value="">
                Month
              </option>
              {Array.from({length: 12}, (_, i) => i + 1).map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </select>
            <select
              className="date-input"
              {...register('year', {
                required: 'Please select the year',
              })}>
              <option defaultValue="" value="">
                Year
              </option>
              {Array.from(
                {length: 100},
                (_, i) => new Date().getFullYear() - i
              ).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          {errors?.day && (
            <span className="text-red-500 text-sm">
              {String(errors?.day?.message)}
            </span>
          )}
          {errors?.month && (
            <span className="text-red-500 text-sm">
              {String(errors?.month?.message)}
            </span>
          )}
          {errors?.year && (
            <span className="text-red-500 text-sm">
              {String(errors?.year?.message)}
            </span>
          )}
        </div>
      )}
      <div className={`form-group${errors?.email ? ' error' : ''}`}>
        <label htmlFor="email" className="form-label">
          Email
        </label>

        <input
          type="email"
          id="email"
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: 'Invalid email address',
            },
          })}
          placeholder="E.g example@email.com"
          className="form-input"
        />

        {errors.email && (
          <span className="text-red-500 text-sm">
            {String(errors?.email?.message)}
          </span>
        )}
      </div>
      <div className={`form-group${errors?.location ? ' error' : ''}`}>
        <label htmlFor="location" className="form-label">
          Location
        </label>
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_PLACES_KEY}
          selectProps={{
            placeholder: 'location',
            className: 'form-input',
            onChange: handleSetLocation,
          }}
          {...register('location', {
            required: 'Location is required',
          })}
          autocompletionRequest={{
            types: ['(cities)'],
          }}
          aria-describedby="basic-addon1"
        />

        {errors?.location && (
          <span className="text-red-500 text-sm">
            {String(errors?.location?.message)}
          </span>
        )}
      </div>
      <div className={`form-group${errors?.password ? ' error' : ''}`}>
        <label htmlFor="password" className="form-label">
          Password
        </label>

        <input
          type="password"
          id="password"
          {...register('password', {
            required: 'Password is required',
            minLength: {
              value: 6,
              message: 'Password must be at least 6 characters',
            },
          })}
          placeholder="Password"
          className="form-input"
        />

        {errors.password && (
          <span className="text-red-500 text-sm">
            {String(errors?.password?.message)}
          </span>
        )}
      </div>
    </OnBoardingLayout>
  );
};

export default BasicDetails;
