import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import AllocateWorker from './AllocateWorker';
import Spinner from '../../../Components/Common/Spinner';
import SubmitSpinner from '../../../Components/Common/SubmitSpinner';
import TextArea from '../../../Components/Common/TextArea';
import Input from '../../../Components/Registration/Input';
import Select from '../../../Components/Registration/Select';
import DialogLayout from '../../../Layouts/DialogLayout';
import ErrorHandlingLayout from '../../../Layouts/ErrorHandlingLayout';
import SuccessLayout from '../../../Layouts/SuccessLayout';
import Profile from '../../../assets/icons/Avatar.svg';
import circularBack from '../../../assets/icons/Circular-Backward.png';
import useAcceptShiftInvitation from '../../../hooks/services/Agencys/useAcceptShiftInvitation';
import useGetSingleAgencyAllocation from '../../../hooks/services/Agencys/useGetSingleAgencyAllocation';
import useGetWorkerShifts from '../../../hooks/services/Agencys/useGetWorkerShiftAllocations';
import useUpdateAgencyAllocations from '../../../hooks/services/Agencys/useUpdateAgencyAllocation';
import useAuthUser from '../../../hooks/services/useUserDetails';
import {calculateChargeRate} from '../../../utils/Methods';

const SingleShiftInvitation = () => {
  const {user} = useAuthUser();
  const navigate = useNavigate();
  const params = useParams();
  const {data, isLoading} = useGetSingleAgencyAllocation(
    params.id !== undefined ? params.id : ''
  );
  const {data: workerAllocations, isLoading: workerAllocationsLoading} =
    useGetWorkerShifts({
      agency_shift_allocation__uid: params.id !== undefined ? params.id : '',
    });
  const country = data?.results.shift_template.provider.location.country.name;

  const [isSuccess, setIsSuccess] = useState(false);
  const [isAllocateWorker, setIsAllocateWorker] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const [isUpdateError, setIsUpdateError] = useState(false);
  const closeError = () => setIsError(false);
  const onCloseSuccess = () => setIsSuccess(false);
  const closeUpdateError = () => setIsUpdateError(false);
  const onCloseUpdateSuccess = () => setIsUpdateSuccess(false);
  const initialState = {
    margin_type: 'PERCENTAGE',
    margin: '0',
    pension_contribution: '0',
    other_costs: '0',

    employers_ni: 15.05,
    holiday_pay: 12.07,
  };
  const [formData, setFormData] = useState(initialState);
  const [action, setAction] = useState('');
  const [chargeRateInput, setChargeRate] = useState(0);
  const {
    mutateAsync,
    isLoading: acceptingInvitation,
    error,
  } = useAcceptShiftInvitation();
  const {mutateAsync: mutateAgencyUpdate, isLoading: updateLoading} =
    useUpdateAgencyAllocations();
  useEffect(() => {
    if (data !== undefined) {
      const {chargeRate} = calculateChargeRate(
        formData.margin_type,
        formData.margin,
        parseFloat(
          data.results.rate !== null ? data.results.rate.base_rate : '0'
        ),
        formData.holiday_pay,
        formData.pension_contribution,
        formData.employers_ni,
        formData.other_costs
      );
      setChargeRate(chargeRate);
    }
  }, [formData, data]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type} = e.target;

    if (type === 'number') {
      const numericValue = value.replace(/[^0-9]/g, '');
      if (parseFloat(numericValue) < 0) {
        setFormData({...formData, [name]: '0'});
      } else {
        setFormData({...formData, [name]: numericValue});
      }
    }

    setFormData({...formData, [name]: value});
  };
  const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };
  if (isLoading || workerAllocationsLoading) {
    return <Spinner />;
  }
  const handleAccept = async () => {
    const post = {
      agency_shift_allocation_uid: params.id !== undefined ? params.id : '',
      action: 'ACCEPT',
      // eslint-disable-next-line
      margin:
        user?.group === 'is_provider' ? data?.results.margin : formData.margin,

      margin_type:
        user?.group === 'is_provider'
          ? data?.results.margin_type
          : formData.margin_type,
      holiday_pay:
        user?.group === 'is_provider'
          ? parseFloat(data !== undefined ? data.results.holiday_pay : '')
          : formData.holiday_pay,
      other_costs:
        user?.group === 'is_provider'
          ? data?.results.other_costs
          : formData.other_costs,
      employers_ni:
        user?.group === 'is_provider'
          ? parseFloat(data !== undefined ? data.results.employers_ni : '')
          : formData.employers_ni,
      pension_contribution:
        user?.group === 'is_provider'
          ? data?.results.pension_contribution
          : formData.pension_contribution,
      action_by: user?.group === 'is_provider' ? 'PROVIDER' : 'AGENCY',
    };
    await mutateAsync(post)
      .then(() => setAction('ACCEPT'))
      .then(() => setIsSuccess(true))
      .catch(() => setIsError(true));
  };
  const handlePublishShift = async () => {
    const post = {
      agency_shift_allocation_uid: params.id !== undefined ? params.id : '',
      status: 'PUBLISHED',
    };
    await mutateAgencyUpdate(post)
      .then(() => setIsUpdateSuccess(true))
      .catch(() => setIsUpdateError(true));
  };
  //eslint-disable-next-line
  const location = data?.results?.shift_template?.location?.region;

  const handleDecline = async () => {
    const post = {
      agency_shift_allocation_uid: params.id !== undefined ? params.id : '',
      action: 'DECLINE',
      // eslint-disable-next-line
      margin:
        user?.group === 'is_provider' ? data?.results.margin : formData.margin,

      margin_type:
        user?.group === 'is_provider'
          ? data?.results.margin_type
          : formData.margin_type,
      holiday_pay:
        user?.group === 'is_provider'
          ? parseFloat(data !== undefined ? data.results.holiday_pay : '')
          : formData.holiday_pay,
      other_costs:
        user?.group === 'is_provider'
          ? data?.results.other_costs
          : formData.other_costs,
      employers_ni:
        user?.group === 'is_provider'
          ? parseFloat(data !== undefined ? data.results.employers_ni : '')
          : formData.employers_ni,
      pension_contribution:
        user?.group === 'is_provider'
          ? data?.results.pension_contribution
          : formData.pension_contribution,
      action_by: user?.group === 'is_provider' ? 'PROVIDER' : 'AGENCY',
    };
    await mutateAsync(post)
      .then(() => setIsSuccess(true))
      .catch(() => setIsError(true));
  };
  return (
    <>
      {/* <DialogLayout
        isOpen={isAllocateWorker}
        onClose={() => setIsAllocateWorker(false)}>
        <AllocateWorker
          shiftId={params.id !== undefined ? params.id : ''}
          handleCloseTemplate={() => setIsAllocateWorker(false)}
          start_date={
            data !== undefined ? data.results.shift_template.start_date : ''
          }
          location_id={
            data !== undefined ? data.results.shift_template.location.uid : ''
          }
          recurrence={
            data !== undefined ? data.results.shift_template.recurrence : ''
          }
        />
      </DialogLayout> */}
      <ErrorHandlingLayout
        isOpen={isUpdateError}
        onClose={closeUpdateError}
        title="Error">
        <p className="mt-2 text-center">
          An error occured while publihing the shift
        </p>
      </ErrorHandlingLayout>
      <SuccessLayout isOpen={isUpdateSuccess} onClose={onCloseUpdateSuccess}>
        <p className="text-lg font-bold">Shift Published </p>
        <p>The shift has been published sucessfully.</p>
      </SuccessLayout>
      <div className="p-8">
        <ErrorHandlingLayout
          isOpen={isError}
          onClose={closeError}
          title={error !== null ? error.response.data.error_code : ''}>
          <p className="mt-2 text-center">
            {error !== null && error.response.data.errors}
          </p>
        </ErrorHandlingLayout>
        <SuccessLayout isOpen={isSuccess} onClose={onCloseSuccess}>
          <p className="text-lg font-bold">
            Shift {action === 'ACCEPT' ? 'Accepted' : 'Rejected'}
          </p>
          <p>
            The shift has been {action === 'ACCEPT' ? 'Accepted' : 'Rejected'}{' '}
            sucessfully.
          </p>
        </SuccessLayout>
        <div className="flex justify-between items-center mb-6">
          <img
            onClick={() => navigate(-1)}
            src={circularBack}
            alt="back"
            className="h-12 w-12 mt-4 ml-4  cursor-pointer"
          />
          {(data?.results.status === 'CONFIRMED' ||
            data?.results.status === 'PUBLISHED') && (
            <button
              onClick={() =>
                navigate(
                  `/agency/allocation-calendar/${
                    params.id !== undefined ? params.id : ''
                  }`
                )
              }
              className="px-6 py-2 bg-plannaPrimaryGreen text-plannaAccentGreen rounded-full">
              + Allocate Workers to the shift
            </button>
          )}
        </div>
        <div className="flex items-center space-x-6">
          <img src={Profile} alt="avatar" className="h-fit w-fit" />
          <div className="flex flex-col">
            <h1 className="text-2xl font-bold text-plannaPrimaryGreen">
              {data?.results.shift_template.provider.company.name}
            </h1>
            <div className="flex space-x-6 mt-4">
              <div className="flex flex-col">
                <h1 className="text-lg border-b w-fit text-plannaSecondaryGreen50 border-plannaSecondaryGreen50">
                  Email
                </h1>
                <p className="text-lg mt-1 text-plannaPrimaryGreen">
                  {data?.results.shift_template.provider.email}
                </p>
              </div>
              <div className="flex flex-col">
                <h1 className="text-lg border-b w-fit text-plannaSecondaryGreen50 border-plannaSecondaryGreen50">
                  Mobile
                </h1>
                <p className="text-lg mt-1 text-plannaPrimaryGreen">
                  {data?.results.shift_template.provider.phone_number}
                </p>
              </div>
              <div className="flex flex-col">
                <h1 className="text-lg text-plannaPrimaryGreen ">Location</h1>
                <span className="mt-1 bg-plannaSecondaryGreen50 text-white   text-center text-sm  px-6 py-1 rounded-full w-fit">
                  {/* eslint-disable-next-line */}
                  {data?.results.shift_template.provider.location.region},
                  {/* eslint-disable-next-line */}
                  {data?.results.shift_template.provider.location.country.name}
                </span>
              </div>
              <div className="flex flex-col">
                <h1 className="text-lg text-plannaPrimaryGreen ">
                  Allocation status
                </h1>
                <span className="mt-1 bg-plannaAccentGreen text-plannaPrimaryGreen   text-center text-sm  px-6 py-1 rounded-full w-fit">
                  {/* eslint-disable-next-line */}
                  {data?.results.status === 'AWAITING_AGENCY_CONFIRMATION'
                    ? 'Awaiting agency to confirm'
                    : data?.results.status === 'AWAITING_PROVIDER_CONFIRMATION'
                      ? 'Awaiting provider to confirm'
                      : data !== undefined
                        ? data.results.status.toLowerCase()
                        : ''}
                </span>
              </div>
            </div>
            <div className="flex space-x-6 mt-4">
              {user?.group === 'is_agency' && (
                <div className="flex flex-col">
                  {data?.results.status === 'CONFIRMED' && (
                    <>
                      <h1 className="text-lg ml-2 text-plannaPrimaryGreen ">
                        Action
                      </h1>
                      <div className="flex space-x-2 mt-1">
                        <button
                          onClick={handlePublishShift}
                          className="bg-plannaNeutralGrey text-center text-plannaPrimaryGreen  font-hanken-grotesk   px-6 py-1 rounded-full">
                          {updateLoading ? <SubmitSpinner /> : 'Publish shift'}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="border-b mt-4 border-plannaNeutral w-full" />
        <h1 className="mt-16 text-plannaPrimaryGreen text-xl font-bold font-space-grotesk">
          Job Template
        </h1>
        {data?.results.shift_template.job_template !== null ? (
          <div className="w-10/12 mt-14">
            <div className="flex justify-between">
              <Input
                name=""
                inputValue={
                  data?.results.shift_template.job_template.template_name
                }
                type="text"
                label="Job name"
                readonly
              />
              <Input
                name=""
                inputValue={
                  data?.results.shift_template.job_template.required_uniform
                    .color
                }
                type="text"
                label="Uniform color"
                readonly
              />
            </div>
            <div className="flex justify-between mt-7">
              <Input
                name=""
                inputValue={
                  data?.results.shift_template.job_template.required_uniform
                    .style
                }
                type="text"
                label="Uniform style"
                readonly
              />
              <Input
                name=""
                inputValue={
                  data?.results.shift_template.job_template.required_uniform
                    .color
                }
                type="text"
                label="Uniform color"
                readonly
              />
            </div>
            <div className="flex mt-7 justify-between">
              <TextArea
                name="description"
                value={
                  data?.results.shift_template.job_template.required_uniform
                    .description
                }
                label="Uniform description"
                readonly
              />
              <TextArea
                name="description"
                value={
                  data?.results.shift_template.job_template.job_description
                }
                label="Job description"
                readonly
              />
            </div>
            <div className="flex mt-7 justify-between">
              <div className="w-96">
                <label
                  htmlFor="inputField"
                  className="block mb-3 text-sm font-medium text-plannaPrimaryGreen">
                  Health and Safety
                </label>
                <textarea
                  className="w-full border border-plannaNeutral rounded-xl py-2 px-3 text-plannaPrimaryGreen resize-none"
                  //eslint-disable-next-line
                  value={data?.results.shift_template.job_template.wellness}
                  readOnly
                />
              </div>
            </div>
          </div>
        ) : (
          'No job template'
        )}
        <h1 className="mt-16 text-plannaPrimaryGreen text-xl font-bold font-space-grotesk">
          Shift Template
        </h1>
        <div className="w-10/12 mt-14">
          <div className="flex justify-between">
            <Input
              name=""
              inputValue={data?.results.shift_template.name}
              type="text"
              label="Shift name"
              readonly
            />
            <Input
              name=""
              inputValue={data?.results.shift_template.worker_count}
              type="text"
              label="Worker count"
              readonly
            />
          </div>
          <div className="flex justify-between mt-7">
            <Input
              name=""
              inputValue={data?.results.shift_template.start_date}
              type="date"
              label="Start date"
              readonly
            />
            <Input
              name=""
              inputValue={location}
              type="text"
              label="Shift location"
              readonly
            />
          </div>
          <div className="flex justify-between mt-7">
            <Input
              name=""
              inputValue={data?.results.shift_template.start_time}
              type="time"
              label="Start time"
              readonly
            />
            <Input
              name=""
              inputValue={data?.results.shift_template.end_time}
              type="time"
              label="End time"
              readonly
            />
          </div>
          <div className="flex mt-7 justify-between">
            <div className="w-96">
              <label
                htmlFor="inputField"
                className="block mb-3 text-sm font-medium text-plannaPrimaryGreen">
                Shift description
              </label>
              <textarea
                className="w-full border border-plannaNeutral rounded-xl py-2 px-3 text-plannaPrimaryGreen resize-none"
                value={data?.results.shift_template.description}
                readOnly
              />
            </div>
          </div>
        </div>
        <h1 className="mt-16 text-plannaPrimaryGreen text-xl font-bold font-space-grotesk">
          Rate
        </h1>
        {data?.results.rate !== null ? (
          <div className="w-10/12 mt-14">
            <div className="flex justify-between">
              <Input
                name=""
                inputValue={data?.results.rate.rate_name.toLowerCase()}
                type="text"
                label="Rate name"
                readonly
              />
              <Input
                name=""
                inputValue={data?.results.rate.rate_type.toLowerCase()}
                type="text"
                label="Rate type"
                readonly
              />
            </div>
            {user?.group !== 'is_provider' && (
              <>
                <div className="flex justify-between mt-7">
                  <Input
                    name=""
                    inputValue={data?.results.rate.base_rate}
                    type="text"
                    label={
                      country === 'Kenya'
                        ? 'Base rate (Kes)'
                        : 'Base rate (Gbp)'
                    }
                    readonly
                  />
                  <Input
                    name=""
                    // eslint-disable-next-line
                    inputValue={data?.results.rate.rate_duration_type.toLowerCase()}
                    type="text"
                    label="Rate duration type"
                    readonly
                  />
                </div>
                <div className="flex justify-between mt-7">
                  <Select
                    inputValue={
                      // eslint-disable-next-line
                      data?.results.status === 'AWAITING_AGENCY_CONFIRMATION'
                        ? formData.margin_type
                        : data?.results.margin_type
                    }
                    label="Select a margin type"
                    onChange={handleOnSelect}
                    name="margin_type"
                    required
                    readonly={
                      data?.results.status === 'AWAITING_AGENCY_CONFIRMATION'
                        ? false
                        : true
                    }
                    options={['PERCENTAGE', 'VALUE']}
                  />
                  <Input
                    inputValue={
                      // eslint-disable-next-line
                      data?.results.status === 'AWAITING_AGENCY_CONFIRMATION'
                        ? parseFloat(formData.margin)
                        : data?.results.margin
                    }
                    type="number"
                    onChange={handleChange}
                    readonly={
                      data?.results.status === 'AWAITING_AGENCY_CONFIRMATION'
                        ? false
                        : true
                    }
                    label={
                      formData.margin_type === 'PERCENTAGE'
                        ? 'Enter a margin (%)'
                        : country === 'Kenya'
                          ? 'Enter a margin(Kes)'
                          : 'Enter a margin (Gbp)'
                    }
                    required
                    name="margin"
                    min="0"
                  />
                </div>
                <div className="flex justify-between mt-7">
                  <Input
                    inputValue={formData.employers_ni}
                    type="number"
                    label="Employers NI (%)"
                    required
                    name="employers_ni"
                    readonly
                  />
                  <Input
                    inputValue={formData.holiday_pay}
                    type="number"
                    label="Holiday pay (%)"
                    required
                    name="holiday_pay"
                    readonly
                  />
                </div>
                <div className="flex justify-between mt-7">
                  <Input
                    inputValue={
                      // eslint-disable-next-line
                      data?.results.status === 'AWAITING_AGENCY_CONFIRMATION'
                        ? parseFloat(formData.pension_contribution)
                        : data?.results.pension_contribution
                    }
                    type="number"
                    label="Enter pension contribution (%)"
                    name="pension_contribution"
                    readonly={
                      data?.results.status === 'AWAITING_AGENCY_CONFIRMATION'
                        ? false
                        : true
                    }
                    onChange={handleChange}
                    min="0"
                  />
                  <Input
                    inputValue={
                      // eslint-disable-next-line
                      data?.results.status === 'AWAITING_AGENCY_CONFIRMATION'
                        ? parseFloat(formData.other_costs)
                        : data?.results.other_costs
                    }
                    type="number"
                    label={
                      country === 'Kenya'
                        ? 'Other costs (Kes)'
                        : 'Other costs (Gbp)'
                    }
                    readonly={
                      data?.results.status === 'AWAITING_AGENCY_CONFIRMATION'
                        ? false
                        : true
                    }
                    name="other_costs"
                    min="0"
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
            <div className="flex justify-between mt-7">
              <Input
                inputValue={data?.results.rate.base_rate}
                type="number"
                label={
                  country === 'Kenya' ? 'Pay rate (Kes)' : 'Pay rate (Gbp)'
                }
                name="pension_contribution"
              />
              <Input
                type="number"
                inputValue={
                  // eslint-disable-next-line
                  data?.results.status === 'AWAITING_AGENCY_CONFIRMATION'
                    ? chargeRateInput.toFixed(2)
                    : parseFloat(
                        // eslint-disable-next-line
                        data !== undefined ? data.results.charge_rate : '0'
                      ).toFixed(2)
                }
                onChange={handleChange}
                placeholder="8"
                required
                min="0"
                label={
                  country === 'Kenya'
                    ? 'Charge rate (Kes)'
                    : 'Charge rate (Gbp)'
                }
                name="other_costs"
              />
            </div>

            {data?.results.status === 'AWAITING_AGENCY_CONFIRMATION' &&
              user?.group === 'is_agency' && (
                <div className="mt-7 flex  space-x-2">
                  <button
                    onClick={handleDecline}
                    className="bg-plannaNeutral text-plannaPrimaryGreen px-6 py-2 rounded-full">
                    {acceptingInvitation ? <SubmitSpinner /> : 'Decline'}
                  </button>
                  <button
                    onClick={handleAccept}
                    className="bg-plannaPrimaryGreen text-plannaAccentGreen px-6 py-2 rounded-full">
                    {acceptingInvitation ? <SubmitSpinner /> : 'Accept'}
                  </button>
                </div>
              )}
            {data?.results.status === 'AWAITING_PROVIDER_CONFIRMATION' &&
              user?.group === 'is_provider' && (
                <div className="mt-7 flex  space-x-2">
                  <button
                    onClick={handleDecline}
                    className="bg-plannaNeutral text-plannaPrimaryGreen px-6 py-2 rounded-full">
                    {acceptingInvitation ? <SubmitSpinner /> : 'Decline'}
                  </button>
                  <button
                    onClick={handleAccept}
                    className="bg-plannaPrimaryGreen text-plannaAccentGreen px-6 py-2 rounded-full">
                    {acceptingInvitation ? <SubmitSpinner /> : 'Accept'}
                  </button>
                </div>
              )}
            {user?.group === 'is_agency' &&
              data?.results.status === 'CONFIRMED' && (
                <>
                  <h1 className="mt-16 text-plannaPrimaryGreen text-xl font-bold font-space-grotesk">
                    Staffed Workers
                  </h1>
                  <div className="flex mt-4 flex-col">
                    <div className="overflow-x-auto">
                      <div className="p-1.5 w-full inline-block align-middle">
                        <div className="overflow-hidden border rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                                  Worker Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                                  Allocation status
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                                  Worker email
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                                  Worker phone number
                                </th>
                              </tr>
                            </thead>
                            {workerAllocations?.results.map((worker) => (
                              <tbody className="divide-y divide-gray-200">
                                <tr>
                                  <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                                    {`${worker.worker.user.first_name} ${worker.worker.user.last_name}`}
                                  </td>

                                  <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                                    <span
                                      className={` px-6 py-1 rounded-xl ${
                                        worker.status ===
                                        'AWAITING_CONFIRMATION'
                                          ? ' bg-plannaPrimaryGreen text-plannaAccentGreen'
                                          : worker.status === 'DECLINED'
                                            ? 'bg-plannaTertiaryRed text-plannaNeutralWhite'
                                            : ' bg-plannaSecondaryGreen50 text-plannaNeutralWhite'
                                      }`}>
                                      {worker.status === 'AWAITING_CONFIRMATION'
                                        ? 'Awaiting confirmation'
                                        : worker.status === 'DECLINED'
                                          ? 'Declined'
                                          : 'Confirmed'}
                                    </span>
                                  </td>
                                  <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                                    {worker.worker.contact_details.email}
                                  </td>

                                  <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                                    {worker.worker.contact_details.phone_number}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        ) : (
          'No rate for this shift'
        )}
      </div>
    </>
  );
};

export default SingleShiftInvitation;
