import {collection, query, where, onSnapshot} from 'firebase/firestore';
import {useEffect, useState} from 'react';

import {db} from '../../..';
import useAuthUser from '../useUserDetails';

export interface INotificationData {
  id: string;
  recipient_uid: string;
  sender_uid: string;
  inbox_uid: string;
  message_uid: string;
  read: boolean;
  sent_at: boolean;
  sender_name: string;
  message: string;
}

const useNotifications = (
  read = false
): [INotificationData[], boolean, string | null] => {
  const [notifications, setNotifications] = useState<INotificationData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const user = useAuthUser();

  useEffect(() => {
    const q = query(
      collection(db, 'notification'),
      where('recipient_uid', '==', user.user?.user_id)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        setLoading(true);
        const newNotifications: INotificationData[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data() as INotificationData;
          newNotifications.push({
            id: doc.id,
            recipient_uid: data.recipient_uid,
            sender_uid: data.sender_uid,
            inbox_uid: data.inbox_uid,
            message_uid: data.message_uid,
            read: data.read,
            sent_at: data.sent_at,
            message: data.message,
            sender_name: data.sender_name,
          });
        });
        setNotifications(newNotifications);
        setLoading(false);
      },
      (err) => {
        setError(err.message);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [read, user.user?.user_id]);

  return [notifications, loading, error];
};

export default useNotifications;
