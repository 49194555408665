/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {motion} from 'framer-motion';
import {useEffect, useState} from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import backIcon from '../../assets/icons/Circular-Backward.png';
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';
import {getLocationInfo, roundTo6DecimalPlaces} from '../../utils/Methods';
import RQCreateAgencyProvider from '../../utils/Queries/RQCreateAgencyProvider';

const ProviderCreate = () => {
  const {
    register,
    clearErrors,
    setValue,
    handleSubmit,
    formState: {errors},
  } = useForm();
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [placeId, setPlaceId] = useState('');
  const [placeInfo, setPlaceInfo] = useState({});
  const navigate = useNavigate();
  const {data: agencyData} = useGetAgencyProfile();

  const onSuccess = (data: any) => {
    navigate('/agency/providers');
  };
  const onError = (data: any) => {
    console.log(data);
  };

  const {mutate: createProvider, isLoading: isCreatingProvider} =
    RQCreateAgencyProvider(onSuccess, onError);

  const onSubmit = (data: any) => {
    // console.log(providerObject(data, placeInfo, agencyData));
    createProvider(providerObject(data, placeInfo, agencyData));
  };

  const handleSelect = (place: any) => {
    const geocoder = new window.google.maps.Geocoder();
    setValue('location', place.value.description);

    geocoder.geocode(
      {placeId: place.value.place_id},
      (results: any, status: any) => {
        if (status === 'OK' && results[0]) {
          const {lat, lng} = results[0].geometry.location;
          setLatitude(parseFloat(lat()));
          setLongitude(parseFloat(lng()));
          setPlaceId(place.value.place_id);
        }
      }
    );
  };

  const getGeoData = () => {
    getLocationInfo(latitude, longitude)
      .then((locationInfo) => {
        setPlaceInfo((prevPlaceInfo) => ({
          ...prevPlaceInfo,
          longitude: roundTo6DecimalPlaces(longitude),
          latitude: roundTo6DecimalPlaces(latitude),
          country_code: locationInfo.countryCode,
          google_place_id: placeId,
          state: locationInfo.state,
          city: locationInfo.city,
          postal_code: locationInfo.postalCode || 'not set',
          region: locationInfo.region,
          street: locationInfo.street,
        }));
      })
      .catch((errr: string) => {
        return errr;
      });
  };

  useEffect(() => {
    clearErrors('location');
    getGeoData();
  }, [latitude, longitude, placeId]);

  if (isCreatingProvider) {
    return <Spinner />;
  }

  return (
    <div className="seeker-profile-container">
      <div className="profile-max-width">
        <div className="profile-header">
          <div
            className="back-icon"
            onClick={() => navigate(`/agency/providers`)}>
            <img src={backIcon} alt="" />
          </div>
          <div className="col">
            <h1 className="profile-title">Agency Provider Onboarding</h1>
            <p className="profile-sub-title">
              Fill out the form below to onboard a provider{' '}
            </p>
          </div>
        </div>
      </div>

      <div className="profile-body">
        <form className="form-action" onSubmit={handleSubmit(onSubmit)}>
          <div className="worker-details">
            <div className="max-width">
              <motion.div
                initial={{
                  height: 'auto',
                }}
                animate={{
                  height: 'auto',
                }}
                transition={{duration: 0.1}}
                className="details-card no-overflow">
                <div className="dropdown-holder active">
                  Personal Information
                </div>
                <div className="editable-form p-2">
                  <div className="split-form-group">
                    <div
                      className={`form-group${
                        errors.firstname ? ' error' : ''
                      }`}>
                      <label htmlFor="" className="form-label">
                        Firstname <span>*</span>
                      </label>
                      <input
                        type="text"
                        {...register('firstname', {
                          required: 'Firstname is required',
                        })}
                        className="form-input"
                      />
                      {errors.firstname && (
                        <span className="text-red-500 text-sm">
                          {String(errors?.firstname?.message)}
                        </span>
                      )}
                    </div>
                    <div
                      className={`form-group${
                        errors.lastname ? ' error' : ''
                      }`}>
                      <label htmlFor="" className="form-label">
                        Lastname <span>*</span>
                      </label>
                      <input
                        {...register('lastname', {
                          required: 'Lastname is required',
                        })}
                        type="text"
                        className="form-input"
                      />
                      {errors.lastname && (
                        <span className="text-red-500 text-sm">
                          {String(errors?.lastname?.message)}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="split-form-group">
                    <div
                      className={`form-group${
                        errors.phone_number ? ' error' : ''
                      }`}>
                      <label htmlFor="" className="form-label">
                        Phone Number <span>*</span>
                      </label>
                      <input
                        type="tel"
                        {...register('phone_number', {
                          required: 'Phone number is required',
                        })}
                        className="form-input"
                      />
                      {errors.phone_number && (
                        <span className="text-red-500 text-sm">
                          {String(errors?.phone_number?.message)}
                        </span>
                      )}
                    </div>
                    <div
                      className={`form-group${errors.email ? ' error' : ''}`}>
                      <label htmlFor="" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        {...register('email', {
                          required: 'Email is required',
                        })}
                        className="form-input"
                      />
                      {errors.email && (
                        <span className="text-red-500 text-sm">
                          {String(errors?.email?.message)}
                        </span>
                      )}
                    </div>
                  </div>

                  <div
                    className={`form-group${errors.location ? ' error' : ''}`}>
                    <label htmlFor="" className="form-label">
                      Location <span>*</span>
                    </label>

                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_PLACES_KEY}
                      selectProps={{
                        placeholder: 'location',
                        className: 'form-input',
                        onChange: handleSelect,
                      }}
                      {...register('location', {
                        required: 'Location is required',
                      })}
                      autocompletionRequest={{
                        componentRestrictions: {country: ['ke', 'gb']},
                      }}
                      aria-describedby="basic-addon1"
                    />
                    {errors.location && (
                      <span className="text-red-500 text-sm">
                        {String(errors?.location?.message)}
                      </span>
                    )}
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{
                  height: 'auto',
                }}
                animate={{
                  height: 'auto',
                }}
                transition={{duration: 0.1}}
                className="details-card">
                <div className="dropdown-holder active">
                  Company Information
                </div>
                <div className="editable-form p-2">
                  <div className="split-form-group">
                    <div
                      className={`form-group${
                        errors.companyName ? ' error' : ''
                      }`}>
                      <label htmlFor="" className="form-label">
                        Company name <span>*</span>
                      </label>
                      <input
                        type="text"
                        {...register('companyName', {
                          required: 'Company name is required',
                        })}
                        className="form-input"
                      />
                      {errors.companyName && (
                        <span className="text-red-500 text-sm">
                          {String(errors?.companyName?.message)}
                        </span>
                      )}
                    </div>
                    <div
                      className={`form-group${
                        errors.clientNumber ? ' error' : ''
                      }`}>
                      <label htmlFor="" className="form-label">
                        Client Number <span>*</span>
                      </label>
                      <input
                        {...register('clientNumber', {
                          required: 'Client number is required',
                        })}
                        type="text"
                        className="form-input"
                      />
                      {errors.clientNumber && (
                        <span className="text-red-500 text-sm">
                          {String(errors?.clientNumber?.message)}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="split-form-group">
                    <div
                      className={`form-group${
                        errors.vatNumber ? ' error' : ''
                      }`}>
                      <label htmlFor="" className="form-label">
                        VAT Number <span>*</span>
                      </label>
                      <input
                        type="tel"
                        {...register('vatNumber', {
                          required: 'VAT number is required',
                        })}
                        className="form-input"
                      />
                      {errors.vatNumber && (
                        <span className="text-red-500 text-sm">
                          {String(errors?.vatNumber?.message)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </motion.div>
              <div className="form-group">
                <button className="btn primary">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProviderCreate;

const providerObject = (data: any, location: any, agencyInfo: any) => {
  return {
    first_name: data.firstname,
    last_name: data.lastname,
    email: data.email,
    phone_number: data.phone_number,
    location: location,
    company_name: data.companyName,
    client_number: data.clientNumber,
    vat_number: data.vatNumber,
    provider_type: 'AGENCY SIGN UP',
    agency_id: agencyInfo?.uid,
    password: 'admin123',
  };
};
