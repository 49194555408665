import {
  RecurrenceEditorComponent,
  RecurrenceEditorChangeEventArgs,
} from '@syncfusion/ej2-react-schedule';
import React, {useEffect, useRef, useState} from 'react';

interface RecurrenceProps {
  ruleOutputCallback: (ruleOutPut: string) => void;
  rule: string;
}
const RecurrenceEditor: React.FC<RecurrenceProps> = ({
  ruleOutputCallback,
  rule,
}) => {
  const [ruleOutput, setRuleOutput] = useState<string>(rule);
  const recObject = useRef<RecurrenceEditorComponent>(null);
  useEffect(() => {
    if (!recObject.current) return;
    recObject.current.endTypes = ['until'];
  }, []);

  const onChange = (args: RecurrenceEditorChangeEventArgs): void => {
    setRuleOutput(args.value);
    ruleOutputCallback(args.value);
  };
  return (
    <>
      <div className="RecurrenceEditor w-full">
        <RecurrenceEditorComponent
          id="RecurrenceEditor"
          value={ruleOutput}
          change={onChange}
          className="w-3/4"
        />
      </div>
    </>
  );
};

export default RecurrenceEditor;
