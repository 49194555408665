// TextAreaComponent.tsx
import React from 'react';

interface TextAreaProps {
  value: string | undefined;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  name: string;
  required?: boolean;
  demo?: boolean;
  readonly?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
  value,
  onChange,
  label,
  name,
  required,
  demo,
  readonly,
}) => {
  return (
    <div className="w-full">
      <label
        htmlFor="inputField"
        className="block mb-3 text-sm font-medium text-plannaPrimaryGreen">
        {label}
        {required && <span className="text-red-600 ml-1">*</span>}
      </label>
      <textarea
        className={`${
          demo ? 'w-full' : 'w-full lg:w-3/4'
        } border border-plannaNeutral rounded-xl py-2 px-3 text-plannaPrimaryGreen resize-none`}
        value={value}
        onChange={onChange}
        name={name}
        required={required}
        readOnly={readonly}
      />
    </div>
  );
};

export default TextArea;
