/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import React, {ChangeEvent, useState} from 'react';
import {MultiSelect} from 'react-multi-select-component';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../../Components/Common/Spinner';
import useGetAgencyProfile from '../../../hooks/services/Agencys/useGetAgencyProfile';
import useGetWorkers, {
  Data,
} from '../../../hooks/services/Agencys/useGetWorkers';
import useIndustriesData from '../../../hooks/services/common/useGetIndustries';
import useLocationsData from '../../../hooks/services/common/useGetLocations';
import useGetRoles from '../../../hooks/services/common/useGetRoles';
type SelectedOption = {
  label: string;
  value: string;
};
const CandidatesTable = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [postalCode, setPostalCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile();
  const {data: industries, isLoading: industriesLoading} = useIndustriesData();
  const {data: roles, isLoading: rolesLoading} = useGetRoles({
    uid: profile?.industry.uid,
  });
  const {data: locations, isLoading: locationsLoading} = useLocationsData();
  const [selectedIndustries, setSelectedIndustries] = useState<
    SelectedOption[]
  >([]);
  const [selectedRoles, setSelectedRoles] = useState<SelectedOption[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<SelectedOption[]>(
    []
  );
  const {
    data: workersData,
    isLoading: workersLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetWorkers({
    status: 'CANDIDATE',
    agency_id: profile !== undefined ? profile.uid : '',
    roles: selectedRoles.map((item) => `${item.value}`).join(','),
    industries: selectedIndustries.map((item) => `${item.value}`).join(','),
    location_id: selectedLocations.map((item) => `${item.value}`).join(','),
    email: email,
    phone_number: phoneNumber,
    first_name: firstName,
    postal_code: postalCode,
    limit: 10,
  });

  const multiIndustries = () => {
    if (industries !== undefined) {
      return industries.industries.map((obj) => ({
        label: obj.name,
        value: obj.uid,
      }));
    }
  };
  const multiRoles = () => {
    return roles?.results.map((obj) => ({
      label: obj.name,
      value: obj.uid,
    }));
  };
  const multiLocations = () => {
    return locations?.locations.map((obj) => ({
      label: `${obj.region},${obj.country.name}`,
      value: obj.uid,
    }));
  };
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setEmail(value);
  };
  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setPhoneNumber(value);
  };
  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setFirstName(value);
  };
  const handlePostalCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setPostalCode(value);
  };

  if (
    industriesLoading ||
    rolesLoading ||
    locationsLoading ||
    workersLoading ||
    profileLoading
  ) {
    return <Spinner />;
  }
  const customIndustryStrings = {
    allItemsAreSelected: 'All items are selected.',
    clearSearch: 'Clear Search',
    noOptions: 'No options',
    search: 'Search',
    selectAll: 'Select All',
    selectAllFiltered: 'Select All (Filtered)',
    selectSomeItems: 'Select industries..',
    create: 'Create',
  };
  const customRoleStrings = {
    allItemsAreSelected: 'All items are selected.',
    clearSearch: 'Clear Search',
    noOptions: 'No options',
    search: 'Search',
    selectAll: 'Select All',
    selectAllFiltered: 'Select All (Filtered)',
    selectSomeItems: 'Select roles..',
    create: 'Create',
  };
  const customLocationStrings = {
    allItemsAreSelected: 'All items are selected.',
    clearSearch: 'Clear Search',
    noOptions: 'No options',
    search: 'Search',
    selectAll: 'Select All',
    selectAllFiltered: 'Select All (Filtered)',
    selectSomeItems: 'Select locations..',
    create: 'Create',
  };
  return (
    <>
      <div className="flex justify-between mb-8">
        <div className="flex justify-end items-center text-sm space-x-2  mt-4 ">
          <div className="flex  items-center flex-wrap text-sm  mt-4 ">
            {profile?.uid !== process.env.REACT_APP_OUTERBOX_ID && (
              <>
                <div className="w-1/6 mb-2 ml-2">
                  <MultiSelect
                    options={multiIndustries() || []}
                    value={selectedIndustries}
                    onChange={setSelectedIndustries}
                    labelledBy="employment status"
                    className="text-plannaPrimaryGreen multi-select border w-full border-plannaNeutral rounded-lg"
                    overrideStrings={customIndustryStrings}
                  />
                </div>

                <div className="w-1/6 mb-2 ml-2">
                  <input
                    type="text"
                    value={postalCode}
                    onChange={handlePostalCodeChange}
                    placeholder="Search by postal code"
                    className="text-plannaPrimaryGreen w-full border border-plannaNeutral rounded-lg py-2 px-3"
                  />
                </div>
              </>
            )}

            <div className="w-1/6 mb-2 ml-2">
              <MultiSelect
                options={multiRoles() || []}
                value={selectedRoles}
                onChange={setSelectedRoles}
                labelledBy="employment status"
                className="text-plannaPrimaryGreen multi-select border w-full border-plannaNeutral rounded-lg "
                overrideStrings={customRoleStrings}
              />
            </div>

            <div className="w-1/6 mb-2 ml-2">
              <MultiSelect
                options={multiLocations() || []}
                value={selectedLocations}
                onChange={setSelectedLocations}
                labelledBy="employment status"
                className="text-plannaPrimaryGreen multi-select border w-full border-plannaNeutral rounded-lg "
                overrideStrings={customLocationStrings}
              />
            </div>
            <div className="w-1/6 mb-2 ml-2">
              <input
                type="text"
                value={postalCode}
                onChange={handlePostalCodeChange}
                placeholder="Search by postal code"
                className="text-plannaPrimaryGreen w-full border border-plannaNeutral rounded-lg py-2 px-3"
              />
            </div>
            <div className="w-1/6 mb-2 ml-2">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Search by email"
                className="text-plannaPrimaryGreen w-full  border border-plannaNeutral rounded-lg py-2 px-3"
              />
            </div>

            <div className="w-1/6 mb-2 ml-2">
              <input
                type="text"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="Search by phone number"
                className="text-plannaPrimaryGreen w-full border border-plannaNeutral rounded-lg py-2 px-3"
              />
            </div>

            <div className="w-1/6 mb-2 ml-2">
              <input
                type="text"
                value={firstName}
                onChange={handleFirstNameChange}
                placeholder="Search by first name"
                className="text-plannaPrimaryGreen w-full border border-plannaNeutral rounded-lg py-2 px-3"
              />
            </div>
            <div className="w-1/6 mb-2 ml-2">
              <button
                onClick={() => {
                  setSelectedIndustries([]),
                    setSelectedRoles([]),
                    setSelectedLocations([]),
                    setEmail('');
                  setPhoneNumber('');
                  setPostalCode('');
                  setFirstName('');
                }}
                className="  bg-plannaNeutralGray text-black px-6 py-2 rounded-xl">
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Registration Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Phone number
                    </th>
                    {/* <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Progress
                    </th> */}

                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Action
                    </th>
                  </tr>
                </thead>
                {workersData?.pages.map(
                  (
                    page: {results: any[]},
                    pageIndex: React.Key | null | undefined
                  ) => (
                    <React.Fragment key={pageIndex}>
                      {page.results.map((worker: Data) => (
                        <tbody className="divide-y divide-gray-200">
                          <tr>
                            <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                              {`${worker.user.first_name} ${worker.user.last_name}`}
                            </td>

                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                              {worker.user.email}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                              {moment(worker.created_at).format('DD MMMM YYYY')}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                              {worker.contact_details !== null
                                ? worker.contact_details.phone_number
                                : 'No phone number'}
                            </td>
                            {/* <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                              <p className="text-plannaBlack mb-2 ">
                                {`${
                                  100 -
                                  (worker.missing_information.length / 5) * 100
                                }%`}{' '}
                                complete
                              </p>
                              <div className="w-full h-2 bg-gray-200 rounded overflow-hidden">
                                <div
                                  className="h-full bg-plannaSecondaryGreen50"
                                  style={{
                                    width: `${
                                      100 -
                                      (worker.missing_information.length / 5) *
                                        100
                                    }%`,
                                  }}
                                />
                              </div>
                            </td> */}

                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                              <div className="flex space-x-3">
                                <button
                                  onClick={() =>
                                    navigate(
                                      `/agency/single-worker/${worker.uid}`
                                    )
                                  }
                                  className="px-6 py-2 bg-plannaPrimaryGreen text-plannaAccentGreen rounded-full">
                                  View profile
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </React.Fragment>
                  )
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
      <div>
        {hasNextPage ? (
          <button
            className=" mt-4 bg-plannaPrimaryGreen text-plannaAccentGreen px-6 py-2 rounded-full"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}>
            {isFetchingNextPage ? 'Loading more...' : 'Load more'}
          </button>
        ) : (
          <p className="mt-4 text-plannaPrimaryGreen" />
        )}
      </div>
    </>
  );
};

export default CandidatesTable;
