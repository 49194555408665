// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import {WorkerShiftResponse} from '../../../utils/types';
import axiosInstance from '../AxiosInstance';
interface WorkerShiftOptions {
  status?: string;
  agency_id?: string;
  agency_shift_allocation__uid?: string;
}
const fetchData = async (
  options: WorkerShiftOptions
): Promise<WorkerShiftResponse> => {
  const {status, agency_id, agency_shift_allocation__uid} = options;
  const queryParams = new URLSearchParams({
    limit: '10',
    start_date: '2024-04-27T00:00:00Z',
    end_date: '2024-08-27T00:00:00Z',
  });
  if (agency_id !== undefined && agency_id !== '') {
    queryParams.append('agency', agency_id);
  }
  if (status !== undefined && status !== '') {
    queryParams.append('status', status);
  }
  if (
    agency_shift_allocation__uid !== undefined &&
    agency_shift_allocation__uid !== ''
  ) {
    queryParams.append(
      'agency_shift_allocation__uid',
      agency_shift_allocation__uid
    );
  }
  try {
    const response: AxiosResponse<WorkerShiftResponse> =
      // eslint-disable-next-line
      await axiosInstance.get(`/shifts/worker-allocations/?${queryParams}`);
    // eslint-disable-next-line
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetWorkerShifts = (
  options: WorkerShiftOptions
): UseQueryResult<WorkerShiftResponse, Error> => {
  const {status, agency_id, agency_shift_allocation__uid} = options;
  return useQuery(
    ['workershifts', status, agency_id, agency_shift_allocation__uid],
    () => fetchData(options)
  );
};

export default useGetWorkerShifts;
