import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from './AxiosInstance';

interface PatchProviderParams {
  vat_number: string;

  client_number: string;
  uid: string;
}

const patchProvider = async (data: PatchProviderParams) => {
  const response = await axiosInstance.patch(
    `providers/provider/${data.uid}/`,
    data
  );

  return response;
};

const usePatchProvider = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  PatchProviderParams
> => {
  const queryClient = useQueryClient();

  return useMutation(patchProvider, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('providerprofile')
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default usePatchProvider;
