import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import useGetAgencyShifts from '../../hooks/services/Agencys/useGetAgencyShifts';
import useGetProviderProfile from '../../hooks/services/useGetProviderProfile';
import {getDateAWeekAgo, getDateAWeekAhead} from '../../utils/Methods';
import {AgencyShift} from '../../utils/types';

const ProviderAgencyAllocationsListView = () => {
  const navigate = useNavigate();
  const {data: profile, isLoading: profileLoading} = useGetProviderProfile();
  const [status, setStatus] = useState('AWAITING_PROVIDER_CONFIRMATION');
  // eslint-disable-next-line
  const {data, isLoading} = useGetAgencyShifts(
    status,
    profile !== undefined ? profile.uid : '',
    'shift_template__provider__uid',
    getDateAWeekAgo(),
    getDateAWeekAhead()
  );

  if (isLoading || profileLoading) {
    return <Spinner />;
  }

  return (
    <div className="p-4">
      <div className="flex justify-end items-center text-xs space-x-2 mt-4 mb-6">
        <button
          onClick={() => setStatus('AWAITING_AGENCY_CONFIRMATION')}
          className={`${
            status !== 'AWAITING_AGENCY_CONFIRMATION' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Awaiting agency confirmation
        </button>
        <button
          onClick={() => setStatus('AWAITING_PROVIDER_CONFIRMATION')}
          className={`${
            status !== 'AWAITING_PROVIDER_CONFIRMATION' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Awaiting provider confirmation
        </button>

        <button
          onClick={() => setStatus('CONFIRMED')}
          className={`${
            status !== 'CONFIRMED' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Confirmed
        </button>
        <button
          onClick={() => setStatus('PUBLISHED')}
          className={`${
            status !== 'PUBLISHED' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Published
        </button>
        <button
          onClick={() => setStatus('COMPLETED')}
          className={`${
            status !== 'COMPLETED' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Completed
        </button>
        <button
          onClick={() => setStatus('DECLINED')}
          className={`${
            status !== 'DECLINED' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Declined
        </button>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Shift name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Workers required
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Start date
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Start time
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      End time
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Actions
                    </th>
                  </tr>
                </thead>
                {/* eslint-disable-next-line */}
                {data?.results.map((shift: AgencyShift) => (
                  <tbody className="divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.shift_template.name}
                      </td>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.worker_count}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.start_date}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.shift_template.start_time}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.shift_template.end_time}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {shift.status === 'DECLINED' && (
                          <button
                            onClick={() => {
                              // eslint-disable-next-line
                              navigate(`/single-allocation/${shift.uid}`);
                            }}
                            className="px-6 py-2 rounded-full bg-plannaPrimaryGreen text-plannaAccentGreen">
                            View
                          </button>
                        )}
                        {shift.status === 'CONFIRMED' && (
                          <button
                            onClick={() => {
                              // eslint-disable-next-line
                              navigate(`/single-allocation/${shift.uid}`);
                            }}
                            className="px-6 py-2 rounded-full bg-plannaPrimaryGreen text-plannaAccentGreen">
                            View
                          </button>
                        )}
                        {shift.status === 'AWAITING_AGENCY_CONFIRMATION' && (
                          <button
                            onClick={() => {
                              // eslint-disable-next-line
                              navigate(`/single-allocation/${shift.uid}`);
                            }}
                            className="px-6 py-2 rounded-full bg-plannaPrimaryGreen text-plannaAccentGreen">
                            View
                          </button>
                        )}
                        {shift.status === 'AWAITING_PROVIDER_CONFIRMATION' && (
                          <button
                            onClick={() => {
                              // eslint-disable-next-line
                              navigate(`/single-allocation/${shift.uid}`);
                            }}
                            className="px-6 py-2 rounded-full bg-plannaPrimaryGreen text-plannaAccentGreen">
                            View
                          </button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderAgencyAllocationsListView;
