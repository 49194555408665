import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import EditProject from './EditProject';
import ProjectStaffedWorkers from './ProjectStaffedWorkers';
import Spinner from '../../../Components/Common/Spinner';
import circularBack from '../../../assets/icons/Circular-Backward.png';
import useGetAgencyProjectsAllocations from '../../../hooks/services/Agencys/Projects/useGetAgencyProjectAllocations';
import Tabs from '../../Home/Components/Tabs';

const ProjectsOverview = () => {
  const navigate = useNavigate();
  const params = useParams();
  const {data: projectAllocation, isLoading: projectAllocationLoading} =
    useGetAgencyProjectsAllocations({project__uid: params.id});
  const allocationUid =
    projectAllocation !== undefined ? projectAllocation.results[0].uid : '';
  const tabs = [
    {
      id: 'tab1',
      label: 'Project Details',
      component: <EditProject id={params.id !== undefined ? params.id : ''} />,
    },
    {
      id: 'tab2',
      label: 'Staffed Workers',
      component: <ProjectStaffedWorkers project_id={allocationUid} />,
    },
  ];

  if (projectAllocationLoading) return <Spinner />;

  return (
    <div className="p-4">
      <img
        onClick={() => navigate(-1)}
        src={circularBack}
        alt="back"
        className="h-12 w-12 ml-12  cursor-pointer"
      />
      <div className=" p-8 ml-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl text-plannaPrimaryGreen font-bold">
            Manage Workers
          </h1>
          <div className="flex space-x-4">
            <button
              onClick={() =>
                navigate(`/agency/projects/allocate-worker/${allocationUid}`)
              }
              className="px-6 py-2 bg-plannaPrimaryGreen text-plannaAccentGreen rounded-full">
              + Allocate Workers to the project
            </button>
          </div>
        </div>
        <div className="mt-8">
          <Tabs tabs={tabs} />
        </div>
      </div>
    </div>
  );
};

export default ProjectsOverview;
