import {useMutation, useQueryClient, UseMutationResult} from 'react-query';
import {useNavigate} from 'react-router-dom';

import axiosInstance from '../AxiosInstance';

interface CreateAgencyParams {
  name: string;
  first_name: string;
  last_name: string;
  password: string;
  phone_number: string;
  email: string;
  website: string;
  location: string;
  industry: string;
  contract: string;
  shift_type: string;
  provider_uid: string | undefined;
}

const createAgency = async (data: CreateAgencyParams) => {
  const newData =
    data.provider_uid === '' || data.provider_uid === undefined
      ? (({provider_uid, ...rest}: CreateAgencyParams) => rest)(data)
      : {...data};
  const response = await axiosInstance.post('/agency/', newData);

  return response;
};

const useCreateAgency = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  CreateAgencyParams
> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(createAgency, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('agencys')
        .then(() => navigate('/onboarding/thank-you'))
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useCreateAgency;
