// useApiData.ts

import {AxiosResponse} from 'axios';
import {useInfiniteQuery, useQuery, UseQueryResult} from 'react-query';

import axiosInstance from '../AxiosInstance';
type User = {
  first_name: string;
  last_name: string;
  email: string;
};

type Document = {
  file_name: string;
  file: File | null;
  file_type: string;
  original_file_name: string;
};
type ContactDetails = {
  email: string;
  address: string;
  postal_code: string;
  town: string;
  next_of_kin_email: string;
  next_of_kin_name: string;
  next_of_kin_phone_number: string;
  worker_uid: string;
  phone_number: string;
};
type PersonalDetails = {
  pronoun: string;
  nick_name: string;
  nationality: string;
  marital_status: string;
  date_of_birth: string;
  student: boolean;
  taxCode: string;
  national_insurance: string;
};
type WorkExperience = {
  job_title: string;
  company: string;
  start_date: string;
  end_date: string;
};
type BankDetails = {
  bank_name: string;
  account_name: string;
  account_number: string;
  sort_code: string;
};
export type TimeOff = {
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
  occurrences: string[];
  comment: string;
};
export type Availability = {
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
  occurrences: string[];
  worker_timeoff: TimeOff[];
};
type Country = {
  name: string;
};
type Location = {
  region: string;
  country: Country;
  city: string;
  state: string;
  postal_code: string;
};
export type Data = {
  missing_information: string[];
  uid: string;
  user: User;
  worker_personal_details: PersonalDetails;
  worker_experience: WorkExperience[];
  bank_details: BankDetails;
  date_of_birth: string;
  contact_details: ContactDetails;
  worker_documents: Document[];
  agency: string;
  status: string;
  worker_availability: Availability;
  created_at: string;
  location: Location;
};

interface ApiResponse {
  // Define the structure of your API response
  // Example: { data: YourDataType }
  results: Data[];
}
interface WorkerShiftOptions {
  status: string;
  limit: number;
  agency_id: string;
  start_date?: string;
  location_id?: string;
  industries?: string;
  roles?: string;
  end_date?: string;
  employment_status?: string;
  city?: string;
  email?: string;
  phone_number?: string;
  job_title?: string;
  first_name?: string;
  postal_code?: string;
  rating?: string;
}
const fetchData = async (
  options: WorkerShiftOptions,
  page: number
): Promise<ApiResponse> => {
  const {
    status,
    start_date,
    agency_id,
    location_id,
    end_date,
    industries,
    roles,
    employment_status,
    city,
    email,
    phone_number,
    job_title,
    first_name,
    postal_code,
    rating,
    limit,
  } = options;
  const offset = page * limit;
  const queryParams = new URLSearchParams({
    limit: limit.toString(),
    offset: offset.toString(),
    status: status,
    agency__uid: agency_id,
  });

  if (start_date !== undefined && start_date !== '') {
    queryParams.append('start_date', start_date);
  }
  if (end_date !== undefined && end_date !== '') {
    queryParams.append('end_date', end_date);
  }
  if (location_id !== undefined && location_id !== '') {
    queryParams.append('location__uid', location_id);
  }
  if (industries !== undefined && industries !== '') {
    queryParams.append('industries', industries);
  }
  if (roles !== undefined && roles !== '') {
    queryParams.append('roles', roles);
  }
  if (employment_status !== undefined && employment_status !== '') {
    queryParams.append('employment_status', employment_status);
  }
  if (city !== undefined && city !== '') {
    queryParams.append('city', city);
  }
  if (email !== undefined && email !== '') {
    queryParams.append('email', email);
  }
  if (phone_number !== undefined && phone_number !== '') {
    queryParams.append('phone_number', phone_number);
  }
  if (job_title !== undefined && job_title !== '') {
    queryParams.append('job_title', job_title);
  }
  if (postal_code !== undefined && postal_code !== '') {
    queryParams.append('postal_code', postal_code);
  }
  if (first_name !== undefined && first_name !== '') {
    queryParams.append('first_name', first_name);
  }
  if (rating !== undefined && rating !== '') {
    queryParams.append('rating', rating);
  }

  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      // eslint-disable-next-line
      `/workers/?${queryParams}`
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useGetWorkers = (options: WorkerShiftOptions) => {
  return useInfiniteQuery(
    ['workers', options],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    ({pageParam = 0}) => fetchData(options, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.results.length === 10 ? pages.length : undefined;
      },
    }
  );
};

export default useGetWorkers;
