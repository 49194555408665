import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import TemplateDescription from './TemplateDescription';
import TemplateInput from './TemplateInput';
import TemplateSelect from './TemplateSelect';
import TemplateSubmitButton from './TemplateSubmitButton';
import RecurrenceEditor from '../../../Components/Common/RecurrenceEditor';
import Spinner from '../../../Components/Common/Spinner';
import SubmitSpinner from '../../../Components/Common/SubmitSpinner';
import DialogLayout from '../../../Layouts/DialogLayout';
import SuccessLayout from '../../../Layouts/SuccessLayout';
import useGetJobTemplates from '../../../hooks/services/Shifts/GetJobTemplates';
import useGetSingleShiftSchedule from '../../../hooks/services/Shifts/useGetSingleShiftSchedule';
import useUpdateShiftTemplate from '../../../hooks/services/Shifts/useUpdateShiftTemplate';
import useLocationsData from '../../../hooks/services/common/useGetLocations';
import useGetProviderProfile from '../../../hooks/services/useGetProviderProfile';
import {removePrefix} from '../../../utils/Methods';
import AllocateToAgency from '../AllocateToAgency';

const EditShiftTemplate = () => {
  const navigate = useNavigate();
  const params = useParams();
  const initialState = {
    name: '',
    description: '',
    workerCount: '0',
    location: '',
    start_time: '',
    end_time: '',
    start_date: '',
    job_template_uid: '',
    recurrenceString: '',
    agency_uid: '',
    rate_type: '',
  };
  const [formData, setFormData] = useState(initialState);
  const {data: profile, isLoading: profileLoading} = useGetProviderProfile();
  const {data: jobTemplates, isLoading: templateLoading} = useGetJobTemplates({
    provider_id: profile !== undefined ? profile.uid : '',
  });
  const {data: locations, isLoading: locationsLoading} = useLocationsData();
  const {data: singleSchedule, isLoading: singleScheduleLoading} =
    useGetSingleShiftSchedule(params.id !== undefined ? params.id : '');
  const [rateTypes, setRateTypes] = useState<string[]>([]);
  useEffect(() => {
    if (singleSchedule !== undefined) {
      const {
        name,
        description,
        worker_count,
        location,
        start_time,
        end_time,
        job_template,
        recurrence,
        agency_uid,
        rate_type,
        start_date,
      } = singleSchedule.data;
      setFormData({
        name: name,
        description: description,
        workerCount: JSON.stringify(worker_count),
        location: location.uid,
        start_time: start_time,
        end_time: end_time,
        job_template_uid: job_template.uid,
        recurrenceString: recurrence,
        agency_uid: agency_uid,
        //eslint-disable-next-line
        rate_type: rate_type,
        start_date: start_date,
      });
    }
  }, [singleSchedule]);
  const {mutateAsync, isLoading: updatingLoading} = useUpdateShiftTemplate();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const handleClose = () => {
    setIsSuccess(false);
  };
  const [isAssignAgency, setIsAssignAgency] = useState(false);
  const handleAssignAgencyClose = () => {
    setIsAssignAgency(false);
  };
  const onClose = () => setIsOpen(false);
  const ruleCallback = (rule: string) => {
    setFormData({
      ...formData,
      ['recurrenceString']: rule === '' ? rule : `RRULE:${rule}`,
    });
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;

    setFormData({...formData, [name]: value});
  };
  const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };
  const handleTemplateSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target;
    const selectedTemplateUid = value;
    const selectedTemplate = jobTemplates?.results.find(
      (template) => template.uid === selectedTemplateUid
    );
    if (selectedTemplate) {
      // Access the rates array from the selected template
      const ratesArray = selectedTemplate.rates;
      const newRateTypes = ratesArray.map((obj) => obj.rate_type);
      setRateTypes(newRateTypes);
      // You can now use the ratesArray as needed
    }
    setFormData({...formData, [name]: value});
  };
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target;

    setFormData({...formData, [name]: value});
  };
  if (
    templateLoading ||
    locationsLoading ||
    singleScheduleLoading ||
    profileLoading
  ) {
    return <Spinner />;
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newDate = new Date(formData.start_date);
    const targetMonth = newDate.getMonth() + 1; // Month is zero-indexed, so we add 1
    const targetDay = newDate.getDate();
    const recurrence = `RRULE:FREQ=MONTHLY;COUNT=1;BYMONTH=${targetMonth};BYMONTHDAY=${targetDay};`;
    const post = {
      ...formData,
      shift_template_uid: params.id !== undefined ? params.id : '',
      worker_count: parseFloat(formData.workerCount),
      recurrence:
        formData.recurrenceString === ''
          ? recurrence
          : formData.recurrenceString,
      update_type: 'ONLY_ONE or ALL',
    };

    await mutateAsync(post).then(() => setIsSuccess(true));
  };
  const archiveShift = async () => {
    const newDate = new Date(formData.start_date);
    const targetMonth = newDate.getMonth() + 1; // Month is zero-indexed, so we add 1
    const targetDay = newDate.getDate();
    const recurrence = `RRULE:FREQ=MONTHLY;COUNT=1;BYMONTH=${targetMonth};BYMONTHDAY=${targetDay};`;
    const post = {
      ...formData,
      shift_template_uid: params.id !== undefined ? params.id : '',
      worker_count: parseFloat(formData.workerCount),
      recurrence:
        formData.recurrenceString === ''
          ? recurrence
          : formData.recurrenceString,
      update_type: 'ONLY_ONE or ALL',
      status: 'ARCHIVED',
    };

    await mutateAsync(post).then(() => setIsArchive(true));
  };
  return (
    <div className="flex flex-col  items-center mt-6">
      <DialogLayout isOpen={isOpen} onClose={onClose}>
        <div className="mt-2 ">
          <RecurrenceEditor
            rule={removePrefix(formData.recurrenceString)}
            ruleOutputCallback={ruleCallback}
          />
        </div>

        <div className="mt-4">
          <button
            type="button"
            onClick={onClose}
            className=" bg-plannaNeutralGrey text-center text-plannaPrimaryGreen text-lg font-hanken-grotesk font-semibold w-full px-6 py-2.5 rounded-full">
            Save
          </button>
        </div>
      </DialogLayout>
      <DialogLayout isOpen={isAssignAgency} onClose={handleAssignAgencyClose}>
        <AllocateToAgency
          handleTemplateClose={handleAssignAgencyClose}
          shiftId={singleSchedule?.data.uid}
          startDate={singleSchedule?.data.start_date}
          recurrence={singleSchedule?.data.recurrence}
          workerCount={singleSchedule?.data.worker_count}
        />
      </DialogLayout>
      <SuccessLayout isOpen={isSuccess} onClose={handleClose}>
        <h1 className="text-plannaPrimaryGreen text-center font-bold">
          Template updated successfully
        </h1>
        <div className="mt-4 text-center">
          <p
            onClick={() => navigate('/shift-templates')}
            className="text-plannaPrimaryGreen underline cursor-pointer">
            Go to calendar view
          </p>
        </div>
      </SuccessLayout>
      <SuccessLayout isOpen={isArchive} onClose={() => setIsArchive(false)}>
        <h1 className="text-plannaPrimaryGreen text-center font-bold">
          The shift has been archived
        </h1>
      </SuccessLayout>
      <div className="flex  space-x-1">
        <h1 className=" text-left  font-space-grotesk font-bold text-plannaPrimaryGreen text-2xl">
          Edit Shift Schedule
        </h1>
      </div>

      <form onSubmit={onSubmit}>
        <div className="mt-6">
          <p className="mt-4 text-sm font-normal text-plannaPrimaryGreen font-hanken-grotesk">
            Prepare a shift schedule that will meet both your requirements and
            the needs of <br /> your temp workers
          </p>
          <div className="mt-7">
            <TemplateInput
              label="Shift name"
              type="text"
              inputValue={formData.name}
              name="name"
              onChange={handleChange}
              placeholder="weekend shift"
              required
            />
          </div>
          <div className="mt-4">
            <div className=" w-full">
              <label
                htmlFor="inputField"
                className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                Job Template
                <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                  *
                </span>
              </label>
              <select
                value={formData !== undefined ? formData.job_template_uid : ''}
                onChange={handleTemplateSelect}
                name="job_template_uid"
                required
                className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-2 px-3 focus:outline-none">
                <option selected>Select job template</option>
                {jobTemplates?.results.map((template) => {
                  return (
                    <option key={template.uid} value={template.uid}>
                      {template.template_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="mt-1 5">
            <p
              onClick={() => navigate('/job-template/new')}
              className="underline cursor-pointer text-plannaSecondaryGreen50 font-medium text-sm font-hanken-grotesk">
              Create new job template
            </p>
          </div>
          <div className="mt-4">
            <TemplateSelect
              label="Rate Type"
              inputValue={formData.rate_type}
              name="rate_type"
              onChange={handleOnSelect}
              required
              options={rateTypes}
            />
          </div>

          <div className="mt-4">
            <div className=" w-full">
              <label
                htmlFor="inputField"
                className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                Location
                <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                  *
                </span>
              </label>
              <select
                value={formData.location}
                onChange={handleOnSelect}
                name="location"
                required
                className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-2 px-3 focus:outline-none">
                <option selected>Select location</option>
                {locations?.locations.map((location) => {
                  return (
                    <option key={location.uid} value={location.uid}>
                      {location.region},{location.country.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mt-4">
              <TemplateInput
                label="Number of workers"
                type="number"
                inputValue={parseInt(formData.workerCount)}
                name="workerCount"
                onChange={handleChange}
                placeholder="8"
                required
                min="0"
              />
            </div>
            <div className="mt-4">
              <TemplateInput
                label="Date"
                type="date"
                inputValue={formData.start_date}
                name="start_date"
                onChange={handleChange}
                required
                min={formData.start_date}
              />
            </div>
            <div className="mt-1 5">
              <p
                onClick={() => setIsOpen(true)}
                className="underline cursor-pointer text-plannaNeutral font-medium text-sm font-hanken-grotesk">
                Create the same shift on multiple days
              </p>
            </div>
            <div className="mt-4 flex space-x-12">
              <TemplateInput
                label="Start time"
                type="time"
                inputValue={formData.start_time}
                name="start_time"
                onChange={handleChange}
                required
              />
              <TemplateInput
                label="End time"
                type="time"
                inputValue={formData.end_time}
                name="end_time"
                onChange={handleChange}
                required
              />
            </div>
            <div className="mt-4">
              <TemplateDescription
                value={formData.description}
                label="Shift Description"
                name="description"
                onChange={handleDescriptionChange}
                placeholder="Write a shift description"
                required
              />
            </div>

            {singleSchedule?.data.status === 'DRAFT' && (
              <div className="mt-16 mb-8 flex space-x-1 justify-between">
                <TemplateSubmitButton
                  label="Save Changes"
                  isLoading={updatingLoading}
                />

                <button
                  type="button"
                  onClick={() => setIsAssignAgency(true)}
                  className=" bg-plannaNeutralGrey text-plannaPrimaryGreen text-center text-lg font-hanken-grotesk font-semibold  px-6 py-2.5 rounded-full">
                  + Assign to Agency
                </button>
                <button
                  type="button"
                  onClick={archiveShift}
                  className=" bg-plannaTertiaryRed text-center text-plannaNeutralWhite text-lg font-hanken-grotesk font-semibold  px-6 py-2.5 rounded-full">
                  {updatingLoading ? <SubmitSpinner /> : 'Archive Shift'}
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditShiftTemplate;
