import React, {ChangeEvent, useState} from 'react';

import Spinner from '../../../Components/Common/Spinner';
import SubmitSpinner from '../../../Components/Common/SubmitSpinner';
import ErrorHandlingLayout from '../../../Layouts/ErrorHandlingLayout';
import SuccessLayout from '../../../Layouts/SuccessLayout';
import usePatchRate from '../../../hooks/services/Shifts/useUpdateRate';
import useGetProviderProfile from '../../../hooks/services/useGetProviderProfile';
interface RateData {
  rate_name: string;
  rate_duration_type: string;
  base_rate: string;
  break_duration: string;
  minimum_shift_duration_for_breaks: string;
  paid_breaks: string;
  rate_type: string;
  default_margin_type: string;
  default_margin: number;
  uid?: string;
}

interface RateConfig {
  type: string;
  data: RateData;
}

interface FormState {
  rates: RateConfig[];
}
type Props = {
  onClose: () => void;
  handleWeekendRateChange: (
    event: ChangeEvent<HTMLInputElement>,
    rateType: string
  ) => void;
  handleSelectRateChange: (
    event: ChangeEvent<HTMLSelectElement>,
    rateType: string
  ) => void;
  rateFormData: FormState | undefined;
  setRate: (value: boolean) => void;
  edit?: boolean;
};
const CreateRate = ({
  onClose,
  handleWeekendRateChange,
  handleSelectRateChange,
  rateFormData,
  setRate,
  edit,
}: Props) => {
  const {data: profile, isLoading: profileLoading} = useGetProviderProfile();
  const {mutateAsync, isLoading} = usePatchRate();
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const onCloseError = () => setIsError(false);

  if (profileLoading) return <Spinner />;
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (edit && rateFormData !== undefined) {
      const filteredArray = rateFormData.rates
        .filter((obj) => {
          // Filter condition: Include objects where 'name' is not empty
          return parseFloat(obj.data.base_rate) !== 0;
        })
        .map((rate) => {
          const post = {
            rate_type: rate.type,
            rate_name: rate.data.rate_type,
            rate_duration_type: rate.data.rate_duration_type,
            base_rate: parseFloat(rate.data.base_rate),
            break_duration: rate.data.break_duration,
            minimum_shift_duration_for_breaks:
              rate.data.minimum_shift_duration_for_breaks,
            paid_breaks: rate.data.paid_breaks === 'true' ? true : false,
            default_margin_type: rate.data.default_margin_type,
            default_margin: rate.data.default_margin,
            uid: rate.data.uid,
          };
          return post;
        });
      const post = {
        rates: filteredArray,
      };
      await mutateAsync(post).then(() => setIsSuccess(true));
    }
    setRate(true);
    onClose();
  };
  const typesArray = rateFormData?.rates.map((item) => item.type);
  return (
    <div className="p-4">
      <ErrorHandlingLayout
        isOpen={isError}
        onClose={onCloseError}
        title="Duplicate error">
        <p className="text-center mt-2">
          A rate name or rate type selected already exists!!
        </p>
      </ErrorHandlingLayout>
      <SuccessLayout
        isOpen={isSuccess}
        onClose={() => {
          setIsSuccess(false);
          onClose();
        }}>
        <h1 className="text-plannaPrimaryGreen font-bold text-center">
          The rates were edited and saved successfully
        </h1>
      </SuccessLayout>
      <h1 className="text-xl mb-4 font-bold text-plannaPrimaryGreen">
        {edit ? 'Edit' : 'Add'} rates
      </h1>
      <form onSubmit={onSubmit}>
        <div className="flex">
          <div>
            <div className="p-2 bg-plannaNeutralGrey h-16 flex flex-col justify-center">
              <p className="text-black  text-sm font-medium font-hanken-grotesk">
                Rate type
              </p>
            </div>
            {typesArray?.includes('WEEKDAY') && (
              <div className="mt-2">
                <input
                  type="text"
                  name="rate_type"
                  readOnly
                  value="Weekday"
                  onChange={(e) => handleWeekendRateChange(e, 'WEEKDAY')}
                  className="w-10/12  border lg:h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
            {typesArray?.includes('HOLIDAY') && (
              <div className="mt-2">
                <input
                  type="text"
                  name="rate_type"
                  readOnly
                  value="Public holiday"
                  onChange={(e) => handleWeekendRateChange(e, 'HOLIDAY')}
                  className="w-10/12  border lg:h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
            {typesArray?.includes('WEEKNIGHT') && (
              <div className="mt-2">
                <input
                  type="text"
                  name="rate_type"
                  readOnly
                  value="Weeknight"
                  onChange={(e) => handleWeekendRateChange(e, 'WEEKNIGHT')}
                  className="w-10/12  border lg:h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
            {typesArray?.includes('WEEKEND') && (
              <div className="mt-2">
                <input
                  type="text"
                  name="rate_type"
                  readOnly
                  value="Weekend"
                  onChange={(e) => handleWeekendRateChange(e, 'WEEKEND')}
                  className="w-10/12 border lg:h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
          </div>
          <div>
            <div className="p-2 bg-plannaNeutralGrey h-16 flex flex-col justify-center">
              <p className="text-black  text-sm font-medium font-hanken-grotesk">
                {profile?.location.country.name === 'Kenya'
                  ? 'Amount (Kes)'
                  : 'Amount (Gbp)'}
              </p>
            </div>
            {typesArray?.includes('WEEKDAY') && (
              <div className="mt-2">
                <input
                  type="number"
                  name="base_rate"
                  min="0"
                  value={parseFloat(
                    rateFormData?.rates.find((rate) => rate.type === 'WEEKDAY')
                      ?.data.base_rate || ''
                  )}
                  onChange={(e) => handleWeekendRateChange(e, 'WEEKDAY')}
                  className="w-3/4  border h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
            {typesArray?.includes('HOLIDAY') && (
              <div className="mt-2">
                <input
                  type="number"
                  name="base_rate"
                  min="0"
                  value={parseFloat(
                    rateFormData?.rates.find((rate) => rate.type === 'HOLIDAY')
                      ?.data.base_rate || ''
                  )}
                  onChange={(e) => handleWeekendRateChange(e, 'HOLIDAY')}
                  className="w-3/4  border lg:h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
            {typesArray?.includes('WEEKNIGHT') && (
              <div className="mt-2">
                <input
                  type="number"
                  name="base_rate"
                  value={parseFloat(
                    rateFormData?.rates.find(
                      (rate) => rate.type === 'WEEKNIGHT'
                    )?.data.base_rate || ''
                  )}
                  min="0"
                  onChange={(e) => handleWeekendRateChange(e, 'WEEKNIGHT')}
                  className="w-3/4  border h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
            {typesArray?.includes('WEEKEND') && (
              <div className="mt-2">
                <input
                  type="number"
                  name="base_rate"
                  value={parseFloat(
                    rateFormData?.rates.find((rate) => rate.type === 'WEEKEND')
                      ?.data.base_rate || ''
                  )}
                  min="0"
                  onChange={(e) => handleWeekendRateChange(e, 'WEEKEND')}
                  className="w-3/4  border h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
          </div>
          <div>
            <div className="p-2 bg-plannaNeutralGrey h-16 flex flex-col justify-center">
              <p className="text-black  text-sm font-medium font-hanken-grotesk">
                Paid breaks
              </p>
            </div>
            {typesArray?.includes('WEEKDAY') && (
              <div className="mt-2">
                <select
                  name="paid_breaks"
                  value={
                    rateFormData?.rates.find((rate) => rate.type === 'WEEKDAY')
                      ?.data.paid_breaks || ''
                  }
                  onChange={(e) => handleSelectRateChange(e, 'WEEKDAY')}
                  className="w-3/4  border h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none">
                  <option selected>Select an option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            )}
            {typesArray?.includes('HOLIDAY') && (
              <div className="mt-2">
                <select
                  name="paid_breaks"
                  value={
                    rateFormData?.rates.find((rate) => rate.type === 'HOLIDAY')
                      ?.data.paid_breaks || ''
                  }
                  onChange={(e) => handleSelectRateChange(e, 'HOLIDAY')}
                  className="w-3/4  border h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none">
                  <option selected>Select an option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            )}
            {typesArray?.includes('WEEKNIGHT') && (
              <div className="mt-2">
                <select
                  name="paid_breaks"
                  value={
                    rateFormData?.rates.find(
                      (rate) => rate.type === 'WEEKNIGHT'
                    )?.data.paid_breaks || ''
                  }
                  onChange={(e) => handleSelectRateChange(e, 'WEEKNIGHT')}
                  className="w-3/4  border h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none">
                  <option selected>Select an option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            )}
            {typesArray?.includes('WEEKEND') && (
              <div className="mt-2">
                <select
                  name="paid_breaks"
                  value={
                    rateFormData?.rates.find((rate) => rate.type === 'WEEKEND')
                      ?.data.paid_breaks || ''
                  }
                  onChange={(e) => handleSelectRateChange(e, 'WEEKEND')}
                  className="w-3/4  border h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none">
                  <option selected>Select an option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            )}
          </div>
          <div>
            <div className="p-2 bg-plannaNeutralGrey h-16 flex flex-col justify-center">
              <p className="text-black  text-sm font-medium font-hanken-grotesk">
                Break duration
              </p>
            </div>
            {typesArray?.includes('WEEKDAY') && (
              <div className="mt-2">
                <input
                  type="text"
                  name="break_duration"
                  value={
                    rateFormData?.rates.find((rate) => rate.type === 'WEEKDAY')
                      ?.data.break_duration || ''
                  }
                  placeholder="hh:mm"
                  onChange={(e) => handleWeekendRateChange(e, 'WEEKDAY')}
                  className="w-3/4  border h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
            {typesArray?.includes('HOLIDAY') && (
              <div className="mt-2">
                <input
                  type="text"
                  name="break_duration"
                  value={
                    rateFormData?.rates.find((rate) => rate.type === 'HOLIDAY')
                      ?.data.break_duration || ''
                  }
                  placeholder="hh:mm"
                  onChange={(e) => handleWeekendRateChange(e, 'HOLIDAY')}
                  className="w-3/4  border h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
            {typesArray?.includes('WEEKNIGHT') && (
              <div className="mt-2">
                <input
                  type="text"
                  name="break_duration"
                  value={
                    rateFormData?.rates.find(
                      (rate) => rate.type === 'WEEKNIGHT'
                    )?.data.break_duration || ''
                  }
                  placeholder="hh:mm"
                  onChange={(e) => handleWeekendRateChange(e, 'WEEKNIGHT')}
                  className="w-3/4  border h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
            {typesArray?.includes('WEEKEND') && (
              <div className="mt-2">
                <input
                  type="text"
                  name="break_duration"
                  value={
                    rateFormData?.rates.find((rate) => rate.type === 'WEEKEND')
                      ?.data.break_duration || ''
                  }
                  placeholder="hh:mm"
                  onChange={(e) => handleWeekendRateChange(e, 'WEEKEND')}
                  className="w-3/4  border h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
          </div>{' '}
          <div>
            <div className="p-2 bg-plannaNeutralGrey h-16 flex flex-col justify-center">
              <p className="text-black  text-sm font-medium font-hanken-grotesk">
                Minimum shift duration for breaks
              </p>
            </div>
            {typesArray?.includes('WEEKDAY') && (
              <div className="mt-2">
                <input
                  type="text"
                  placeholder="hh:mm"
                  name="minimum_shift_duration_for_breaks"
                  value={
                    rateFormData?.rates.find((rate) => rate.type === 'WEEKDAY')
                      ?.data.minimum_shift_duration_for_breaks || ''
                  }
                  onChange={(e) => handleWeekendRateChange(e, 'WEEKDAY')}
                  className="w-3/4  border h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
            {typesArray?.includes('HOLIDAY') && (
              <div className="mt-2">
                <input
                  type="text"
                  placeholder="hh:mm"
                  name="minimum_shift_duration_for_breaks"
                  value={
                    rateFormData?.rates.find((rate) => rate.type === 'HOLIDAY')
                      ?.data.minimum_shift_duration_for_breaks || ''
                  }
                  onChange={(e) => handleWeekendRateChange(e, 'HOLIDAY')}
                  className="w-3/4  border h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
            {typesArray?.includes('WEEKNIGHT') && (
              <div className="mt-2">
                <input
                  type="text"
                  placeholder="hh:mm"
                  name="minimum_shift_duration_for_breaks"
                  value={
                    rateFormData?.rates.find(
                      (rate) => rate.type === 'WEEKNIGHT'
                    )?.data.minimum_shift_duration_for_breaks || ''
                  }
                  onChange={(e) => handleWeekendRateChange(e, 'WEEKNIGHT')}
                  className="w-3/4  border h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
            {typesArray?.includes('WEEKEND') && (
              <div className="mt-2">
                <input
                  type="text"
                  placeholder="hh:mm"
                  name="minimum_shift_duration_for_breaks"
                  value={
                    rateFormData?.rates.find((rate) => rate.type === 'WEEKEND')
                      ?.data.minimum_shift_duration_for_breaks || ''
                  }
                  onChange={(e) => handleWeekendRateChange(e, 'WEEKEND')}
                  className="w-3/4  border h-12 border-plannaNeutral text-plannaPrimaryGreen rounded-lg py-2 px-3 focus:outline-none"
                />
              </div>
            )}
          </div>
        </div>

        <div className="mt-4 flex justify-center items-center">
          {edit ? (
            <button className=" bg-plannaPrimaryGreen text-white px-6 py-1 rounded-full">
              {isLoading ? <SubmitSpinner /> : 'Edit rates'}
            </button>
          ) : (
            <button className=" bg-plannaPrimaryGreen text-white px-6 py-1 rounded-full">
              Save rates
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default CreateRate;
