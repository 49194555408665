/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {useMutation} from 'react-query';

import axiosInstance from '../../hooks/services/AxiosInstance';

const RQCreateAgencyUser = (onSuccess: any, onError: any) => {
  const sendDirectMessage = async (data: any) => {
    const response = await axiosInstance.post('/agency/agency-users/', data);
    return response?.data;
  };

  return useMutation(sendDirectMessage, {
    onSuccess,
    onError,
  });
};

export default RQCreateAgencyUser;
