import moment from 'moment';
import React from 'react';
import {FaFilePdf} from 'react-icons/fa';

import Input from '../../../Components/Registration/Input';
type Document = {
  file_name: string;
  file: File | null;
  file_type: string;
  original_file_name: string;
  url: string;
  expiry_date: string;
};
type Props = {
  documents: Document[] | undefined;
};
const Documents = ({documents}: Props) => {
  return (
    <div className="p-4 w-full">
      {documents !== undefined && documents.length > 0 ? (
        <div className="flex flex-wrap">
          {documents.map((document, index) => (
            <>
              {document.file_name !== null && (
                <div className="w-64">
                  <div key={index} className="mt-4 ">
                    <Input
                      inputValue={document.file_name}
                      label="Document Name"
                      type="text"
                      name="file_name"
                      readonly
                    />
                  </div>

                  <div className="mt-2 ">
                    <a
                      className=" text-plannaPrimaryGreen"
                      href={document.url !== null ? document.url : '#'}
                      target="_blank">
                      <label className="w-3/4 h-36 flex items-center justify-center bg-plannaNeutralMedium border border-gray-300 rounded-md">
                        <FaFilePdf />
                      </label>
                    </a>

                    <div key={index} className="mt-4 ">
                      <Input
                        inputValue={
                          document.expiry_date === null ||
                          document.expiry_date === ''
                            ? 'No expiry date'
                            : moment(document.expiry_date).format(
                                'MMMM Do YYYY'
                              )
                        }
                        label="Expiry Date"
                        type="text"
                        name="file_name"
                        readonly
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      ) : (
        <h1>Documents are currently null</h1>
      )}
    </div>
  );
};

export default Documents;
