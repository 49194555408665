import React, {useState} from 'react';

import Input from '../../Components/Registration/Input';
import SuccessLayout from '../../Layouts/SuccessLayout';
import useInviteAgency from '../../hooks/services/Agencys/useInviteAgency';

const InviteAgency = () => {
  const initialState = {
    agency_name: '',
    agency_email: '',
  };
  const [formData, setFormData] = useState(initialState);
  const [open, setIsOpen] = useState(false);
  const openDialog = () => {
    setIsOpen(true);
  };
  const {mutate, isLoading} = useInviteAgency(openDialog);
  const onClose = () => {
    setIsOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;

    setFormData({...formData, [name]: value});
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(formData);
  };

  return (
    <div className=" p-4 mt-16">
      <SuccessLayout isOpen={open} onClose={onClose}>
        <div className="p-4 flex flex-col items-center justify-center">
          <p className="text-lg font-bold">Agent Invited</p>
          <p>The agent has been invited sucessfully.</p>
        </div>
      </SuccessLayout>
      <h1 className="text-lg ml-24  font-bold">Invite an agency</h1>
      <div className="flex flex-col items-center justify-center">
        <div className="card w-10/12 p-4">
          <form onSubmit={onSubmit}>
            <div className="mt-4">
              <Input
                inputValue={formData.agency_name}
                type="text"
                label="Agency Name"
                required
                name="agency_name"
                onChange={handleChange}
              />
            </div>
            <div className="mt-2">
              <Input
                inputValue={formData.agency_email}
                type="email"
                label="Agency Email"
                required
                name="agency_email"
                onChange={handleChange}
              />
            </div>

            <div className="mt-4">
              <button className="px-4 py-2 text-white w-3/4 bg-plannaPrimaryGreen rounded-xl mt-8 focus:outline-none">
                {isLoading ? 'inviting...' : 'Invite Agency'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InviteAgency;
