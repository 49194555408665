import React from 'react';

import BankDetails from './Components/BankDetails';
import ContactInformation from './Components/ContactInformation';
import Documents from './Components/Documents';
import PersonalDetails from './Components/PersonalDetails';
import WorkExperience from './Components/WorkExperience';
import {ApiResponse} from '../../hooks/services/Agencys/useGetSingleWorker';

type Props = {
  data: ApiResponse | undefined;
};
const WorkerDetails = ({data}: Props) => {
  const identificationDocuments = data?.Worker.worker_documents.filter(
    (obj) => obj.document_type === 'CERTIFICATE' || obj.document_type === null
  );
  const licenses = data?.Worker.worker_documents.filter(
    (obj) => obj.document_type === 'LICENSE'
  );
  return (
    <>
      <div className="mt-16">
        <h1 className=" text-plannaPrimaryGreen text-xl font-bold font-space-grotesk">
          Personal Details
        </h1>
        <PersonalDetails
          personalDetails={data?.Worker.worker_personal_details}
          user={data?.Worker.user}
        />
      </div>
      <div className="mt-16">
        <h1 className=" text-plannaPrimaryGreen text-xl font-bold font-space-grotesk">
          Contact Information
        </h1>
        <ContactInformation contacts={data?.Worker.contact_details} />
      </div>
      <div className="mt-16">
        <h1 className=" text-plannaPrimaryGreen text-xl font-bold font-space-grotesk">
          Work Experience
        </h1>
        <WorkExperience experiences={data?.Worker.worker_experience} />
      </div>
      <div className="mt-16">
        <h1 className=" text-plannaPrimaryGreen text-xl font-bold font-space-grotesk">
          Bank Details
        </h1>
        <BankDetails bankDetails={data?.Worker.bank_details} />
      </div>
      <div className="mt-16">
        <h1 className=" text-plannaPrimaryGreen text-xl font-bold font-space-grotesk">
          Identification Documents
        </h1>
        <Documents documents={identificationDocuments} />
      </div>
      <div className="mt-16">
        <h1 className=" text-plannaPrimaryGreen text-xl font-bold font-space-grotesk">
          Licenses & Trainings
        </h1>
        <Documents documents={licenses} />
      </div>
    </>
  );
};

export default WorkerDetails;
