// usecreateShift.ts
import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

interface UpdateAgencyAllocationParams {
  agency_shift_allocation_uid: string;
  status: string;
}

const updateAgencyAllocation = async (data: UpdateAgencyAllocationParams) => {
  const response = await axiosInstance.patch(
    '/shifts/agency-allocations/',
    data
  );

  return response;
};

const useUpdateAgencyAllocations = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  UpdateAgencyAllocationParams
> => {
  const queryClient = useQueryClient();

  return useMutation(updateAgencyAllocation, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('singleAllocation')
        .then((data) => {
          return data;
        })
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useUpdateAgencyAllocations;
