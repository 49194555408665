/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import Spinner from '../../Components/Common/Spinner';
import BasicDetails from '../../Components/Registration/BasicDetails';
import KnockoutQuestions from '../../Components/Registration/KnockoutQuestions';
import OnBoardingQuestions from '../../Components/Registration/OnBoardingQuestions';
import WorkExperience from '../../Components/Registration/WorkExperience';
import ErrorHandlingLayout from '../../Layouts/ErrorHandlingLayout';
import useCreateWorker from '../../hooks/services/RegisterWorker';
import {getLocationInfo, roundTo6DecimalPlaces} from '../../utils/Methods';
export type Location = {
  longitude: number | null;
  latitude: number | null;
  country_code: string | null;
  google_place_id: string | null;
  state: string | null;
  city: string | null;
  street: string | null;
  postal_code: string | null;
  region: string | null;
};
type WorkerType = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  citizen: string;
  country: string;
  agency: string | undefined;
  persona: string;
  experience: string;
  industries: string[];
  roles: string[];
  day: string;
  month: string;
  year: string;
  phone_number: string;
  portifolio_url: string;
  location: Location;
};

const Worker = () => {
  const params = useParams();

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const navigate = useNavigate();
  const {mutateAsync, isLoading} = useCreateWorker();
  const initialState: WorkerType = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    citizen: '',
    country: '',
    agency: params.id !== undefined ? params.id : '',
    persona: '',
    experience: '',
    industries: [],
    roles: [],
    day: '',
    month: '',
    year: '',
    phone_number: '',
    portifolio_url: '',
    location: {
      longitude: 0,
      latitude: 0,
      country_code: '',
      google_place_id: '',
      state: '',
      city: '',
      street: '',
      postal_code: '',
      region: '',
    },
  };
  const [locationInfo, setLocationInfo] = useState<Location>({
    longitude: 0,
    latitude: 0,
    country_code: '',
    google_place_id: '',
    state: '',
    city: '',
    street: '',
    postal_code: '',
    region: '',
  });
  const [longitude, setLongitude] = useState<number>(0);
  const [latitude, setLatitude] = useState<number>(0);
  const [placeId, setPlaceId] = useState('');
  const handleSetLocation = (place: any) => {
    const geocoder = new window.google.maps.Geocoder();
    // eslint-disable-next-line
    geocoder.geocode(
      {placeId: place.value.place_id},
      (results: any, status: any) => {
        if (status === 'OK' && results[0]) {
          const {lat, lng} = results[0].geometry.location;
          setLatitude(parseFloat(lat()));
          setLongitude(parseFloat(lng()));
          setPlaceId(place.value.place_id);
        }
      }
    );
    setValue('location', place);
  };

  useEffect(() => {
    getLocationInfo(latitude, longitude)
      .then((locationInfoz) => {
        setLocationInfo((prevLocationInfo) => ({
          ...prevLocationInfo,
          longitude: roundTo6DecimalPlaces(longitude),
          latitude: roundTo6DecimalPlaces(latitude),
          country_code: locationInfoz.countryCode,
          google_place_id: placeId,
          state: locationInfoz.state,
          city: locationInfoz.city,
          postal_code: locationInfoz.postalCode,
          region: locationInfoz.region,
          street: locationInfoz.street,
        }));
      })
      .catch((errr: string) => {
        return errr;
      });
  }, [latitude, longitude, placeId]);
  const [formData, setFormData] = useState(initialState);
  const [step, setStep] = useState<number>(1);
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleCitizenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const handleIndustryToggle = (industry: string) => {
    if (formData.industries.includes(industry)) {
      setFormData({
        ...formData,
        ['industries']: formData.industries.filter(
          (selected) => selected !== industry
        ),
      });
    } else {
      const newIndustries = [...formData.industries, industry];
      setFormData({...formData, ['industries']: newIndustries});
    }
  };
  const handleRolesToggle = (role: string) => {
    if (formData.roles.includes(role)) {
      setFormData({
        ...formData,
        ['roles']: formData.roles.filter((selected) => selected !== role),
      });
    } else {
      const newRoles = [...formData.roles, role];
      setFormData({...formData, ['roles']: newRoles});
    }
  };
  const currentYear: number = new Date().getFullYear();
  const handleNextStep = () => {
    if (
      formData.citizen === 'no' ||
      currentYear - parseInt(formData.year) < 18
    ) {
      navigate('/onboarding/thank-you-not-registered');
    } else {
      setStep((prevStep) => {
        if (prevStep === 1 && params.id === process.env.REACT_APP_OUTERBOX_ID) {
          return prevStep + 2;
        } else {
          return prevStep + 1;
        }
      });
    }
  };
  const handlePreviousStep = () => {
    setStep((prevStep) => {
      if (prevStep === 3 && params.id === process.env.REACT_APP_OUTERBOX_ID) {
        return prevStep - 2;
      } else {
        return prevStep - 1;
      }
    });
  };
  const onSubmit = async () => {
    if (formData.experience === 'no') {
      navigate('/onboarding/thank-you-not-registered');
    } else {
      // await mutateAsync(formData).catch(() => setIsOpen(false));
    }
  };
  const createWorker = async (values: any) => {
    if (step !== 3) {
      handleNextStep();
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (values?.experience === 'no') {
      navigate('/onboarding/thank-you-not-registered');
    } else {
      // Use newValues as needed, but ensure you handle fileData separately for uploading
      const newValues = {
        ...values,
        agency_uid: params.id !== undefined ? params.id : '',
        locationData: {
          longitude: locationInfo.longitude,
          latitude: locationInfo.latitude,
          country_code: locationInfo.country_code,
          google_place_id: locationInfo.google_place_id,
          state: locationInfo.state,
          city: locationInfo.city,
          street: locationInfo.street,
          postal_code: locationInfo.postal_code,
          region: locationInfo.region,
        },
        roles:
          params.id !== undefined &&
          params.id === process.env.REACT_APP_OUTERBOX_ID
            ? [values.roles]
            : values.roles,
      };

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      await mutateAsync(newValues)
        .then(() =>
          toast.success(`Worker registered successfully`, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        )
        .catch(() =>
          toast.error('There was an error, the email is already registered', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        );
    }
  };
  if (isLoading) return <Spinner />;
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <BasicDetails
            register={register}
            errors={errors}
            formData={formData}
            handleChange={handleChange}
            handleNext={handleNextStep}
            step={step}
            handleSelect={handleSelect}
            setValue={setValue}
            handleSetLocation={handleSetLocation}
            id={params.id !== undefined ? params.id : ''}
          />
        );
      case 2:
        return (
          <KnockoutQuestions
            watch={watch}
            setValue={setValue}
            register={register}
            errors={errors}
            formData={formData}
            handleNext={handleNextStep}
            handleSelect={handleSelect}
            step={step}
            handlePrevious={handlePreviousStep}
            handleCitizenChange={handleCitizenChange}
          />
        );

      case 3:
        return (
          <WorkExperience
            id={params.id !== undefined ? params.id : ''}
            register={register}
            errors={errors}
            watch={watch}
            getValues={getValues}
            setValue={setValue}
            formData={formData}
            handleNext={onSubmit}
            step={step}
            handlePrevious={handlePreviousStep}
            handleCitizenChange={handleCitizenChange}
            handleIndustryToggle={handleIndustryToggle}
            handleRolesToggle={handleRolesToggle}
            isLoading={isLoading}
          />
        );
    }
  };

  return (
    <>
      <ErrorHandlingLayout
        isOpen={isOpen}
        onClose={onClose}
        title="Registration Error">
        <p className="mt-2 text-center">
          A user with this email is already registered, please continue to the
          worker app
        </p>
      </ErrorHandlingLayout>

      <form
        onSubmit={handleSubmit(createWorker)}
        className="form-action inner-max-width-onboarding">
        {renderStep()}
        <button className="btn primary full">Next</button>
      </form>
    </>
  );
};

export default Worker;
