// useApiData.ts

import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import axiosInstance from '../AxiosInstance';
type Country = {
  name: string;
};
type Locations = {
  city: string;
  uid: string;
  state: string;
  region: string;
  country: Country;
};

interface ApiResponse {
  locations: Locations[];
}

const fetchData = async (): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> =
      await axiosInstance.get('/common/locations/');
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    // eslint-disable-next-line
    throw new Error(error.message);
  }
};

const useLocationsData = (): UseQueryResult<ApiResponse, Error> => {
  return useQuery('locations', fetchData);
};

export default useLocationsData;
