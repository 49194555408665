/* eslint-disable @typescript-eslint/no-unsafe-return */
import {useQuery} from 'react-query';

import axiosInstance from '../../hooks/services/AxiosInstance';

const RQGetProviders = (uid: any) => {
  const getAgencyProviders = async () => {
    const response = await axiosInstance.get(`/providers/?agency_id=${uid}`);

    return response?.data;
  };

  return useQuery(['agency-providers', uid], getAgencyProviders, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 15,
  });
};

export default RQGetProviders;
