import './App.css';
import './style.css';
import {Suspense} from 'react';
import {ToastContainer, toast} from 'react-toastify';

import AppBar from './Components/Common/AppBar';
import Sidebar from './Components/Common/Sidebar';
import Spinner from './Components/Common/Spinner';
import RoutesComponent from './Routes';
import useAuthToken from './hooks/services/useAuthToken';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css/animate.css';
import 'react-chat-elements/dist/main.css';
import '@syncfusion/ej2-base/styles/tailwind.css';
import '@syncfusion/ej2-buttons/styles/tailwind.css';
import '@syncfusion/ej2-calendars/styles/tailwind.css';
import '@syncfusion/ej2-dropdowns/styles/tailwind.css';
import '@syncfusion/ej2-inputs/styles/tailwind.css';
import '@syncfusion/ej2-lists/styles/tailwind.css';
import '@syncfusion/ej2-navigations/styles/tailwind.css';
import '@syncfusion/ej2-popups/styles/tailwind.css';
import '@syncfusion/ej2-splitbuttons/styles/tailwind.css';
import '@syncfusion/ej2-react-schedule/styles/tailwind.css';
import Scroll from './utils/Scroll';

function App() {
  const {token} = useAuthToken();

  return (
    <Suspense fallback={<Spinner />}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        className="w-full"
      />
      <div className="flex h-screen  bg-white">
        {token !== null && <Sidebar />}

        <div
          className={`main-content-container${
            token !== null ? ' side-content' : ''
          } `}>
          {/* <div
          className={`flex-1 lg:w-full ${
            token !== null ? 'lg:ml-64' : 'lg:ml-0 p-0'
          }   sm:w-full overflow-x-hidden overflow-y-auto `}> */}
          {token !== null && <AppBar />}
          <div style={{marginTop: '80px', width: '100%', overflowY: 'auto'}}>
            {/* <Scroll /> */}
            <RoutesComponent />
          </div>
          {/* </div> */}
        </div>
      </div>
    </Suspense>
  );
}

export default App;
