import {useMutation, UseMutationResult, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';

import axiosInstance from '../AxiosInstance';

interface ChangeWorkerStatusParams {
  comment: string;
  status: string;
  worker_id: string;
}

const changeWorkerStatus = async (data: ChangeWorkerStatusParams) => {
  const response = await axiosInstance.put(
    `/agency/approve-worker/${data.worker_id}`,
    data
  );

  return response;
};

const useChangeWorkerStatus = (
  worker_id: string
): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  ChangeWorkerStatusParams
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(changeWorkerStatus, {
    onSuccess: () => {
      queryClient
        .invalidateQueries(['worker', worker_id])
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useChangeWorkerStatus;
