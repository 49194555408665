/* eslint-disable @typescript-eslint/restrict-template-expressions */

import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';

const AgencyFinanceReports = () => {
  const {data: agencyData} = useGetAgencyProfile();

  const iframeUrl = `https://lookerstudio.google.com/embed/u/0/reporting/6a624e70-c63b-460f-922f-cfca7c152059/page/jtP1D?params=%7B%22df17%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${agencyData?.uid}%22%7D`;

  return (
    <div className="p-4">
      <iframe
        height="1800"
        className="w-full"
        src={iframeUrl}
        frameBorder="0"
        style={{border: '0'}}
        allowFullScreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      />
    </div>
  );
};

export default AgencyFinanceReports;
