// usecreateShift.ts
import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from '../../AxiosInstance';

interface EditProjectParams {
  title: string;
  description: string;
  workers_count: string;
  start_date: string;
  end_date: string;
  status: string;
  type: string;
  rate: string;
  additional_links: string;
  project_uid: string;
}

const editProject = async (data: EditProjectParams) => {
  const response = await axiosInstance.patch(
    `/projects/update/${data.project_uid}/`,
    data
  );

  return response;
};

const useEditProject = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  EditProjectParams
> => {
  const queryClient = useQueryClient();
  return useMutation(editProject, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('projects')
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useEditProject;
