import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import Input from '../../Components/Registration/Input';
import ErrorHandlingLayout from '../../Layouts/ErrorHandlingLayout';
import SuccessLayout from '../../Layouts/SuccessLayout';
import circularBack from '../../assets/icons/Circular-Backward.png';
import useGetProviderProfile from '../../hooks/services/useGetProviderProfile';

const ProfileDetails = () => {
  const navigate = useNavigate();
  const {data, isLoading} = useGetProviderProfile();
  const initialState = {
    vat_number: '',

    client_number: '',
    phone_number: '',
    uid: '',
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    if (data !== undefined) {
      const {
        vat_number,

        client_number,
        phone_number,
        uid,
      } = data;
      setFormData({
        vat_number,

        client_number,
        phone_number,
        uid,
      });
    }
  }, [data]);
  const [isOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const onClose = () => setIsOpen(false);
  const onErrorClose = () => setIsError(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="mx-8">
      <ErrorHandlingLayout
        isOpen={isError}
        onClose={onErrorClose}
        title="Provider update error">
        <p className="mt-2 text-center">
          Some fields are null, please fill all the fields
        </p>
      </ErrorHandlingLayout>
      <SuccessLayout isOpen={isOpen} onClose={onClose}>
        <p className="text-lg font-bold">Business details updated</p>
        <p>The details have been updated sucessfully.</p>
      </SuccessLayout>
      <img
        onClick={() => navigate(-1)}
        src={circularBack}
        alt="back"
        className="h-12 w-12  cursor-pointer"
      />
      <h1 className="text-4xl text-plannaPrimaryGreen font-bold mt-9">
        Business Details
      </h1>
      <p className=" text-sm text-plannaPrimaryGreen mt-2 font-normal">
        Manage your business details
      </p>
      <div className="lg:w-8/12 w-full">
        <form onSubmit={onSubmit}>
          <div className="mt-4">
            <Input
              inputValue={data?.company.name}
              name="company"
              type="text"
              label="Company_name"
              readonly
            />
          </div>
          <div className="mt-2">
            <Input
              inputValue={formData.vat_number}
              name="vat_number"
              onChange={handleChange}
              type="text"
              label="Company Vat number"
            />
          </div>
          <div className="mt-2">
            <Input
              inputValue={formData.client_number}
              name="client_number"
              onChange={handleChange}
              type="text"
              label="Company client number"
            />
          </div>
          <div className="mt-2">
            <Input
              inputValue={formData.phone_number}
              name="phone_number"
              onChange={handleChange}
              type="text"
              label="Company phone number"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileDetails;
