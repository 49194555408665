import React, {ChangeEvent, useEffect, useState} from 'react';

import Spinner from '../../../Components/Common/Spinner';
import SubmitSpinner from '../../../Components/Common/SubmitSpinner';
import SuccessLayout from '../../../Layouts/SuccessLayout';
import useDeleteProject from '../../../hooks/services/Agencys/Projects/useDeleteProject';
import useEditProject from '../../../hooks/services/Agencys/Projects/useEditProjects';
import useGetProjects from '../../../hooks/services/Agencys/Projects/useGetProjects';
import TemplateCancelButton from '../../Shifts/Components/TemplateCancelButton';
import TemplateDescription from '../../Shifts/Components/TemplateDescription';
import TemplateInput from '../../Shifts/Components/TemplateInput';
import TemplateSubmitButton from '../../Shifts/Components/TemplateSubmitButton';

type Props = {
  id: string;
};
const EditProject = ({id}: Props) => {
  const [success, setSuccess] = useState(false);
  const {data: project, isLoading: projectLoading} = useGetProjects({
    uid: id,
  });

  const initialState = {
    title: '',
    description: '',
    workers_count: '0',
    start_date: '',
    end_date: '',
    rate: '',
    type: '',
    additional_links: '',
    status: 'ASSIGNED',
    project_uid: '',
    rate_type: '',
  };

  const {mutateAsync, isLoading} = useEditProject();
  const {mutate, isLoading: projectDeleteLoading} = useDeleteProject();
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    const singleProject = project?.results[0];
    if (singleProject !== undefined) {
      setFormData({
        title: singleProject.title,
        description: singleProject.description,
        workers_count: singleProject.workers_count.toString(),
        start_date: singleProject.start_date,
        end_date: singleProject.end_date,
        rate: singleProject.rate,
        type: singleProject.type,
        additional_links: singleProject.additional_links,
        status: 'ASSIGNED',
        project_uid: singleProject.uid,
        rate_type: singleProject.rate_type,
      });
    }
  }, [project?.results]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type} = e.target;
    if (type === 'number') {
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData({...formData, [name]: numericValue});
    }

    setFormData({...formData, [name]: value});
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target;

    setFormData({...formData, [name]: value});
  };
  const handleTemplateSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await mutateAsync(formData).then(() => setSuccess(true));
  };
  const onDelete = () => {
    mutate(id);
  };
  if (projectLoading) {
    return <Spinner />;
  }
  console.log(project);
  return (
    <div className="p-4">
      <div className="flex flex-col mt-6 w-3/4 ">
        <SuccessLayout isOpen={success} onClose={() => setSuccess(false)}>
          <p>Project updated successfully</p>
        </SuccessLayout>
        <form onSubmit={onSubmit} className="w-full">
          <div className="mt-6">
            <h1 className="text-center font-space-grotesk font-bold text-plannaPrimaryGreen text-2xl">
              Edit this Project
            </h1>
            <p className="mt-4 text-sm font-normal text-center text-plannaPrimaryGreen font-hanken-grotesk">
              Prepare a project that will meet both your requirements and the
              needs of your workers, save and later on <br /> allocate to the
              workers
            </p>
            <div className="mt-7">
              <TemplateInput
                label="Project Name"
                type="text"
                inputValue={formData.title}
                name="title"
                onChange={handleChange}
                placeholder="weekend shift"
                required
              />
            </div>
            <div className="mt-7">
              <TemplateInput
                label="Project Type"
                type="text"
                inputValue={formData.type}
                name="type"
                onChange={handleChange}
                placeholder="e.g software engineering"
                required
              />
            </div>
            <div className="mt-4">
              <div className="mt-4">
                <TemplateInput
                  label="Project rate in $"
                  type="text"
                  inputValue={formData.rate}
                  name="rate"
                  onChange={handleChange}
                  placeholder="8"
                  required
                  min="0"
                />
              </div>
              <div className="mt-4">
                <div className=" w-full">
                  <label
                    htmlFor="inputField"
                    className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen">
                    Rate Type
                    <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
                      *
                    </span>
                  </label>
                  <select
                    value={formData.rate_type}
                    onChange={handleTemplateSelect}
                    name="rate_type"
                    required
                    className="mt-2 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-3 px-3 focus:outline-none">
                    <option selected>Select a rate type</option>
                    {['Hourly', 'Monthly', 'Weekly', 'Biweekly'].map((type) => {
                      return (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="mt-4">
                <TemplateInput
                  label="Number of workers"
                  type="number"
                  inputValue={parseInt(formData.workers_count)}
                  name="workers_count"
                  onChange={handleChange}
                  placeholder="8"
                  required
                  min="0"
                />
              </div>
              <div className="mt-4">
                <TemplateInput
                  label="Start Date"
                  type="date"
                  inputValue={formData.start_date}
                  name="start_date"
                  onChange={handleChange}
                  required
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>
              <div className="mt-4">
                <TemplateInput
                  label="End Date"
                  type="date"
                  inputValue={formData.end_date}
                  name="end_date"
                  onChange={handleChange}
                  required
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>
              <div className="mt-4">
                <TemplateInput
                  label="Project additional links"
                  type="text"
                  inputValue={formData.additional_links}
                  name="additional_links"
                  onChange={handleChange}
                  placeholder="https://docs.google.com/"
                  required
                />
              </div>
              <div className="mt-4">
                <TemplateDescription
                  value={formData.description}
                  label="Project Description"
                  name="description"
                  onChange={handleDescriptionChange}
                  placeholder="Write a project description"
                  required
                />
              </div>
              <div className="mt-16 mb-8 space-x-6 flex justify-between">
                <TemplateCancelButton label="Cancel" />
                <TemplateSubmitButton
                  label="Edit Project"
                  isLoading={isLoading}
                  shift
                />
                <button
                  type="button"
                  onClick={onDelete}
                  className="bg-plannaTertiaryRed text-center text-plannaNeutralWhite text-lg font-hanken-grotesk font-semibold w-full px-6 py-2.5 rounded-full">
                  {projectDeleteLoading ? <SubmitSpinner /> : 'Delete Project'}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProject;
