import React from 'react';
type Question = {
  question: string;
  expected_answer: null;
};
type Props = {
  questions: Question[];
  handleQuestionChange: (index: number, newName: string) => void;
  handleRemoveQuestion: (index: number) => void;
};
const Question = ({
  questions,
  handleQuestionChange,
  handleRemoveQuestion,
}: Props) => {
  return (
    <div className="mt-7">
      {questions.map((question, index) => (
        <>
          <label
            htmlFor="inputField"
            className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ">
            Enter a question
            <span className="text-sm font-normal font-hanken-grotesk text-plannaPrimaryGreen ml-1">
              *
            </span>
          </label>

          <textarea
            id={`question${index}`}
            value={question.question}
            onChange={(e) => handleQuestionChange(index, e.target.value)}
            name="question"
            placeholder="Enter a question you want your workers to answer"
            className="mt-2 h-32 text-lg font-normal text-plannaPrimaryGreen font-hanken-grotesk w-full  rounded-lg border border-plannaNeutral py-2 px-3 focus:outline-none"
          />

          {questions.length > 1 && (
            <div className="flex justify-end mt-2">
              <button
                onClick={() => handleRemoveQuestion(index)}
                className=" bg-plannaNeutral w-1/4 px-4 py-2 text-white rounded-full">
                Remove question
              </button>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default Question;
