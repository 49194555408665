import ProviderAgencyAllocationsListView from './ProviderAgencyAllocations';
const ProviderAgencyAllocations = () => {
  return (
    <div className="p-4">
      <h1 className="text-plannaPrimaryGreen font-bold text-xl">
        Provider Agency Allocations
      </h1>

      <ProviderAgencyAllocationsListView />
    </div>
  );
};

export default ProviderAgencyAllocations;
