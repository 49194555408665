import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../Components/Common/Spinner';
import useGetAgencyProfile from '../../hooks/services/Agencys/useGetAgencyProfile';
import useGetWorkerShiftsApplications from '../../hooks/services/Agencys/useWorkerShiftApplication';
import {WorkerShift} from '../../utils/types';

const WorkerShiftApplications = () => {
  const navigate = useNavigate();
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile();
  const [status, setStatus] = useState('CONFIRMED');
  const {data, isLoading} = useGetWorkerShiftsApplications({
    status: status,
    agency_id: profile !== undefined ? profile.uid : '',
  });
  if (isLoading || profileLoading) return <Spinner />;

  return (
    <div className="p-4">
      <div className="flex justify-end items-center text-xs space-x-2 mt-4 mb-6">
        <button
          onClick={() => setStatus('AWAITING_CONFIRMATION')}
          className={`${
            status !== 'AWAITING_CONFIRMATION' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Awaiting confirmation
        </button>
        <button
          onClick={() => setStatus('CONFIRMED')}
          className={`${
            status !== 'CONFIRMED' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Confirmed
        </button>

        <button
          onClick={() => setStatus('COMPLETED')}
          className={`${
            status !== 'COMPLETED' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Completed
        </button>
        <button
          onClick={() => setStatus('DECLINED')}
          className={`${
            status !== 'DECLINED' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Declined
        </button>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Shift name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Worker Name
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Start date
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Start time
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      End time
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Actions
                    </th>
                  </tr>
                </thead>
                {/* eslint-disable-next-line */}
                {data?.results.map((shift: WorkerShift) => (
                  <tbody className="divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.agency_shift_allocation.shift_template.name}
                      </td>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.worker.user.first_name}{' '}
                        {shift.worker.user.last_name}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.agency_shift_allocation.start_date}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {
                          shift.agency_shift_allocation.shift_template
                            .start_time
                        }
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* eslint-disable-next-line */}
                        {shift.agency_shift_allocation.shift_template.end_time}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {/* {shift.status === 'AWAITING_CONFIRMATION' && (
                          <div className="flex space-x-2">
                            <button
                              // eslint-disable-next-line
                      onClick={() => handleAcceptShiftApplication(shift.uid)}
                              className="px-6 py-2 rounded-full bg-plannaPrimaryGreen text-plannaAccentGreen">
                              {actionLoading ? <SubmitSpinner /> : 'Accept'}
                            </button>
                            <button // eslint-disable-next-line
                      className="px-6 py-2 rounded-full bg-plannaTertiaryRed text-plannaNeutralWhite">
                              Reject
                            </button>
                          </div>
                        )} */}
                        <button
                          // eslint-disable-next-line
                          onClick={() =>
                            navigate(`/agency/application-worker/${shift.uid}`)
                          }
                          className="px-6 py-2 rounded-full bg-plannaPrimaryGreen text-plannaAccentGreen">
                          View
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkerShiftApplications;
