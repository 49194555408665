import React from 'react';
import {useNavigate} from 'react-router-dom';

import Navbar from './Navbar';
import plannaButterfly from '../../assets//icons/planna-butterfly.png';
import circularForward from '../../assets/icons/circular-forward.png';
import people from '../../assets/icons/people.png';

const OnBoardingPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <div className="flex flex-col mt-28 justify-center items-center">
        <img src={plannaButterfly} alt="planna" className="h-fit w-fit" />
        <h1 className="mt-8 text-plannaPrimaryGreen text-5xl">
          It’s all in Planna
        </h1>
        <p className="text-plannaPrimaryGreen md:text-left text-center mt-4 text-lg">
          To begin this journey, tell us what type of account you’d be opening.
        </p>
        <div
          onClick={() => navigate('/new/agency')}
          className=" lg:w-5/12 w-full p-4 cursor-pointer border border-plannaPrimaryGreen rounded-lg mt-12 flex items-center justify-between"
          style={{borderColor: '#C2CDD0'}}>
          <img src={people} alt="people" className="h-fit w-fit" />
          <div className="p-2">
            <h1 className="text-lg text-plannaPrimaryGreen font-semibold">
              Sign up as an agency
            </h1>
            <p className="text-plannaPrimaryGreen">
              For our frontline superstars. Here you can sign up to Planna,
              <br />
              create a profile and start earning money.
            </p>
          </div>
          <img src={circularForward} alt="forward" className="h-fit w-fit" />
        </div>
        <div
          onClick={() => navigate('/onboarding/provider')}
          className="lg:w-5/12 w-full cursor-pointer p-4 border border-plannaPrimaryGreen rounded-lg mt-8 flex items-center justify-between"
          style={{borderColor: '#C2CDD0'}}>
          <img src={people} alt="people" className="h-fit w-fit" />
          <div className="p-2">
            <h1 className="text-lg text-plannaPrimaryGreen font-semibold">
              Get started as a business
            </h1>
            <p className="text-plannaPrimaryGreen">
              For businesses ready to simplify how their frontline workers
              <br /> work.
            </p>
          </div>
          <img src={circularForward} alt="forward" className="h-fit w-fit" />
        </div>
      </div>
    </>
  );
};

export default OnBoardingPage;
