import {format, addDays, subDays} from 'date-fns';
import moment from 'moment';
import React, {useCallback, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import Spinner from '../../../Components/Common/Spinner';
import SubmitSpinner from '../../../Components/Common/SubmitSpinner';
import SuccessLayout from '../../../Layouts/SuccessLayout';
import useAllocateWorkerProject from '../../../hooks/services/Agencys/Projects/useAllocateProjectWorkers';
import useGetAgencyProjectsAllocations from '../../../hooks/services/Agencys/Projects/useGetAgencyProjectAllocations';
import useGetWorkerProjectsAllocations from '../../../hooks/services/Agencys/Projects/useGetWorkerProjectAllocations';
import useGetAgencyProfile from '../../../hooks/services/Agencys/useGetAgencyProfile';
import useGetWorkers, {
  Availability,
  Data,
  TimeOff,
} from '../../../hooks/services/Agencys/useGetWorkers';

const getNextSevenDays = (startDate: Date): string[] => {
  const dates = [];
  for (let i = 0; i < 7; i++) {
    dates.push(format(addDays(startDate, i), 'yyyy-MM-dd'));
  }
  return dates;
};

const AllocateWorkersProject: React.FC = () => {
  const navigate = useNavigate();
  const {data: profile, isLoading: profileLoading} = useGetAgencyProfile();
  const params = useParams();
  const {
    data: workersData,
    isLoading: workersLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetWorkers({
    status: 'SEEKER',
    agency_id: profile !== undefined ? profile.uid : '',
    start_date: '2024-06-29',
    end_date: '2024-07-29',
    limit: 10,
  });
  const {mutateAsync, isLoading} = useAllocateWorkerProject();
  const {data, isLoading: allocationsLoading} = useGetAgencyProjectsAllocations(
    {uid: params.id}
  );
  const {data: workerAllocations, isLoading: workerAllocationsLoading} =
    useGetWorkerProjectsAllocations({
      agency_project_allocation__uid: params.id,
      status: status,
    });
  const [success, setSuccess] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const dates = useMemo(() => getNextSevenDays(startDate), [startDate]);
  const [selectedWorkers, setSelectedWorkers] = useState<string[]>(
    workersData?.pages.flatMap((page) =>
      page.results.map((worker) => worker.uid)
    ) || []
  );

  const handleHeaderCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      setSelectedWorkers(
        workersData?.pages.flatMap((page) =>
          page.results.map((worker) => worker.uid)
        ) || []
      );
    } else {
      setSelectedWorkers([]);
    }
  };

  const handleWorkerCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, uid: string) => {
      if (e.target.checked) {
        setSelectedWorkers((prevSelected) => [...prevSelected, uid]);
      } else {
        setSelectedWorkers((prevSelected) =>
          prevSelected.filter((id) => id !== uid)
        );
      }
    },
    []
  );

  const getAvailability = useCallback(
    (worker: Data, date: string): Availability | undefined => {
      return worker.worker_availability?.occurrences
        .map((occurence) => new Date(occurence))
        .map((occurenceDate) => occurenceDate.toISOString().slice(0, 10))
        .includes(date)
        ? worker.worker_availability
        : undefined;
    },
    []
  );

  const getTimeOff = useCallback((worker: Data, date: string): TimeOff[] => {
    const workerTimeOffs = worker.worker_availability?.worker_timeoff.filter(
      (timeOff) =>
        timeOff.occurrences
          .map((occurrence) => new Date(occurrence))
          .map((occurrenceDate) => occurrenceDate.toISOString().slice(0, 10))
          .includes(date)
    );

    return workerTimeOffs || [];
  }, []);

  const getCellContent = useCallback(
    (worker: Data, date: string) => {
      const availability = getAvailability(worker, date);
      const timeOff = getTimeOff(worker, date);
      if (availability || timeOff) {
        return (
          <div>
            {availability !== null && availability !== undefined ? (
              <div className=" bg-plannaSecondaryGreen50 cursor-pointer text-plannaNeutralWhite py-3 px-2 flex flex-col items-center mb-2">
                <p> Available</p>(
                {moment(availability?.start_time, 'HH:mm:ss').format('hA')} -
                {moment(availability?.end_time, 'HH:mm:ss').format('hA')})
              </div>
            ) : (
              <div className="bg-gray-200  text-plannaPrimaryGreen py-3 px-1 flex flex-col items-center mb-2">
                Unavailable
              </div>
            )}
            {timeOff.map((off) => (
              <div className=" bg-plannaTertiaryRed text-plannaNeutralWhite px-1 py-3 flex flex-col items-center mb-2">
                <p> {off.comment !== null ? off.comment : 'Time off'} </p>({' '}
                {moment(off.start_time, 'HH:mm:ss').format('hA')} -
                {moment(off.end_time, 'HH:mm:ss').format('hA')})
              </div>
            ))}
          </div>
        );
      }

      return (
        <div className="bg-gray-200  text-plannaPrimaryGreen py-3 px-1 flex flex-col items-center mb-3">
          Unavailable
        </div>
      );
    },
    [getAvailability, getTimeOff]
  );

  const handleNextWeek = () => {
    setStartDate((prevDate) => addDays(prevDate, 7));
  };
  const handlePreviousWeek = () => {
    setStartDate((prevDate) => subDays(prevDate, 7));
  };
  const isLoadingAll =
    profileLoading ||
    workersLoading ||
    allocationsLoading ||
    workerAllocationsLoading;

  if (isLoadingAll) {
    return <Spinner />;
  }
  const allocatedWorkerIds = workerAllocations?.results.map(
    (item) => item.worker.uid
  );
  const onAllocateWorker = async () => {
    const post = {
      workers_uid: selectedWorkers,
      agency_project_allocation_uid: params.id !== undefined ? params.id : '',
      start_date: data !== undefined ? data.results[0].project.start_date : '',
      end_date: data !== undefined ? data.results[0].project.start_date : '',
    };
    await mutateAsync(post).then(() => setSuccess(true));
  };

  return (
    <div className="overflow-x-auto mt-8">
      <SuccessLayout isOpen={success} onClose={() => setSuccess(false)}>
        <p>Worker allocated successfully</p>
      </SuccessLayout>
      <div className="flex items-end justify-end mb-8">
        <div className="flex items-end justify-between ">
          <div className=" p-4">
            <button
              onClick={onAllocateWorker}
              className=" bg-plannaPrimaryGreen text-plannaAccentGreen px-6 py-2 rounded-xl">
              {isLoading ? <SubmitSpinner /> : 'Allocate project'}
            </button>
          </div>
          <div className="p-4">
            {startDate > new Date() && (
              <button
                onClick={handlePreviousWeek}
                className="bg-plannaPrimaryGreen text-plannaAccentGreen py-1 px-3 mr-2 rounded ">
                {'<'}
              </button>
            )}
            <span className="font-bold text-sm">
              {moment(dates[0]).format('Do MMMM YYYY')} -{' '}
              {moment(dates[dates.length - 1]).format('Do MMMM YYYY')}
            </span>

            <button
              onClick={handleNextWeek}
              className="bg-plannaPrimaryGreen text-plannaAccentGreen py-1 px-3 rounded ml-2">
              {`>`}
            </button>
          </div>
        </div>
      </div>
      <table className="min-w-full bg-white border-collapse mt-8">
        <thead>
          <tr>
            <th className="border py-2 px-4 bg-gray-200 flex items-center">
              <input
                type="checkbox"
                className="mr-2 h-5 w-5 accent-plannaPrimaryGreen"
                onChange={handleHeaderCheckboxChange}
                checked={
                  selectedWorkers.length ===
                  workersData?.pages.flatMap((page) =>
                    page.results.map((worker) => worker.uid)
                  ).length
                }
              />
              Workers
            </th>
            {dates.map((date, index) => (
              <th
                key={index}
                className="border py-2 px-4 bg-gray-200  items-center">
                {date}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {workersData?.pages.map((page, pageIndex) => (
            <React.Fragment key={pageIndex}>
              {page.results.map((worker, index) => (
                <>
                  {!allocatedWorkerIds?.includes(worker.uid) && (
                    <tr key={index} className="border-t">
                      <td className="py-2 px-4 flex items-center">
                        <input
                          type="checkbox"
                          className="mr-2 h-5 w-5 accent-plannaPrimaryGreen"
                          onChange={(e) =>
                            handleWorkerCheckboxChange(e, worker.uid)
                          }
                          checked={selectedWorkers.includes(worker.uid)}
                        />
                        <div
                          onClick={() =>
                            navigate(`/agency/single-worker/${worker.uid}`)
                          }
                          className="flex space-x-3 items-center cursor-pointer">
                          <div className="bg-plannaPrimaryGreen flex items-center text-plannaAccentGreen rounded-full h-8 w-8 justify-center">
                            {worker.user.first_name.charAt(0)}
                            {worker.user.last_name.charAt(0)}
                          </div>
                          <span className="font-semibold text-plannaPrimaryGreen">
                            {worker.user.first_name} {worker.user.last_name}
                          </span>
                        </div>
                      </td>
                      {dates.map((date, idx) => (
                        <td key={idx} className="py-2 border px-4">
                          {selectedWorkers.includes(worker.uid) &&
                            getCellContent(worker, date)}
                        </td>
                      ))}
                    </tr>
                  )}
                </>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div>
        {hasNextPage ? (
          <button
            className=" mt-4 bg-plannaPrimaryGreen text-plannaAccentGreen px-6 py-2 rounded-full"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}>
            {isFetchingNextPage ? 'Loading more...' : 'Load more'}
          </button>
        ) : (
          <p className="mt-4 text-plannaPrimaryGreen" />
        )}
      </div>
    </div>
  );
};

export default AllocateWorkersProject;
