/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import Spinner from '../../Components/Common/Spinner';
import SubmitButton from '../../Components/Common/SubmitButton';
import Input from '../../Components/Registration/Input';
import ErrorHandlingLayout from '../../Layouts/ErrorHandlingLayout';
import SuccessLayout from '../../Layouts/SuccessLayout';
import circularBack from '../../assets/icons/Circular-Backward.png';
import useGetProviderProfile from '../../hooks/services/useGetProviderProfile';
import usePatchProvider from '../../hooks/services/usePatchProvider';

const BusinessDetails = () => {
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: {errors},
  } = useForm();
  const navigate = useNavigate();
  const {data, isLoading} = useGetProviderProfile();
  const {mutateAsync, isLoading: patchLoading} = usePatchProvider();
  const initialState = {
    vat_number: '',
    client_number: '',
    phone_number: '',
    uid: '',
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    if (data !== undefined) {
      setValue('company', data.company.name);
      setValue('vat_number', data.vat_number);
      setValue('client_number', data.client_number);
      setValue('phone_number', data.phone_number);
      setValue('city', `${data.location.region},${data.location.country.name}`);

      const {vat_number, client_number, phone_number, uid} = data;
      setFormData({
        vat_number,
        client_number,
        phone_number,
        uid,
      });
    }
  }, [data]);
  const [isOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const onClose = () => setIsOpen(false);
  const onErrorClose = () => setIsError(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const onSubmitBusinessDetails = async (values: any) => {
    const newValues = {...values, uid: formData.uid};
    await mutateAsync(newValues)
      .then(() =>
        toast.success('Business details updated successfully', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      )
      .catch(() =>
        toast.error('An error ocurred when updating your business details', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      );
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="mx-8">
      <ErrorHandlingLayout
        isOpen={isError}
        onClose={onErrorClose}
        title="Provider update error">
        <p className="mt-2 text-center">
          Some fields are null, please fill all the fields
        </p>
      </ErrorHandlingLayout>
      <SuccessLayout isOpen={isOpen} onClose={onClose}>
        <p className="text-lg font-bold">Business details updated</p>
        <p>The details have been updated sucessfully.</p>
      </SuccessLayout>
      <img
        onClick={() => navigate(-1)}
        src={circularBack}
        alt="back"
        className="h-12 w-12 mt-4  cursor-pointer"
      />
      <div className="flex flex-col justify-center items-center">
        <div className="lg:w-8/12 w-full">
          <h1 className="text-2xl text-plannaPrimaryGreen font-bold mt-9">
            Business Details
          </h1>
          <p className=" text-sm text-plannaPrimaryGreen mt-2 font-normal">
            Manage your business details
          </p>
          <form
            onSubmit={handleSubmit(onSubmitBusinessDetails)}
            className="form-action inner-max-width">
            <div className={`form-group${errors?.company ? ' error' : ''}`}>
              <label htmlFor="company" className="form-label">
                Company Name
              </label>
              <input
                type="text"
                {...register('company', {
                  required: 'Company name is required',
                })}
                disabled
                placeholder="Company Name"
                className="form-input"
              />
              {errors.company && (
                <span className="text-red-500 text-sm">
                  {String(errors?.company?.message)}
                </span>
              )}
            </div>

            <div className={`form-group${errors?.vat_number ? ' error' : ''}`}>
              <label htmlFor="company" className="form-label">
                Company VAT Number
              </label>
              <input
                type="text"
                {...register('vat_number', {
                  required: 'Company VAT number is required',
                })}
                placeholder="Company VAT Number"
                className="form-input"
              />
              {errors.vat_number && (
                <span className="text-red-500 text-sm">
                  {String(errors?.vat_number?.message)}
                </span>
              )}
            </div>

            <div
              className={`form-group${errors?.client_number ? ' error' : ''}`}>
              <label htmlFor="company" className="form-label">
                Company Registration Number
              </label>
              <input
                type="text"
                {...register('client_number', {
                  required: 'Company registration number is required',
                })}
                placeholder="Company Registration Number"
                className="form-input"
              />
              {errors.client_number && (
                <span className="text-red-500 text-sm">
                  {String(errors?.client_number?.message)}
                </span>
              )}
            </div>

            <div
              className={`form-group${errors?.phone_number ? ' error' : ''}`}>
              <label htmlFor="company" className="form-label">
                Company Phone Number
              </label>
              <input
                type="text"
                {...register('phone_number', {
                  required: 'Company phone number is required',
                })}
                placeholder="Company Phone Number"
                className="form-input"
              />
              {errors.phone_number && (
                <span className="text-red-500 text-sm">
                  {String(errors?.phone_number?.message)}
                </span>
              )}
            </div>

            <div className={`form-group${errors?.city ? ' error' : ''}`}>
              <label htmlFor="company" className="form-label">
                Company Location
              </label>
              <input
                type="text"
                {...register('city', {
                  required: 'Company location is required',
                })}
                disabled
                placeholder="Company Location"
                className="form-input"
              />
              {errors.city && (
                <span className="text-red-500 text-sm">
                  {String(errors?.city?.message)}
                </span>
              )}
            </div>

            <div className="mt-4">
              <SubmitButton buttonLabel="Save" isLoading={patchLoading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BusinessDetails;
