import Spinner from '../../../Components/Common/Spinner';
import useGetShifts from '../../../hooks/services/Shifts/UseGetShifts';
import useGetProviderProfile from '../../../hooks/services/useGetProviderProfile';
import {getDateAWeekAgo, getDateAWeekAhead} from '../../../utils/Methods';

const Table = () => {
  const {data: profile, isLoading: profileLoading} = useGetProviderProfile();
  const {data, isLoading} = useGetShifts(
    'PUBLISHED',
    profile !== undefined ? profile.uid : '',
    getDateAWeekAgo(),
    getDateAWeekAhead()
  );
  if (isLoading || profileLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                    Shift Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                    Shift status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                    Start
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                    End
                  </th>
                </tr>
              </thead>
              {data?.results.map((shift) => (
                <tbody className="divide-y divide-gray-200">
                  <tr>
                    <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                      {shift.name}
                    </td>

                    <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                      {shift.status}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                      {shift.start_time}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                      {shift.end_time}
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
