import moment from 'moment';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Spinner from '../../../Components/Common/Spinner';
import useGetWorkerProjectsAllocations from '../../../hooks/services/Agencys/Projects/useGetWorkerProjectAllocations';
type Props = {
  project_id: string;
};
const ProjectStaffedWorkers = ({project_id}: Props) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState('CONFIRMED');
  const {data, isLoading} = useGetWorkerProjectsAllocations({
    agency_project_allocation__uid: project_id,
    status: status,
  });
  if (isLoading) return <Spinner />;
  return (
    <div className="p-4">
      <div className="flex justify-end items-center text-xs space-x-2 mt-4 mb-6">
        <button
          onClick={() => setStatus('AWAITING_CONFIRMATION')}
          className={`${
            status !== 'AWAITING_CONFIRMATION' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Awaiting confirmation
        </button>
        <button
          onClick={() => setStatus('CONFIRMED')}
          className={`${
            status !== 'CONFIRMED' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Confirmed
        </button>

        <button
          onClick={() => setStatus('ENDED')}
          className={`${
            status !== 'ENDED' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Ended
        </button>
        <button
          onClick={() => setStatus('IN_PROGRESS')}
          className={`${
            status !== 'IN_PROGRESS' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          In progress
        </button>
        <button
          onClick={() => setStatus('DECLINED')}
          className={`${
            status !== 'DECLINED' ? 'opacity-30' : ''
          }bg-plannaTertiaryGrey text-plannaPrimaryGreen px-4 py-1 rounded-full`}>
          Declined
        </button>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className=" bg-plannaSecondaryGreen100 text-plannaPrimaryGreen">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Worker name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Project name
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Worker start date
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left  uppercase ">
                      Worker end date
                    </th>
                  </tr>
                </thead>
                {data?.results.map((worker, index) => (
                  <tbody key={index} className="divide-y divide-gray-200">
                    <tr>
                      <td
                        onClick={() =>
                          navigate(`/agency/single-worker/${worker.worker.uid}`)
                        }
                        className="px-6 cursor-pointer py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {worker.worker.user.first_name}{' '}
                        {worker.worker.user.last_name}
                      </td>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {worker.agency_project_allocation.project.title}
                      </td>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {}
                        {worker.start_at !== null
                          ? moment(worker.start_at).format(
                              'DD MMMM YYYY hh:mm:ss A'
                            )
                          : 'Not started'}
                      </td>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {worker.stop_at !== null
                          ? moment(worker.stop_at).format(
                              'DD MMMM YYYY hh:mm:ss A'
                            )
                          : 'Not Ended'}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectStaffedWorkers;
