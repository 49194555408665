import {useMutation, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

interface CreatePersonalDetailsParams {
  first_name?: string;
  last_name?: string;
  nationality?: string;
  marital_status?: string;
  pronoun?: string;
  worker_uid: string;
  date_of_birth?: string;
  has_medical_condition?: boolean;
  medical_condition?: string;
  is_trained?: boolean;
  is_student?: boolean;
  has_criminal_conviction?: boolean;
  opt_in?: boolean;
  national_insurance?: string;
}

const createPersonalDetailsParams = async (
  data: CreatePersonalDetailsParams
) => {
  const response = await axiosInstance.patch(
    `/workers/${data.worker_uid}/personal-details`,
    data
  );

  return response;
};

const useUpdatePersonalDetails = (): // eslint-disable-next-line
UseMutationResult<any, Error, CreatePersonalDetailsParams> => {
  return useMutation(createPersonalDetailsParams, {
    onSuccess: () => {
      //invalidate ['worker', id] query
    },
  });
};

export default useUpdatePersonalDetails;
