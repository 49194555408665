import React from 'react';

import ShiftApprovals from './Components/ShiftApprovals';

const Attendance = () => {
  return (
    <div className="p-4">
      <div className="mt-8">
        <h1 className="text-black font-bold text-xl">Shift Approvals</h1>
        <p className="text-black mt-2 text-sm">
          View shift information with real-time insights and streamlined views
          on worker attendance
        </p>
        <div className="mt-8">
          <ShiftApprovals />
        </div>
      </div>
    </div>
  );
};

export default Attendance;
