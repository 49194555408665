import React from 'react';

import {capitalizeFirstLetter} from '../../utils/Methods';

type Props = {
  inputValue: string | number | undefined;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label: string;
  name: string;
  options: string[];
  required?: boolean;
  demo?: boolean;
  readonly?: boolean;
};

const Select = ({
  inputValue,
  onChange,
  label,
  name,
  options,
  required,
  demo,
  readonly,
}: Props) => {
  return (
    <div className=" w-full">
      <label
        htmlFor="inputField"
        className="block mb-3 text-sm font-medium text-plannaPrimaryGreen">
        {label}
        {required && <span className="text-red-600 ml-1">*</span>}
      </label>
      <select
        aria-readonly={readonly}
        required={required}
        value={inputValue}
        onChange={onChange}
        name={name}
        className={`${
          demo ? 'w-full' : 'lg:w-3/4 w-full'
        }  text-plannaPrimaryGreen border border-plannaNeutral rounded-lg py-2 px-3`}>
        <option selected>Select an option</option>
        {options.map((option) => {
          return (
            <option key={option} value={option}>
              {capitalizeFirstLetter(option.toLowerCase())}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select;
