import React from 'react';

import Logo from '../../assets/icons/planna-logo-white.png';
import Appstore from '../../assets/images/app-store.png';
import FooterImage from '../../assets/images/footer-image.png';
import GooglePlay from '../../assets/images/googleplay.png';
const Footer = () => {
  return (
    <>
      {/* <div className="footer-container">
        <div className="footer-header">
          <h1>Workers can download the app</h1>
          <div className="footer-content">
            <div className="col">
              <p>
                Our frontline worker app allows staff to manage their shifts,
                communicate with managers,
                <br /> complete digital training and manage their
                <br /> finances.
              </p>
              <div className="footer-apps">
                <img src={GooglePlay} alt="googleplay" />
                <img src={Appstore} alt="applestore" />
              </div>
            </div>

            <div className="col image">
              <img src={FooterImage} alt="footer image" />
            </div>
          </div>
        </div>
        <div className="footer-body">
          <div className="top-container">
            <div className="col">
              <div className="footer-logo">
                <img src={Logo} alt="planna" />
              </div>
              <div className="footer-links">
                <p>Product</p>
                <p>Company</p>
                <p>Support</p>
              </div>
            </div>
            <div className="col">
              <h1 className="title">How can we help?</h1>
              <p className="subtitle">Help Docs & FAQs</p>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-border" />
          <p>2024 Planna. All Rights Reserved</p>
        </div>
      </div> */}
      <div className="relative bottom-0 left-0 right-0">
        <div className="flex md:absolute left-[90px] justify-center items-center z-30 bg-plannaAccentGreen md:rounded-lg text-black  md:w-10/12">
          <div className="flex flex-col md:flex-row  justify-center items-center">
            <div className="flex flex-col md:text-left text-center">
              <h1 className="text-xl font-semibold font-hanken-grotesk md:text-2xl md:font-bold text-black p-4">
                Workers can download the app
              </h1>
              <p className="mt-4 text-black p-4">
                Our frontline worker app allows staff to manage their shifts,
                communicate with managers,
                <br /> complete digital training and manage their
                <br /> finances.
              </p>
              <div className="flex justify-center md:justify-start lg:space-x-4 mt-4 space-x-2">
                <img src={GooglePlay} alt="googleplay" className="h-fit" />
                <img src={Appstore} alt="applestore" className="h-fit" />
              </div>
            </div>
            <img src={FooterImage} alt="footer image" className="h-fit w-fit" />
          </div>
        </div>

        <div className="bg-plannaPrimaryGreen md:p-10 pt-14 text-white  md:absolute z-20  md:top-60 w-full">
          <div className="flex md:flex-row flex-col md:justify-between md:items-end items-center justify-center w-full">
            <div className="">
              <div className="md:mt-44">
                <img src={Logo} alt="planna" className="h-12 w-fit" />
              </div>
              <div className="flex md:flex-row items-center md:items-start space-x-6 mt-4">
                <p className=" text-plannaNeutral">Product</p>
                <p className=" text-plannaNeutral">Company</p>
                <p className=" text-plannaNeutral">Support</p>
              </div>
            </div>
            <div className="text-right">
              <h1 className="text-white md:font-bold md:text-3xl mt-4">
                How can we help?
              </h1>
              <h1 className="font-semibold text-plannaAccentGreen mt-4">
                Help Docs & FAQs
              </h1>
            </div>
          </div>

          <div className=" border border-plannaNeutral mt-4 w-full" />
          <p className="mt-4 text-plannaNeutral">
            2024 Planna. All Rights Reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
