import React, {useState} from 'react';
import {FaList} from 'react-icons/fa';
import {FaCalendarDays} from 'react-icons/fa6';

import Shifts from '.';
import ShiftTemplatesCalendar from './ShiftTemplatesCalendar';

const ShiftTemplates = () => {
  const [isCalendar, setIsCalendar] = useState(false);
  return (
    <div className="p-4">
      <h1 className="text-plannaPrimaryGreen font-bold text-xl">Shifts</h1>

      <div className="flex justify-end items-center space-x-4 mr-16">
        <button
          onClick={() => setIsCalendar(false)}
          className={`${
            !isCalendar
              ? ' bg-plannaAccentGreen text-plannaPrimaryGreen'
              : ' bg-plannaNeutral text-plannaPrimaryGreen '
          } px-6 py-2 rounded-3xl flex  items-center`}>
          <FaList className="mr-2" />
          Draft shifts schedules
        </button>
        <button
          onClick={() => setIsCalendar(true)}
          className={`${
            isCalendar
              ? ' bg-plannaAccentGreen text-plannaPrimaryGreen'
              : ' bg-plannaNeutral text-plannaPrimaryGreen'
          } px-6 py-2 rounded-3xl flex  items-center`}>
          <FaCalendarDays className="mr-2" />
          Assigned shifts schedules
        </button>
      </div>
      {isCalendar ? <Shifts /> : <ShiftTemplatesCalendar />}
    </div>
  );
};

export default ShiftTemplates;
