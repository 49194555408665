import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

interface AllocateWorkerParams {
  agency_shift_allocation_uid: string;
  workers_uid: string[];
  start_date: string;
  recurrence: string;
  allocation_type: string;
}

interface ResponseData {
  message: string;
  overlapping_workers: string[];
}

interface ErrorData {
  errors: string;
  error_code: string;
}

interface Response {
  data: ResponseData;
}

interface Error {
  response: {
    data: ErrorData;
  };
}

const AllocateWorkerShift = async (
  data: AllocateWorkerParams
): Promise<ResponseData> => {
  const response = await axiosInstance.post<ResponseData>(
    '/shifts/worker-allocations/',
    data
  );
  return response.data;
};

const useAllocateWorkerShift = (): UseMutationResult<
  ResponseData,
  Error,
  AllocateWorkerParams
> => {
  const queryClient = useQueryClient();

  return useMutation(AllocateWorkerShift, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient.invalidateQueries('shifts').catch((error: Error) => {
        throw new Error(error.response.data.errors);
      });
    },
  });
};

export default useAllocateWorkerShift;
