import React from 'react';
import {useNavigate} from 'react-router-dom';

import NavbarLayout from './NavbarLayout';
import Button from '../Components/LandingPage/Button';
import circularBack from '../assets/icons/Circular-Backward.png';

type Props = {
  children: React.ReactNode;
  title?: string;
  step?: number;
  handlePrevious?: () => void;
  provider?: boolean;
};
const OnBoardingLayout = ({
  children,
  title,
  step,
  handlePrevious,
  provider,
}: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <NavbarLayout>
        <div className="block lg:inline-block mt-4 lg:mt-0 lg:ml-4">
          <Button label="Help" bg="plannaPrimaryGreen" text="white" />
        </div>
      </NavbarLayout>
      <div
        className="mt-2 p-4"
        style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        <div
          className="flex justify-between"
          style={{
            maxWidth: '582px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}>
          {step === 1 || provider ? (
            <img
              onClick={() => navigate(-1)}
              src={circularBack}
              alt="back"
              className="h-12 w-12  cursor-pointer"
            />
          ) : (
            <img
              onClick={handlePrevious}
              src={circularBack}
              alt="back"
              className="h-12 w-12  cursor-pointer"
            />
          )}
        </div>
      </div>
      <div className="onboarding-container">
        <h1 className="form-title inner-max-width mt-3 text-left text-plannaPrimaryGreen  text-xl font-bold">
          {title}
        </h1>
        {children}
      </div>
    </>
  );
};

export default OnBoardingLayout;
