import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

interface AcceptShiftApllicationParams {
  worker_shift_application_uid: string;
  action: 'ACCEPT' | 'REJECT';
}
type Data = {
  errors: string;
  error_code: string;
};
type Error = {
  data: Data;
};
type Response = {
  response: Error;
};
const acceptShiftApplication = async (data: AcceptShiftApllicationParams) => {
  const response = await axiosInstance.post(
    '/shifts/worker-applications/action/',
    data
  );

  return response;
};

const useAcceptShiftApplication = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Response,
  AcceptShiftApllicationParams
> => {
  const queryClient = useQueryClient();

  return useMutation(acceptShiftApplication, {
    onSuccess: () => {
      queryClient
        .invalidateQueries('workerapplications')
        .catch((error: Response) => {
          throw new Error(error.response.data.errors);
        });
    },
  });
};

export default useAcceptShiftApplication;
