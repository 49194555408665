import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from '../AxiosInstance';

interface AcceptShiftInvitationParams {
  agency_shift_allocation_uid: string | undefined;
  action: string | undefined;
  margin?: string | undefined;
  margin_type?: string | undefined;
  holiday_pay?: number | undefined;
  other_costs?: string | undefined;
  employers_ni?: number | undefined;
  pension_contribution?: string | undefined;
  action_by: string | undefined;
}
type Data = {
  errors: string;
  error_code: string;
};
type Error = {
  data: Data;
};
type Response = {
  response: Error;
};
const acceptShiftInvitation = async (data: AcceptShiftInvitationParams) => {
  const response = await axiosInstance.post(
    '/shifts/agency-allocations/action/',
    data
  );

  return response;
};

const useAcceptShiftInvitation = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Response,
  AcceptShiftInvitationParams
> => {
  const queryClient = useQueryClient();

  return useMutation(acceptShiftInvitation, {
    onSuccess: () => {
      queryClient
        .invalidateQueries('singleAllocation')
        .catch((error: Response) => {
          throw new Error(error.response.data.errors);
        });
    },
  });
};

export default useAcceptShiftInvitation;
