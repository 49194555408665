import {useMutation, UseMutationResult, useQueryClient} from 'react-query';

import axiosInstance from '../AxiosInstance';

interface SyncWorkersToWagestreamParams {
  agency_id: string;
}

const syncWorkersToWagestream = async (data: SyncWorkersToWagestreamParams) => {
  const response = await axiosInstance.post(
    'workers/worker-wage-stream/',
    data
  );

  return response;
};

const useSyncWorkersToWagestream = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Error,
  SyncWorkersToWagestreamParams
> => {
  const queryClient = useQueryClient();
  return useMutation(syncWorkersToWagestream, {
    onSuccess: () => {
      queryClient
        .invalidateQueries()
        // eslint-disable-next-line
        .catch((error: any) => {
          // eslint-disable-next-line
          throw new Error(error.message);
        }); // Adjust the key as needed
    },
  });
};

export default useSyncWorkersToWagestream;
