import React from 'react';
import {Route, Routes} from 'react-router-dom';

import LandingRedirect from './LandingRedirect';
import ProtectedRoutes from './ProtectedRoutes';
import FindJobs from '../Components/LandingPage/FindJobs';
import Home from '../Components/LandingPage/Home';
import OnBoardingPage from '../Components/LandingPage/OnBoarding';
import PrivacyPolicy from '../Components/LandingPage/PrivacyPolicy';
import RequestDemo from '../Components/LandingPage/RequestDemo';
import PasswordReset from '../Components/Registration/PasswordReset';
import ThankYouNotRegistered from '../Components/Registration/ThankYouNotRegistered';
import ThankYouPage from '../Components/Registration/ThankYouPage';
import AboutPage from '../Screens/About';
import CreateAgency from '../Screens/Agency/CreateAgency';
import Blog from '../Screens/Blogs';
import OnBoarding from '../Screens/Onboarding';
import AppDownloadPage from '../Screens/Onboarding/AppDownloadPage';
import Login from '../Screens/Onboarding/Login';
import OuterBoxVerification from '../Screens/Onboarding/OuterboxVerification';
import ResetPassword from '../Screens/Onboarding/ResetPassword';
import ResetPasswordAcknowledgement from '../Screens/Onboarding/ResetPasswordAcknowledgement';
import OuterBoxThankYou from '../Screens/Onboarding/ThankYou';
import VerifyEmail from '../Screens/Onboarding/VerifyEmail';
import Worker from '../Screens/Onboarding/Worker';
import ProductPage from '../Screens/Product';
import SupportPage from '../Screens/Support';

const RoutesComponent = () => {
  return (
    <>
      <ProtectedRoutes />
      <Routes>
        <Route element={<LandingRedirect />}>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/" element={<ProductPage />} />
        </Route>

        <Route path="/find-jobs" element={<FindJobs />} />
        <Route path="/onboarding" element={<OnBoardingPage />} />
        <Route path="/onboarding/provider" element={<OnBoarding />} />
        <Route path="/onboarding/thank-you" element={<ThankYouPage />} />
        <Route
          path="/onboarding/thank-you-not-registered"
          element={<ThankYouNotRegistered />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/verify-email/:token" element={<VerifyEmail />} />
        <Route path="/app-download" element={<AppDownloadPage />} />
        <Route
          path="/outerbox/verify-email/:token"
          element={<OuterBoxVerification />}
        />
        <Route path="/outerbox/app-download" element={<OuterBoxThankYou />} />
        <Route path="/request-demo" element={<RequestDemo />} />

        <Route path="/new/agency" element={<CreateAgency />} />
        <Route path="/agency/new/:id" element={<CreateAgency />} />

        <Route path="/onboarding/worker" element={<Worker />} />
        <Route path="/onboarding/worker/:id" element={<Worker />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/forget-password/:id" element={<ResetPassword />} />
        <Route path="/reset-password" element={<PasswordReset />} />
        <Route
          path="/reset-password-acknowledgement"
          element={<ResetPasswordAcknowledgement />}
        />
        <Route path="/blog/:slug" element={<Blog />} />
      </Routes>
    </>
  );
};

export default RoutesComponent;
