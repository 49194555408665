import React from 'react';

import Input from '../../../Components/Registration/Input';
type BankDetailsType = {
  bank_name: string;
  account_name: string;
  account_number: string;
  sort_code: string;
};
type Props = {
  bankDetails: BankDetailsType | undefined;
};
const BankDetails = ({bankDetails}: Props) => {
  return (
    <div className="p-4 w-full">
      {bankDetails !== null ? (
        <>
          <div className="flex ">
            <Input
              label="Bank name"
              type="text"
              name="bank_name"
              inputValue={bankDetails?.bank_name ?? ''}
              readonly
            />
            <Input
              label="Account name"
              type="text"
              name="account_name"
              inputValue={bankDetails?.account_name ?? ''}
              readonly
            />
          </div>
          <div className="flex mt-4">
            <Input
              label="Account number"
              type="text"
              name="account_number"
              inputValue={bankDetails?.account_number ?? ''}
              readonly
            />
            <Input
              label="Sort code"
              type="text"
              name="sort_code"
              inputValue={bankDetails?.sort_code ?? ''}
              readonly
            />
          </div>
        </>
      ) : (
        <h1>Bank details are currently null</h1>
      )}
    </div>
  );
};

export default BankDetails;
