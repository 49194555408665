import {useMutation, useQueryClient, UseMutationResult} from 'react-query';

import axiosInstance from '../../AxiosInstance';

interface AllocateWorkerParams {
  agency_project_allocation_uid: string;
  workers_uid: string[];
  start_date: string;
  end_date: string;
}

interface ResponseData {
  message: string;
  overlapping_workers: string[];
}

interface ErrorData {
  errors: string;
  error_code: string;
}

interface Response {
  data: ResponseData;
}

interface Error {
  response: {
    data: ErrorData;
  };
}

const AllocateWorkerShift = async (
  data: AllocateWorkerParams
): Promise<ResponseData> => {
  const response = await axiosInstance.post<ResponseData>(
    '/projects/project-worker-allocation/',
    data
  );
  return response.data;
};

const useAllocateWorkerProject = (): UseMutationResult<
  ResponseData,
  Error,
  AllocateWorkerParams
> => {
  const queryClient = useQueryClient();

  return useMutation(AllocateWorkerShift, {
    onSuccess: () => {
      // Invalidate and refetch relevant queries after a successful mutation
      queryClient
        .invalidateQueries('workersallocation')
        .catch((error: Error) => {
          throw new Error(error.response.data.errors);
        });
    },
  });
};

export default useAllocateWorkerProject;
