import React from 'react';

import Footer from './Footer';
import HeroSection from './HeroSection';
import JobCard from './JobCard';
import Navbar from './Navbar';
import FindJobsImage from '../../assets/images/find-jobs.png';

const FindJobs = () => {
  const jobs = [
    {
      name: 'Warehouse Operative',
      location: 'London, UK',
    },
    {
      name: 'Warehouse Operative',
      location: 'London, UK',
    },
    {
      name: 'Warehouse Operative',
      location: 'London, UK',
    },
    {
      name: 'Warehouse Operative',
      location: 'London, UK',
    },
    {
      name: 'Warehouse Operative',
      location: 'London, UK',
    },
    {
      name: 'Warehouse Operative',
      location: 'London, UK',
    },
    {
      name: 'Warehouse Operative',
      location: 'London, UK',
    },
  ];
  return (
    <>
      <div className="main-container">
        <Navbar />
        <HeroSection currentPage="findJobs" />

        <div className="container-max-width">
          <div className="flex flex-col mt-8 space-y-2 justify-center items-center mb-20">
            <JobCard jobs={jobs} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FindJobs;
